import React, { useState } from 'react';
import DpMenu from '../../Menu/DpMenu';
import DpHeader from '../../Header/DpHeader';
import { ToastContainer } from 'react-toastify';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { GoFilter, GoSearch } from 'react-icons/go';
import {
    BsChevronDown,
    BsFillChatLeftDotsFill,
    BsSquare,
    BsThreeDotsVertical,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { PiUploadSimpleLight } from 'react-icons/pi';
import { MdOutlineHelp } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';
import { LiaAngleUpSolid } from 'react-icons/lia';
import { Accordion } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { AccordionDetails } from '@mui/material';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LuCalendarDays } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot'; // No need to import 'botAvatar' here

const theme = {
    background: '#fff',
    headerBgColor: '#FF8E00',
    headerFontColor: '#fff',
    headerFontSize: '18px',
    botFontColor: '#555555',
    userBubbleColor: '#FF8E00',
    userFontColor: '#fff',
    borderRadius: '4px',
    border: '1px solid black',
};

const HelpAndSupport = () => {
    const navigate = useNavigate();
    const HelpNaviagte = () => {
        navigate('/home');
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const customAccordionClass = {
        border: 'none', // Remove the border
    };

    const [chatbotOpen, setChatbotOpen] = useState(true); // Initial chatbot state

    const openChatbot = () => {
        setChatbotOpen(true);
    };

    const closeChatbot = () => {
        setChatbotOpen(false);
    };

    const renderSummary = (steps) => {
        const { name, gender, age } = steps;

        return (
            <div className='bg-[#EF6C00]' style={{ width: '100%' }}>
                <h3>Summary</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{name.value}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{gender.value}</td>
                        </tr>
                        <tr>
                            <td>Age</td>
                            <td>{age.value}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const steps = [
        {
            id: '1',
            message: 'Hi, Partner! I can help you with:',
            trigger: '2',
        },
        {
            id: '2',
            options: [
                { value: 1, label: 'Adding a Screen', trigger: '3' },
                { value: 2, label: 'Uploading Contents', trigger: 'content' },
                { value: 3, label: 'Scheduling Contents', trigger: 'contents' },
                { value: 4, label: 'Linking Bank A/C', trigger: '3' },
                { value: 5, label: 'Tracking Earnings', trigger: '3' },
            ],
        },
        {
            id: 'content',
            message: 'To upload a content, navigate to the "My Contents" section. Click on the "Add Content" button, a pop-up screen will appear, you can upload your desired content either photo or video. Once added, the content will get displayed on my content section.',
            trigger: '4'

        },
        {
            id: 'contents',
            message: 'To schedule a content, navigate to the "My screens" section. Click on the "screen" that you want to place content. Screen page will open, you can upload photo/video in the desired slot either current or tomorrow  directly from your desktop or saved contents.Once added, you can assign the content play now or schedules tomorrow. Congrats your content is scheduled!',
            trigger: '4'

        },
        {
            id: '3',
            message: 'To add a new screen, navigate to the "My Screens" section. Click on the "Add New Screen" button, where you can enter the screens pairing code displayed on the screen and other details such as name and location. Once submitted, your screen will automatically get paired. ',
            trigger: '4',
        },
        {
            id: '4',
            message: 'Are you satisfied with above results? ',
            trigger: '5'

        },
        {
            id: '5',
            options: [
                { value: 1, label: 'yes', trigger: 'msg' },
                { value: 2, label: 'No', trigger: '6' },
            ],
        },
        {
            id: '6',
            message: 'Sorry to hear that.If you need any further assistance. we are here to help you have an elite-class experiance',
            trigger: '7',
        },
        {
            id: '7',
            options: [
                { value: 1, label: 'Leave Your Message', trigger: 'name' },
                { value: 2, label: 'View Website', trigger: 'link' },
            ],
        },
        {
            id: 'name',
            user: true,
            trigger: '8',
        },
        {
            id: '8',
            message: 'Your Message has been shared with  the team, you will be reverted back shortly. Thank you for your Time!',
            end: true
        },
        {
            id: 'link',
            component: (

                <a href="https://www.everidoor.com">www.everidoor.com </a>
            ),
            asMessage: true,
            trigger: '9',
        },
        {
            id: '9',

            message: 'Thank you for your Time!',
            end: true
        },

        {
            id: 'msg',
            message: "Thank you for your positive feedback! 😊 We're glad to hear that you're satisfied with the results. If you have any more questions or need assistance with anything else, please feel free to ask. We're here to help!",
            trigger: 'rating',
        },
        {
            id: 'rating',
            message: "How much would you rate us from 1 to 5 with the help you received?",
            trigger: 'rating1',
        },
        {
            id: 'rating1',
            options: [
                { value: 1, label: '1 ☆', trigger: 'after-rating' },
                { value: 2, label: '2 ☆', trigger: 'after-rating' },
                { value: 3, label: '3 ☆', trigger: 'after-rating' },
                { value: 4, label: '4 ☆', trigger: 'after-rating' },
                { value: 5, label: '5 ☆', trigger: 'after-rating' },
            ],
        },
        {
            id: 'after-rating',
            message: "Thank you so much for rating us. We appreciate your time.",
            end: true
        },


    ];


    return (
        <>
            <div className="h-screen flex w-screen">
                <DpMenu />
                <div className="h-[100%] overflow-hidden w-[100%]  bg-[#FFFDF9]  ">
                    <ToastContainer />
                    <DpHeader />
                    <div className=" flex items-center p-4">
                        <span onClick={HelpNaviagte} className="text-[#555555] cursor-pointer">
                            Home
                        </span>{' '}
                        <IoChevronForwardOutline /> Help & Support
                    </div>
                    <div className="bg-[#FFF5E7] flex mx-4 items-center h-16">
                        <div className="w-[52%] flex justify-between">
                            <MdOutlineHelp className="mx-8 text-[#FFE8CC]" size={36} />
                            <p className="text-[#555555] text-[28px]"> Help & Support</p>
                        </div>
                    </div>
                    <div className="w-[100%]  h-[70%] mx-4 my-4 flex">
                        <div className="w-[75%] h-[100%]">
                            <div className="w-[100%] h-12 rounded-[4px] bg-white border-[#FFE8CC] flex items-center gap-4 hover:shadow-md">
                                <AiOutlineSearch size={24} className="text-[#FE7E02] mx-2" />
                                <input
                                    type="search"
                                    placeholder="Search..."
                                    className="placeholder-[#FE7E02] w-[100%] outline-none"
                                />
                            </div>
                            <p className="my-12 text-[#555555] text-[20px]">Dashboard Queries</p>
                            <div className="flex flex-col gap-4   w-[100%]">
                                <Accordion style={{ border: 'none', boxShadow: '0px 4px 6px rgba(255, 241, 235, 0.3)' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography style={{ color: '#555555', fontWeight: '500' }}>
                                            How do I add a new display to the platform?
                                        </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails
                                        style={{ border: 'none', boxShadow: 'none', fontWeight: '400' }}
                                    >
                                        <Typography style={{ color: '#555555', borderBottom: 'none' }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="w-[25%] mx-4">
                            <ul className="flex flex-col gap-4">
                                <ul>
                                    <Link
                                        // to={"/personalInfo"}
                                        className="flex items-center mx-4 px-2 py-2 rounded-md  w-[80%]  text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-[#555555] "
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Dashboard Queries
                                    </Link>
                                </ul>
                                <ul>
                                    <Link
                                        // to={"/personalInfo"}
                                        className="flex items-center mx-4 px-2 py-2 rounded-md  w-[84%]  text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-[#555555] "
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Earnings & Withdrawals
                                    </Link>
                                </ul>
                                <ul>
                                    <Link
                                        // to={"/personalInfo"}
                                        className="flex items-center mx-4 px-2 py-2 rounded-md  w-[80%]   text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-[#555555] "
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Support & Assistance
                                    </Link>
                                </ul>
                                <ul>
                                    <Link
                                        // to={"/personalInfo"}
                                        className="flex items-center mx-4 px-2 py-2 rounded-md w-[80%] text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-red visited:bg-yellow"
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Guidelines
                                    </Link>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <ThemeProvider theme={theme}>
                {chatbotOpen && (
                    <ChatBot
                        headerTitle={"Support Chat"}
                        hideUserAvatar={true}
                        hideBotAvatar={true}
                        speechSynthesis={true}
                        customStyle={true}
                        steps={steps}
                        floating={true}
                        floatingIcon={<BsFillChatLeftDotsFill size={24} className="text-white" />}
                        onFloatingButtonClick={closeChatbot}

                    />
                )}
            </ThemeProvider>
        </>
    );
};

export default HelpAndSupport;