import React,{useEffect, useState , useRef} from 'react'
import { useParams } from 'react-router-dom';
import Axios from '../Axios/axios'
import { io } from "socket.io-client";
import ReactPlayer from "react-player";
function ShareCompaignUrl() {
    const Websocket = io('https://vkvp4qqj-4001.inc1.devtunnels.ms/');
    const videoRef = useRef(null);
    Websocket.on('connect', () => {
        console.log('Websocket ID:', Websocket.id);
    });   
    const  {screenId , username}  = useParams();

    const baseURL = "https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/";
    const [videoUrl, setvideoUrl] = useState(baseURL + '00000001.mp4');

    Websocket.on("device-disconnecting", ({ status}) => {
      console.log("Connectivity Status Update " + status);
      if( status == false){
          setvideoUrl(baseURL + '00000001.mp4');
      }
    });
  
  Websocket.emit("join-device-room", {
    username: username,
    screenId: screenId,
  });
  Websocket.emit("get-current-video", {
    username: username,
    screenId: screenId,
  });

  console.log(
    "get-current-video emiited from mydisplay***********",
    username,
    screenId
  );
  Websocket.on("ack-get-current-video", ({ contentId, seekTo }) => {
    Websocket.off("get-current-video");
    console.log("Current Video Details------->>>>>>>>>>");
    console.log("contentid from ack-get-current-video", contentId);
    setvideoUrl(baseURL + contentId + ".mp4");
    console.log('inside ack-get-current-video--------------->>>>>>>>>>>>',videoUrl);
    videoRef.current?.seekTo(seekTo / 1000)
  });
  console.log("ack-get-current-video called------------->");
  Websocket.on("update-video-on-webpage", ({ contentId }) => {
    console.log("Update Video on webpage");
    console.log(contentId);
    setvideoUrl(baseURL + contentId + ".mp4");
  });
    

   
  return (
    <div style={{ display: 'flex', justifyContent: 'center' , alignItems:'center' , height:'100vh' }}>
      
      <ReactPlayer
        ref={videoRef}
        url={videoUrl}
        controls={false}
        loop={"true"}
        playing={"true"}
        className="cursor-pointer "
        />
    
    </div>
  )
}

export default ShareCompaignUrl