import React, { useState,useEffect } from "react";
import Header from "../../Header/AdHeader";
import { BsFilter } from "react-icons/bs";
import Menu from "../Menu";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../../Axios/axios";

export default function Account() {
    const navigate = useNavigate();
    
    const handledescription = (campId) => {
        navigate("/advDescription?campId=" + campId);
    };
    

    return (
        <>
            <div className="flex w-full  overflow-hidden">
                <Menu />

                <div className="w-full  overflow-hidden">
                    <Header />
                    <div className="bg-[#f2f2f2] border-t-2" style={ {overflowY: "auto"}}>
                        <div className="w-full p-4 rounded-lg mt-8"  style={{ maxHeight: "640px", minHeight:"600px"}}>
                            {/* <Billing /> */}
                            <Invoices />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Invoices() {
    const [datas , setDatas] = useState([]);
    const username = sessionStorage.getItem('username');
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get(`advertisementPayHistrory?username=rudra@gmail.com`);
                console.log(response);
                setDatas(response.data);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }

        fetchData();

    }, [username]);
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        console.log("Search Text:", e.target.value);
    };
    const filteredData = searchText === '' ? datas : datas.filter(item => item.orderId.toLowerCase().includes(searchText.toLowerCase()));
    const formatInvoiceDate = (date) => {
        if (date) {
            const [datePart, timePart] = date.split('T');
            return datePart;
        }
        return '';
    };

    return (
        <div className="mt-8 m-8">
            <div className="flex justify-between items-center">
                <h2 className="text-3xl text-red-400">Invoices</h2>
                <div className="flex justify-between">
                    {/* SEARCH BAR START */}
                    <div class="max-w-md ">
                        <div class="relative flex items-center w-full rounded-full focus-within:shadow-lg bg-white overflow-hidden border-2 border-gray-200">
                            <div class="grid place-items-center h-full w-12 text-gray-300">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </div>
                            <input
                                class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 p-2"
                                type="text"
                                id="search"
                                placeholder="Search invoices.."
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    {/* SEARCH BAR END */}
                    <div className="flex gap-2 items-center mx-4">
                        <p>Found: 0 results</p>
                        <button class="hover:bg-white text-gray-800 font-semibold py-2 px-4 rounded">
                            <BsFilter className="text-2xl" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-4" >
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="table-header border-b-2 mb-4">
                        <tr>
                            <th className="py-4">Invoice No.</th>
                            <th scope="col">Issue Date</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
            
                    <tbody className="">
                    {filteredData.map((item,index)=>(
                        <tr className="" key={index}>
                            <td className="py-4">{item.orderId}</td>
                            <td className="py-4">{formatInvoiceDate(item.date)}</td>
                            <td className="py-4">{item.amount}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
