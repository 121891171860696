import AdminHeader from "../adminHeader";
import React, { useState, useEffect, useMemo } from "react";
import Axios from "../../Axios/axios";
import Menu from "../../Menu/Menu";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Tabs from "../Tabs";
import { Link } from "react-router-dom";
import Otp from "../Component/Otp";

function Module2() {
    const [currentTab, setCurrentTab] = useState("1");
    const [open, setOpen] = useState(true);
    const [arr, setArr] = useState([]);
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/");
    };
    const [pending, setPending] = useState([]);
    const [approved, setApproved] = useState([]);
    const [rejected, setRejected] = useState([]);
    const [expired, setExpired] = useState([]);
    const [emergency, setEmergency] = useState([]);

    useEffect(() => {
        // Fetch JSON data during page loading
        const fetchData = async () => {
            try {
                const response = await Axios.get("getInbox");
                setPending(response.data.allTypes.Pending);
                setApproved(response.data.allTypes.Approved);
                setRejected(response.data.allTypes.Rejected);
                setExpired(response.data.allTypes.Expired);
                setEmergency(response.data.allTypes.Emergency);
                setTotalReq(response.data.allTypes.Approved.length + response.data.allTypes.Emergency.length +response.data.allTypes.Expired.length +response.data.allTypes.Pending.length +response.data.allTypes.Rejected.length )
                console.log(response, "data set successfully");
                setCurrentPage(1);
            } catch (error) {
                console.error("Error fetching JSON data:", error);
            }
        };
        fetchData();
    }, []);

    const [currentPage, setCurrentPage] = useState(0);
    const PageSize = 16;
    const currentTableData = (arr, currentPage) => {
        const firstPageIndex = currentPage * PageSize - 1;
        const lastPageIndex = firstPageIndex + PageSize;
        return arr.slice(firstPageIndex, lastPageIndex);
    };
    const [pendingPageData, setPendingPageData] = useState([]);
    const [approvedPageData, setApprovedPageData] = useState([]);
    const [rejectedPageData, setRejectedPageData] = useState([]);
    const [expiredPageData, setExpiredPageData] = useState([]);
    const [emergencyPageData, setEmergencyPageData] = useState([]);

    const nPages = (arr) => {
        return Math.ceil(arr.length / 16);
    };

    const [totalReq, setTotalReq] = useState();
    useEffect(() => {
        setTotalReq(0);
    }, [currentTab]);
    const [otp, setOtp] = useState(false)
    useEffect(() => {
        const data = currentTableData(approved, currentPage);
        console.log("data for approved button with current page----------->>>>>>.",data);
        setApprovedPageData(data);
    }, [currentPage]);
    useEffect(() => {
        const data = currentTableData(expired, currentPage);
        setExpiredPageData(data);
    }, [currentPage]);
    return (
        <div className="w-full">
            <div className="flex bg-gray-100 text-black">
                <div className="w-fit">
                    <Menu
                        otp={otp} setOtp={setOtp}
                    />
                </div>
                <div className="w-full h-[88vh]">
                    <AdminHeader />
                    <div className="h-[12vh]">
                        <div className="w-full flex items-center py-4 px-2">
                            <Link
                                to={"/AdminDashboard"}
                                className="text-[#8E8E8E] px-2"
                            >
                                HOME
                            </Link>
                            <span>
                                <MdKeyboardArrowRight />
                            </span>
                            <Link
                                to={"/admindashboard/usersinbox"}
                                className="text-[#555555] px-2"
                            >
                                Campaign Requests
                            </Link>
                        </div>
                        <div className="flex justify-between items-center px-4">
                            <p className="text-[24px] w-fit flex gap-4 items-center">
                                <MdKeyboardArrowLeft />
                                Advertiser Campaign Requests
                            </p>
                            <p className="font-bold">
                                Total Campaign Requests: {totalReq}
                            </p>
                        </div>
                    </div>
                    <div className="">
                     {otp ? <Otp/> :<Tabs
                            pending={pending}
                            approved={approved}   //approvedPageData
                            rejected={rejected}
                            expired={expiredPageData}
                            emergency={emergency}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                        />}
                    </div>
                    <div className="bg-white w-full h-[12vh]">
                        <button
                            type="button"
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Module2;
