import React, { useState } from "react";
import { BiLogOutCircle } from "react-icons/bi";
import { RxDashboard } from "react-icons/rx";
import { VscFileSubmodule } from "react-icons/vsc";
import { FaDesktop, FaInbox } from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import Edlogo from "../Images/everydoor.png";

const Menu = ({otp, setOtp}) => {
    const menu = [

        // { title: "Dashboard",
        //  icons: <RxDashboard size={20} />,
        //   to: "/AdminDashboard", gap: true 
        // },
        {
            title: "All Screens",
            icons: <FaDesktop size={24} />,
            to: "/adminDashboard/screensdetails",
            click: false
        },
        {
            title: "Campaign Requests",
            icons: <FaInbox size={24} />,
            to: "/admindashboard/usersinbox",
            click: true,
        },
        {
            title: "My Content",
            icons: <RiAdvertisementFill size={24} />,
            to: "/admindashboard/mycampaign",
            click: false
        },
        {
            title: "All Users",
            icons: <FiUsers size={24} />,
            to: "/admindashboard/module4",
            click: false
        },
        {
            title: "DP Content Requests",
            icons: <VscFileSubmodule size={24} />,
            to: "/admindashboard/module5",
            click: false
        },
        {
            title: "Add Users",
            icons: <VscFileSubmodule size={24} />,
            to: "/admindashboard/createUsers",
            click: false
        },
        {
            title: "Push Notifications",
            icons: <VscFileSubmodule size={24} />,
            to: "/admindashboard/pushnotifications",
            click: false
        }
    ];
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);

    return (
        <div className="h-full">
            <div
                className={`${open ? "w-[300px]" : "w-24"
                    } p-5 pt-3 border bg-white ease-in-out duration-500 relative h-screen shadow-lg`}
            >
                <div className="flex mb-6">
                    <div className="h-28">
                        <img className="w-64 h-26 " src={Edlogo} alt="" />
                        <hr class="w-38 h-1 bg-gray-500 opacity-80 rounded  dark:bg-gray-500" />
                    </div>
                </div>
                <div
                    className={`origin-left text-[16px] font-semibold text-[#001122] items-center gap-1 ease-in-out duration-300 ${!open && "scale-24"
                        }`}
                >
                    <div
                        className={`origin-left text-black font-bold items-center gap-1 ease-in-out duration-700`}
                    >
                        <div
                            className={`bg-gray-100 mx-auto p-4 ease-in-out duration-700 rounded-xl ${open ? "w-64" : "w-18"
                                } ${open ? "h-14" : "h-14"}`}
                        >
                            <span
                                className={`origin-left ease-in-out ${!open && "hidden"
                                    } ${open ? "ml-2" : "ml-0"
                                    }  duration-500 cursor-default tracking-wide text-[15px] font-bold text-[#340c42]`}
                            >
                                ADMIN PANEL
                            </span>
                            <BsArrowLeftCircleFill
                                size={32}
                                className={`absolute ease-in-out duration-500 cursor-pointer top-[160px]  ${!open && "rotate-180"
                                    } ${open ? "left-56 " : "left-8"}`}
                                onClick={() => setOpen(!open)}
                            />
                        </div>
                    </div>
                    <ul
                        className={`${open ? "": "flex flex-col items-center justify-center" }`}
                    >
                        <li
                            className={`mt-4 flex items-center text-black gap-x-4  rounded-md cursor-pointer transition-all duration-500 hover:bg-[#fe4d00] hover:text-white`}
                        >
                            <button type="button" className="w-full h-full">
                                <Link
                                    to="/admindashboard"
                                    className="flex items-center gap-2"
                                >
                                    <span>
                                        {" "}
                                        <RxDashboard size={25} />
                                    </span>
                                    <p
                                        className={`${open ? "" : "hidden scale-0"
                                            } origin-center`}
                                    >
                                        Dashboard
                                    </p>
                                </Link>
                            </button>
                        </li>
                        {menu.map((item, index) => (
                            <li
                                key={index}
                                className={`${item.gap ? "mt-4" : "mt-4"
                                    } flex items-center text-black gap-x-4  rounded-md cursor-pointer transition-all duration-500 hover:bg-[#fe4d00] hover:text-white`}
                            >
                                <button
                                // onClick={() => item?.click ? setOtp(false) : null}
                                type="button"
                                    className="w-full h-full text-left"
                                >
                                    <a
                                        href={item.to? `${item.to}` : ''}
                                        className="flex items-center gap-2"
                                    >
                                        <span>{item.icons}</span>
                                        <p
                                            className={`${open ? "" : "hidden scale-0"} origin-center`}
                                        >
                                            {item.title}
                                            {/* {console.log(item.to)} */}
                                        </p>
                                    </a>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Menu;
