import React, { useContext, useState } from "react";
import { CiTrash } from "react-icons/ci";
import "./Inbox_box.scss";
import Axios from "../../Axios/axios";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import { SocketContext } from "../../../socketcontext";
import { useNavigate } from "react-router-dom";


const buttonStyle =
    "w-full rounded-full border-solid border border-[#FF4D00] text-[#FF4D00] transition-all hover:bg-[#FF4D00] hover:text-white";

const DpInbox = ({
    total_amount,
    status,
    holderName_1,
    Account_Name,
    username,
    RequireAmount,
    Content

}) => {
    const navigate = useNavigate();

    const handleView = () => {
        navigate(`/view?campId=${holderName_1}`);
    };

    
   

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const WebSocket = useContext(SocketContext);
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

  

    const handleReject = async () => {
        try {
            const Reject = await Axios.post("payingDP", {
                status: "Rejected",
                username: username,
            });
            // if (Reject.data.Message === "Status updated to rejected") {
            //     alert("Request Rejected");
            // } else {
            //     alert("Something went wrong");
            // }
            console.log("rejected");
        } catch (err) {
            console.log(err);
        }
    };

    const handleApprove = async () => {
        try {
            console.log(status);
            console.log(username);
            const approveRes = await Axios.post("payingDP", {
                status: "Approved",
                username: username,
            });
            console.log(approveRes);
            console.log(approveRes);

        } catch (e) {
            alert("Server Error. Try again later");
        }
    };

    const modalStyles = {
        overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        content: {
            position: "relative",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
            border: "none",
            borderRadius: "8px",
            padding: "0",
            width: "calc(100% - 300px)", // Adjust the value as needed
            maxWidth: "800px", // Adjust the value as needed
            maxHeight: "calc(100% - 300px)", // Adjust the value as needed
            margin: "200px",
            background: "#000",
        },
    };

    return (
        <>
            <div className="p-4 h-[460px] w-[312px] rounded-xl border-2 shadow-lg bg-white mb-8 flex flex-col justify-between mx-4">
                <div>
                    <div className="w-[280px] h-[180px] overflow-hidden rounded-lg border-2">
                        <img
                            src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKxQscCLJb_ee0LP-2SPiIGVa4jTacAwdaqUQIpRbyLO-c71RRXilv3bMj_7OilXtHHJk&usqp=CAU"}
                            alt="img"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="mt-4">
                        <h4 className="text-xl font-bold">{""}</h4>
                        <p></p>
                        <h5 className="text-md text-gray-500 mt-2">
                            <span className="text-black font-medium">
                                Total Amount:{" "}
                            </span>
                            {total_amount}
                        </h5>
                        <div className="mt-2 text-md text-gray-500">
                            {/* <p>Req To: {selectedDisplayProviderId}, </p> */}
                            <p>
                                <span className="text-black font-medium">
                                    Request Amount:
                                </span>{RequireAmount}

                            </p>
                            <p>
                                <span className="text-black font-medium">
                                    Account Number:
                                </span>{Account_Name}
                                {""}
                            </p>
                            <p>
                                <span className="text-black font-medium">
                                    Account Holder Name:
                                </span>{holderName_1}
                                {""}
                            </p>
                            <p>
                                <span className="text-black font-medium">
                                    Contact:
                                </span>{Content}
                                {""}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    {status === "pending" && (
                        <div className="flex gap-2">
                            {/* <button
                                className={buttonStyle}
                                onClick={handleView}
                            >
                                View
                            </button> */}
                            <button
                                className={buttonStyle}
                                onClick={handleApprove}
                            >
                                Approve
                            </button>
                            <button
                                className={buttonStyle}
                                onClick={handleReject}
                            >
                                Reject
                            </button>
                        </div>
                    )}
                    {status === "Approved" && (
                        <div className="down">
                            <button
                                className={buttonStyle}
                                onClick={""}
                            >
                                View
                            </button>
                        </div>
                    )}
                    {status === "Rejected" && (
                        <div className="down">
                            <button
                                className={buttonStyle}
                                onClick={""}
                            >
                                View
                            </button>
                        </div>
                    )}
                   
                </div>

                <ReactModal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Video Modal"
                    className="modal"
                    style={modalStyles}
                    ariaHideApp={false}
                >
                    <ReactPlayer
                        url={""}
                        playing={modalIsOpen}
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                    <button className="close-button" onClick={closeModal}>
                        <span className="close-cross"></span>
                    </button>
                </ReactModal>
            </div>
        </>
    );
};

export default DpInbox;
