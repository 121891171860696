import React, { useState, useCallback } from "react";
import { BiHelpCircle } from "react-icons/bi";
import { BsDisplay } from "react-icons/bs";
import { RiFolderUploadLine } from "react-icons/ri";
import {
    MdOutlineKeyboardArrowLeft,
    MdOutlineManageAccounts,
    MdSpaceDashboard,
} from "react-icons/md";
import { RxCalendar } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Edlogo from "../../Components/Images/everydoor.png";
import { VscAccount } from "react-icons/vsc";
import { AiOutlineTeam } from "react-icons/ai";

const DpMenu = (props) => {

    const navigate = useNavigate();
    const handlePublisher = () => {
        navigate("/PublisherAccount");
    };
    const [open, setOpen] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState(() => {
        return parseInt(sessionStorage.getItem("selectedMenuItem")) || 0;
    });
    const location = useLocation();
    const menu = [
        {
            title: "Dashboard",
            icons: <MdSpaceDashboard size={20} />,
            to: "/Publisher",
        },
        {
            title: "My Screens",
            icons: <BsDisplay size={20} />,
            to: "/PublisherScreens", // Route for My Displays
        },

        {
            title: "My Content",
            icons: <RiFolderUploadLine size={20} />,
            to: "/PublisherContent",
        },

        {
            title: "Schedules",
            icons: <RxCalendar size={20} />,
            to: "/PublisherSchedule",
        },

    ];
 const handleMenuItemClick = useCallback((index) => {
        console.log(index);
        setSelectedMenuItem(index);
        sessionStorage.setItem("selectedMenuItem", index);
    }, []);

    return (
        <div className="h-full">
        <div
            className={`${open ? "w-[290px]" : "w-24"
                } p-4 pt-3 bg-white ease-in-out duration-500 flex-col gap-5 items-start relative h-screen shadow-lg shadow-[#FFF1EB] shadow-opacity-[30%] `}
        >
            <div className={` ease-in-out duration-500 cursor-default tracking-wide ${open ? "w-[90%] h-[42px]" : "w-[90%]"} flex items-center justify-center   mb-8`}>
                <img className="h-[110px] w-[85%]  " src={Edlogo} alt="everidoor" />
            </div>

            <div
                className={` origin-left  text-xl text-black  items-center gap-1  ease-in-out duration-300 ${!open && "scale-24"
                    }`}
            >
                <div
                    className={` origin-left w-[260px]  text-xl  font-bold flex   items-center gap-1  ease-in-out duration-700 `}
                >
                    <div className={` ${open ? "ml-1" : "ml-0"
                        } p-3 border-b-[0.5px]  border-[#DEDEDE] ease-in-out duration-700 flex items-center  ${open ? "w-[250px]" : "w-[70px]"} ${open ? "h-12" : "h-12"}`}
                    >
                        <span className={`origin-left ease-in-out ${!open && "hidden"} ${open ? "ml-2" : "ml-0"} font-thin text-[#555555] duration-500 cursor-default tracking-wide  `} >
                            Display Network
                        </span>
                        <MdOutlineKeyboardArrowLeft
                            size={24}
                            className={`absolute ease-in-out text-white bg-[#FF8E00] rounded-full  duration-500 cursor-pointer top-[160px]  ${!open && "rotate-180"
                                } ${open ? "left-[15rem]  top-28 " : "left-9 top-30"}`}
                            onClick={() => setOpen(!open)}
                        />
                    </div>
                </div>
                <ul className={`${open ? "" : "flex flex-col items-center justify-center"}`}>
                    {menu.map((item, index) => (
                        <li
                            key={index}
                            className={`${item.gap ? "mt-4" : "mt-4"} flex items-center gap-x-4 rounded-[4px] cursor-pointer transition-all duration-500`}
                        >
                            <button
                                type="button"
                                onClick={() => handleMenuItemClick(index)}
                                className={`w-full  hover:bg-[#F6F6F6] hover:text-[#555555]  hover:text-red visited:bg-yellow hover:text-[#FF8E00]" h-full ${selectedMenuItem === index ? "bg-[#FFF5E7] font-semibold text-[#FF8E00] "
                                    : "text-[#555555]"
                                    }`}
                            >
                                <Link to={item.to} className="flex items-center gap-2">
                                    <span>{item.icons}</span>
                                    <p className={`${open ? "" : "hidden scale-0 "} origin-center text-[18px]`}>
                                        {item.title}
                                    </p>
                                </Link>
                            </button>
                        </li>
                    ))}
                    <li className={`flex items-center mt-3  w-full`}>
                        <details class="group [&_summary::-webkit-details-marker]:hidden w-full origin-center ">
                            <summary
                                className={`flex items-center w-full p-3 hover:bg-[#F6F6F6] hover:text-[#555555]  origin-center text-[#555555] rounded-[4px] cursor-pointer transition-all duration-500 gap-2`}
                            >
                                <span class="shrink-0 ml-2">
                                    <MdOutlineManageAccounts size={20} />
                                </span>
                                <span
                                    class={`text-xl  font-medium ${open ? "" : "hidden scale-0"
                                        }  origin-center text-[18px]`}
                                >
                                    {" "}
                                    Account{" "}
                                </span>
                            </summary>

                            <ul class=" space-y-1 px-4 py-1 ">
                                <li
                                    className={` w-full transition-all ease-in-out duration-700 ${open
                                        ? ""
                                        : "hidden scale-0 transition-all ease-in-out duration-700"
                                        } `}
                                >
                                    <Link
                                            to={"/PublisherAccount"}
                                            class="flex items-center  rounded-lg  w-full  text-[18px] font-medium text-gray-500 hover:bg-white hover:text-[#FF8E00]"
                                        >
                                            <VscAccount size={20} className="mr-2 ml-2" />{" "}
                                            Personal Info
                                        </Link>
                                </li>
                            </ul>
                        </details>
                    </li>
                    <li className={`flex items-center w-full p-3 pl-4  hover:bg-[#F6F6F6] text-[18px] hover:text-[#555555] origin-center text-[#555555]  rounded-[4px] cursor-pointer transition-all duration-500 gap-2`}
                    >
                        <Link
                            to={"/Publisherhelp"}
                            className={`origin-left w-full h-full flex gap-2 items-center`}
                        >
                            <BiHelpCircle size={24} />
                            <p
                                className={`${open ? "" : "hidden"
                                    } origin-center transition-opacity delay-500`}
                            >
                                Help & Support
                            </p>
                        </Link>

                    </li>

                </ul>
            </div>
        </div>
    </div>
    );
};

export default DpMenu;