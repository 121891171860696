import React, { useContext, useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import ContentImg from "../Images/Rectangle.png";
import DpHeader from "../Header/DpHeader";
import Axios from "../Axios/axios";
import { LuStopCircle } from "react-icons/lu";
import {
    CiBrightnessUp,
    CiPause1,
    CiPlay1,
} from "react-icons/ci";
import ReactPlayer from "react-player";
import { BsBootstrapReboot,  } from "react-icons/bs";
import { SocketContext } from "../../socketcontext";
import DpMenu from "../Menu/DpMenu";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineClose, AiOutlineInfoCircle, AiOutlineSetting } from "react-icons/ai";
import {
    MdOutlineScreenLockLandscape,
    MdScreenLockPortrait,
} from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import ContentSelect from "./ContentSelect";
import { BiEdit } from "react-icons/bi";
import ContentSelectForAd from "./ContentSelectForAd";

const   Description = ({ }) => {
    const videoRef = useRef(null);
    const baseURL = "https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/";
    const [videoUrl, setvideoUrl] = useState(baseURL);
    const [play_pause, setPlayPause] = useState(false);
    const [hover, setHover] = useState(false);

    const navigate = useNavigate();
    const onlyOnce = 1;
    const [screenDets, setScreenDets] = useState({
        screenId: "905385",
        screenName: "Everidoor",
        screenSize: "2280x1080",
        OS: "Android",
        date_of_addition: "8/4/123, 22:8:25",
        screen_address: "Test",
        SCREEN_ADDRESS_FROM_PHONE: "New Delhi",
        last_known_location: ["", ""],
        screenType: "none",
        volume: 0,
        brightness: 0,
        connectivity_status: false,
        playlistId: "",
        play_pause: false,
        ongoing_video: "",
        _id: "645925814d7db76703927c52",
    });

    useEffect(() => {
        async function details() {
            console.log("Getting Screen Details");
            const result = await Axios.get(
                "getScreenDetails?username=" +
                username +
                "&screenId=" +
                screenId
            ).then((response) => {
                    console.log(response.data.screenDetails, "screenDetails data ------>>>>");
                    setScreenDets(response.data.screenDetails);
                    setPlayPause(response.data.screenDetails.play_pause);
                    Websocket.emit("join-device-room", {
                        username: username,
                        screenId: screenId,
                    });
                    console.log("Device Joined Room--------->>>>");
                    Websocket.on("device-disconnecting", ({ status }) => {
                        console.log("Connectivity Status Update " + status);
                        setScreenDets({
                            ...response.data.screenDetails,
                            connectivity_status: status,
                        });
                    });
                    Websocket.emit("get-current-video", {
                        username: username,
                        screenId: screenId,
                    });
                    Websocket.on(
                        "ack-get-current-video",
                        ({ contentId, play_pause_status, seekTo }) => {
                            Websocket.off("get-current-video");
                            console.log("Current Video Details");
                            console.log(contentId, play_pause_status, seekTo);
                            setPlayPause(play_pause_status);
                            setvideoUrl(baseURL + contentId + ".mp4");
                            videoRef.current?.seekTo(seekTo / 1000);
                        }
                    );
                })
                .finally(() => {
                    console.log("Screen Details Updated");
                });
        }
        details();
        Websocket.on("update-video-on-webpage", ({ contentId }) => {
            console.log("Update Video on webpage");
            console.log(contentId);
            setvideoUrl(baseURL + contentId + ".mp4");
        });
    }, [onlyOnce]);
    const [vol, setVol] = useState({})
    const handleVol = (volume) => {
        setActive(1);
        console.log(screenDets);
        console.log("Volume Changed");
        setScreenDets({
            ...screenDets,
            volume: parseInt(volume),
        });
        const d = {
            username: username,
            screenId: screenId,
            volumeVal: volume,
        };
        setVol(d);
        
    };
    console.log(vol);
    const [bright, setBright] = useState({})
    const handleBrightness = (bright) => {
        setActive(1);
        setScreenDets({
            ...screenDets,
            brightness: parseInt(bright),
        });
        const d = {
            username: username,
            screenId: screenId,
            brightnessVal: bright,
        };
        setBright(d);
    };
console.log(bright);
    const removeDevice = async () => {
        const d = {
            username: username,
            screenId: screenDets.screenId,
        };
        Websocket.emit("stop_device", d);
        const result = await Axios.post("removeScreen", {
            username: username,
            screenId: screenDets.screenId,
        });
        navigate("/home");
    };

    // const handleStop = () => {
    //     console.log("reboot clicked---------------->>>>>>>>>>>>>>>");
    //     const d = {
    //         username: username,
    //         screenId: screenDets.screenId,
    //     };
    //     Websocket.emit("stop_device", d);
    //     console.log("stop_device called------------>>>>>>>>>>>>");
    // };

    const handlePlay = () => {
        const d = {
            username: username,
            screenId: screenId,
        };
        console.log("Emitting Play Pause" + d);
        Websocket.emit("play_pause", d);
        setPlayPause(!play_pause);
    };

    const [imagePreview, setImagePreview] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const filePicekerRef = useRef(null);

    function previewFile(e) {
        const reader = new FileReader();
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
        reader.onload = (readerEvent) => {
            if (selectedFile.type.includes("image")) {
                setImagePreview(readerEvent.target.result);
            } else if (selectedFile.type.includes("video")) {
                setVideoPreview(readerEvent.target.result);
            }
        };
    }
    function clearFiles() {
        setImagePreview(null);
        setVideoPreview(null);
    }

    const fileInputRef = useRef(null);

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
    };

    const loc = useLocation();
    const params = new URLSearchParams(loc.search);
    const screenId = params.get("screenId");
    const username = sessionStorage.getItem("username");
   

    const notify = (text, type) => {
        if (type === "success") {
            toast.success(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            toast.error(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const Websocket = useContext(SocketContext);

    const [content, setContent] = useState([
        {
            title: "Food - Tactos",
            duration: "00:00",
            advertiser: "Oceanic Airlines Ad",
            thumbnailPath: "",
            contentId: "ididid",
        },
    ]);
    const [showContentSelect, setShowContentSelect] = useState(false);
    const close = () => {
        setShowContentSelect(false);
    };
    const [slot, setSlot] = useState(0);

    //FOR DP SLOTS
    const [initialValue, setInitialValue] = useState([
        {
            advertiser: "Everidoor",
            contentId: "000000000",
            sameAdvertisementForNextnSlots: 1,
            booked: "Not Booked",
        },
        {
            advertiser: "Everidoor",
            contentId: "000000000",
            sameAdvertisementForNextnSlots: 1,
            booked: "Not Booked",
        },
    ]);
    const [dpSlots, setDpSlots] = useState([
        {
            advertiser: "Everidoor",
            contentId: "000000000",
            sameAdvertisementForNextnSlots: 1,
            booked: "Not Booked",
        },
        {
            advertiser: "Everidoor",
            contentId: "000000000",
            sameAdvertisementForNextnSlots: 1,
            booked: "Not Booked",
        },
    ]);

    async function getScreenSlotsDetails() {
        console.log("getScreenSlotsDetails called========================?????????????????????");
        await Axios.post("getScreenSlotsDetails", {
            username: username,
            screenId: screenId,
        }).then((res) => {
            console.log("dpslots------------------------>>>>>>>>>>>>>>", res);
            setContent(res.data.contentDetails);
            setDpSlots(res.data.slots);
            setInitialValue(res.data.slots);
            setSelected(res.data.slots.contentId);
            setSelectedTwo(res.data.slots.contentId);
        });
    }

    async function dpSlotBooking() {
        console.log({
            username: username,
            screenId: screenId,
            slotArray: dpSlots,
        });
        await Axios.post("dpSlotBooking", {
            username: username,
            screenId: screenId,
            slotArray: dpSlots,
        }).then(async function (response) {
            console.log("response", response);
            if (response.status === 200) {
                notify("Content scheduled successfully!", "success");
            } else {
                alert("Error");
            }
        });
    }

    async function dpInstantBooking() {
        console.log("Instant Booking called by DP -------------------------")
        Websocket.emit("dp-instant-booking", {
            username: username,
            screenId: screenId,
            slotArray: dpSlots,
        });
        Websocket.on("success-dpSlotBooking", () => {
            notify("Content Assigned Successfully!", "success");
            Websocket.off("success-dpSlotBooking");
        });
        Websocket.on("failure-dpSlotBooking", () => {
            notify("Error Assigning Content");
            Websocket.off("failure-dpSlotBooking", "failure");
        });
        console.log("Instant Booking done");
    }

    async function dpAdSlotBooking() {
        console.log("----------------------------------",adSlotContent);
        Websocket.emit("dp-advertiser-instant-booking", adSlotContent);
        notify("Content Assigned on available slots!", "success");
        Websocket.on("success-DP-Advertiser-SlotBooking", () => {
            notify("Content Assigned on available slots!", "success");
            console.log("Instant Booking for Ad Slots done");
        });
    }

    Websocket.emit("join-device-room", {
        username: username,
        screenId: screenId,
    });
    console.log("Device Joined Room");

    useEffect(() => {
        getScreenSlotsDetails();
        findDuration();
        findDurationTwo();
        titleSetter(dpSlots[0].contentId);
        titleSetter2(dpSlots[1].contentId);
    }, []);

    const [selected, setSelected] = useState("");
    const [selectedTwo, setSelectedTwo] = useState("");
    const [title, setTitle] = useState(dpSlots[0].advertiser);
    const [titleTwo, setTitleTwo] = useState(dpSlots[1].advertiser);
    const [selectedDuration, setSelectedDuration] = useState(10);
    const [selectedDurationTwo, setSelectedDurationTwo] = useState(10);

    function setEveridoor(i) {
        // Make copy of existing array
        const updatedDpSlots = initialValue;

        if (i === 0) {
            updatedDpSlots[i] = {
                advertiser: username,
                contentId: selected,
                sameAdvertisementForNextnSlots: 1,
                booked: "Booked",
            };
        } else if (i === 1) {
            updatedDpSlots[i] = {
                advertiser: "Everidoor",
                contentId: "000000000",
                sameAdvertisementForNextnSlots: 1,
                booked: "Booked",
            };
        }
        // Set state to new array
        setDpSlots(updatedDpSlots);
    }

    //main setter function
    function updateDpSlots(i, selectedContentId, selectedDuration) {
        setDisableButtons(false);
        const updatedDpSlots = initialValue;
        //when slot 1 is selected and duration < 10
        if (i === 0 && selectedDuration <= 10) {
            updatedDpSlots[0] = {
                advertiser: username,
                contentId: selectedContentId,
                sameAdvertisementForNextnSlots: 1,
                booked: "Booked",
            };
            setSelectedDuration(selectedDuration);
        }
        //when slot 1 has duration > 10
        if (i === 0 && selectedDuration > 10) {
            updatedDpSlots[0] = {
                advertiser: username,
                contentId: selectedContentId,
                sameAdvertisementForNextnSlots: 2,
                booked: "Booked",
            };
            updatedDpSlots[1] = {
                advertiser: username,
                contentId: selectedContentId,
                sameAdvertisementForNextnSlots: 2,
                booked: "Booked",
            };
            setSelectedDuration(selectedDuration);
            setShowSlotTwoDetails(false);
        }
        //when slot 2 is selected and duration < 10
        if (i === 1 && selectedDuration <= 10) {
            updatedDpSlots[1] = {
                advertiser: username,
                contentId: selectedContentId,
                sameAdvertisementForNextnSlots: 1,
                booked: "Booked",
            };
            setSelectedDurationTwo(selectedDuration);
        }
        if (i === 1 && selectedDuration > 10) {
            alert("Content for Slot 2 cannot be more than 10 seconds.");
        }

        // Set state to new array
        console.log("updated dp slots are", updatedDpSlots);
        setDpSlots(updatedDpSlots);
    }

    const [disableButtons, setDisableButtons] = useState(true);

    function titleSetter(id) {
        for (let i = 0; i < content.length; i++) {
            if (id === content[i].contentId) {
                setTitle(content[i].title);
            }
        }
    }
    function titleSetter2(id) {
        for (let i = 0; i < content.length; i++) {
            if (id === content[i].contentId) {
                setTitleTwo(content[i].title);
            }
        }
    }
    const findDuration = () => {
        for (let i = 0; i < content.length; i++) {
            if (dpSlots[0].contentId === content[i].contentId) {
                setSelectedDuration(content[i].duration);
            }
        }
    };
    const findDurationTwo = () => {
        for (let i = 0; i < content.length; i++) {
            if (dpSlots[1].contentId === content[i].contentId) {
                setSelectedDurationTwo(content[i].duration);
            }
        }
    };

    const [showSlotTwoDetails, setShowSlotTwoDetails] = useState(true);

    useEffect(() => {
        titleSetter(dpSlots[0].contentId);
        titleSetter2(dpSlots[1].contentId);
        findDuration();
        findDurationTwo();
        if (title === titleTwo) {
            setShowSlotTwoDetails(false);
        }
        if (selectedDuration <= 10) {
            setShowSlotTwoDetails(true);
        }

        console.log(dpSlots);
    }, [dpSlots, selectedDuration, selectedDurationTwo]);

    function handleTomorrow(e) {
        console.log(dpSlots);
        if (selectedDuration <= 10 && selectedDurationTwo > 10) {
            alert("Please select a video less than 10 seconds for Slot 2");
        } else if (selectedDuration > 10 && selectedDurationTwo > 10) {
            dpSlotBooking();
        } else if (selectedDuration <= 10 && selectedDurationTwo <= 10) {
            dpSlotBooking();
        } else if (selectedDuration <= 10 && selectedTwo === "") {
            setEveridoor(1);
            dpSlotBooking();
        }
    }
    function handleInstant(e) {
        if (selectedDuration <= 10 && selectedDurationTwo > 10) {
            alert("Please select a video less than 10 seconds for Slot 2");
        } else if (selectedDuration > 10 && selectedDurationTwo > 10) {
            dpInstantBooking();
        } else if (selectedDuration <= 10 && selectedDurationTwo <= 10) {
            dpInstantBooking();
        } else if (selectedDuration <= 10 && selectedTwo === "") {
            setEveridoor(1);
            dpInstantBooking();
        }
    }

    //for advertiser slot booking
    const emptySlots = 4;
    const [showContentSelectForAd, setShowContentSelectForAd] = useState(false);
    const closeAdSelect = () => {
        setShowContentSelectForAd(false);
    };

    const [adSlotContent, setAdSlotContent] = useState({
        username: username,
        screenId: screenId,
        duration: 0,
        content_to_be_played: "000000000",
    });

    const [adSlotContentTitle, setAdSlotContentTitle] =
        useState("Select content");
    const [adSlotContentDuration, setAdSlotContentDuration] = useState(0);

    const updateAdSlotContent = (
        selectedContentId,
        selectedDuration,
        selectedTitle
    ) => {
        setAdSlotContent({
            username: username,
            screenId: screenId,
            duration: selectedDuration,
            content_to_be_played: selectedContentId,
        });
        setAdSlotContentDuration(selectedDuration);
        setAdSlotContentTitle(selectedTitle);
    };
    useEffect(() => {
        console.log(adSlotContent);
    }, [adSlotContent]);

    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        if (adSlotContentTitle === "Select content") {
            if (screenDets.EmaptySlotforDP === 0) {
                setDisabled(true);
            }
            setDisabled(true);
        } else {
            if (screenDets.EmaptySlotforDP === 0) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }
    }, [disabled, screenDets, adSlotContentTitle]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarOpen1, setIsSidebarOpen1] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const toggleSidebar1 = () => {
        setIsSidebarOpen1(!isSidebarOpen1);
    };
    const [brightness, setBrightness] = useState(50); // Initial brightness value
    const [volume, setVolume] = useState(50); // Initial volume value
    const [active, setActive] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [buttonUpdate, setButtonUpdate] = useState("Save Settings");
    const handleBrightnessChange = (event) => {
        setBrightness(event.target.value);
        setActive(1);
    };

    const handleVolumeChange = (event) => {
        setVolume(event.target.value);
        setActive(1);
    };
    const handleButtonChange = () => {
        setActive(1);
        Websocket.emit("update-volume", vol);
        Websocket.emit("update-brightness", bright);
        setButtonUpdate('✓  Changes updated');

    }
    const handleInputFieldChange = (event) => {
        setActive(1);
        setInputValue(event.target.value);
    };
    const clearInputField = () => {
        setInputValue(''); 
        setActive(0);
        setButtonUpdate('Save Settings')
    };
    const brightnessTrackColor = ` linear-gradient(90deg, #FF8E00 ${brightness} %, #ccc ${brightness} %)`;
    const volumeTrackColor = `linear-gradient(90deg, #FF8E00 ${volume} %, #ccc ${volume} %)`;
    const thumbColor = '#fff';
    const rangeStyles = {
        brightnessTrack: {
            background: brightnessTrackColor,
        },
        volumeTrack: {
            background: volumeTrackColor,
        },
        thumb: {
            background: thumbColor,
            borderColor: '#FF8E00', // Border color for the thumb
        },
    };
    return (
        <>
            <div className="Description"></div>
            <>
                <div className="h-screen flex w-screen ">
                    <DpMenu />
                    <div className="h-screen overflow-hidden w-screen bg-[#F2F2F2]">
                        <DpHeader />
                        <ToastContainer />
                        {showContentSelect && (
                            <div className="bg-black/70 absolute inset-0">
                                <div className="absolute left-[50%] z-10 -translate-x-[50%] top-[50%] -translate-y-[50%] h-3/4 w-3/4">
                                    <ContentSelect
                                        dpSlots={dpSlots}
                                        setDpSlots={setDpSlots}
                                        updateDpSlots={updateDpSlots}
                                        display={screenId}
                                        close={close}
                                        slot={slot}
                                    />
                                </div>
                            </div>
                        )}
                        {showContentSelectForAd && (
                            <div className="bg-black/70 absolute inset-0">
                                <div className="absolute left-[50%] z-10 -translate-x-[50%] top-[50%] -translate-y-[50%] h-3/4 w-3/4">
                                    <ContentSelectForAd
                                        display={screenId}
                                        close={closeAdSelect}
                                        updateAdSlotContent={
                                            updateAdSlotContent
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="w-[100%] h-[100%]  bg-[#F2F2F2]  flex flex-col p-2">
                            <div className="w-[100%] p-4 rounded-[10px] gap-[10px] flex justify-between">
                                <div>
                                    <p className="text-[#001122] w-[100%] h-[10%]">
                                        Display: {screenDets.screenName}
                                    </p>
                                </div>
                                <div className="flex gap-10  relative z-10 items-center">
                                    <div className="flex items-center gap-2">
                                        <AiOutlineInfoCircle className="w-[18px] cursor-pointer h-[20px]" />
                                        <p className="cursor-pointer text-[#555555] text-[16px]"
                                            onClick={toggleSidebar1}
                                        >
                                            Screen Info
                                        </p>
                                        <div className={`fixed flex flex-col gap-4 top-16 right-0 h-full w-[300px] bg-white p-4 transform ${isSidebarOpen1 ? 'translate-x-0' : 'translate-x-full'
                                            } transition-transform duration-300 ease-in-out`}>
                                            <div className="flex justify-between items-center border-b py-4">
                                                <p
                                                    className="text-[#555555] text-[18px] font-medium"
                                                >Screen Information</p>
                                                <AiOutlineClose size={22} className="text-[#9D9D9D] cursor-pointer"
                                                    onClick={toggleSidebar1} />
                                            </div>
                                            <div className="flex flex-col gap-4 text-[#9D9D9D]">
                                                <p>Screen Code:
                                                    <span className="text-[#555555]"> {screenDets.screenId}</span>
                                                </p>
                                                <p>Screen Orientation:<span className="text-[#555555]">{screenDets.screenType}</span></p>
                                                {/* <p>Time Zone:{""}<span></span></p> */}
                                                <p>Screen Location:<span className="text-[#555555]">{screenDets.screen_address}</span></p>
                                                <p>Screen Address:<span className="text-[#555555]">{screenDets.SCREEN_ADDRESS_FROM_PHONE}</span></p>
                                                <p>Operating System:<span className="text-[#555555]">{screenDets.OS}</span></p>
                                                {/* <p>Player Version:{""}</p> */}
                                                <p>Screen Resolution:<span className="text-[#555555]">{screenDets.screenSize}</span></p>
                                                {/* <p>Used/ Total Space:{""}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <AiOutlineSetting className="w-[18px] cursor-pointer h-[20px]" />
                                        <p
                                            className="cursor-pointer text-[#555555] text-[16px]"
                                            onClick={toggleSidebar}
                                        >
                                            Additional Settings
                                        </p>
                                        <div className={`fixed flex flex-col gap-4 top-16 right-0 h-full w-[300px] bg-white p-4 transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
                                            } transition-transform duration-300 ease-in-out`}>
                                            <div className="flex justify-between items-center border-b py-4">
                                                <p
                                                    className="text-[#555555] text-[18px] font-medium"
                                                >Additional Settings</p>
                                                <AiOutlineClose size={22} className="text-[#9D9D9D] cursor-pointer"
                                                    onClick={() => { toggleSidebar(); clearInputField() }} />
                                            </div>
                                            <div class="relative mt-4  w-full">
                                                <input
                                                    type="text"
                                                    className="border p-2 rounded-md w-full outline-none"
                                                    value={inputValue}
                                                    onChange={handleInputFieldChange}

                                                />

                                                <label htmlFor="Screen Name" class="absolute  bottom-8 left-3 bg-white px-1 text-sm">Screen Name</label>
                                            </div>
                                            <div className="flex my-2">
                                                <p className="text-[#555555] text-[16px] font-medium">  Additional Settings</p>
                                            </div>
                                            <div className="mb-4 flex flex-col gap-8">
                                                <label htmlFor="brightness" className="text-[#9D9D9D]">Screen Brightness:
                                                    <span className="mx-2">{screenDets.brightness}%</span>
                                                </label>
                                                
                                                <input
                                                    type="range"
                                                    value={
                                                        screenDets.brightness
                                                    }
                                                    min="0"
                                                    max="100"
                                                    className="w-16 lg:w-[250px]  cursor-pointer outline-none"
                                                    onChange={(
                                                        e
                                                    ) =>
                                                        handleBrightness(
                                                            e
                                                                .target
                                                                .value
                                                        )
                                                    }
                                                />

                                            </div>
                                            <div className="flex flex-col gap-8">
                                                <label htmlFor="volume" className="text-[#9D9D9D]">Screen Volume:
                                                    <span className="mx-2">{screenDets.volume}%</span>
                                                </label>
                                                
                                                <input
                                                    type="range"
                                                    value={
                                                        screenDets.volume
                                                    }
                                                    min="0"
                                                    max="100"
                                                    onChange={(
                                                        e
                                                    ) =>
                                                        handleVol(
                                                            e
                                                                .target
                                                                .value
                                                        )
                                                    }
                                                    className=" vertical-range cursor-pointer w-16 lg:w-[250px]"

                                                />

                                            </div>

                                            <div className={`bg-[#DEDEDE] rounded-[2px] flex items-center justify-center my-12 ${active ? 'bg-primary text-white' : 'text-[#ABABAB]'}`}>
                                                <button
                                                    onClick={handleButtonChange}
                                                    className={`focus:outline-none transition-colors duration-2000 ease-in-out hover:bg-primary hover:text-white`}
                                                >
                                                    {buttonUpdate ? (
                                                        <>
                                                            {buttonUpdate}
                                                        </>
                                                    ) : (
                                                        'Save Settings'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[100%]  h-[80%] overflow-hidden justify-center pt-4 gap-[10px] flex">
                                <div className="w-[38%]  h-full gap-4 mb-4 pr-2 flex flex-col overflow-y-auto">
                                  {screenDets.DpSlotVisibility
                                  
                                  &&  ( <div className="w-[100%]  h-[68%]  p-[18px] rounded-[10px] bg-[#FFFFFF]  flex flex-col">
                                        <div>
                                            <p className="text-[#001122] font-semibold text-xl ">
                                                Manage Content
                                            </p>
                                            <p className="text-sm mt-2">
                                                Play content upto 20 Seconds on
                                                this display.
                                            </p>
                                            <p className="text-[#555555] text-sm mt-3 mb-1">
                                                Currently Assigned
                                            </p>
                                            <div className="p-2">
                                                <div className="flex justify-between items-center gap-2">
                                                    <p className="w-4">1</p>
                                                    <div>
                                                        <img
                                                            src={ContentImg}
                                                            alt=""
                                                            className="h-8 w-8"
                                                        />
                                                    </div>
                                                    <div>{title}</div>
                                                    <div>
                                                        {selectedDuration}s
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setSlot(0);
                                                            setShowContentSelect(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <BiEdit size={26} />
                                                    </button>
                                                </div>
                                                {showSlotTwoDetails && (
                                                    <div className="flex justify-between items-center gap-2">
                                                        <p className="w-4">2</p>
                                                        <div>
                                                            <img
                                                                src={ContentImg}
                                                                alt=""
                                                                className="h-8 w-8"
                                                            />
                                                        </div>
                                                        <div>{titleTwo}</div>
                                                        <div>
                                                            {
                                                                selectedDurationTwo
                                                            }
                                                            s
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setSlot(1);
                                                                setShowContentSelect(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <BiEdit size={26} />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                           
                                        </div>
                                        <div
                                            className={`flex justify-between mt-4 ${disableButtons ? "pointer-events-none grayscale" : "pointer-events-auto"}`}
                                        >
                                            <button
                                                type="button"
                                                className="bg-primary text-white rounded-md"
                                                onClick={handleInstant}
                                            >
                                                Play Now
                                            </button>
                                            <button
                                                type="button"
                                                className="bg-primary text-white rounded-md"
                                                onClick={handleTomorrow}
                                            >
                                                Schedule Tomorrow
                                            </button>
                                        </div>
                                    </div>)}

                                    <div className=" w-[100%] h-[52%] bg-white rounded-[10px] p-[18px] flex flex-col gap-[8px]">
                                        <div className="flex justify-between">
                                            <p className="text-[#001122] font-semibold text-xl">
                                                Screen Specifications{" "}
                                            </p>
                                            <div className="bg-[#20A756] select-none text-[#FFFFFF] rounded-[20px] flex items-center justify-center gap-[10px] w-[78px] h-[27px]">
                                                <p className=" flex items-center justify-center">
                                                    {screenDets.connectivity_status
                                                        ? "Active"
                                                        : "Inactive"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex gap-[4px]">
                                                <p className="text-[#001122] font-normal">
                                                    Screen Code :
                                                </p>
                                                <p className="text-[#555555]">
                                                    {screenDets.screenId}
                                                </p>
                                            </div>
                                           
                                        </div>
                                        <div className="flex flex-col gap-[6px]">
                                            <div className="flex gap-[4px]">
                                                <p className="text-[#001122] font-normal">
                                                    Venue:
                                                </p>
                                                <p className="text-[#555555]">
                                                    {screenDets.TYPE}
                                                </p>
                                            </div>
                                           
                                            <div className="flex gap-[4px]">
                                                <p className="text-[#001122] font-normal">
                                                    Address:
                                                </p>
                                                <p className="text-[#555555]">
                                                    {
                                                        screenDets.SCREEN_ADDRESS_FROM_PHONE
                                                    }
                                                </p>
                                            </div>
                                            
                                            <div className="flex gap-[4px]">
                                                <p className="text-[#001122] font-normal">
                                                    Resolution :
                                                </p>
                                                <p className="text-[#555555]">
                                                    {screenDets.screenSize}
                                                </p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* ADVERTISER SLOT BOOKING */}
                                    {screenDets.AllowDpforAddSlot && (
                                        <div className=" w-[100%] h-full bg-white rounded-[10px] p-[18px] flex flex-col gap-[8px]">
                                            <h1 className="text-lg font-bold">
                                                Publish more content
                                            </h1>
                                            <p>
                                                Play your videos instantly if
                                                slots are available
                                            </p>
                                            <p>
                                                Available Slots:{" "}
                                                {screenDets.EmaptySlotforDP}
                                            </p>
                                            <p className="text-sm mt-2">
                                                Play content upto{" "}
                                                {screenDets.EmaptySlotforDP *
                                                    10}{" "}
                                                Seconds on this display.
                                            </p>
                                            <p className="text-[#555555] text-sm mt-3 mb-1">
                                                Currently Assigned
                                            </p>
                                            <div className="p-2">
                                                <div className="flex justify-between items-center gap-2">
                                                    <p className="w-4">1</p>
                                                    <div>
                                                        <img
                                                            src={ContentImg}
                                                            alt=""
                                                            className="h-8 w-8"
                                                        />
                                                    </div>
                                                    <div>
                                                        {adSlotContentTitle}
                                                    </div>
                                                    <div>
                                                        {adSlotContentDuration}s
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setShowContentSelectForAd(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <BiEdit size={26} />
                                                    </button>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="bg-primary text-white disabled:grayscale rounded-md grayscale-0 mt-4 -ml-2"
                                                    onClick={dpAdSlotBooking}
                                                    disabled={disabled}
                                                >
                                                    Play Now  
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* CONTENT PREVIEW SECTION */}
                                <div className="w-[60%] h-[96%] flex flex-col items-center justify-center">
                                    <div className="flex justify-between p-4  rounded-t-xl w-full bg-[#FFFFFF] items-center">
                                        <p className="text-[#001122] font-semibold text-xl">
                                            Content Preview
                                        </p>
                                        <p className="flex gap-[8px] items-center">
                                            {screenDets.screenType ===
                                                "Portrait" ? (
                                                <span className="text-black flex items-center ">
                                                    <MdScreenLockPortrait
                                                        size={22}
                                                        className="text-[#9D9D9D] "
                                                    />
                                                    {screenDets.screenType}
                                                </span>
                                            ) : (
                                                <span className="  text-black flex items-center gap-1">
                                                    <MdOutlineScreenLockLandscape
                                                        size={24}
                                                        className="text-[#9D9D9D] "
                                                    />
                                                    {screenDets.screenType}
                                                </span>
                                            )}
                                        </p>
                                    </div>

                                    <div className="w-[100%] h-[97%]  items-center justify-center  rounded-[20px] flex flex-col">
                                        <div
                                            className="w-[100%] h-[100%]    bg-[#E5E5E5]"
                                            onMouseOver={() =>
                                                setHover(
                                                    screenDets.connectivity_status
                                                )
                                            }
                                            onMouseOut={() => setHover(false)}
                                        >
                                            {screenDets.connectivity_status ? (
                                                <div className="flex flex-col  relative z-0  w-[100%] h-[100%]  ">
                                                    <div className="playerContainer  mb-1 w-[100%] h-[100%]  relative  ">
                                                        <div className="player  relative z-0 w-full justify-center   items-center h-[100%] ">
                                                            <ReactPlayer
                                                                ref={videoRef}
                                                                url={videoUrl}
                                                                playing={
                                                                    play_pause
                                                                }
                                                                loop={true}
                                                                muted={true}
                                                                width="100%"
                                                                height="99%"
                                                            />
                                                        </div>
                                                        {hover ? (
                                                            <div className="controls lg:z-1 flex gap-3  w-full justify-around items-center h-[100%] absolute top-0">
                                                                {screenDets.connectivity_status ? (
                                                                    <>
                                                                        <div className="flex flex-col items-center w-10 h-2">

                                                                            <div className="icons-video text-white mt-6 lg:mt-[8em] lg:mr-1 lg:h-5">
                                                                                <CiBrightnessUp
                                                                                    size={
                                                                                        30
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {!play_pause ? (
                                                                            <CiPlay1
                                                                                size={
                                                                                    75
                                                                                }
                                                                                className="cursor-pointer text-white opacity-100 z-1"
                                                                                onClick={
                                                                                    handlePlay
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="cursor-pointer text-white opacity-100 w-40 h-40 z-1" onClick={handlePlay}><path d="M9.48 29a2.65 2.65 0 0 1-1.13-.25A2.3 2.3 0 0 1 7 26.69V5.31a2.3 2.3 0 0 1 1.35-2.06 2.63 2.63 0 0 1 2.77.33l13 10.69a2.2 2.2 0 0 1 0 3.46l-13 10.69a2.61 2.61 0 0 1-1.64.58zm0-24a.61.61 0 0 0-.28.06c-.08 0-.21.12-.21.25v21.38c0 .13.13.21.21.25a.62.62 0 0 0 .64-.06l13-10.7a.2.2 0 0 0 0-.36l-13-10.7A.62.62 0 0 0 9.49 5z" /></svg>
                                                                            <CiPause1
                                                                                size={
                                                                                    75
                                                                                }
                                                                                className="cursor-pointer text-white opacity-100 z-1"
                                                                                onClick={
                                                                                    handlePlay
                                                                                }
                                                                            />
                                                                            // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="cursor-pointer opacity-100 w-40 h-40 z-1" onClick={handlePlay}><path d="M11.12 2H7.88A2.88 2.88 0 0 0 5 4.88v22.24A2.88 2.88 0 0 0 7.88 30h3.24A2.88 2.88 0 0 0 14 27.12V4.88A2.88 2.88 0 0 0 11.12 2zM12 27.12a.89.89 0 0 1-.88.88H7.88a.89.89 0 0 1-.88-.88V4.88A.89.89 0 0 1 7.88 4h3.24a.89.89 0 0 1 .88.88zM24.12 2h-3.24A2.88 2.88 0 0 0 18 4.88v22.24A2.88 2.88 0 0 0 20.88 30h3.24A2.88 2.88 0 0 0 27 27.12V4.88A2.88 2.88 0 0 0 24.12 2zM25 27.12a.89.89 0 0 1-.88.88h-3.24a.89.89 0 0 1-.88-.88V4.88a.89.89 0 0 1 .88-.88h3.24a.89.89 0 0 1 .88.88z" /></svg>
                                                                        )}


                                                                    </>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>

                                                    {/* <div className="bg-[#FFFFFF] flex items-center w-full h-12 p-2 rounded-b-xl">
                                                        <div className="flex ">
                                                            {screenDets.connectivity_status ? (
                                                                <div className="flex gap-1 lg:gap-1 items-center lg:mx-3">
                                                                    <LuStopCircle
                                                                        size={
                                                                            24
                                                                        }
                                                                        defaultChecked
                                                                        onClick={() =>
                                                                            handleStop()
                                                                        }
                                                                    />
                                                                    <span className="text-xs cursor-pointer lg:text-base">
                                                                        Stop
                                                                        Device
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                            {screenDets.connectivity_status ? (
                                                                <div className="flex gap-1 lg:gap-1 items-center lg:mx-3">
                                                                    <BsBootstrapReboot
                                                                        size={
                                                                            24
                                                                        }
                                                                        defaultChecked
                                                                        onClick={() =>
                                                                            handleStop()
                                                                        }
                                                                    />
                                                                    <span className="text-xs cursor-pointer lg:text-base">
                                                                        Reboot
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </div>
                                                    </div> */}
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="w-full flex justify-center items-center first-letter h-[100%] border-2">
                                                        Offline
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="bg-[#FFFFFF] w-full h-12 rounded-b-xl"></div>
                                </div>
                            </div>
                            {/* <div className="w-[100%] h-[8%] bg-white p-3 flex justify-end items-center gap-[20px]"></div> */}
                        </div>
                    </div>
                </div >
            </>
        </>
    );
};

export default Description;