import React, { useEffect, useState } from 'react'
import ManagerMenu from '../Menus/ManagerMenu'
import DpHeader from "../../Components/Header/DpHeader"
import { IoChevronForwardOutline } from 'react-icons/io5'
import { BiPlus } from 'react-icons/bi';
import Axios from '../../Components/Axios/axios'
import {  useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactModal from 'react-modal';
import { CiSearch } from 'react-icons/ci';
import { GoFilter } from 'react-icons/go';
import { PiWarningCircleBold } from 'react-icons/pi'
import Members from '../../Components/Dashboard/Pages/Members';



function TeamMember() {

    const notifyUser = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyUserExists = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const navigate = useNavigate();
    const PrNaviagte = () => {
        navigate("/home")
    }
    const [isParentModalOpen, setIsParentModalOpen] = useState(false);
    const openParentModal = () => {
        setIsParentModalOpen(true);
    };

    const closeParentModal = () => {
        setIsParentModalOpen(false);
    };



    const handleChange = (e) => {
        setFormData({
            ...formData, 
            [e.target.name]: e.target.value,
        });
    };





    // automatically password generated within the handleOptionChange function
    const generateRandomPassword = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const passwordLength = 8; // You can adjust the password length as needed
        let password = '';

        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters.charAt(randomIndex);
        }

        return password;
    };
    // Event handler to update the state with the selected option value




    const username = sessionStorage.getItem("username")

    const [formData, setFormData] = useState({
        role: 'Role',
        name: "",
        username: "",

    });

    const [memberData, setMemberData] = useState({});
   
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        const randomPassword = generateRandomPassword();
        console.log(formData)
        try {
            console.log(randomPassword, "", username)
            const response = await Axios.post('sub/subUserLogin', {
                DpUsername: username,
                password: randomPassword,
                ...formData,
            });
           
            if (response.status === 200) {
                notifyUser("User Created!")
                closeParentModal();
                console.log('Response Data:', response);

            }
            else if (response.status === 201) {
                notifyUserExists("User ALready Exists!")
            }
            else {
                // Request failed, handle errors here
                console.error('Request failed:', response.status);
            }

        } catch (error) {
            // Handle any network or other errors that might occur during the request
            console.error('Error:', error);
        }

    };

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get(`sub/subUserData?DpUsername=${sessionStorage.getItem("username")}`
                );
                setData(response.data);
                console.log('userdata',response);
             
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        }

        fetchData();
    }, []);
   
    const [categoryFilter, setCategoryFilter] = useState("sort");

    const handleCategoryChange = (e) => {
        setCategoryFilter(e.target.value);
        console.log("Category Filter:", e.target.value);
    };

    const filteredData = data.filter((item) => {
        const category = categoryFilter.toLowerCase();
        console.log("Category Filter:", category);

        if (category === "sort") {
            if (item.role === "Publisher") return item;
            if (item.role === "Manager") return item;
            if (item.role === "Viewer") return item;
            if (item.role === "Editor") return item;
            return true;
        }

        // Filter based on the selected category
        return item.role.toLowerCase() === category;
    });
    console.log(filteredData)
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        console.log("Search Text:", e.target.value);
    };
    const filteredDataBySearch = filteredData.filter((member) =>
    member.name.toLowerCase().includes(searchText.toLowerCase())
   );
  
    return (

        <div className="h-screen flex w-screen">
            <ToastContainer />
            <ManagerMenu/>
            <div className="h-screen w-screen  overflow-hidden bg-[#FFFDF9]" >
                <DpHeader
                />
                <div className="p-5 h-[100%]">
                    <div className=" flex items-center ">
                        <span className='text-[#8E8E8E] cursor-pointer' onClick={PrNaviagte}> Home</span> <IoChevronForwardOutline /> <span className='text-[#555555]'> Team Member</span>
                    </div>
                    <div className="flex gap-3  justify-between items-center">
                        <h1 className='text-xl mt-6 mb-4 text-[#555555]'> Team Members </h1>
                        <button
                                    className=" flex items-center gap-1 py-[9px] px-3  rounded-sm text-[#FF8E00] text-[16px] border-[0.5px] border-solid "
                                    onClick={openParentModal}
                                >
                                    <span>
                                        <BiPlus size={24} className="" />{" "}
                                    </span>
                                    Invite Member
                                </button>
                    </div>
                    <div className="w-[100%]  py-3">
                        <div className='flex items-center justify-between'>
                            <div className=" flex gap-3 items-center ">
                                <div className="flex shadow-sm border-2 bg-white rounded-md">
                                    <div className="bg-white flex items-center gap-2  px-4 py-2">
                                        <CiSearch
                                            size={24}
                                            className=" text-gray-400"
                                        />
                                        <input
                                            className=" outline-none h-fit w-full"
                                            type="text"
                                            placeholder="Search Members"
                                            value={searchText}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                                <GoFilter className='text-[#555555]' />
                                <p className='text-[#555555]'>Filter: </p>
                                <select
                                
                                    className='flex gap-2 px-3 py-1 cursor-pointer  border rounded-sm outline-none'>
                                    <option value="role">role</option>
                                    <option value="Publisher">Publisher</option>
                                    <option value="Viewer">Viewer</option>
                                    <option value="Editor">Editor</option>
                                </select>
                                <select
                                  
                                    className='flex gap-2 px-3 py-1 cursor-pointer border  rounded-sm outline-none'
                                    >
                                     
                                
                                    <option value="all">Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                   
                                </select>
                            </div>
                            <ReactModal
                                isOpen={isParentModalOpen}
                                onRequestClose={closeParentModal}
                                contentLabel="Video Modal"
                                className=" flex w-screem h-screen bg-[#000000]/70 justify-center items-center"
                                ariaHideApp={false}
                            >
                                <div className="border border-gray-300 bg-white rounded-lg p-4 w-[60%] h-[48%] ">
                                    <div className="flex justify-between w-[100%] border-b border-[#DEDEDE] py-2">
                                        <p className="text-[20px] font-normal  ml-3   text-[#555555]">
                                            Invite New Member
                                        </p>
                                    </div>
                                    <form
                                        className="h-[70%]"
                                        style={{ marginTop: "20px" }}
                                    >
                                        <div className="flex flex-col gap-2 py-2">
                                            <div className="w-[100%] gap-5 flex">
                                                <div className="w-[100%] relative">
                                                    <label htmlFor="phoneNumber" class="absolute left-2 bottom-14 text-[#555555] bg-white px-2 text-sm">
                                                        Name
                                                    </label>
                                                    <input
                                                        className="p-3 w-[100%] mb-3 rounded-md placeholder-[#9D9D9D] border border-gray-400 outline-none"
                                                        required
                                                        type="text"
                                                        name="name"
                                                        placeholder="Enter team member name"
                                                        value={formData.name}
                                                        onChange={handleChange}

                                                    />
                                                </div>
                                                <div className="w-[100%] relative">
                                                    <label htmlFor="phoneNumber" class="absolute left-2 bottom-14 text-[#555555] bg-white px-2 text-sm">
                                                        Email
                                                    </label>
                                                    <input
                                                        className="p-3 w-[100%] mb-3 rounded-md placeholder-[#9D9D9D] border border-gray-400 outline-none"
                                                        required
                                                        type="email"
                                                        name="username"
                                                        placeholder="Enter Email ID"
                                                        value={formData.username}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-2 items-center bg-[#DE47481A] py-2 rounded-[4px]'>
                                                <PiWarningCircleBold className='text-[#DE4748] mx-2' size={20} />
                                                <p className='text-[#555555]'> Kindly assign atleast one set of role for the member.</p>
                                            </div>
                                            <div className='flex justify-between py-4'>
                                                <div>
                                                    <p className='text-[#555555] px-4 text-[16px]'>Roles</p>
                                                    <p className='text-[#DE4748] px-4 text-[12px]'>
                                                        *Required Field
                                                    </p>
                                                </div>
                                     
                                                <select id="" className=' cursor-pointer outline-none border px-2 w-32 rounded-sm text-[#555555]' name="role" value={formData.role} onChange={handleChange}>
                                                    <option className=' cursor-pointer' value="Role">Role</option>
                                                    <option className=' cursor-pointer' value="Publisher">Publisher</option>
                                                    <option className=' cursor-pointer' value="Viewer">Viewer</option>
                                                    <option className=' cursor-pointer' value="Editor">Editor</option>
                                                </select>


                                            </div>
                                            <div className={` flex gap-5 justify-end py-4`}>
                                                <button className='bg-[#E6E6E6] px-8 rounded-md'
                                                    onClick={closeParentModal}
                                                >
                                                    Cancel
                                                </button>
                                                <button onClick={handleSubmit} className='bg-[#FF8E00] px-8 rounded-md text-[#FFFFFF]'>
                                                    Verify
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </ReactModal>
                            <div className='flex justify-end items-center gap-2'>
                                <select
                                    value={categoryFilter}
                                    onChange={handleCategoryChange}
                                    className='flex gap-2 px-3 py-1 cursor-pointer bg-[#DEDEDE]  border  rounded-sm outline-none'>
                                    <option value="sort">Sort By</option>
                                    <option value="Publisher">Publisher</option>
                                    <option value="Viewer">Viewer</option>
                                    <option value="Editor">Editor</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className=" w-[100%] h-[80%] py-2 rounded-md border-gray-300 text-[#555555]">
                        {filteredDataBySearch.length === 0 ? (
                            <p>No results found.</p>
                        ) : (
                            <div className='flex w-[100%] h-[85%] gap-4 flex-wrap flex-row  overflow-y-scroll'>
                                {filteredDataBySearch.map((member) => (
                                    <Members key={member.id} member={member} />
                                ))}
                            </div>
                        )}

                    </div>

                </div>

            </div>
        </div>
    )
}

export default TeamMember