import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../Axios/axios";
import Ad_sec from "../Ad_sec";
import Ad_th from "../Ad_th";
import food from "../../Images/food.png";
import Advertise from "..";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Payment from "./Payment";

const formatTime = (durationInSeconds) => {
  const durationSeconds = Math.floor(durationInSeconds);
  return durationSeconds;
};

function CreateCampaign({ Ad, setAd }) {
  //Toast
  const notify = (text) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyy = (text) =>
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const [currentStep, setCurrentStep] = useState(1);

  //marked screen selected
  const [screenSelected, setScreenSelected] = useState([]);
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [formattedCurrentDate, setFormattedCurrentDate] = useState("");
  const token = localStorage.getItem("token");
  //Add content
  const [btn, setbtn] = useState(false);
  const handlecontent = () => {
    setbtn(!btn);
  };
  const handledisplay = () => {
    setbtn(!btn);
  };

  const [noOfDays, setNoOfDays] = useState(1);

  const [saved, setSaved] = useState(true);
  const [adcontent, setAdContent] = useState(null);

  const [paymentId, setPaymentId] = useState();
  const [orderId, setOrderId] = useState();

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);

    setAd((prevAdData) => ({
      ...prevAdData,
      [name]: value,
    }));

    setNew((prevAdData) => ({
      ...prevAdData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);
  const [content, setContent] = useState([
    {
      CampName: "Food - Tactos",
      duration: "0",
      advertiser: "Oceanic Airlines Ad",
      imgDetail: food,
      contentId: "ididid",
    },
  ]);

  const [New, setNew] = useState({
    CampName: "",
    duration: "",
    user_slot: "",
    company: "",
    videoDetail: "",
    imgDetail: "",
    videoPath: "",
    description: "",
  });
  console.log("slots ..................----------->>>>>>>>>", New);

  useEffect(() => {
    console.log(adcontent);
  });

  useEffect(() => {
    async function getContent() {
      const result = await Axios.get(
        "getAllContent?username=" + sessionStorage.getItem("username")
      );
      setContent(result.data.Content);
    }
    getContent();
  }, []);

  const [dur, setdurr] = useState(0);
  const [instant, setInstant] = useState(false);
  // const [slots, setSlots] = useState(0);

  const handleVideoUpload = (videoFile, videoRef) => {
    const file = videoFile;
    const videoURL = URL.createObjectURL(file);
    console.log("vidoe URL", videoURL);
    videoRef.current.src = videoURL;
    videoRef.current.onloadedmetadata = () => {
      const durationInSeconds = Math.ceil(videoRef.current.duration);
      const formattedDuration = formatTime(durationInSeconds);
      const maxDuration = calculateMaxDuration(New.slots);
      if (durationInSeconds > maxDuration) {
        alert(
          `The maximum allowed duration for ${New.slots} slots is ${maxDuration} seconds. Please select a shorter video or increase the slots.`
        );
        return ;
      }
      setdurr(formattedDuration);
    };
  };

  const calculateMaxDuration = (slots) => {
    if (slots === "1") {
      return 30;
    } else if (slots === "2") {
      return 60;
    } else if (slots === "3") {
      return 90;
    } else if (slots === "4") {
      return 120;
    }
    return 0;
  };

  // useEffect(() => {
  //   setSlots(Math.ceil(dur / 10));
  // }, [dur, handleVideoUpload]);

  const username = sessionStorage.getItem("username");

  const saveLocally = async () => {
    const username = sessionStorage.getItem("username");
    var data = new FormData();

    data.set("postedvideos", New.videoDetail);
    data.set("thumbnail", New.imgDetail);
    data.set("username", username);
    data.set("title", New.CampName);
    data.set("duration", dur);
    data.set("size", New.videoDetail.size);
    data.set("advertiser", New.company);
    data.set("description", New.description);
    data.set("mimetype", New.videoDetail.type.split("/")[1]);

    var newContentId = "newIdId";
    const headers = {
      "content-type": "multipart/form-data",
    };
    console.log("data for add content api ************----------->", data);
    await Axios.post("addContent", data, {
      headers: headers,
    }).then(async function (response) {
      console.log(data);
      newContentId = response.data.contentId;
      if (response.status === 200) {
        console.log(response.data);
        setContent([
          {
            title: New.title,
            duration: dur,
            advertiser: New.company,
            imgDetail:
              "https://s3.ap-south-1.amazonaws.com/everidoor2.0/" +
              sessionStorage.getItem("username") +
              "/Thumbnails/" +
              response.data.contentId +
              "." +
              New.imgDetail.type.split("/")[1],
            contentId: newContentId,
          },
        ]);
        console.log("setcontent data from addcontent api ", content);

        setNew({
          title: "",
          duration: "",
          company: "",
          imgDetail: "",
          videoPath: "",
          description: "",
        });
        setAdContent({
          campaignName: "Sample Campaign 1",
          username: "desk@desk.com",
          content_to_be_played: response.data.videoUrl,
          thumbnail_for_content_to_be_played: response.data.thumbnailUrl,
          fromDate: "",
          toDate: "",
          selectedScreenId: screenSelected.selectedScreenId,
          selectedDisplayProviderId: screenSelected.selectedDisplayProviderId,
        });
        const json = JSON.stringify({
          campaignName: New.CampName,
          username: username,
          content_to_be_played: response.data.videoUrl,
          thumbnail_for_content_to_be_played: response.data.thumbnailUrl,
          fromDate: Ad.fromDate,
          toDate: Ad.toDate,
          selectedScreens: screenSelected,
          duration: dur,
          additional_comments: response.data.contentId,
        });
        sessionStorage.setItem("Ad", json);
      }
    });
  };
  var newContentId = "newIdId";

  const addContent = async (e) => {
    const StandardContentId = Math.floor(Math.random() * 100000000).toString();
    console.log("addInstantContent called ----->>>>>>>>>>>>");

    if (!New?.videoDetail || !New?.imgDetail) return;
    console.log("*******");

    try {
      setUploading(true);
      console.log("presigned url *********", token);
      // Request a pre-signed URL from your backend Thumbnail
      const thumbnailResponse = await Axios.post(
        `advertiser/presigned-url?${new URLSearchParams({
          type: "thumbnail",
          contentFormat: New?.imgDetail?.type?.split("/")[1],
          contentId: StandardContentId,
        })}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { thumbnailUrl } = thumbnailResponse.data;
      console.log("PreSigned URL ****", thumbnailUrl);

      
      

      if (thumbnailResponse.status !== 200) {
        throw new Error(
          "Error uploading file: Thumbnail response status is not 200"
        );
      }

      // Request a pre-signed URL from your backend video
      const VideoResponse = await Axios.post(
        `advertiser/presigned-url?${new URLSearchParams({
          type: "video",
          contentFormat: New?.videoDetail?.type?.split("/")[1],
          contentId: StandardContentId,
        })}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { videoUrl } = VideoResponse.data;
      console.log("Presigned URL ****:", videoUrl);

      //upload thumbnail using presenged URL
      if (thumbnailResponse.status !== 200 && VideoResponse.status !== 200) {
        throw new Error(
          "Error uploading file: Thumbnail or Video response status is not 200"
        );
      }

      await axios.put(thumbnailUrl, New?.imgDetail, {
        headers: {
          "Content-Type": New?.imgDetail.type,
        },
      });
      console.log("thumbnail uploded ..........");

      // Upload the file using the pre-signed URL

      await axios.put(videoUrl, New?.videoDetail, {
        headers: {
          "Content-Type": New?.videoDetail.type,
        },
      });

      console.log("PUT request successful");
      console.log("VIdeoFile uploaded successfully!..............");
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    setSaved(false);

    const username = sessionStorage.getItem("username");
    const StandardData = {
      username: username,
      title: New?.CampName,
      size: New?.videoDetail?.size,
      duration: New.slots,
      advertiser: New?.company,
      description: New?.description,
      thumbnailMimetype: New?.imgDetail?.type?.split("/")[1],
      videoMimetype: New?.videoDetail?.type?.split("/")[1],
      contentId: StandardContentId,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log(
      "standard data what will be sent ------>>>>>>>>>",
      StandardData
    );
    await Axios.post("advertiser/addNewContent", StandardData, {
      headers: headers,
    })
      .then(async function (response) {
        console.log(response.data);
        newContentId = response.data.contentId;
        if (response.status === 200) {
          console.log(response.data);
          setSaved(true);
          setbtn(!btn);
          setContent([
            {
              title: New.title,
              duration: dur,
              advertiser: New.company,
              imgDetail:
                "https://s3.ap-south-1.amazonaws.com/everidoor2.0/" +
                sessionStorage.getItem("username") +
                "/Thumbnails/" +
                response.data.contentId +
                "." +
                New.imgDetail.type.split("/")[1],
              contentId: newContentId,
            },
          ]);
          console.log(content);

          setNew({
            title: "",
            duration: "",
            company: "",
            imgDetail: "",
            videoPath: "",
            description: "",
          });
          setAdContent({
            campaignName: "Sample Campaign 1",
            username: "desk@desk.com",
            content_to_be_played: response.data.videoUrl,
            thumbnail_for_content_to_be_played: response.data.thumbnailUrl,
            fromDate: "",
            toDate: "",
            fromTime: "",
            toTime: "",
            selectedScreenId: screenSelected.selectedScreenId,
            selectedDisplayProviderId: screenSelected.selectedDisplayProviderId,
          });

          await Axios.post(
            "advertiser/createCampaignRequest",
            {
              campaignName: New.CampName,
              username: sessionStorage.getItem("username"),
              content_to_be_played: response.data.videoUrl,
              thumbnail_for_content_to_be_played: response.data.thumbnailUrl,
              fromDate: Ad.fromDate,
              toDate: Ad.toDate,
              fromTime: Ad.fromTime,
              toTime: Ad.toTime,
              selectedScreens: screenSelected,
              duration: New.slots,
              additional_comments: response.data.contentId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          ).then((response) => {
            console.log(response.data);
            if (response.data["Message"] === "Admin notified. Request sent") {
              setLoad(true);
              notifyy("Request sent");
              setTimeout(() => {
                navigate("/home");
              }, 3000);
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        notify("Request Failed to sent");
      });
  };

  console.log(
    "video and image details **************",
    New.videoDetail,
    New.imgDetail
  );

  // code for Presigned URL for instant only ----------->>>>>>>>>>>>>>

  const [uploading, setUploading] = useState(false);

  console.log("token is called for instant", token);

  const addInstantContent = async (e) => {
    const InstantContentId = Math.floor(Math.random() * 100000000).toString();
    console.log("addInstantContent called ----->>>>>>>>>>>>");

    if (!New?.videoDetail || !New?.imgDetail) return;
    console.log("*******");
    try {
      setUploading(true);
      console.log("presigned url *********", token);
      // Request a pre-signed URL from your backend Thumbnail
      const thumbnailResponse = await Axios.post(
        `advertiser/presigned-url?${new URLSearchParams({
          type: "thumbnail",
          contentFormat: New?.imgDetail?.type?.split("/")[1],
          contentId: InstantContentId,
        })}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { thumbnailUrl } = thumbnailResponse.data;
      console.log("PreSigned URL ****", thumbnailUrl);

      if (thumbnailResponse.status !== 200) {
        throw new Error(
          "Error uploading file: Thumbnail response status is not 200"
        );
      }

      // Request a pre-signed URL from your backend video
      const VideoResponse = await Axios.post(
        `advertiser/presigned-url?${new URLSearchParams({
          type: "video",
          contentFormat: New?.videoDetail?.type?.split("/")[1],
          contentId: InstantContentId,
        })}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { videoUrl } = VideoResponse.data;
      console.log("Presigned URL ****:", videoUrl);

      //upload thumbnail using presenged URL
      if (thumbnailResponse.status !== 200 && VideoResponse.status !== 200) {
        throw new Error(
          "Error uploading file: Thumbnail or Video response status is not 200"
        );
      }

      await axios.put(thumbnailUrl, New?.imgDetail, {
        headers: {
          "Content-Type": New?.imgDetail.type,
        },
      });
      console.log("thumbnail uploded ..........");

      // Upload the file using the pre-signed URL

      await axios.put(videoUrl, New?.videoDetail, {
        headers: {
          "Content-Type": New?.videoDetail.type,
        },
      });

      console.log("PUT request successful");
      console.log("VIdeoFile uploaded successfully!..............");
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    setSaved(false);

    const username = sessionStorage.getItem("username");
    const Instantdata = {
      username: username,
      title: New?.CampName,
      size: New?.videoDetail?.size,
      duration: New.slots,
      advertiser: New?.company,
      description: New?.description,
      thumbnailMimetype: New?.imgDetail?.type?.split("/")[1],
      videoMimetype: New?.videoDetail?.type?.split("/")[1],
      contentId: InstantContentId,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log("instant data what will be sent ------>>>>>>>>>", Instantdata);
    await Axios.post("advertiser/addNewContent", Instantdata, {
      headers: headers,
    })
      .then(async function (response) {
        console.log("addNewContent response--------->>>>>", response);
        // newContentId = response.data.contentId;
        if (response.status === 200) {
          console.log("status is 200 for instant campaign", response.data);
          setSaved(true);
          setbtn(!btn);
          setContent([
            {
              title: New.title,
              duration: dur,
              advertiser: New.company,
              imgDetail:
                "https://s3.ap-south-1.amazonaws.com/everidoor2.0/" +
                sessionStorage.getItem("username") +
                "/Thumbnails/" +
                response.data.contentId +
                "." +
                New.imgDetail.type.split("/")[1],
              contentId: newContentId,
            },
          ]);
          console.log(
            "addContent data for instant -------->>>>>>>>>>>",
            content
          );

          setNew({
            title: "",
            duration: "",
            company: "",
            imgDetail: "",
            videoPath: "",
            description: "",
          });
          setAdContent({
            campaignName: "Sample Campaign 1",
            username: "desk@desk.com",
            content_to_be_played: response.data.videoUrl,
            thumbnail_for_content_to_be_played: response.data.thumbnailUrl,
            fromDate: "",
            toDate: "",
            fromTime: "",
            toTime: "",
            selectedScreenId: screenSelected.selectedScreenId,
            selectedDisplayProviderId: screenSelected.selectedDisplayProviderId,
          });

          await Axios.post(
            "advertiser/InstantCampaignRequest",
            {
              campaignName: New.CampName,
              username: sessionStorage.getItem("username"),
              content_to_be_played: response.data.videoUrl,
              thumbnail_for_content_to_be_played: response.data.thumbnailUrl,
              fromDate: Ad.fromDate,
              toDate: Ad.toDate,
              fromTime: Ad.fromTime,
              toTime: Ad.toTime,
              selectedScreens: screenSelected,
              duration: New.slots,
              additional_comments: response.data.contentId,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          ).then((response) => {
            console.log(
              "compaign data **************--------->",
              response.data
            );
            if (response.data["Message"] === "Admin notified. Request sent") {
              setLoad(true);
              notifyy("Request sent");
              setTimeout(() => {
                navigate("/home");
              }, 3000);
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        notify("Request Failed to sent");
      });
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const [index, setIndex] = useState("");
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Advertise
            Ad={Ad}
            setAd={setAd}
            nextStep={nextStep}
            handleChange={handleChange}
            New={New}
            setNew={setNew}
            handleVideoUpload={handleVideoUpload}
            duration={dur}
          />
        );
      case 2:
        return (
          <Ad_sec
            Ad={Ad}
            setAd={setAd}
            nextStep={nextStep}
            prevStep={prevStep}
            setNoOfDays={setNoOfDays}
            instant={instant}
            setInstant={setInstant}
            setFormattedCurrentDate={setFormattedCurrentDate}
          />
        );
      case 3:
        return (
          <Ad_th
            Ad={Ad}
            setAd={setAd}
            prevStep={prevStep}
            nextStep={nextStep}
            addContent={addContent}
            setPaymentId={setPaymentId}
            setOrderId={setOrderId}
            setScreenSelected={setScreenSelected} 
            screenSelected={screenSelected}
            instant={instant}
            load={load}
            setLoad={setLoad}
            duration={dur}
            // user_slot={New.slots}
            slots={New.slots} 
            noOfDays={noOfDays}
            addInstantContent={addInstantContent}
            saveLocally={saveLocally}
            setIndex = {setIndex}
          />
        );
      case 4:
        return (
          <Payment
            Ad={Ad}
            setAd={setAd}
            addContent={addContent}
            paymentId={paymentId}
            orderId={orderId}
            username={username}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="h-full">
      {currentStep === 4 ? <></> : <></>}
      <div className="flex justify-center">
        {renderStep()}
        <ToastContainer />
      </div>
    </div>
  );
}

export default CreateCampaign;
