import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../../Axios/axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import signImg from "../../SignIn/images/signIn.svg";
import evd from "../../SignIn/images/everidoorlogo.png";
import { useUserContext } from "../../Advertise/Context/Usercontext";
import { useSignedContext } from "../../Advertise/Context/SignedContext";


const notify = (text) =>
  toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const ViewPinLogin = ({ username }) => {
  const {signed, setSigned} = useSignedContext();
  const navigate = useNavigate();

  const [data, setData] = useState({
    username,
    cid: '',
    pin: ''
  })
  console.log(data)
  const {advertiser, setAdvertiser} = useUserContext()
  const handleSubmit = async() => {
    const response = await Axios.post('loginfromadmin', data);
    if(response.status === 200){
        setSigned(true);
        // localStorage.setItem("token", response?.data?.token);
        sessionStorage.setItem("username", username);
        if (response.data.role === "advertiser") {
          localStorage.setItem("token", response?.data?.token);
        } else {
          localStorage.setItem("Dp_token", response?.data?.token);
        }
        console.log("token at viewpinlogin: " + localStorage.getItem("token"));
        localStorage.setItem("Admin", response.data.DpUsername);
        sessionStorage.setItem("role", response.data.role);
        setAdvertiser(response.data)
        navigate("/home");
        
    }
  }
  
  return (
    <div className="w-full flex h-[80vh] ">
      <div className="w-full h-full ">
        <img
          src={signImg}
          alt="everidoor screen"
          className="object-cover h-full w-full"
        />
      </div>
      <ToastContainer />
      <div className="bg-[#FFFFFF]  w-full min-h-full">
        <div className="flex items-end justify-end pr-8 pt-[20px]">
          <img src={evd} alt="everidoor" height={"27px"} />
        </div>
        <h1 className="text-[40px] w-[500px] text-center leading-[50px]    mx-auto mt-20">
          <span className="text-[#000000] font-[Ramillas]  ">
            {" "}
            Tap into The Future of
          </span>
          <br />
          <span className=" ">
            {" "}
            <span className="text-[#FF8E00] font-[Ramillas] ">
              Digital
            </span>{" "}
            Advertising Realm!
          </span>
        </h1>
        <div className="pt-24  flex items-center justify-center">
          <div className="w-[50%] gap-6 flex items-center flex-col justify-center ">
            <span className="text-[24px] text-[#555555] font-[Ramillas] ">
              Welcome Back,{" "}
              <span className=" font-semibold font-[Ramillas] ">
                Display Partners.
              </span>
            </span>
            <input type="text" className="border-2 border-slate-200 p-2" value={data.cid} onChange={(e)=>setData({...data, cid: e.target.value})}/>
            <input type="text" className="border-2 border-slate-200 p-2" value={data.pin} onChange={(e)=>setData({...data, pin: e.target.value})}/>
            <button onClick={handleSubmit} className="border-2 border-orange-300">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPinLogin;
