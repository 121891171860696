import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import React, { useState, useContext, useEffect, useRef } from "react";
import food from "../Images/food.png";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../Axios/axios";
import { IoChevronForwardOutline, IoCloseOutline } from "react-icons/io5";
import { SocketContext } from "../../socketcontext";
import DpMenu from "../Menu/DpMenu";
import { ToastContainer, toast } from "react-toastify";
import DpHeader from "../Header/DpHeader";
import ReactModal from "react-modal";
import ReactModal1 from "react-modal";
import ReactModal2 from "react-modal";
import { CiSearch } from "react-icons/ci";
import { MdOutlineFileUpload } from "react-icons/md";
import drop from "../Images/dragdropfill.png";
import { CiTrash } from "react-icons/ci";
import { GrView } from "react-icons/gr";
import upload from "../Images/uploadVideo.png";
import uploadimg from "../Images/uploadImg.png";
import ReactPlayer from "react-player";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ThreeDots } from "react-loader-spinner";
import { TbEdit } from "react-icons/tb"
import nodeContext from "../../Context/nodeContext";
import axios from "axios";
import { duration } from "@mui/material";
const fileTypes = ["JPEG", "PNG", "GIF"];
const videoTypes = ["MP4"];

const formatTime = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const formattedHours =
        hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";
    const formattedMinutes = `${minutes.toString().padStart(2, "0")}:`;
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return formattedHours + formattedMinutes + formattedSeconds;
};

export default function Content({ setCamp, Camp }) {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const [managePlay, setPlay] = useState([]);
    const [isloaded, setLoaded] = useState(false);
    const [saved, setSaved] = useState(true);

    const Websocket = useContext(SocketContext);

    // regarding playlist add popup
    const videoRef = useRef(null);

    const [currContentId, setCurrContentId] = useState("");
    const addPlay = async (item) => {
        let updated = [...managePlay];
        const foundIndex = updated.findIndex(
            (element) => element.playlistId === item.playlistId
        );
        updated[foundIndex].included = true;
        setPlay(updated);

        const r = await Axios.post("addToPlaylist", {
            username: sessionStorage.getItem("username"),
            playlistId: item.playlistId,
            contentId: currContentId,
        });

        if (r.data.Message === "Successfully uploaded the file") {
            const d = {
                username: sessionStorage.getItem("username"),
                playlistId: item.playlistId,
            };
            console.log("Emitting Update Playlist Socket:", d);
            Websocket.emit("update-playlist", d);
        }
    };

    const [imageFile, setImageFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);

    const handleImageUpload = (file) => {
        setImageFile(file);
    };

    const handleVideoUploadd = (file) => {
        setVideoFile(file);
    };

    // Popup

    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };
    const [btn, setbtn] = useState(false);
    const [isParentModalOpen, setIsParentModalOpen] = useState(false);
    const handlecontent = () => {
        setbtn(!btn);
    };
    const handledisplay = () => {
        setbtn(!btn);
    };

    const navigate = useNavigate();
    const ContentNaviagte = () => {
        navigate("/home");
    };

    //this is to be calculated, not an input param - old useState

    const [content, setContent] = useState([
        {
            title: "Food - Tactos",
            duration: "00:00",
            advertiser: "Oceanic Airlines Ad",
            thumbnailPath: food,
            contentId: "ididid",
            // selected: false,
        },
    ]);
    const [New, setNew] = useState({
        CampName: "",
        duration: "",
        user_slot: "",
        company: "",
        videoDetail: "",
        imgDetail: "",
        videoPath: "",
        description: "",
      });
    const [count,setCount]=useState(0);
    useEffect(() => {
        async function getContent() {
            const result = await Axios.get(
                "getAllContent?username=" + sessionStorage.getItem("username")
            );
            console.log(result.data.Content);
            setContent(result.data.Content);
            setLoaded(true);
        }
        getContent();
    }, [count]);

    const [dur, setdurr] = useState("");

    const handleVideoUpload = (videoFile) => {
        const file = videoFile;
        const videoObjectURL = URL.createObjectURL(file);
        videoRef.current.src = videoObjectURL;
        videoRef.current.addEventListener("loadedmetadata", () => {
            const durationInSeconds = Math.ceil(videoRef.current.duration);
            const formattedDuration = formatTime(durationInSeconds);
            console.log("Video duration:", formattedDuration);
            setdurr(formattedDuration);
            console.log("Set Duration");
        });
    };

    // add content fun
    const token = localStorage.getItem("Dp_token");
  
        const addContent = async (e) => {
            e.preventDefault();
            console.log(dur);
            setSaved(false);
            console.log(content);
            console.log(New);
            const username = sessionStorage.getItem("username");
            // var data = new FormData();
            // data.set("postedvideos", New.videoDetail);
            // data.set("thumbnail", New.imgDetail);
            // data.set("username", username);
            // data.set("title", New.title);
            // data.set("size", New.video.size);
            // data.set("duration", dur);
            // data.set("advertiser", New.company);
            // data.set("description", New.description);
            // data.set("mimetype", New.video.type.split("/")[1]);
            // console.log(data.get("postedvideos"));
            // var newContentId = "newIdId";

            const InstantContentId = Math.floor(Math.random() * 100000000).toString();

            const contentToUpload = {
                username: username,
                title: New?.CampName,
                size: New?.videoDetail?.size,
                duration: dur,
                advertiser: New?.company,
                description: New?.description,
                lastModifiedDate: New?.videoDetail?.lastModifiedDate,
                thumbnailMimetype: New?.imgDetail?.type?.split("/")[1],
                videoMimetype: New?.videoDetail?.type?.split("/")[1],
                contentId: InstantContentId,
            }
    
            // const headers = {
            //     "content-type": "multipart/form-data",
            // };

            
            try {
                console.log("presigned url *************");
                const thumbnailResponse = await Axios.post(
                    `/presigned-url?${new URLSearchParams({
                        type: "thumbnail",
                        contentFormat: New?.imgDetail?.type?.split("/")[1],
                        contentId: InstantContentId,
                    })}`,
                    {username: username},
                );

                console.log("this is response for thumbnail response-------------", thumbnailResponse);

                const { thumbnailUrl } = thumbnailResponse.data;
                console.log("PreSigned URL ****", thumbnailUrl);

                if (thumbnailResponse.status !== 200) {
                  throw new Error(
                    "Error uploading file: Thumbnail response status is not 200"
                  );
                }
            
                // Request a pre-signed URL from your backend video
                const VideoResponse = await Axios.post(
                  `/presigned-url?${new URLSearchParams({
                    type: "video",
                    contentFormat: New?.videoDetail?.type?.split("/")[1],
                    contentId: InstantContentId,
                  })}`,
                  {username: username},
                );

                console.log("this is response for video response-------------", VideoResponse);
            
                const { videoUrl } = VideoResponse.data;
                console.log("Presigned URL ****:", videoUrl);
                //upload thumbnail using presenged URL
                if (thumbnailResponse.status !== 200 && VideoResponse.status !== 200) {
                  throw new Error(
                    "Error uploading file: Thumbnail or Video response status is not 200"
                  );
                }

            
                await axios.put(thumbnailUrl, New?.imgDetail, {
                  headers: {
                    "Content-Type": New?.imgDetail.type,
                  },
                });
                console.log("thumbnail uploded ..........");
            
                // Upload the file using the pre-signed URL
            
                await axios.put(videoUrl, New?.videoDetail, {
                  headers: {
                    "Content-Type": New?.videoDetail.type,
                  },
                });
            
                console.log("PUT request successful");
                console.log("VIdeoFile uploaded successfully!..............");
            } catch(error) {
                console.error("Error uploading file:", error);
            }

            await Axios.post("addNewContent", contentToUpload)
                .then(async function(response) {
                    console.log(response.data);
                    const newContentId = response.data.contendId;
                    if (response.status === 200) {
                        console.log(response.data);
                        setSaved(true);
                        setContent([
                            {
                                title: New.CampName,
                                duration: New.videoDetail.size,
                                advertiser: New.company,
                                imgDetail:
                                  "https://s3.ap-south-1.amazonaws.com/everidoor2.0/" +
                                  sessionStorage.getItem("username") +
                                  "/Thumbnails/" +
                                  response.data.contentId +
                                  "." +
                                  New.imgDetail.type.split("/")[1],
                                contentId: newContentId,
                            },
                        ]);
                        console.log("setcontent data from addcontent api ", content);
                        setNew({
                            CampName: "",
                            duration: "",
                            user_slot: "",
                            company: "",
                            videoDetail:"",
                            imgDetail:"",
                            videoPath:"",
                            description:"",
                        });
                        setIsParentModalOpen(false);
                    }
                })
    
            // await Axios.post("addContent", data, {
            //     headers: headers,
            // })
            //     .then(function (response) {
            //         // console.log(JSON.stringify(response.data));
            //         newContentId = response.data.contentId;
            //         // console.log(response.status);
            //         if (response.status === 200) {
            //             setSaved(true);
            //             setbtn(!btn);
    
            //             setContent([
            //                 ...content,
            //                 {
            //                     title: New.title,
            //                     duration: dur,
            //                     advertiser: New.company,
            //                     thumbnailPath:
            //                         "https://s3.ap-south-1.amazonaws.com/everidoor2.0/" +
            //                         sessionStorage.getItem("username") +
            //                         "/Thumbnails/" +
            //                         response.data.contentId +
            //                         "." +
            //                         New.thumbnail.type.split("/")[1],
            //                     contentId: newContentId,
            //                 },
            //             ]);
            //             setNew({
            //                 title: "",
            //                 duration: "00:00",
            //                 company: "",
            //                 thumbnailPath: "",
            //                 videoPath: "",
            //                 description: "",
            //             });
            //             setIsParentModalOpen(false);
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
        };

    //CampForm
    const [CampForm, setCampForm] = useState(false);

    const selectfile = () => {
        document.getElementById("myfile").click();
    };

    const openParentModal = () => {
        setIsParentModalOpen(true);
    };

    const closeParentModal = () => {
        setIsParentModalOpen(false);
    };

    const [searchText, setSearchText] = useState("");
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = content.filter((i) => {
        const searchTerm = searchText;
        return i.title.includes(searchTerm);
    });
    const managePlaylist = async () => {
        setCurrContentId(content.contentId);
        setPopupVisible(true);
        // console.log("Manage Playlist");

        await Axios.get(
            "managePlaylist?username=" +
            sessionStorage.getItem("username") +
            "&contentId=" +
            content.contentId
        ).then((res) => {
            console.log("managePlaylist");
            console.log(res.data);
            setPlay(res.data.playlists);
        });
    };

    const handleDelete = async (contentId) => {

        const username = sessionStorage.getItem("username")||"ahdimainnskkkhugbsyuflabuijkhdaujio@yfyugkbwuihdiohwxcbniojwbnguinbiuFGVI.cuiwgdgbwuiohiugppiohbbkjhhkm";
        const contentIdToDelete = contentId;
        console.log(username);
        console.log(contentIdToDelete);
        await Axios.post("removeContent", {
            username: username,
            contentId: contentIdToDelete,
        });
        const updatedContent = content.filter(
            (item) => item.contentId !== contentIdToDelete
        );
        setContent(updatedContent); //can't use title in the long run
        console.log("delete");
        toast.success('Content deleted successfully', {
            position: 'top-right',
            autoClose: 3000,
        });
    };
    //   let videoUrl = "";
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const handleOpen = (contentId) => {
        const selectedContent = content.find(
            (item) => item.contentId === contentId
        );
        if (selectedContent) {
            const videoUrl = `https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/${selectedContent.contentId}.mp4`;
            console.log(videoUrl);
            setSelectedVideoUrl(videoUrl);
        }
        console.log("hello");
    };

    //   console.log(videoUrl);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [conId, setConId] = useState(0);
    const openModal1 = (id) => {
        setModalOpen(true);
        setConId(id);
    };

    const closeModal1 = () => {
        setModalOpen(false);
    };
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const [edit, setEdit] = useState(
        {
            title: "",
            id:"",
        }
    );
    const [previewImage, setPreviewImage] = useState(null);
    console.log(edit,"---------------------");
    const handleEdit = (id,title)=>{
        setEdit({
            ...edit, // Spread the previous state to retain other properties
            id: id,
            title: title
        });
     
    }
   
    const handleEditSubmit=async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('contentId', edit.id);
        formData.append('title', edit.title);
        formData.append('username', username);
        formData.append('mimetype', editedImage.type?.split("/")[1]);
        const blob = dataURItoBlob(previewImage);
        console.log("this is your blob-------------", blob);
        formData.append('thumbnail', blob);

        try{
            const res= await Axios.post("editContent",formData)
            if (res.status === 200) { 
                setCount(count)
                setShowModal(false)         
                setPreviewImage(null);  
                console.log("Edit successful from backend", res.data);

                const imageEditPresignedUrl = res.data.imageURL;
                console.log("this is your presigned edit image url", imageEditPresignedUrl);
                await axios.put(imageEditPresignedUrl, editedImage, {
                    headers: {
                      "Content-Type": editedImage.type,
                    },
                });

                console.log("image edit completed successfully");
            } else {
             
                console.error("Edit failed with status code:", res.status);
            }
           }
           catch(error){
    
           }
    }
    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
    const modalStyles = {
        overlay: {
            display: "flex",
            justifyContent: "center",
            //   alignItems: 'center',
        },
        content: {
            position: "relative",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
            border: "none",
            borderRadius: "8px",
            padding: "0",
            width: "calc(100% - 300px)", // Adjust the value as needed
            maxWidth: "800px", // Adjust the value as needed
            maxHeight: "calc(100% - 300px)", // Adjust the value as needed
            margin: "200px",
            background: "#000",
        },
    };
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleCheckboxClick = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            // If it is not selected, add it to the selectedRows
            setSelectedRows([...selectedRows, rowId]);
        }
        selectedRows.map((i) => console.log(i));
    };
    const handleSelectAllClick = () => {
        if (selectedRows.length === content.length) {
            // If all rows are selected, clear the selection
            setSelectedRows([]);
            setSelectAll(false);
        } else {
            // Otherwise, select all rows
            setSelectedRows(content.map((i) => i.contentId));
            setSelectAll(true);
        }

        selectedRows.map((i) => console.log(i));
    };


    const [page, setPage] = useState(1);
    const itemsPerPage = 12;
    var totalDisplay = content.length;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    console.log(content);
    console.log(totalPages);
    console.log(totalDisplay);
    const currentPageData = filteredData.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: '#MyContent',
                popover: {
                    title: 'Upload contents',
                    description: 'View, upload and manage all of your contents from here.', side: "right", align: 'start'
                }
            },
            {
                element: '#addContent',
                popover: {
                    title: 'Upload contents',
                    description: 'Effortlessly store your contents for quick retrieval and share those contents on screens.', side: "right", align: 'start'
                }
            },

        ]
    });


    const a = useContext(nodeContext);

    if (a.state.count === 1) {
        driverObj.drive();
        a.state.count = 0;
        console.log(a.count);
    }

    const [editedImage, setEditedImage] = useState();
 
    const handleFileChange = (e) => {

        const file = e.target.files[0];
        setEditedImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEdit(prevEdit => ({
            ...prevEdit,
            [name]: value
        }));
    };
    const [showModal, setShowModal] = React.useState(false);
    const username=sessionStorage.getItem("username");
    var imageUrlPNG = `https://s3.ap-south-1.amazonaws.com/everidoor2.0/${username}/Thumbnails/${edit.id}.png`;
    var imageUrlJPEG = `https://s3.ap-south-1.amazonaws.com/everidoor2.0/${username}/Thumbnails/${edit.id}.jpeg`;

    
    return (
        <>
            <div className="h-screen flex overflow-hidden w-screen ">
                <DpMenu />
                <div className="h-[100%] border-2 w-full bg-[#FFFDF9]">
                    <DpHeader />
                    <ToastContainer />
                    <div className=' flex flex-col gap-3 h-[90%] bg-[#FFFDF9] '>
                        <div className="flex p-3 items-center justify-between bg-[#FFFDF9] ">
                            <div className="flex items-center ">
                                <span
                                    onClick={ContentNaviagte}
                                    className="text-[#8E8E8E] cursor-pointer "
                                >
                                    Home
                                </span>{" "}
                                <IoChevronForwardOutline className="ml-2" />
                                <span className="text-[#555555] ml-3">
                                    My Content
                                </span>
                            </div>
                        </div>
                        <div className='p-3 w-fit '>
                            <p id="MyContent" className='text-2xl text-[#555555]'>My Content</p>
                        </div>
                        <div className=' flex flex-col  gap-3 h-[100%] '>
                            <div className='flex items-center ml-2 justify-center  text-white rounded-full'>
                                <div className=" flex flex-col gap-3 mt-3   w-full">
                                    <div className="px-2 flex justify-between items-center ">
                                        <div className=" flex gap-3 items-center ">
                                            <div className="flex justify-between items-center shadow-sm border bg-white rounded">
                                                <div className=" flex items-center justify-center h-10 w-10  ">
                                                    <CiSearch
                                                        size={24}
                                                        className=" text-gray-400"
                                                    />
                                                </div>
                                                <div className="flex items-center w-40">
                                                    <input
                                                        className=" outline-none h-fit  w-36 text-black"
                                                        type="text"
                                                        placeholder="Search Content"
                                                        value={searchText}
                                                        onChange={
                                                            handleSearchChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <button id="addContent" className=" flex items-center gap-1 py-[9px] px-3  rounded-sm text-[#FF9002] border-solid border-[0.5px] " onClick={openParentModal}  >
                                                <MdOutlineFileUpload size={24} className='' /> Upload Content </button>
                                        </div>
                                    </div>
                                </div >
                                <ReactModal
                                    isOpen={isParentModalOpen}
                                    onRequestClose={closeParentModal}
                                    contentLabel="Video Modal"
                                    className=" flex w-screen h-screen  bg-[#000000]/70 justify-center items-center"
                                    ariaHideApp={false}
                                >

                                    <div className="w-[70%] border justify-center bg-white items-center rounded-xl opacity-100  h-[80%]  flex flex-col gap-8">
                                        <div
                                            class={`${!saved ? "invisible" : "visible"
                                                }`}
                                        >
                                            <p className="text-[#001122] font-semibold text-2xl">
                                                Upload Content
                                            </p>
                                        </div>
                                        <video
                                            ref={videoRef}
                                            controls
                                            style={{ display: "none" }}
                                        />

                                        <form className="flex justify-center w-[90%]  flex-col gap-6">
                                            {/* loader start */}
                                            {saved ? (
                                                <>
                                                    <input
                                                        className=" rounded-md p-3 text-[#8E8E8E]  placeholder-[#8E8E8E] outline-none border border-[#555555]"
                                                        required
                                                        type="text"
                                                        value={New.title}
                                                        placeholder="Enter Content Name"
                                                        onChange={(e) =>
                                                            setNew({
                                                                ...New,
                                                                CampName: e.target
                                                                    .value,
                                                            })
                                                        }
                                                    />
                                                    <input
                                                        className=" rounded-md p-3 text-[#8E8E8E]  placeholder-[#8E8E8E] outline-none border border-[#555555]"
                                                        required
                                                        type="text"
                                                        value={New.company}
                                                        placeholder="Company"
                                                        onChange={(e) =>
                                                            setNew({
                                                                ...New,
                                                                company:
                                                                    e.target.value,
                                                            })
                                                        }
                                                    />
                                                    <input
                                                        className=" rounded-md p-3 text-[#8E8E8E]  placeholder-[#8E8E8E] outline-none border border-[#555555]"
                                                        required
                                                        type="text"
                                                        value={New.description}
                                                        placeholder="Describe the Advertisement"
                                                        onChange={(e) =>
                                                            setNew({
                                                                ...New,
                                                                description:
                                                                    e.target.value,
                                                            })
                                                        }
                                                    />
                                                    <div className="h-56 w-full flex justify-center">
                                                        <div className=" border-dashed flex justify-center items-center w-[80%] h-56  rounded-xl gap-10 border-2">
                                                            {New.videoPath == "" ? (
                                                                <>
                                                                    <div className="flex justify-center  w-[25%]">
                                                                        <label
                                                                            className=" cursor-pointer origin-center"
                                                                            htmlFor="postedvideos"
                                                                        >
                                                                            <img
                                                                                className=""
                                                                                src={
                                                                                    upload
                                                                                }
                                                                            />
                                                                            Enter
                                                                            Advertisement
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="flex flex-row justify-center  w-[25%]">
                                                                        <label
                                                                            className="cursor-pointer origin-center"
                                                                            htmlFor="postedvideos"
                                                                        >
                                                                            <img
                                                                                className="ml-6"
                                                                                src={
                                                                                    upload
                                                                                }
                                                                            />
                                                                            Advertisement
                                                                            Selected
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <input
                                                                required
                                                                type="file"
                                                                id="postedvideos"
                                                                style={{
                                                                    display: "none",
                                                                }}
                                                                placeholder="Enter Advertisement"
                                                                accept="video/*"
                                                                onChange={(e) => {
                                                                    setNew({
                                                                        ...New,
                                                                        videoDetail: e
                                                                            .target
                                                                            .files[0],
                                                                        videoPath:
                                                                            e.target
                                                                                .value,
                                                                    });
                                                                    handleVideoUpload(
                                                                        e.target
                                                                            .files[0]
                                                                    );
                                                                }}
                                                            />
                                                            {New.thumbnailPath ==
                                                                "" ? (
                                                                <div className="flex flex-row  justify-center  w-[25%]">
                                                                    <label
                                                                        className="cursor-pointer origin-center"
                                                                        htmlFor="thumbnail"
                                                                    >
                                                                        <img
                                                                            className=""
                                                                            src={
                                                                                uploadimg
                                                                            }
                                                                        />
                                                                        Enter Thumbnail
                                                                    </label>
                                                                </div>
                                                            ) : (
                                                                <div className="flex justify-center  w-[25%]">
                                                                    <label
                                                                        className="cursor-pointer  origin-center"
                                                                        htmlFor="thumbnail"
                                                                    >
                                                                        <img
                                                                            className="ml-2"
                                                                            src={
                                                                                uploadimg
                                                                            }
                                                                        />
                                                                        Thumbnail Selected
                                                                    </label>
                                                                </div>
                                                            )}
                                                            <input
                                                                required
                                                                type="file"
                                                                id="thumbnail"
                                                                style={{
                                                                    display: "none",
                                                                }}
                                                                placeholder="Enter Thumbnail"
                                                                onChange={(e) => {
                                                                    setNew({
                                                                        ...New,
                                                                        imgDetail:
                                                                            e.target
                                                                                .files[0],
                                                                        imgPath:
                                                                            e.target
                                                                                .value,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-[100%] h-12  flex justify-end gap-5 ">
                                                        <div className=" rounded-md w-32 text-xl  text-[#FF4D00]  border-2 flex items-center justify-center">
                                                            <button
                                                                type="reset"
                                                                onClick={
                                                                    closeParentModal
                                                                }
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                        <div className=" rounded-md w-32 text-xl  text-white bg-primary  border-2 flex items-center justify-center">
                                                            <button
                                                                id="Addbtn"
                                                                type="submit"
                                                                onClick={addContent}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="flex justify-center items-center">
                                                    <div className="load">
                                                        <ThreeDots
                                                            height="80"
                                                            width="80"
                                                            radius="9"
                                                            color="#FF4D00"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClassName=""
                                                            visible={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {/* loader completion */}
                                        </form>
                                    </div>
                                    {/* </div> */}
                                </ReactModal>
                            </div>
                            <div className=' flex flex-col gap-3  mx-4  h-[85%] bg-white shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]'>
                                <div className="w-[99%] h-[70%] ml-2 overflow-x-hidden items-center flex flex-col overflow-y-auto">
                                    <table className="border-spacing-y-4 w-[98%]  text-[#555555] text-left border-separate  ">
                                        <thead className="">
                                            <tr className=" text-left w-[98%]">
                                                <th scope="col" className=" pl-6 border-b " colSpan={5}>
                                                    <input
                                                        type="checkbox"
                                                        className="w-6 cursor-pointer h-6 mt-2  accent-[#FF8E00] text-white"
                                                        checked={selectAll}
                                                        onChange={handleSelectAllClick}
                                                    />
                                                </th>
                                                <th scope="col"
                                                    className="pl-12 border-b"
                                                >
                                                    Name
                                                </th>
                                                <th scope="col" className=" border-b">
                                                    { }
                                                </th>
                                                <th scope="col" className="py-4 
                                                         text-right   border-b" >
                                                    Last Modified On
                                                </th>
                                                <th scope="col" className=" border-b" >

                                                </th>
                                                <th scope="col" className=" text-right  pr-16  border-b">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <div id="default-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
   
</div>
                                        {/* <div className=" float-right mr-3  w-[100%] rounded-r-md border-r flex"> */}
                                        {isloaded ? (
                                            content.length > 0 ? (
                                                <tbody className="">
                                                    {currentPageData?.map((ind) => {
                                                        return (

                                                            <tr className={`${selectedRows.includes(ind.contentId) ? 'selected ' : ''} h-14 text-[#555555]`}
                                                                key={ind.contentId}
                                                            >
                                                                <td className=" bg-white pl-6 " colSpan={5}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="w-6 h-6 mt-2 cursor-pointer rounded-md accent-[#FF8E00]"

                                                                        checked={selectedRows.includes(ind.contentId)}
                                                                        onChange={() => handleCheckboxClick(ind.contentId)}
                                                                    />
                                                                </td>
                                                                <td className=" bg-white pl-12">
                                                                    <img className='w-16 h-12' src={ind.thumbnailPath} alt="img" />
                                                                </td>
                                                                <td className="bg-white  capitalize ">
                                                                    {ind.title}
                                                                </td>

                                                                <td className="bg-white text-center pl-96">
                                                                    {ind.lastModifiedDate}
                                                                </td>
                                                                <td></td>
                                                                <td className="bg-white justify-end ml-28 flex">
                                                                    <button className="pl-20  rounded-[20px]"
                                                                        onClick={() => { openModal(); { handleOpen(ind.contentId) } }} >
                                                                        <GrView />
                                                                    </button>
                                                                    <button
                                                                                className="   "
                                                                                type="button"
                                                                                onClick={() => {setShowModal(true); handleEdit(ind.contentId, ind.title)}}
                                                                            >
                                                                                      <TbEdit />
                                                                            </button>
                                                                           
                                                                            {showModal ? (
                                                                            <>
                                                                            
                                                                            <div
                                                                                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                                                            >
                                                                            <form onSubmit={handleEditSubmit}>

                                                                        
                                                                                <div className="relative w-auto my-6 mx-auto max-w-5xl">
                                                                                {/*content*/}
                                                                                <div className="border-0  rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                                                    {/*header*/}
                                                                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                                                                    <h3 className="text-3xl font-semibold">
                                                                                     Edit Your Content
                                                                                    </h3>
                                                                                    <button
                                                                                        className="p-1 ml-auto border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                                        onClick={() => setShowModal(false)}
                                                                                    >
                                                                                        <span className=" text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                                                        ×
                                                                                        </span>
                                                                                    </button>
                                                                                    </div>
                                                                                    {/*body*/}
                                                                                    <div className="relative  p-6 w-[1200px] h-[500px] ">
                                                                                 <p> content_ID: {edit.id}</p>
                                                                                 <p> thumbnail: </p>
                                                                                <div className="flex w-70 h-56 gap-3 ">
                                                                                 {imageUrlPNG && <img className="w-50 h-50 " src={imageUrlPNG} onError={(e) => e.target.style.display = 'none'} alt="PNG Image" />}

                                                                                 {imageUrlJPEG && <img onError={(e) => e.target.style.display = 'none'} className="w-50 h-50" src={imageUrlJPEG} alt="JPEG Image" />}
                                                                                 <div>
                                                                                 <input 
                                                                                        type="file" 
                                                                                        accept="image/png, image/jpeg" 
                                                                                        className="" 
                                                                                        onChange={handleFileChange} 
                                                                                    />
                                                                                    <img src={previewImage}  onChange={handleFileChange} alt="" className="w-40 h-40 mt-3 " />                          
                                                                                 </div>
                                                                                </div>
                                                                                     <p> Title: </p>
                                                                                 <input type="text" value={edit.title} name="title" onChange={handleInputChange}  className="border p-3 mt-3 capitalize"/>  
                                                                                    </div>
                                                                                    {/*footer*/}
                                                                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                                                                    <button
                                                                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                                        type="button"
                                                                                        onClick={() => setShowModal(false)}
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                    <button
                                                                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                                        type="submit"
                                                                                    >
                                                                                        Save Changes
                                                                                    </button>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </form>
                                                                            </div>
                                                                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                                                            </>
                                                                            ) : null}
                                                                    <button className=" rounded-[20px]"
                                                                    >
                                                                        <CiTrash
                                                                            className=""
                                                                            size={25} onClick={() => {
                                                                                openModal1(ind.contentId);
                                                                            }} />
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            ) : (
                                                <tbody className="">
                                                    <tr>
                                                        <td colSpan={10}>
                                                            <div className="w-[60%] ml-60 h-full ">
                                                                <div className='w-[98%] h-72 flex flex-col items-center justify-center rounded-lg border-2 border-dashed mt-3 border-[#9D9D9D]'>
                                                                    <div onClick={openParentModal} className="flex cursor-pointer items-center">
                                                                        <img className='w-100 h-100 mr-5  text-[#FF8E00]' src={drop} alt="Drop an Image" />
                                                                        <span className="text-xl ">Upload an Image / Video or <span className="text-[#FF8E00] underline text-lg">Upload a File</span></span>
                                                                    </div>
                                                                    <div><p>Nothing to display here!</p></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        ) : (
                                            <tbody className="">
                                                <tr>
                                                    <td colSpan={10}>
                                                        <div className="w-full h-full flex justify-center items-center ">
                                                            <ThreeDots
                                                                height="80"
                                                                width="80"
                                                                radius="9"
                                                                color="#fe4d00"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                        {/* </div> */}
                                    </table>

                                    <ReactModal1
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        contentLabel="Video Modal"
                                        className="modal"
                                        style={modalStyles}
                                        ariaHideApp={false}
                                    >
                                        <ReactPlayer
                                            playing={modalIsOpen}
                                            url={selectedVideoUrl}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                        />
                                    </ReactModal1>
                                    <ReactModal2
                                        isOpen={modalOpen}
                                        onRequestClose={closeModal1}
                                        contentLabel="Video Modal"
                                        className=" flex w-screen h-screen  bg-[#000000]/70 justify-center items-center"
                                        ariaHideApp={false}
                                    >

                                        <div className="w-[20%] border   bg-white items-center rounded-xl opacity-100  h-[20%]  flex flex-col gap-8">
                                            <div className="my-4 ">
                                                <p className="text-[#001122] font-normal text-lg">
                                                    Are You Want to delete this Content ?
                                                </p>
                                                <div className="flex my-6 gap-8 items-center justify-center">
                                                    <div className="border rounded-md w-20 hover:bg-[#FF8E00] hover:text-white" onClick={() => { closeModal1(); handleDelete(conId) }}>
                                                        <button >Yes</button>
                                                    </div>
                                                    <div className="border rounded-md w-20 hover:bg-[#FF8E00] hover:text-white" onClick={closeModal1} >
                                                        <button>No</button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        {/* </div> */}
                                    </ReactModal2>

                                </div>

                                <div className='w-[100%] h-[10%]  bg-white flex  items-center justify-center '>
                                    <div className="w-[97%] border-t flex items-center justify-between pt-2 ">
                                        <div>
                                            <p className="flex  text-[#555555] select-none"> Showing {totalDisplay} of {itemsPerPage} Users
                                            </p>
                                        </div>
                                        <div>
                                            <Stack spacing={10}>
                                                <Pagination
                                                    color="secondary"
                                                    defaultPage={page}
                                                    onChange={(event, value) => {
                                                        setPage(value);
                                                    }}
                                                    count={totalPages} // Use the totalPages calculated from filtered data
                                                    variant="outlined"
                                                    shape="rounded"
                                                />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}
// {
//     /* In this part kavin, Make it such that the above functionality and variable names do not change */
// }
// {
//     /* <label htmlFor="file">
//                 {
//                     (New.videoPath =='')?
//                     <p id='myfile' style={{cursor:"pointer"}} onClick={selectfile}>Select file</p>:
//                     <p id='myfile' style={{cursor:"pointer"}} onClick={selectfile}>File Selected : {New.videoPath}</p>
//                 }
//             </label> */
// }