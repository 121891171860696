import React, { useContext, useEffect, useState, useRef } from "react";
import Axios from "../../Axios/axios";
import "react-step-progress-bar/styles.css";
import ReactModal from "react-modal"; 
import { useLocation, Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import Menu from "../../Advertise/Menu/index";
import Header from "../../Header/AdHeader";
import { IoChevronForwardOutline } from "react-icons/io5";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import { SocketContext } from "../../../socketcontext";
import { FaShareFromSquare } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
function UserDescritpion() {
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const campaignRequestId = params.get("campId");

  const [adData, setAdData] = useState({
    steps: ["Pending", "Pending", "Pending"],
  });
  
  const [newdata, setNewData] = useState([]);
  const [location, setLocation] = useState({ lat: 28.7041, lng: 77.1025 });
  const [locations, setLocations] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [stepZero, setStepZero] = useState(false);
  const [screens, setScreens] = useState([]);
  const [screensName, setScreenName] = useState();
  const baseURL = "https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/";
  const [videoUrl, setvideoUrl] = useState(baseURL + '00000001.mp4');

  const thumnailURL = "";
  const [imgUrl, setimgUrl] = useState(thumnailURL);
  const [open, setOpen] = useState(true);
  const [upload, setUpload] = useState(false);
  const thumbnailInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const [isParentModalOpen, setIsParentModalOpen] = useState(false);
  const [campaignVideo, setCampaignVideo] = useState();
  const [campaignThumbnail, setcampaignThumbnail] = useState();
  const [videoDuration, setVideoDuration] = useState();
  const [videoSize, setvideoSize] = useState();
  const username = sessionStorage.getItem("username");
  const [copied , Setcopied] = useState(-1) ;
  useEffect(() => {
    let calculatedPercentage = 0;
    if (adData.steps[0] === "Approved") {
      setStepZero(true);
    }
    if (adData.steps[1] === "Approved") {
      calculatedPercentage = 50;
    }
    if (adData.steps[2] === "Approved") {
      calculatedPercentage = 100;
    }
    setPercentage(calculatedPercentage);
  }, [adData.steps]);

  useEffect(() => {
    async function fetchDetails() {
      await Axios.get(
        `getaAdvertiser?campaignRequestId=${campaignRequestId}&username=${sessionStorage.getItem(
          "username"
        )}`
      ).then((res) => {
        console.log(
          "data for updating campaing adshjshdnjsanjsndjaksnlkjadjalsnjl",
          res
        );
        console.log(res.data.result);
        setAdData(res.data.result);
        setScreenName(res.data.result.campaignName);
        // setvideoUrl(res.data.result.content_to_be_played);
        setimgUrl(res.data.result.thumbnail_for_content_to_be_played);
        setNewData(res.data.result);
        setScreens(res.data.result.selectedScreens);
        console.log(
          "screendata---------->>>>>>>>",
          res.data.result.selectedScreens
        );
      });
    }
    fetchDetails();
  }, []);

  console.log("sds", adData);

  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const handleUpdateCompaign = (e) => {
    const file = e.target.files[0];
    console.log("from file ", file);
    const video = document.createElement("video");
    const videoURL = URL.createObjectURL(file);
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      if (video.duration > adData.duration) {
        console.log("duration of video", video.duration);
        alert(
          `Please choose a video with duration less than or equal to ${adData.duration} seconds.`
        );
        return;
      } else {
        setCampaignVideo(file);
        setVideoDuration(video.duration);
        setvideoSize(file.size);
        console.log("video", file);
      }
      URL.revokeObjectURL(videoURL); // cleanup
    };
    video.src = videoURL;
  };
  const [thumbnail_mimeType , setThumbnail_mimeType] = useState()
  console.log(thumbnail_mimeType);
  const handleUpdateThumbnail = (e) => {
    const thumbnail = e.target.files[0];
    setThumbnail_mimeType(thumbnail.type.split('/')[1])
    setcampaignThumbnail(thumbnail);
    console.log("thumbnail", thumbnail);
  };

  const token = localStorage.getItem("token");
  const changeCampaign = async (e) => {
    e.preventDefault();
    setUpload(true);
    console.log("changecampaign------___>>>>>>>.", token);
    const InstantContentId = Math.floor(Math.random() * 100000000).toString();

    if (!campaignVideo || !campaignThumbnail) {
      alert("pls select thumbnail !");
      setUpload(false);
      return;
    }

    try {
      const thumbnailResponse = await Axios.post(
        `advertiser/presigned-url?${new URLSearchParams({
          type: "thumbnail",
          contentFormat: thumbnail_mimeType,
          contentId: InstantContentId,
        })}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { thumbnailUrl } = thumbnailResponse.data;
      console.log("PreSigned URL ****", thumbnailUrl);

      if (thumbnailResponse.status !== 200) {
        throw new Error(
          "Error uploading file: Thumbnail response status is not 200"
        );
      }

      const VideoResponse = await Axios.post(
        `advertiser/presigned-url?${new URLSearchParams({
          type: "video",
          contentFormat: "mp4",
          contentId: InstantContentId,
        })}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { videoUrl } = VideoResponse.data;
      console.log("Presigned URL ****:", videoUrl);

      if (thumbnailResponse.status !== 200 && VideoResponse.status !== 200) {
        throw new Error(
          "Error uploading file: Thumbnail or Video response status is not 200"
        );
      }

      await Axios.put(thumbnailUrl, campaignThumbnail, {
        headers: {
          "Content-Type": thumbnail_mimeType,
        },
      });
      console.log("thumbnail uploded ..........");

      await Axios.put(videoUrl, campaignVideo, {
        headers: {
          "Content-Type": "mp4",
        },
      });

      console.log("PUT request successful");
      console.log("VIdeoFile uploaded successfully!..............");
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUpload(false);
      if (thumbnailInputRef.current) {
        thumbnailInputRef.current.value = '';
      }
      if (videoInputRef.current) {
        videoInputRef.current.value = '';
      }
    }
    
    const username = sessionStorage.getItem("username");
    const updatecampaignData = {
      campaignRequestId: adData.campaignRequestId,
      contentId: InstantContentId,
    };

    console.log(
      "data need to be send for update campaign----------->>>>>",
      updatecampaignData
    );

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log(
      "instant data what will be sent ------>>>>>>>>>",
      updatecampaignData
    );

    const addcontent = {
      username: username,
      title: adData.campaignName,
      size: videoSize,
      duration: videoDuration,
      advertiser: "",
      description: "",
      thumbnailMimetype: campaignThumbnail?.type?.split("/")[1],
      videoMimetype: campaignVideo?.type?.split("/")[1],
      contentId: InstantContentId,
    };
    const addContentResponse = await Axios.post(
      "advertiser/addNewContent",
      addcontent,
      {
        headers: headers,
      }
    );
    console.log("addcontent api response", addContentResponse);

    const data = await Axios.put(
      "advertiser/editInstantCampaignRequest",
      updatecampaignData,
      {
        headers: headers,
      }
    );
    if(data.status == 200){
      toast.success("Campaign updated !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else{
      toast.error("Campaign updation failed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    console.log("editInstantcampaign request response", data);
  };

  //get Current Video what is playing in the screen
  //For Dropdown live campaign
  const Websocket = useContext(SocketContext);
  const videoRef = useRef(null);
  const [showContentMap, setShowContentMap] = useState({});

  const toggleShowContent = (index) => {
    setShowContentMap((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    const screen = screens[index];
    
    Websocket.on("device-disconnecting", ({ status}) => {
        console.log("Connectivity Status Update " + status);
        if( status == false){
            setvideoUrl(baseURL + '00000001.mp4');
        }
    });
    
    Websocket.emit("join-device-room", {
      username: screen.username,
      screenId: screen.screenId,
    });
    Websocket.emit("get-current-video", {
      username: screen.username,
      screenId: screen.screenId,
    });

    console.log(
      "get-current-video emiited from mydisplay***********",
      username,
      screens.screenId
    );
    Websocket.on("ack-get-current-video", ({ contentId, seekTo }) => {
      Websocket.off("get-current-video");
      console.log("Current Video Details------->>>>>>>>>>");
      console.log("contentid from ack-get-current-video", contentId);
      setvideoUrl(baseURL + contentId + ".mp4");
      console.log('inside ack-get-current-video--------------->>>>>>>>>>>>',videoUrl);
      videoRef.current?.seekTo(seekTo / 1000)
    });
    console.log("ack-get-current-video called------------->");
    Websocket.on("update-video-on-webpage", ({ contentId }) => {
      console.log("Update Video on webpage");
      console.log(contentId);
      setvideoUrl(baseURL + contentId + ".mp4");
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

  // Function to handle opening the modal
  const handleOpenModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const CopyUrl =(i ,screenId , username)=>{
    console.log("copyURL screenID and username ------>>>>>>>",screenId , username);
    const url = `http://localhost:3000/compaignURL/${screenId}/${username}`;
   
    navigator.clipboard.writeText(url)
    .then(() => {
      console.log("URL copied to clipboard:", url);
      Setcopied(i);
      // navigate(url);
    })
    .catch(err => {
      console.error("Failed to copy URL to clipboard:", err);
    });
  }

  return (
    <>
      <div className="w-screen flex h-screen ">
        <div className=" ">
          <Menu open={open} setOpen={setOpen} handleLogout={handleLogout} />
        </div>
        <ToastContainer/>
        <div className="flex flex-col h-screen w-[100%]  bg-gray-100">
          <div className="border bg-[white] ">
            <Header />
          </div>

          <div className="flex bg-gray-100 items-center justify-between p-5">
            <div className=" flex items-center ">
              Home <IoChevronForwardOutline /> Schedules{" "}
              <IoChevronForwardOutline />
              <p className="uppercase">{screensName}</p>
            </div>

            {adData.status === 1 && 
              <div className="border p-5 ">
                <form className="">
                  <h2>Update your Content : </h2>
                  <div>
                    ThumbNail :{" "}
                    <input ref={thumbnailInputRef} onChange={handleUpdateThumbnail} type="file" />
                    Campaign Video :{" "}
                    <input ref={videoInputRef} onChange={handleUpdateCompaign} type="file" />
                    <button
                      onClick={changeCampaign}
                      type="submit"
                      className="border bg-orange-500 text-white w-[100px]"
                      disabled={upload}
                    >
                      {!upload ? "Upload" : ""}
                      {upload && (
                        <ThreeDots
                            height="20"
                            width="50"
                            radius="9"
                            color="#ffffff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            }

          </div>
          <div className="h-[100%] overflow-y-scroll">
            <div className="w-full  h-auto  p-5   flex  items-center justify-center  ">
              <div className="p-4 w-[100%]   border rounded-md border-gray-100 shadow-lg bg-white flex flex-col  ">
                <div className="w-[100%] flex flex-col justify-between h-18 mb-2 ">
                  <div className="flex text-[14px] items-center justify-between">
                    <div className="text-[#555555FF]">
                      Campaign ID:
                      {adData.campaignRequestId}
                    </div>
                    <div className="text-[#555555FF]">
                      Schedule Date:{adData.fromDate}
                    </div>
                  </div>
                  <div className="text-[24px] mt-2 uppercase">
                    {screensName}
                  </div>
                </div>
                <div className="flex flex-col gap-5  w-full h-[100%]">
                  <div className="">
                    <h1 className="text-[20px] text-[#555555FF] font-bold">
                      Total Advertised Display:&nbsp;
                      {screens.length}
                    </h1>

                  </div>
                  
                  <div className="">
                    <table className="border-spacing-y-2  w-full border-separate  bg-white">
                      <thead className="bg-gray-50  capitalize">
                        <tr className=" bg-[#DEDEDE] h-14 rounded-full">
                          <th className="  rounded-l-full font-medium ">
                            S/No.
                          </th>
                          <th className="py-4 font-medium ">screenId</th>
                          <th className="py-4 font-medium ">screenName</th>
                          <th className="py-4 font-medium ">type</th>
                          <th className="py-4 font-medium ">type_val</th>
                          <th className="py-4 font-medium ">username</th>
                          <th className="py-4 font-medium ">Action</th>
                          <th className="py-4 font-medium">Share URL</th>
                        </tr>
                      </thead>
                      <tbody className="text-center bg-white">
                        {screens.map((users, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td className="py-2">{i + 1} </td>
                              <td className="py-2">{users.screenId} </td>
                              <td className="py-2">{users.screenName} </td>
                              <td className="py-2">{users.type} </td>
                              <td className="py-2">{users.type_val} </td>
                              <td className="py-2">{users.username} </td>

                              <td className="py-2">
                                <button onClick={() => toggleShowContent(i)} className="border bg-orange-500 text-white">
                                  View Live Campaign
                                </button>
                              </td>
                              <td className="py-2">
                                <button onClick={() => CopyUrl( i,users.screenId , users.username)} className="border flex items-center gap-2 bg-orange-500 text-white">
                                <FaShareFromSquare /> {copied == i ? 'Copied URL' : 'Share URL'}
                                </button>
                              </td>
                            </tr>
                            {/* <tr>
                              <td colSpan="6">
                              {showContentMap[i] && (
                                 <div>
                                  <h1>Additional Content</h1>
                                  <p>This is additional content for FAQ or details.</p>
                                  </div>
                                )}
                               </td>
                            </tr> */}
                           

                            <ReactModal
                              isOpen={showContentMap[i]}
                              onRequestClose={() => toggleShowContent(i)} // Close the modal when clicking outside or pressing the escape key
                              contentLabel="Additional Content"
                              style={{
                                content: {
                                  width: "70%", // Set the width
                                  height: "70%", // Set the height
                                  top: "50%",
                                  left: "50%",
                                  right: "auto",
                                  bottom: "auto",
                                  marginRight: "-50%",
                                  transform: "translate(-50%, -50%)", // Center the modal
                                },
                              }}
                            >
                                {/* {users.screenType === 'Landscape'&& ( */}
                                    <ReactPlayer
                                        ref={videoRef}
                                        url={videoUrl}
                                        controls={false}
                                        loop={"true"}
                                        playing={"true"}
                                        width="100%"
                                        height="100%"
                                        className="cursor-pointer"
                                    />
                                 {/* )} */}
                                
                                {/* {users.screenType === 'Portrait'&& ( */}
                                    {/* <ReactPlayer
                                        ref={videoRef}
                                        url={videoUrl}
                                        controls={false}
                                        loop={"true"}
                                        playing={"true"}
                                        width="70%"
                                        height="190%"
                                        className="cursor-pointer"
                                    />
                                )} */}
                            </ReactModal>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDescritpion;
