import React, { useState, useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import Axios from "../Axios/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactModal from "react-modal";



const Signup = ({ setSigned }) => {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");

    const [active1, setActive1] = useState(true);
    const [active2, setActive2] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const username = sessionStorage.getItem("username");
        if (username) {
            navigate("/Signup");
        }
    }, []);

    useEffect(() => {
        if (active1 === true && active2 === false) {
            setRole("advertiser");
        } else if (active1 === false && active2 === true) {
            setRole("display-provider");
        }
        console.log(role);
    }, [active1, active2, role]);

    const notify = (text) =>
        toast.warn(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const notifyBalance = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyAlready = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyAccount = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyUserDetalis = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const NotifyOtp = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });


   
    const sendOTP = async () => {
        const response = await Axios.post("sendOTP", {
            username: username,
        })
    }
    const register = async (e) => {
        e.preventDefault();
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("role", role);
        console.log(sessionStorage.getItem("role"));
        const response = await Axios.post("register", {
            name: name,
            username: username,
            password: password,
            role: role,
        }).then((response) => {
            console.log(response);
            console.log(response.data, response);
            if (response.status === 200) {
                setSigned(true);
                openParentModal();
                sendOTP();
            } if (response.status === 201) {
                console.log("User already exists");
                notify("User Already Registered, Please Sign In instead");
                navigate("/Signup");
            }
            if (response.status === 202) {
                console.log("Email already registered");
                notify("Email already registered, Please Sign In instead");
                navigate("/Signup");
            } else {
                console.log("Signup failed");
            }
        });
    };

    const newUsername = sessionStorage.getItem("username")
    console.log(newUsername);
    const [formDataOtp, setFormDataOtp] = useState({
        otp: "",
    });

    const handleInputChangeOtp = (event) => {
        const { name, value } = event.target;
        setFormDataOtp({ ...formDataOtp, [name]: value });
    };
    const [parentModalOpen, setParentModalOpen] = useState(false);


    const openParentModal = () => {
        setParentModalOpen(true);
    };

    const closeParentModal = () => {
        setParentModalOpen(false);
    };


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="[#FF4D00]"
            />
    
            <div className="overflow-y-auto flex h-full min-h-screen w-screen">
                <div className="w-full h-full">
                    <img
                        src="signIn.png"
                        alt="everidoor screen"
                        className="object-cover w-full h-screen"
                    />
                </div>
                <div className="bg-[#f2f2f2] w-full ">
                    <p className="text-[48px] w-[500px] leading-none font-[TTRamillasTrialMedium]  text-[#424242] text-center mx-auto mt-12">
                        Welcome To The<br></br>
                        <span className="text-[#340c42]"> Digital</span>{" "}
                        <span className="text-[#FE7E02] ">Campaign</span> Era!
                    </p>
                    <div className="w-[50%] h-[56%] mx-auto">
                        <div className="w-full flex items-center justify-center">
                            <div className="w-[50%] ml-8 border border-[#FE7E02]">
                            </div>
                        </div>
                        <div className="w-full mt-4 mb-2  flex items-center justify-center">
                            <p className="text-[#555555]">Are you ?</p>
                        </div>

                        <form
                            onSubmit={(e) => register(e)}
                            className="bg-white rounded-md p-6 h-[100%] shadow-xl text-[18px] pt-10"
                        >
                            <div className="w-[100%] items-center justify-center  bg-[#f7f7f7] flex rounded-md mb-4">
                                <div className="w-[100%]">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setActive1(true);
                                            setActive2(false);
                                        }}
                                        className={`w-[50%] rounded-md transition-all duration-300 ${active1
                                            ? "bg-[#FE7E02] text-white"
                                            : "bg-[#f7f7f7] text-black"
                                            }`}
                                    >
                                        Advertiser
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setActive1(false);
                                            setActive2(true);
                                        }}
                                        className={`w-[50%] rounded-md transition-all duration-300 ${active2
                                            ? "bg-[#FE7E02] text-white"
                                            : "bg-[#f7f7f7] text-black"
                                            }`}
                                    >
                                        Display Network
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <input
                                    type="text"
                                    id="name"
                                    placeholder="Full Name"
                                    onChange={(e) => setName(e.target.value)}
                                    className="w-full rounded-md border-[1px] outline-none placeholder-sm border-gray-300 px-4 py-2"
                                />
                            </div>
                            <div className="">
                                <input
                                    type="email"
                                    id="Email"
                                    placeholder="Enter your email"
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    className="w-full rounded-md border-[1px] outline-none border-gray-300 px-4 py-2 mt-4"
                                />
                            </div>
                            <div className="">
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="Enter your password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    className="w-full rounded-md border-[1px] outline-none border-gray-300 px-4 py-2 mt-4"
                                />

                            </div>

                            <div className="w-full flex flex-col mt-6 items-center justify-center">
                                <button
                                    id="sign"
                                    type="submit"
                                    className="w-full mt-4 py-2 bg-[#FE7E02] text-white rounded-md"
                                >
                                    Create User
                                </button>
                             
                            </div>
                           
                            <div className="w-full flex items-center justify-center">
                                <div className="flex items-center">
                                    <p style={{ opacity: 0.6 }}>
                                        Already have an account?
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            sessionStorage.clear();
                                            navigate("/SignIn");
                                        }}
                                        className="text-[#FE7E02] underline"
                                    >
                                        Log In
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
        </>
    );
};

export default Signup;