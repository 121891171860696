import React, { useState } from "react";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import {
    MdAddToQueue,
    MdOutlineManageAccounts,
} from "react-icons/md";
import { RxCalendar, RxDashboard } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import Edlogo from "../../Images/everydoor.png";
import nodeContext from "../../../Context/nodeContext";

const Menu = (props) => {
    const navigate = useNavigate();
   
    const [open, setOpen] = useState(true);

    const menu = [
        {
            title: "Dashboard",
            icons: <RxDashboard size={24} />,
            to: "/home",
        },
        {
            title: "Create Ad",
            icons: <MdAddToQueue size={24} />,
            to: "/advertiser/CreateCampaign",
        },
        {
            title: "View Ads",
            icons: <RxCalendar size={24} />,
            to: "/advertiser/ViewCampaign",
        },
        {
            title: "Account",
            icons: <MdOutlineManageAccounts size={24} />,
            to: "/advertiser/Account",
        },
    ];

    // const a = useContext(nodeContext);
    // console.log("asdas", a.state.count);
    // const handler = () => {
    //     a.updateFunction();
    // }
    return (
        <div className="h-full">
            <div
                className={`${
                    open ? "xl:w-[16.5rem] w-24" : "w-24"
                } p-5 pt-3 border bg-white ease-in-out duration-500 relative h-screen shadow-lg`}
            >
                <div className="flex  mb-6">
                    <div className=" h-28">
                        <img className="w-64 h-26 " src={Edlogo} alt="" />
                        <hr class="w-38 h-1 bg-gray-500 opacity-80 rounded  dark:bg-gray-500" />
                    </div>
                </div>
                <div className={` origin-left  text-xl text-black  items-center gap-1  ease-in-out duration-300 ${!open && "scale-24"}`}>
                    <div
                        className={` origin-left  text-xl text-black font-bold  items-center gap-1  ease-in-out duration-700 `}
                    >
                        <div
                            className={`bg-gray-100 ${open ? "xl:ml-4" : "ml-0"
                            } p-3  ease-in-out duration-700 rounded-xl ${
                                open ? "xl:w-44  " : "w-18"
                            } ${open ? "h-14" : "h-14"}`}
                        >
                            <span
                                className={`origin-left ease-in-out ${!open ? "hidden":"hidden md:block" } ${open ? "xl:ml-2" : "ml-0"
                                }  duration-500 cursor-default tracking-wide text-[15px] font-bold text-[#340c42]`}
                            >
                                ADVERTISER
                            </span>
                            <BsArrowLeftCircleFill size={32} 
                            className={`absolute ease-in-out duration-500 cursor-pointer top-[160px]  ${!open && "rotate-180"} ${open ? "xl:left-[10.5rem]" : "left-8"}`}
                                onClick={() => setOpen(!open)}
                            />
                        </div>
                    </div>
                    <ul className={`${open? "": "flex flex-col items-center justify-center"}`}>
                        {menu.map((item, index) => (
                            <li
                                key={index}
                                className={`${
                                    item.gap ? "mt-4" : "mt-4"
                                } flex items-center text-black gap-x-4 p-2 rounded-md cursor-pointer transition-all duration-500 hover:bg-[#fe4d00] hover:text-white`}
                            >
                                <Link
                                    to={item.to}
                                    className="flex items-center gap-2 w-full h-full"
                                >
                                    <button type="button" className="xl:w-full h-full flex gap-2">
                                        <span className={`${open ? " scale-0 md:scale-100 md:block  " : "block"} origin-center`}>{item.icons}</span>
                                        <p className={`${open ? "hidden scale-0 md:block md:scale-100" : "hidden scale-0"} origin-center`}>
                                            {item.title}
                                        </p>
                                    </button>
                                </Link>
                            </li>
                        ))}
                       
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Menu;
