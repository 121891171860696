import { useEffect } from "react";
import { useState } from "react";
import ReactModal from "react-modal";
import Axios from "../../Components/Axios/axios";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SocketContext } from "../../socketcontext";
import Cart from "./Cart";
import ads from "../../Components/Images/screen.png";
import { useNavigate } from "react-router-dom";
import DpHeader from "../../Components/Header/DpHeader";
import ManagerMenu from "../Menus/ManagerMenu"
import { ThreeDots } from "react-loader-spinner";
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { IoChevronForwardOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { BiPlus } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";


const notify = (text) =>
    toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

const notifySuccess = (text) =>
    toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

function ManagerScreens({ setSigned }) {
    const Websocket = useContext(SocketContext);
    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
   
        const isDuplicate = Datas.some(
            (data) =>
                data.screenId === New.screenId ||
                data.screenName === New.screenName

        );
        var newDeviceId = "";

        !isDuplicate
            ? Axios.post("addScreen", {
                username: sessionStorage.getItem("username"),
                screenId: New.screenId,
                screenName: New.screenName,
                screen_address: New.screen_address,
                TYPE: New.TYPE,
                TYPE_VAL: New.TYPE_VAL,
            }).then((response) => {
      
                if (response.status === 200) {
                    newDeviceId = response.data.screenId;
      
                    notifySuccess("Screen Added Successfully");
                    const d = {
                        username: sessionStorage.getItem("username"),
                        screenId: New.screenId,
                    };
                    Websocket.emit("connectClient", d);
                    console.log("Emitting connectClient" + d);
                    setDatas([
                        ...Datas,
                        {
                            screenId: New.screenId,
                            screenName: New.screenName,
                            screen_address: New.screen_address,
                            date_of_addition: "",
                        },
                    ]);
                } else if (response.status === 202) {
                    notify("Screen Code Wrong");
                }
                // setbtn(!btn);
            })
            : notify("Duplicated");

        setNew({});
        e.target.reset();
        setIsParentModalOpen(false);
    };

    const [New, setNew] = useState({
        screenId: "",
        screenName: "",
        screen_address: "",
        TYPE: "",
        TYPE_VAL: "",
    });

    // console.log(New, "myvalue")
    const [Datas, setDatas] = useState([
        {
            screenId: "",
            screenName: "",
            screen_address: "",
            date_of_addition: "",
            TYPE: "",
            TYPE_VAL: "",
        },
    ]);

    const [Values, setValues] = useState([]);
    const [type, setType] = useState(false);
    const [isloaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);

    function handleType() {
        setType(true);
    }
    let details = [
        {

            type: "Hotels",
            type_val: [
                "The Oberoi",
                "The Taj",
                "Ashoka",
                "Hyatt Regency",
                "Eros Hotel",
                "The Lalit",
                "Hotel Novotel",
                "Radisson Blu",
                "The Leela",
                "The Imperial",
                "Others",
            ],
        },
        {
            type: "Restaurants",
            type_val: [
                "Indian Accent - The Manor",
                "Bukhara - Itc Maurya",
                "On The Waterfront - The Lodhi",
                "Sevilla - The Claridges",
                "64/6 - Country Inn Suites",
                "Others",
            ],
        },
        {
            type: "Spa and Fitness Centers",
            type_val: [
                "Chi, The Spa",
                "Quan Spa",
                "Blue Terra Spa",
                "NeoVeda Spa",
                "Others",
            ],
        },
        {
            type: "Clubhouse",
            type_val: ["Lutyens Delhi", "Others"],
        },
        {
            type: "Course",
            type_val: ["Molog", "Others"],
        },
        {
            type: "Pro Shop",
            type_val: ["Molog", "Others"],
        },
        {
            type: "Airport Lounges",
            type_val: ["Air India T3", "Indigo T3", "Others"],
        },
        {
            type: "Others",
            type_val: ["Molog"],
        },
    ];

    const handleTypeChange = (e) => {
        setNew({ ...New, TYPE: e.target.value });
        setValues(
            details.find((elem) => elem.type === e.target.value).type_val
        );
    };

    const [isParentModalOpen, setIsParentModalOpen] = useState(false);

    const openParentModal = () => {
        setIsParentModalOpen(true);
    };

    const closeParentModal = () => {
        setIsParentModalOpen(false);
    };
    const [categoryFilter, setCategoryFilter] = useState("All");
  

    const [screen, setScreen] = useState("");
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        console.log("Search Text:", e.target.value);
    };

    const handleCategoryChange = (e) => {
        setCategoryFilter(e.target.value);
        console.log("Category Filter:", e.target.value);
    };

    useEffect(() => {
        async function getScreens() {
            await Axios.post("getScreenList", {
                username: sessionStorage.getItem("username"),
            }).then((response) => {
                console.log(response.data.screens);
                setDatas(response.data.screens);
                setLoaded(true);
            });
        }

        if (sessionStorage.getItem("username")) {
            getScreens();
            Websocket.emit("join-campaign-room", {
                username: sessionStorage.getItem("username"),
            });
        } else {
            setSigned(false);
            navigate("/");
        }
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
    });

    const venueTime = () => {
        Websocket.emit("venueTime", {
            username: sessionStorage.getItem("username"),
            screenId: New.screenId,
            type: New.TYPE,
        });
        console.log("emitting");
    };
    const itemsPerPage = 12;
    var totalDisplay = Datas.length;

    const openParentModal1 = () => {
      
        setIsParentModalOpen(true);
    };

   
    const filteredData = Datas.filter((item) => {
        var searchTerm = searchText.toLowerCase();
        const category = categoryFilter.toLowerCase();

        if (category === "inactive") {
            if (item.connectivity_status === false) return item;
        } else if (searchText === "active") {
            if (item.connectivity_status === true) return item;
        }

        return (
            (category === "all" ||
                item.screenType.toLowerCase() === category) &&
            (item.screenId.toLowerCase().includes(searchTerm) ||
                item.screenName.toLowerCase().includes(searchTerm) ||
                item.screen_address.toLowerCase().includes(searchTerm))
        );
    });

    // Calculate the total number of pages based on the filtered data and itemsPerPage
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Get the current page of data based on the selected page
    const currentPageData = filteredData.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    // console.log("Hello", Datas.ScreenType);

    const MyDisplayNaviagte = () => {
        navigate("/Manager");
    };




    return (
        <>
            <div className="h-screen flex w-screen text-[#555555]">
                <ManagerMenu />
                <div className="h-[100%] overflow-hidden w-[100%] bg-[#FFFDF9]">
                    <ToastContainer />
                    <DpHeader />
                    <div className="h-[80%] w-[100%] ">
                        <div className="flex items-center justify-between p-4">
                            <div className="flex items-center ">
                                <span
                                    onClick={MyDisplayNaviagte}
                                    className="text-[#8E8E8E] cursor-pointer"
                                >
                                    {" "}
                                    Home
                                </span>{" "}
                                <IoChevronForwardOutline />
                                <span className="text-[#555555]">
                                    My Display
                                </span>
                            </div>
                        </div>
                        <div className="px-8">
                            <p className="text-2xl">My Screens</p>
                        </div>
                   
                        <div className="w-[100%] px-[35px] py-3">
                            <div className=" flex gap-3 items-center ">
                                <div className="flex  border-2 bg-white rounded-[4px]">
                                    <div className="bg-white flex items-center gap-2  px-2 py-2">
                                        <CiSearch
                                            size={24}
                                            className=" text-gray-400"
                                        />
                                        <input
                                            className=" outline-none h-fit w-full"
                                            type="text"
                                            placeholder="Search Screens"
                                            value={searchText}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                                <button
                                    className=" flex items-center gap-1 py-[9px] px-3 hover:bg-[#FF9002] hover:text-white rounded-[4px] text-[#FF9002] text-[16px] border-solid border-[2px] "
                                    onClick={openParentModal1}
                                >
                                    <span>
                                        <BiPlus size={24} className="" />{" "}
                                    </span>
                                    Add Screen
                                </button>
                            </div>
                            <div className="flex w-[100%] items-center justify-between mt-5">
                                <div className=" flex gap-3 items-center">
                                    <p className="flex items-center gap-2">
                                        <BsFilter size={24} />
                                        Filters:
                                    </p>
                                  
                                    <select
                                        className="rounded-[4px] flex px-3 py-2 outline-none border"
                                        value={categoryFilter}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="All">Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">
                                            Inactive
                                        </option>
                                    </select>
                                    <select
                                        className="rounded-[4px] flex px-3 py-2 outline-none border"
                                        value={categoryFilter}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="All">Type</option>
                                        <option value="Landscape">
                                            Landscape
                                        </option>
                                        <option value="Portrait">
                                            Portrait
                                        </option>
                                    </select>
                                </div>
                                <div className=" text-[#8E8E8E] flex justify-between  items-center mt-3">
                                    <p>
                                        Total Display: {totalDisplay} 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ReactModal
                            isOpen={isParentModalOpen}
                            onRequestClose={closeParentModal}
                            contentLabel="Video Modal"
                            className=" flex w-screem h-screen bg-[#000000]/70 justify-center items-center"
                            ariaHideApp={false}
                        >
                            <div className="border border-gray-300 bg-white rounded-lg p-4 w-[55%] h-[50%] ">
                                <div className="flex justify-between ">
                                    <p className="text-[24px] ml-3 mt-3 font-semibold">
                                        Add a display
                                    </p>
                                </div>
                                <form
                                    className="h-[70%]"
                                    style={{ marginTop: "20px" }}
                                    onSubmit={submitHandler}
                                >
                                    <div className="flex flex-col gap-2">
                                        <div className="w-[100%] gap-5 flex">
                                            <div className="w-[50%] ">
                                                <input
                                                    className="p-3 w-[100%] mb-3 rounded-md placeholder-[#9D9D9D] border border-gray-400 outline-none"
                                                    required
                                                    type="text"
                                                    value={New.code}
                                                    placeholder="Enter Screen Name"
                                                    onChange={(e) => {
                                                        setNew({
                                                            ...New,
                                                            screenName:
                                                                e.target.value,
                                                        });
                                                        console.log(New);
                                                    }}
                                                />
                                            </div>
                                            <div className="w-[50%] ">
                                                <input
                                                    className="p-3 w-[100%] mb-3 rounded-md placeholder-[#9D9D9D] border border-gray-400 outline-none"
                                                    required
                                                    type="text"
                                                    value={New.screenId}
                                                    placeholder="Enter Activation Code "
                                                    onChange={(e) =>
                                                        setNew({
                                                            ...New,
                                                            screenId:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="h-36 ">
                                            <div className="w-[100%]  gap-5 flex">
                                                <select
                                                    className="p-3 w-1/2 mb-3 rounded-md placeholder-[#9D9D9D] border border-gray-400 outline-none"
                                                    required
                                                    value={New.TYPE}
                                                    onClick={handleType}
                                                    onChange={(e) => {
                                                        handleTypeChange(e);
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                        className="p-3"
                                                    >
                                                        Venue Type
                                                    </option>
                                                    {details.map((ele) => (
                                                        <option
                                                            value={ele.type}
                                                        >
                                                            {ele.type}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    className="w-1/2 p-3 mb-3 rounded-md placeholder-[#9D9D9D] border border-gray-400 outline-none"
                                                    re
                                                    value={New.TYPE_VAL}
                                                    onChange={(e) => {
                                                        setNew({
                                                            ...New,
                                                            TYPE_VAL:
                                                                e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="">
                                                        Select Type Value
                                                    </option>
                                                    {Values.map((elem) => (
                                                        <option value={elem}>
                                                            {elem}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div
                                            className={` flex gap-5 justify-end`}
                                        >
                                            <button
                                                className="px-8 py-2 rounded-md bg-[#E6E6E6]"
                                                type="reset"
                                                onClick={closeParentModal}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={venueTime}
                                                className="px-8 py-3 button-bg rounded-lg text-white after:"
                                                id="Addbtn"
                                                type="submit"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ReactModal>

                        <div className="flex w-[100%] flex-wrap h-[75%] mt-4">
                            <div className="w-[100%] h-[100%] bg-[#FFFDF9]">
                                {isloaded ? (
                                    currentPageData.length > 0 ? (
                                        <div className="w-[100%] overflow-hidden h-[100%]">
                                            <div className="h-[100%] w-[100%] overflow-y-auto flex-wrap flex flex-row px-[40px] gap-8">
                                                {currentPageData.map(
                                                    (Data, i) => (
                                                        <div
                                                            key={i}
                                                            className="w-fit h-fit border rounded overflow-hidden mb-2 hover:shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]"
                                                        >
                                                            <Cart Data={Data} />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex h-[64%] flex-col border-[2px] border-[#DEDEDE] rounded-2xl border-dashed items-center justify-center m-16 py-8">
                                             <div className="  items-center gap-2">
                                                
                                                <p className=" text-center py-4 text-[#555555]">
                                                    No Screen Added
                                                </p>
                                                <div className="flex">
                                                <img
                                                    src={ads}
                                                    className="  overflow-hidden"
                                                    alt="Everidroor"
                                                />
                                               
                                                <h1 className="text-[32px] text-center py-4 ml-10 text-[#555555]">
                                                    Add your 1st Screen
                                                </h1>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={openParentModal}
                                                    className="bg-[#FFE8CC] py-[9px] px-5 hover:bg-[#ffe8cc] rounded-[4px] text-[#FF8E00] border-gray-100 border "
                                                >
                                                    + Add New Screen
                                                </button>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="w-full h-full flex justify-center items-center ">
                                        <ThreeDots
                                            height="80"
                                            width="80"
                                            radius="9"
                                            color="#fe4d00"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-[100%] h-[7%]  bg-white flex items-center pr-6 justify-end">
                        <div className="">
                            <Stack spacing={2}>
                                <Pagination
                                    color="secondary"
                                    defaultPage={page}
                                    onChange={(event, value) => {
                                        setPage(value);
                                    }}
                                    count={totalPages} 
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagerScreens;

