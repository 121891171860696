import React, { useEffect, useState } from 'react'
import DpMenu from '../../Menu/DpMenu'
import DpHeader from "../../Header/DpHeader"
import { IoChevronForwardOutline, IoFilterSharp } from 'react-icons/io5'
import ReactModal from 'react-modal';
import { BiRupee } from 'react-icons/bi';
import Axios from '../../Axios/axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai'


function PersonalInfo() {
    const notify = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyBalance = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyAlready = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyAccount = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyUserDetalis = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const NotifyOtpErr = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const NotifyOtp = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });


    const [isParentModalOpen, setIsParentModalOpen] = useState(false);


    const openParentModal = () => {
        setIsParentModalOpen(true);
    };

    const closeParentModal = () => {
        setIsParentModalOpen(false);
    };

    const [screenData, setScreenData] = useState({});
    const [screenDatas, setScreenDatas] = useState([]);
    var username = sessionStorage.getItem("username");
    console.log(screenData)
    useEffect(() => {
        // Function to make the GET request
        const fetchData = async () => {
            try {
                const response = await Axios.get(`amount?username=${username}`);
                console.log(response);
                setScreenData(response.data); // Set the fetched data to the state
                setScreenDatas(response.data.paymentData)
                // setDatas(response.data.data);

            } catch (error) {
                console.error('Error fetching data:', error);

                // Handle specific error cases
                if (error.response) {
                    console.log('Response status:', error.response.status);
                    console.log('Response data:', error.response.data);
                } else if (error.request) {
                    console.log('Request error:', error.request);
                } else {
                    console.log('Error:', error.message);
                }
            }
        };
        fetchData(); // Call the function to initiate the request
    }, []);

    const [formData, setFormData] = useState({
        username: username,
        amount: "",
    });

    const [formDataOtp, setFormDataOtp] = useState({
        username: username,
        otp: "",
    });

    console.log(formData);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    console.log(formDataOtp);
    const handleInputChangeOtp = (event) => {
        const { name, value } = event.target;
        setFormDataOtp({ ...formData, [name]: value });
    };

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [msg, setMsg] = useState(false);
    const handleFinds = async (event) => {
        event.preventDefault();


        try {
            console.log('data:', formData);
            const response = await Axios.post('/verfingPayment', formData);

            console.log('data:', response);

            if (response.status === 202) {
                notify("User Not Found!");

            } else if (response.status === 200) {
                NotifyOtp("Otp Send Sucessfully!")
                setNotice(true);
                openParentModal();
                setIsButtonDisabled(true);
            }
            else if (response.status === 400) {
                notifyUserDetalis("Require filed Missing!");
            }
            else if (response.status === 201) {
                notifyAccount("Account Details Not found!");
            }
            else if (response.status === 203) {
                notifyBalance("Insufficient Funds!");
            }
            else if (response.status === 206) {
                notifyAlready("Already Requested!");
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }


    const handleOtp = async (event) => {
        event.preventDefault();
        console.log('data:', formDataOtp);
        try {
            const response = await Axios.post('/verfingPaymentOTP', formDataOtp);

            console.log('data:', response);

            if (response.status === 201) {
                notify("InValid User!");

            } else if (response.status === 202) {
                NotifyOtpErr("Wrong Otp!")
            }
            else if (response.status === 203) {
                notifyUserDetalis("Otp Used!");
            }
            else if (response.status === 200) {
                notifyAccount("Funds Will Adds Soon!");
                closeParentModal();
            }
            else if (response.status === 204) {
                notifyBalance("Technical issues!");
            }


        } catch (error) {
            console.error('Error:', error);
        }
    }
    const navigate = useNavigate();
    const FundsNaviagte = () => {
        navigate("/home")
    }
    const [parentModalOpen, setParentModalOpen] = useState(false);

    const openModal = () => {
        setParentModalOpen(true);
    }
    const closeModal = () => {
        setParentModalOpen(false);
    }
    console.log(screenData)

    const [categoryFilter, setCategoryFilter] = useState("status");
    // const [filteredItems, setFilteredItems] = useState([]);

    const [screen, setScreen] = useState("");
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        console.log("Search Text:", e.target.value);
    };

    const handleCategoryChange = (e) => {
        setCategoryFilter(e.target.value);
        console.log("Category Filter:", e.target.value);
    };

    const filteredData = screenDatas.filter((item) => {
        // var searchTerm = searchText.toLowerCase();
        const category = categoryFilter.toLowerCase();
        console.log(category);

        if (category === "status") {
            if (item.status === "Approved") return item;
            if (item.status === "Rejected") return item;
            if (item.status === "pending") return item;
        }

        return (
            (category === "status" ||
                item.status.toLowerCase() === category)

        );
    });


    const itemsPerPage = 3;
    var totalDisplay = screenDatas.length;
    const [page, setPage] = useState(1);

    const currentPageData = screenDatas.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const currentPageDataAll = filteredData.slice();
    // In your component or stylesheet
    const modalContentClasses = "bg-white h-[80%] w-[50%] rounded-md p-6";
    const modalOverlayClasses = "bg-black bg-opacity-70 fixed inset-0 flex justify-center items-center z-50";
    const [notice, setNotice] = useState(false)
    return (
        <div className="h-screen  flex w-screen ">
            <ToastContainer />
            <DpMenu />
            < div className="h-screen w-screen  overflow-hidden bg-[#FFFDF9]" >
                <DpHeader />
                <div className="p-5 h-[100%]">
                    <div className=" flex items-center ">
                        <span className='text-[#8E8E8E] cursor-pointer ' onClick={FundsNaviagte}> Home</span> <IoChevronForwardOutline /> <span className='text-[#555555]'> Withdraw Funds</span>
                    </div>
                    <div className='pl-2 pt-5'>
                        <span className='text-[#555555] text-2xl '> Withdraw Funds</span>
                    </div>
                    <div className='mt-6 w-[70%] h-[85%]   gap-5  flex flex-col '>
                        <div className='w-[100%] justify-evenly gap-5 h-[14%] flex '>
                            <div className='bg-white p-3  flex flex-col gap-1  w-[33%]  shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] rounded'>
                                <div className=''><span>Total Revenue</span></div>
                                <div className='text-2xl flex  items-center text-[#555555]'><BiRupee size={28} />{screenData.totalRevenue}</div>
                            </div>
                            <div className='bg-white p-3  flex flex-col gap-1  w-[33%]  shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] rounded'>
                                <div className=''><span>Available Revenue</span></div>
                                <div className='text-2xl flex  items-center text-[#555555]'><BiRupee size={28} />{screenData.AvailableFunds}</div>
                            </div>
                            <div className='bg-white p-3  flex flex-col gap-1  w-[33%]  shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] rounded'>
                                <div className=''><span>Total Withdrawal’s</span></div>
                                <div className='text-2xl flex  items-center text-[#555555]'><BiRupee size={28} />{screenData.totalWithdrawals}</div>
                            </div>
                        </div>
                        <div className='bg-white p-5 rounded-md h-[25%] shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] '>
                            <form className=''>
                                <p className="mb-8 text-[20px] text-[#555555]">Submit A Withdrawal Request</p>
                                <div className='relative flex justify-between '>
                                    <input type='text'
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        className='border outline-none p-2 w-[75%] rounded-md' >
                                    </input>
                                    <label className='absolute left-3 bottom-8 bg-white px-1 text-[#555555] '>Enter Withdrawal Amount</label>
                                    <div className='flex justify-end '>
                                        <button onClick={handleFinds}
                                            disabled={isButtonDisabled}
                                            className={`${isButtonDisabled ?
                                                ' border px-3 py-2 text-white w-max  bg-[#FF8E00]' : 'border px-3 py-2 text-white w-max rounded-sm bg-[#FF8E00]'}`}>
                                            Confirm Withdrawal</button>
                                    </div>
                                </div>
                                {!notice ? "" : (<p className='pl-2 text-sm pt-1 text-red-500'>Otp Send To Your Register Email</p>)}
                            </form>
                        </div>
                        <div className='h-[40%] bg-white overflow-y-visible shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] rounded'>
                            <div className='p-2 pt-4 '>
                                <div className='flex justify-between'>
                                    <span className='text-[#555555] text-2xl pl-4'>Recent Withdrawal Requests</span>  <span onClick={openModal} className=' text-sm text-[#FF8E00] pr-4 cursor-pointer '>View Histroy</span>
                                </div>

                                <table className="border-spacing-y-4  border-separate w-full ">
                                    <thead className="">
                                        <tr className="border ">
                                            <th scope="col"
                                                className="text-left pl-4 text-[#555555] rounded-l-md "
                                            >
                                                Transaction Date
                                            </th>
                                            <th scope="col" className="py-4 
                                                        text-center pl-24 text-[#555555] " >
                                                Amount
                                            </th>
                                            <th scope="col" className="text-[#555555]   rounded-r-md ">
                                                Status
                                            </th>
                                        </tr>
                                        <tr>
                                            <td colspan="3">
                                                <hr />
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody className=' '>
                                        {currentPageData.map((item, index) => (
                                            <tr key={index} className="text-center">
                                                <td className="bg-white text-left  pl-4">{item.createdAt}</td>
                                                <td className="bg-white text-center  pl-24">{item.amount}</td>
                                                <td className={` rounded-full w-[90px] text-center ${item.status === "Approved" ? "bg-[#E9F6EE] text-[#20A756]" :
                                                    item.status === "pending" ? "bg-[#FDEDCF] capitalize text-[#F4A50D]" :
                                                        item.status === "Rejected" ? "bg-[#FCEDED] text-[#EF4E38]" : ""
                                                    }`}>
                                                    {item.status}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <ReactModal
                isOpen={parentModalOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                className={modalContentClasses}
                overlayClassName={modalOverlayClasses}
                ariaHideApp={false}
            >
                <div className='w-[100%] bg-white rounded-md py-5  h-[100%] '>
                    <div className='flex px-5 justify-between'>
                        <p className='text-[#555555] font-bold text-xl gap-3 mb-8'>
                            All Withdrawal History
                        </p>
                        <AiOutlineClose className=' cursor-pointer' onClick={closeModal} size={20} />
                    </div>
                    <div className='flex items-center px-5 gap-4'>
                        <IoFilterSharp />
                        <p>Filters: </p>
                        <div className='flex gap-5'>
                            <div className='border-2 rounded-md px-3  '>
                                <select

                                    className=' outline-none cursor-pointer'>
                                    <option>Last Month</option>
                                    <option value="LastWeek">Last Week</option>
                                    <option value="LastYear">Last Year</option>
                                </select>
                            </div>
                            <div

                                className='border-2 rounded-md px-3  '>
                                <select
                                    value={categoryFilter}
                                    onChange={handleCategoryChange}
                                    className='outline-none cursor-pointer'>
                                    <option value="Status">Status</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="pending">Pending</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full h-[90%]  px-2  overflow-y-scroll  '>
                        <table className="border-spacing-y-4 border-separate w-full ">
                            <thead className="border-b ">
                                <tr className=" border-b ">
                                    <th scope="col"
                                        className="text-left border-b pl-4 text-[#555555] "
                                    >
                                        Transaction Date
                                    </th>
                                    <th scope="col" className="py-4 
                                                     border-b   text-center pl-24 text-[#555555] " >
                                        Amount
                                    </th>
                                    <th scope="col" className="text-[#555555] border-b  ">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {currentPageDataAll.map((item, index) => (
                                    <tr key={index} className="text-center">
                                        <td className="bg-white text-left  pl-4">{item.createdAt}</td>
                                        <td className="bg-white text-center  pl-24">{item.amount}</td>
                                        <td className={` capitalize rounded-full w-[90px] text-center ${item.status === "Approved" ? "bg-[#E9F6EE] text-[#20A756]" :
                                            item.status === "pending" ? "bg-[#FDEDCF] text-[#F4A50D]" :
                                                item.status === "Rejected" ? "bg-[#FCEDED] text-[#EF4E38]" : ""
                                            }`}>
                                            <span class>{item.status}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </ReactModal >
            <ReactModal
                isOpen={isParentModalOpen}
                onRequestClose={closeParentModal}
                contentLabel="Video Modal"
                className=" flex bg-[#000000]/70 w-screem h-screen justify-center items-center"
                ariaHideApp={false}
            >
                <div className='w-[40%] bg-white rounded-md py-5 px-5 h-[33%] border'>
                    <h3 className='text-[#555555]  text-xl gap-3 mb-8'>Enter OTP for Withdrawal Confirmation</h3>
                    <form onSubmit-="">
                        <div className='relative'>
                            <input
                                name="otp"
                                value={formDataOtp.otp}
                                onChange={handleInputChangeOtp}
                                className='border border-[#9D9D9D] p-2 placeholder-black   rounded-sm w-[100%] bg-white outline-none'
                                type='password' placeholder='***'
                            />
                            <label className='absolute left-3 bottom-8 text-[#555555] bg-white px-1'>Enter OTP Number</label>
                        </div>
                        <div className='flex justify-between mt-2 w-full'>
                            <div className='px-2 text-[12px]'>OTP Sent To Registered Email & Phone Number</div>
                            <div className='w-fit text-[12px] text-[#FF4D00]'>Resend OTP</div>
                        </div>
                        <div className='w-fit flex gap-4 float-right mt-14'>
                            <div onClick={closeParentModal} className='border w-fil bg-[#F2F2F2] rounded-sm'>
                                <button className='  border-1  text-[#555555]  px-2 py-2 rounded-full mx-6 '>Cancel</button>
                            </div>
                            <div onClick={handleOtp} className='border w-fil bg-[#FF8E00] border-[#FF8E00] rounded-sm'>
                                <button className='border-1  text-white  px-2 py-2 rounded-full mx-6 '>Verify</button>
                            </div>
                        </div>
                    </form>
                </div>
            </ReactModal>
        </div >
    )
}

export default PersonalInfo