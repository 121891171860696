import React, { useState, useContext, useEffect, useRef } from "react";
import food from "../../Components/Images/food.png";
import { useNavigate } from "react-router-dom";
import Axios from "../../Components/Axios/axios";
import { IoChevronForwardOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { SocketContext } from "../../socketcontext";
import PublisherMenu from "../Menus/PublisherMenu";
import { ToastContainer } from "react-toastify";
import DpHeader from "../../Components/Header/DpHeader";
import ReactModal from "react-modal";
import ReactModal1 from "react-modal";
import { CiSearch } from "react-icons/ci";
import { MdOutlineFileUpload } from "react-icons/md";
import drop from "../../Components/Images/dragdropfill.png";
import { CiTrash } from "react-icons/ci";
import { GrView } from "react-icons/gr";
import upload from "../../Components/Images/uploadVideo.png";
import uploadimg from "../../Components/Images/uploadImg.png";
import ReactPlayer from "react-player";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ThreeDots } from "react-loader-spinner";
import { TbEdit } from "react-icons/tb"
const fileTypes = ["JPEG", "PNG", "GIF"];
const videoTypes = ["MP4"];

const formatTime = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const formattedHours =
        hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";
    const formattedMinutes = `${minutes.toString().padStart(2, "0")}:`;
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return formattedHours + formattedMinutes + formattedSeconds;
};

export default function Content({ setCamp, Camp }) {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const [managePlay, setPlay] = useState([]);
    const [isloaded, setLoaded] = useState(false);
    const [saved, setSaved] = useState(true);

    const Websocket = useContext(SocketContext);

    // regarding playlist add popup
    const videoRef = useRef(null);

    const [currContentId, setCurrContentId] = useState("");
    const addPlay = async (item) => {
        let updated = [...managePlay];
        const foundIndex = updated.findIndex(
            (element) => element.playlistId === item.playlistId
        );
        updated[foundIndex].included = true;
        setPlay(updated);

        const r = await Axios.post("addToPlaylist", {
            username: sessionStorage.getItem("username"),
            playlistId: item.playlistId,
            contentId: currContentId,
        });

        if (r.data.Message === "Successfully uploaded the file") {
            const d = {
                username: sessionStorage.getItem("username"),
                playlistId: item.playlistId,
            };
            console.log("Emitting Update Playlist Socket:", d);
            Websocket.emit("update-playlist", d);
        }
    };

    const [imageFile, setImageFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);

    const handleImageUpload = (file) => {
        setImageFile(file);
    };

    const handleVideoUploadd = (file) => {
        setVideoFile(file);
    };

    const removePlay = async (item) => {
        let updated = [...managePlay];
        const foundIndex = updated.findIndex(
            (element) => element.playlistId === item.playlistId
        );
        updated[foundIndex].included = false;
        setPlay(updated);

        const r = await Axios.post("removeFromPlaylist", {
            username: sessionStorage.getItem("username"),
            playlistId: item.playlistId,
            contentId: currContentId,
        });

        if (r.data.Message === "Successfully Removed Content") {
            console.log("Emitting Update Playlist Socket");
            Websocket.emit("update-playlist", {
                username: sessionStorage.getItem("username"),
                playlistId: item.playlistId,
            });
        }
    };

    // Popup

    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };
    const [btn, setbtn] = useState(false);
    const [isParentModalOpen, setIsParentModalOpen] = useState(false);
    const handlecontent = () => {
        setbtn(!btn);
    };
    const handledisplay = () => {
        setbtn(!btn);
    };

    const navigate = useNavigate();
    const ContentNaviagte = () => {
        navigate("/Manager");
    };

    //this is to be calculated, not an input param - old useState

    const [content, setContent] = useState([
        {
            title: "Food - Tactos",
            duration: "00:00",
            advertiser: "Oceanic Airlines Ad",
            thumbnailPath: food,
            contentId: "ididid",
            // selected: false,
        },
    ]);
    const [New, setNew] = useState({
        title: "",
        duration: "",
        company: "",
        thumbnailPath: "",
        videoPath: "",
        description: "",
    });
    useEffect(() => {
        async function getContent() {
            const result = await Axios.get(
                "getAllContent?username=" + sessionStorage.getItem("username")
            );
            console.log(result.data.Content);
            setContent(result.data.Content);
            setLoaded(true);
        }
        getContent();
    }, []);

    const [dur, setdurr] = useState("");

    const handleVideoUpload = (videoFile) => {
        const file = videoFile;
        const videoObjectURL = URL.createObjectURL(file);
        videoRef.current.src = videoObjectURL;
        videoRef.current.addEventListener("loadedmetadata", () => {
            const durationInSeconds = Math.ceil(videoRef.current.duration);
            const formattedDuration = formatTime(durationInSeconds);
            console.log("Video duration:", formattedDuration);
            setdurr(formattedDuration);
            console.log("Set Duration");
            // Use the video duration as needed
        });
    };

    // add content fun
    const addContent = async (e) => {
        e.preventDefault();
        console.log(dur);
        setSaved(false);
        const username = sessionStorage.getItem("username");
        console.log(username);
        var data = new FormData();
        data.set("postedvideos", New.video);
        data.set("thumbnail", New.thumbnail);
        data.set("username", username);
        data.set("title", New.title);
        data.set("size", New.video.size);
        data.set("duration", dur);
        data.set("advertiser", New.company);
        data.set("description", New.description);
        data.set("mimetype", New.video.type.split("/")[1]);
        console.log(data.get("postedvideos"));
        var newContentId = "newIdId";

        const headers = {
            "content-type": "multipart/form-data",
        };

        //Do not remove this Kavin, I have commented so that you can see changes for now in Frontend
        await Axios.post("addContent", data, {
            headers: headers,
        })
            .then(function (response) {      
                newContentId = response.data.contentId;     
                if (response.status === 200) {
                    setSaved(true);
                    setbtn(!btn);

                    setContent([
                        ...content,
                        {
                            title: New.title,
                            duration: dur,
                            advertiser: New.company,
                            thumbnailPath:
                                "https://s3.ap-south-1.amazonaws.com/everidoor2.0/" +
                                sessionStorage.getItem("username") +
                                "/Thumbnails/" +
                                response.data.contentId +
                                "." +
                                New.thumbnail.type.split("/")[1],
                            contentId: newContentId,
                        },
                    ]);
                    setNew({
                        title: "",
                        duration: "00:00",
                        company: "",
                        thumbnailPath: "",
                        videoPath: "",
                        description: "",
                    });
                    setIsParentModalOpen(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //CampForm
    const [CampForm, setCampForm] = useState(false);

    const selectfile = () => {
        document.getElementById("myfile").click();
    };

    const openParentModal = () => {
        setIsParentModalOpen(true);
    };

    const closeParentModal = () => {
        setIsParentModalOpen(false);
    };

    const [searchText, setSearchText] = useState("");
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = content.filter((i) => {
        const searchTerm = searchText;
        return i.title.includes(searchTerm);
    });

    const managePlaylist = async () => {
        setCurrContentId(content.contentId);
        setPopupVisible(true);
        // console.log("Manage Playlist");

        await Axios.get(
            "managePlaylist?username=" +
            sessionStorage.getItem("username") +
            "&contentId=" +
            content.contentId
        ).then((res) => {
            console.log("managePlaylist");
            console.log(res.data);
            setPlay(res.data.playlists);
        });
    };

    const handleDelete = async (contentId) => {
        // console.log(content);
        const username = sessionStorage.getItem("username");
        const contentIdToDelete = contentId;
        console.log(contentIdToDelete);
        console.log(sessionStorage.getItem("username"));
        await Axios.post("removeContent", {
            username: username,
            contentId: contentIdToDelete,
        });
        const updatedContent = content.filter(
            (item) => item.contentId !== contentIdToDelete
        );
        setContent(updatedContent); //can't use title in the long run
        console.log("delete");
        // content.filter((item) => console.log(item.contentId))
    };
    //   let videoUrl = "";
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const handleOpen = (contentId) => {
        const selectedContent = content.find(
            (item) => item.contentId === contentId
        );
        if (selectedContent) {
            const videoUrl = `https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/${selectedContent.contentId}.mp4`;
            setSelectedVideoUrl(videoUrl);
        }
        console.log("hello");
    };

    //   console.log(videoUrl);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const modalStyles = {
        overlay: {
            display: "flex",
            justifyContent: "center",
            //   alignItems: 'center',
        },
        content: {
            position: "relative",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
            border: "none",
            borderRadius: "8px",
            padding: "0",
            width: "calc(100% - 300px)", // Adjust the value as needed
            maxWidth: "800px", // Adjust the value as needed
            maxHeight: "calc(100% - 300px)", // Adjust the value as needed
            margin: "200px",
            background: "#000",
        },
    };
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleCheckboxClick = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            // If it is not selected, add it to the selectedRows
            setSelectedRows([...selectedRows, rowId]);
        }
        selectedRows.map((i) => console.log(i));
    };
    const handleSelectAllClick = () => {
        if (selectedRows.length === content.length) {
            // If all rows are selected, clear the selection
            setSelectedRows([]);
            setSelectAll(false);
        } else {
            // Otherwise, select all rows
            setSelectedRows(content.map((i) => i.contentId));
            setSelectAll(true);
        }

        selectedRows.map((i) => console.log(i));
    };

    const handleDeleteAll = async () => {
        const username = sessionStorage.getItem("username");
        selectedRows.map((i) => console.log(i));
        const contentIdToDelete = selectedRows.map((i) => i);
        console.log(contentIdToDelete);
        console.log(sessionStorage.getItem("username"));
        await Axios.post("removeContent", {
            username: username,
            contentId: contentIdToDelete,
        });
        // const updatedContent = content.filter((item) => item.contentId !== contentIdToDelete);
        const updatedContent = content.filter(
            (item) => !selectedRows.includes(item.contentId)
        );

        updatedContent.map((i) => console.log(i.contentId));
        setContent(updatedContent); //can't use title in the long run
        console.log("delete");
        setSelectAll(false);
    };
    const [page, setPage] = useState(1);
    const itemsPerPage = 12;
    var totalDisplay = content.length;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    console.log(content);
    console.log(totalPages);
    console.log(totalDisplay);
    const currentPageData = filteredData.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );
    return (
        <>
            <div className="h-screen flex w-screen ">
                <PublisherMenu />
                <div className="h-[100%] border-2 w-full  bg-[#FFFDF9]">
                    <DpHeader />
                    <ToastContainer />
                    <div className=' flex flex-col gap-3  mx-4  h-[90%] bg-[#FFFDF9]'>
                        <div className="flex p-3 items-center justify-between bg-[#FFFDF9] ">
                            <div className="flex items-center ">
                                <span
                                    onClick={ContentNaviagte}
                                    className="text-[#8E8E8E] cursor-pointer "
                                >
                                    Home
                                </span>{" "}
                                <IoChevronForwardOutline />
                                <span className="text-[#555555]">
                                    My Content
                                </span>
                            </div>
                        </div>
                        <div className='ml-3  '>
                            <p className='text-2xl text-[#555555]'>My Content</p>
                        </div>
                        <div className='flex items-center ml-2 justify-center  text-white rounded-full'>
                            <div className=" flex flex-col gap-3 mt-3   w-full">
                                <div className="px-2 flex justify-between items-center ">
                                    <div className=" flex gap-3 items-center ">
                                        <div className="flex justify-between items-center shadow-sm border bg-white rounded-lg">
                                            <div className=" flex items-center justify-center h-10 w-10  ">
                                                <CiSearch
                                                    size={24}
                                                    className=" text-gray-400"
                                                />
                                            </div>
                                            <div className="flex items-center w-40">
                                                <input
                                                    className=" outline-none h-fit  w-[100%] text-black"
                                                    type="text"
                                                    placeholder="Search Display"
                                                    value={searchText}
                                                    onChange={
                                                        handleSearchChange
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <button className=" hover:bg-[#FF9002] hover:text-white flex items-center gap-1 py-[9px] px-3  rounded-[4px] text-[#FF9002]  border-[2px] border-solid " onClick={openParentModal}  >
                                            <MdOutlineFileUpload size={24} className='' /> Upload Content </button>
                                    </div>
                                    <div className='flex justify-end items-center mx-3 mb-10 '>
                                        <span className='bg-[#DEDEDE] w-8 h-8  mr-3 flex items-center justify-center rounded-lg'>
                                            <AiOutlineDelete size={24} className="text-[#9D9D9D] cursor-pointer" onClick={handleDeleteAll} /></span ><span onClick={handleDeleteAll} className="text-[#9D9D9D] cursor-pointer">Delete</span>
                                    </div>
                                </div>
                            </div >
                            <ReactModal
                                isOpen={isParentModalOpen}
                                onRequestClose={closeParentModal}
                                contentLabel="Video Modal"
                                className=" flex w-screen h-screen  bg-[#000000]/70 justify-center items-center"
                                ariaHideApp={false}
                            >

                                <div className="w-[70%] border justify-center bg-white items-center rounded-xl opacity-100  h-[80%]  flex flex-col gap-8">
                                    <div
                                        class={`${!saved ? "invisible" : "visible"
                                            }`}
                                    >
                                        <p className="text-[#001122] font-semibold text-2xl">
                                            Upload Content
                                        </p>
                                    </div>
                                    <video
                                        ref={videoRef}
                                        controls
                                        style={{ display: "none" }}
                                    />

                                    <form className="flex justify-center w-[90%]  flex-col gap-6">
                                        {/* loader start */}
                                        {saved ? (
                                            <>
                                                <input
                                                    className=" rounded-md p-3 text-[#8E8E8E]  placeholder-[#8E8E8E] outline-none border border-[#555555]"
                                                    required
                                                    type="text"
                                                    value={New.title}
                                                    placeholder="Enter Content Name"
                                                    onChange={(e) =>
                                                        setNew({
                                                            ...New,
                                                            title: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                                <input
                                                    className=" rounded-md p-3 text-[#8E8E8E]  placeholder-[#8E8E8E] outline-none border border-[#555555]"
                                                    required
                                                    type="text"
                                                    value={New.company}
                                                    placeholder="Company"
                                                    onChange={(e) =>
                                                        setNew({
                                                            ...New,
                                                            company:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                                <input
                                                    className=" rounded-md p-3 text-[#8E8E8E]  placeholder-[#8E8E8E] outline-none border border-[#555555]"
                                                    required
                                                    type="text"
                                                    value={New.description}
                                                    placeholder="Describe the Advertisement"
                                                    onChange={(e) =>
                                                        setNew({
                                                            ...New,
                                                            description:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                                <div className="h-56 w-full flex justify-center">
                                                    <div className=" border-dashed flex justify-center items-center w-[80%] h-56  rounded-xl gap-10 border-2">
                                                        {New.videoPath == "" ? (
                                                            <>
                                                                <div className="flex justify-center  w-[25%]">
                                                                    <label
                                                                        className=" cursor-pointer origin-center"
                                                                        htmlFor="postedvideos"
                                                                    >
                                                                        <img
                                                                            className=""
                                                                            src={
                                                                                upload
                                                                            }
                                                                        />
                                                                        Enter
                                                                        Advertisement
                                                                    </label>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="flex flex-row justify-center  w-[25%]">
                                                                    <label
                                                                        className="cursor-pointer origin-center"
                                                                        htmlFor="postedvideos"
                                                                    >
                                                                        <img
                                                                            className="ml-6"
                                                                            src={
                                                                                upload
                                                                            }
                                                                        />
                                                                        Advertisement
                                                                        Selected
                                                                    </label>
                                                                </div>
                                                            </>
                                                        )}
                                                        <input
                                                            required
                                                            type="file"
                                                            id="postedvideos"
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            placeholder="Enter Advertisement"
                                                            accept="video/*"
                                                            onChange={(e) => {
                                                                setNew({
                                                                    ...New,
                                                                    video: e
                                                                        .target
                                                                        .files[0],
                                                                    videoPath:
                                                                        e.target
                                                                            .value,
                                                                });
                                                                handleVideoUpload(
                                                                    e.target
                                                                        .files[0]
                                                                );
                                                            }}
                                                        />
                                                        {New.thumbnailPath ==
                                                            "" ? (
                                                            <div className="flex flex-row  justify-center  w-[25%]">
                                                                <label
                                                                    className="cursor-pointer origin-center"
                                                                    htmlFor="thumbnail"
                                                                >
                                                                    <img
                                                                        className=""
                                                                        src={
                                                                            uploadimg
                                                                        }
                                                                    />
                                                                    Enter Thumbnail
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-center  w-[25%]">
                                                                <label
                                                                    className="cursor-pointer  origin-center"
                                                                    htmlFor="thumbnail"
                                                                >
                                                                    <img
                                                                        className="ml-2"
                                                                        src={
                                                                            uploadimg
                                                                        }
                                                                    />
                                                                    Thumbnail Selected
                                                                </label>
                                                            </div>
                                                        )}
                                                        <input
                                                            required
                                                            type="file"
                                                            id="thumbnail"
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            placeholder="Enter Thumbnail"
                                                            onChange={(e) => {
                                                                setNew({
                                                                    ...New,
                                                                    thumbnail:
                                                                        e.target
                                                                            .files[0],
                                                                    thumbnailPath:
                                                                        e.target
                                                                            .value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-[100%] h-12  flex justify-end gap-5 ">
                                                    <div className=" rounded-md w-32 text-xl  text-[#FF4D00]  border-2 flex items-center justify-center">
                                                        <button
                                                            type="reset"
                                                            onClick={
                                                                closeParentModal
                                                            }
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className=" rounded-md w-32 text-xl  text-white bg-primary  border-2 flex items-center justify-center">
                                                        <button
                                                            id="Addbtn"
                                                            type="submit"
                                                            onClick={addContent}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex justify-center items-center">
                                                <div className="load">
                                                    <ThreeDots
                                                        height="80"
                                                        width="80"
                                                        radius="9"
                                                        color="#FF4D00"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {/* loader completion */}
                                    </form>
                                </div>
                                {/* </div> */}
                            </ReactModal>
                        </div>
                       <div className= " w-[99%] h-[100%] ml-2  bg-white shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] ">
                        <div className="w-[99%] h-[90%] ml-2 overflow-x-hidden items-center flex flex-col overflow-y-visible">
                            <table className="border-spacing-y-4 w-[98%]  text-[#555555] text-left border-separate  ">
                                <thead className="">
                                    <tr className=" text-left w-[98%]">
                                        <th scope="col" className=" pl-6 border-b " colSpan={5}>
                                            <input
                                                type="checkbox"
                                                className="w-6 cursor-pointer h-6 mt-2 rounded-md accent-[#FF8E00] text-white"
                                                checked={selectAll}
                                                onChange={handleSelectAllClick}
                                            />
                                        </th>
                                        <th scope="col"
                                            className="pl-12 border-b"
                                        >
                                            Content Name
                                        </th>
                                        <th scope="col" className=" border-b">
                                            { }
                                        </th>
                                        <th scope="col" className="py-4 
                                                        text-right pr-4  border-b" >
                                            Last Modified On
                                        </th>
                                        <th scope="col" className=" border-b" >

                                        </th>
                                        <th scope="col" className=" text-center pr-12  border-b">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                {/* <div className=" float-right mr-3  w-[100%] rounded-r-md border-r flex"> */}
                                {isloaded ? (
                                    content.length > 0 ? (
                                        <tbody className="">
                                            {currentPageData?.map((ind) => {
                                                return (

                                                    <tr className={`${selectedRows.includes(ind.contentId) ? 'selected ' : ''}h-14 text-[#555555]`}
                                                        key={ind.contentId}
                                                    >
                                                        <td className=" bg-white pl-6 " colSpan={5}>
                                                            <input
                                                                type="checkbox"
                                                                className="w-6 h-6 mt-2 cursor-pointer rounded-md accent-[#FF8E00]"

                                                                checked={selectedRows.includes(ind.contentId)}
                                                                onChange={() => handleCheckboxClick(ind.contentId)}
                                                            />
                                                        </td>
                                                        <td className=" bg-white pl-12">
                                                            <img className='w-16 h-12' src={ind.thumbnailPath} alt="img" />
                                                        </td>
                                                        <td className="bg-white ">
                                                            {ind.title}
                                                        </td>

                                                        <td className="bg-white text-center pl-96">
                                                            {ind.duration}
                                                        </td>
                                                        <td></td>
                                                        <td className="bg-white flex">
                                                            <button className="pl-20  rounded-[20px]"
                                                                onClick={() => { openModal(); { handleOpen(ind.contentId) } }} >
                                                                <GrView />
                                                            </button>
                                                            <button className=" rounded-[20px]"
                                                                onClick={() => { }} >
                                                                <TbEdit />
                                                            </button>
                                                            <button className=" rounded-[20px]"
                                                            >
                                                                <CiTrash
                                                                    className=""
                                                                    size={25} onClick={() => handleDelete(ind.contentId)} />
                                                            </button>

                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    ) : (
                                        <tbody className="">
                                            <tr>
                                                <td colSpan={10}>
                                                    <div className="w-full h-full flex justify-center items-center mx-4">
                                                        <div className='w-[58%] h-72 flex items-center justify-center rounded-[4px] border-2 border-dashed mt-3 border-[#DEDEDE]'>
                                                            <div onClick={openParentModal} className="flex cursor-pointer items-center">
                                                                <img className='w-100 h-100 text-[#FF8E00]' src={drop} alt="Drop an Image" />
                                                                <span className="text-xl ml-5">Upload an Image / or <span className="text-[#FF8E00] underline text-lg">Upload a File</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                ) : (
                                    <tbody className="">
                                        <tr>
                                            <td colSpan={10}>
                                                <div className="w-full h-full flex justify-center items-center ">
                                                    <ThreeDots
                                                        height="80"
                                                        width="80"
                                                        radius="9"
                                                        color="#fe4d00"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                                {/* </div> */}
                            </table>

                            <ReactModal1
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Video Modal"
                                className="modal"
                                style={modalStyles}
                                ariaHideApp={false}
                            >
                                <ReactPlayer
                                    playing={modalIsOpen}
                                    url={selectedVideoUrl}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                />
                            </ReactModal1>
                        </div>

                        <div className='w-[100%] h-[10%]  bg-white flex  items-center justify-center '>
                            <div className="w-[97%] border-t flex items-center justify-between pt-2 ">
                                <div>
                                    <p className="flex  text-[#555555] select-none"> Showing {totalDisplay} of {itemsPerPage} Users
                                    </p>
                                </div>
                                <div>
                                    <Stack spacing={10}>
                                        <Pagination
                                            color="secondary"
                                            defaultPage={page}
                                            onChange={(event, value) => {
                                                setPage(value);
                                            }}
                                            count={totalPages} // Use the totalPages calculated from filtered data
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </>
    );
}

