import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../Axios/axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import signImg from "./images/signIn.svg";
import evd from "./images/everidoorlogo.png";
import { useUserContext } from "../Advertise/Context/Usercontext";
import { useSignedContext } from "../Advertise/Context/SignedContext";
import { CircularProgress } from "@mui/material";
import { startAuthentication } from "@simplewebauthn/browser";
import { Modal } from '@mui/material'

const notify = (text) =>
  toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const SignIn = () => {
  const navigate = useNavigate();

  const initialValues = {
    username: "",
    password: "",
  };
  const {advertiser, setAdvertiser} = useUserContext();
  const { signed, setSigned } = useSignedContext();
  const [ loading, setLoading ] = useState(false);
  const [passkeyUsername, setPasskeyUsername] = useState("");
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Handle form submission logic here
      setLoading(true);
      console.log(values);
      setSubmitting(false);
      const formdata = {
        username: values.username,
        password: values.password,
      };
      console.log(formdata);
      sessionStorage.setItem("username", values.username);
      
      const result = await Axios.post("login", formdata);
      console.log("sigin in result" ,result);

      if(result.data.role == "display-provider"){
        localStorage.setItem("Dp_token", result?.data?.token);
      }
      else{
        localStorage.setItem("token", result?.data?.token);
      }
      
      console.log(result?.data?.token);

      sessionStorage.setItem("role", result.data);
      localStorage.setItem("Admin", result.data.DpUsername);
       console.error("An error occurred:", result?.status);
      if (result?.status === 200) {
        setAdvertiser(result.data)
        sessionStorage.setItem("role", result.data.role);
        setSigned(true);
        navigate("/home");

      } 
     
       else if (result?.status === 202) {
        setSigned(true);
        
        if (result.data.role === "Manager") {
          sessionStorage.setItem("role", result.data.role);
          navigate("/Manager");
        } else if (result.data.role === "Publisher") {
          sessionStorage.setItem("role", result.data.role);
          navigate("/Publisher");
        } else if (result.data.role === "Viewer") {
          sessionStorage.setItem("role", result.data.role);
          navigate("/Viewer");
        }
      }
      if (result?.status == 201) {
        notify("Invalid Username");
        sessionStorage.clear();
   
      }
    } catch (error) {
      // Handle error here
      if (error?.response?.status == 401) {
        notify("Invalid Password");
        sessionStorage.clear();
   
      }
     
      console.error("An error occurred:", error?.response?.status);
    } finally {
      setLoading(false);
    }
  };
  
  const validationSchema = Yup.object().shape({
    username: Yup.string()
     
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

    const role = sessionStorage.getItem("role");
    if (role === "display-provider") {
      setSigned(true);
      navigate("/home");
    }
    if (role === "advertiser") {
      setSigned(true);
      navigate("/home");
    }
    if (role === "Viewer") {
      setSigned(true);
      navigate("/Viewer");
    }
    if (role === "Publisher") {
      setSigned(true);
      navigate("/Publisher");
    }
    if (role === "Manager") {
      setSigned(true);
      navigate("/Manager");
    }

    const handlePasskeyLogin = async () => {
      try {
        const res = await Axios.post('/generate-login-options', { username: passkeyUsername });
        console.log("following are the options: ", res);
        const options = res.data.options;
        const loginRes = await startAuthentication(options);
        console.log(loginRes);
        const verifyRes = await Axios.post('/verify-passkey-login', { username: passkeyUsername, cred: loginRes });
        console.log("response from server on verification of passkey login: ", verifyRes);
        if (!verifyRes.data.verified) {
          notify("Could not verify! Try adding passkey in user profile")
        }
  
        // if (verifyRes.data.verified) {
        //   setAdvertiser(verifyRes.data.user);
        //   setSigned(true);
        //   navigate("/home");
        // } else {
        //   notify("Passkey login failed");
        // }

        sessionStorage.setItem("username", passkeyUsername);

        if(verifyRes.data.role === "display-provider"){
          localStorage.setItem("Dp_token", verifyRes?.data?.token);
        }
        else{
          localStorage.setItem("token", verifyRes?.data?.token);
        }
        
        console.log(verifyRes?.data?.token);
        console.log("this is the user data from the backedn ----------------> " , verifyRes?.data)
  
        sessionStorage.setItem("role", verifyRes.data);
        localStorage.setItem("Admin", verifyRes.data.DpUsername);
         console.error("An error occurred:", verifyRes?.status);
        if (verifyRes?.status === 200) {
          setAdvertiser(verifyRes.data)
          sessionStorage.setItem("role", verifyRes.data.role);
          setSigned(true);
          navigate("/home");
  
        } 
       
         else if (verifyRes?.status === 202) {
          setSigned(true);
          
          if (verifyRes.data.role === "Manager") {
            sessionStorage.setItem("role", verifyRes.data.role);
            navigate("/Manager");
          } else if (verifyRes.data.role === "Publisher") {
            sessionStorage.setItem("role", verifyRes.data.role);
            navigate("/Publisher");
          } else if (verifyRes.data.role === "Viewer") {
            sessionStorage.setItem("role", verifyRes.data.role);
            navigate("/Viewer");
          }
        }
      } catch (error) {
        console.error(error);
        notify("Could not verify! Try adding passkey in user profile");
      }
    };

    const [passkeyModalOpen, setPasskeyModalOpen] = useState(false);

  return (
    <div className="w-full flex h-full ">
      <div className="w-full h-full ">
        <img
          src={signImg}
          alt="everidoor screen"
          className="object-cover h-full w-full"
        />
      </div>
      <ToastContainer />
      <Modal
        open={passkeyModalOpen}
        onClose={() => { setPasskeyModalOpen(false) }}
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white p-10 rounded-lg shadow-lg w-[40vh]">
            <button
              onClick={() => { setPasskeyModalOpen(false) }}
              className="absolute top-4 right-4 text-gray-600"
            >
              x
            </button>
            <h2 className="text-[24px] text-[#555555] font-[Ramillas] text-center mb-4">
              Welcome, Dear User
            </h2>
            <div className="text-[18px] mt-10">
              <input
                type="text"
                id="passkey-username"
                placeholder="Enter Username"
                value={passkeyUsername}
                onChange={(e) => setPasskeyUsername(e.target.value)}
                className="w-full border-b-[0.5px] text-[18px] outline-none border-[#DEDEDE] px-4 py-2"
              />
              <button
                type="button"
                onClick={handlePasskeyLogin}
                className="w-full mt-8 py-2 bg-[#FF8E00] text-white rounded-[2px]"
              >
                Login with Passkey
              </button>
            </div>
          </div>
        </div>
      </Modal>


      <div className="bg-[#FFFFFF]  w-full min-h-full">
        <div className="flex items-end justify-end pr-8 pt-[20px]">
          <img src={evd} alt="everidoor" height={"27px"} />
        </div>
        <h1 className="text-[40px] w-[500px] text-center leading-[50px]    mx-auto mt-20">
          <span className="text-[#000000] font-[Ramillas]  ">
            {" "}
            Tap into The Future of
          </span>
          <br />
          <span className=" ">
            {" "}
            <span className="text-[#FF8E00] font-[Ramillas] ">
              Digital
            </span>{" "}
            Advertising Realm!
          </span>
        </h1>
        <div className="pt-24  flex items-center justify-center">
          <div className="w-[50%] flex items-center flex-col justify-center ">
            <span className="text-[24px] text-[#555555] font-[Ramillas] ">
              Welcome Back,{" "}
              <span className=" font-semibold font-[Ramillas] ">
                Display Partners.
              </span>
            </span>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="bg-white p-8  text-[18px] ">
                  <Field
                    type="text"
                    id="Email"
                    name="username"
                    placeholder="Enter Username/ Email Id"
                    className="w-full border-b-[0.5px]  text-[18px] outline-none border-[#DEDEDE] px-4 py-2"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="w-full px-4 py-2 text-left  text-[18px] outline-none text-red-400 "
                  />

                  <Field
                    type="password"
                    id="password"
                    placeholder="Enter Password"
                    name="password"
                    className="w-full border-b-[0.5px] outline-none  text-[18px] border-[#DEDEDE] px-4 py-2 mt-4"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="w-full px-4 py-2 text-left  text-[18px] outline-none text-red-400 "
                  />
                  <div className="w-full flex justify-end ">
                    <Link to="/forgot" className="text-[#FF8E00] text-[12px] ">
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    id="sign"
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full mt-8 py-2 bg-[#FF8E00] text-white rounded-[2px]"
                  >
                    {!loading ? "Sign In" : "Please wait.. "}
                    {loading && (
                      <CircularProgress
                        sx={{ color: 'grey.700' }}
                        size={20}
                        className="ml-2"
                      />
                    )}
                  </button>
                  <h2 className="w-full text-center mt-8">or</h2>
                  <div className="">
                    <button onClick={() => {setPasskeyModalOpen(true)}} className="w-full mt-8 py-2 bg-[#FF8E00] text-white rounded-[2px]">Login with Passkey</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
