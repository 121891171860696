import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import { IoMdNotifications, } from "react-icons/io"
import { SocketContext } from "../../socketcontext";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { CgProfile } from "react-icons/cg";

export default function Header({ Account }) {
    const [nav, setNav] = useState(false);
    const [username, setUsername] = useState("");
    const [numberOfNotifications, setNumberOfNotifications] = useState(0);

    const [notifications, setNotifications] = useState([{
        title: "No notifications",
        content: "Try exploring something new and come back here for updates!"
    }]);

    const notifRef = useRef(null);

    useEffect(() => {
        setUsername(sessionStorage.getItem("username")?.split("@")[0]);
    }, []);

    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.removeItem("Dp_token");
        // Account = false;
        navigate("/Signin");
    };

    const [notif, setNotif] = useState(false);
    const WebSocket = useContext(SocketContext);
    const handleNotificationClick = () => {
        if (!notif) {
            WebSocket.emit("sendNotificationAdvertiser");
            WebSocket.on("recieverNotificationAdvertiser", (data) => {
                const now = new Date();
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(now.getDate() - 7);

                const filteredNotifications = data.filter(notification => {
                    const notificationDate = new Date(notification.createdAt);
                    return notification.recipientType === "2" && (notificationDate >= sevenDaysAgo || notification.important === 1);
                });

                setNotifications(filteredNotifications.length > 0 ? filteredNotifications : [{
                    title: "No notifications",
                    content: "Try exploring something new and come back here for updates!"
                }]);
                setNumberOfNotifications(filteredNotifications.length);
            });
        }
        setNotif(!notif);
    };

    useEffect(() => {
        console.log("fetching from useEffect for number of notifications");
        WebSocket.emit("sendNotificationAdvertiser");
        WebSocket.on("recieverNotificationAdvertiser", (data) => {
            const now = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(now.getDate() - 7);
            const filteredNotifications = data.filter(notification => {
                const notificationDate = new Date(notification.createdAt);
                return notification.recipientType === "2" && (notificationDate >= sevenDaysAgo || notification.important === 1);
            });
            setNotifications(filteredNotifications.length > 0 ? filteredNotifications : [{
                title: "No notifications",
                content: "Try exploring something new and come back here for updates!"
            }]);
            setNumberOfNotifications(filteredNotifications.length);
        });
    }, []);

    const toggleImportant = async (id, currentImportant) => {
        const updatedImportant = currentImportant === "1" ? "0" : "1";

        setNotifications(prevNotifications => 
            prevNotifications.map(notification => 
                notification._id === id ? { ...notification, important: updatedImportant } : notification
            )
        );

        WebSocket.emit("starNotificationupdate", {
            important: updatedImportant,
            id: id
        });

        WebSocket.on("Updated-Notification-status", (response) => {
            if (response.message !== "status updated of notification") {
                setNotifications(prevNotifications => 
                    prevNotifications.map(notification => 
                        notification._id === id ? { ...notification, important: currentImportant } : notification
                    )
                );
            }
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notifRef.current && !notifRef.current.contains(event.target)) {
                setNotif(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notifRef]);

    return (
        <div>
            <div className="h-[66px] shadow-sm  border-b shadow-[#FFF1EB] shadow-opacity-[30%]  flex items-center justify-end  bg-white ">
                <div className="flex gap-5 bg-white">
                    <div className="">
                        <div
                            className={`flex px-4 py-2 rounded-[10px] cursor-pointer bg-white items-center w-auto`}
                            
                        >
                            <div className="items-center flex gap-2 w-fit  ">
                                <div className="flex items-center gap-3 w-fit">
                                <div className="relative">
                                    <h1 className="text-[#000000] bg-red-500 rounded-full w-fit px-[0.4rem] absolute right-3 text-[0.75rem]">{numberOfNotifications}</h1>
                                    <IoMdNotifications size={27} className="text-[#555555] mr-4 size-10" onClick={handleNotificationClick} />
                                </div>
                                <div className="flex items-center gap-3 w-fit" onClick={() => {setNav(!nav);}}>
                                    <div className="">
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                src="https://cdn-icons-png.flaticon.com/512/236/236832.png?w=740&t=st=1690788839~exp=1690789439~hmac=4d4fa9ad6657c9817da41cf0e72d2857df6e1f320bfc8a6e75333d25bc8546b8"
                                                alt="user"
                                            />
                                        </div>
                                        <h1>{username}</h1>
                                    </div>
                                    <div className="">
                                        <button type="button">
                                            <FiChevronDown
                                                size={24}
                                                className={`transition-all ${nav ? "rotate-180" : ""}`}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {nav ? (
                    <div className="z-[100] absolute shadow-lg rounded-[10px] bg-white  right-2 top-16 w-[180px] h-36 p-3">
                        <ul className="text-lg text-left">
                            <Link to={'/profile'}>
                                <li className="flex items-center justify-start text-black gap-x-4 p-2 mt-2 rounded-md cursor-pointer transition-all duration-500 hover:bg-[#fe4d00] hover:text-white">
                                    <CgProfile size={26} />
                                    Profile
                                </li>
                            </Link>
                            <Link
                                to={"/help"}>
                                <li

                                    className={` flex items-center justify-start text-black gap-x-4 p-1 rounded-md cursor-pointer transition-all duration-500 hover:bg-primary hover:text-white`}
                                >
                                    <BiSupport size={26} />
                                    Support
                                </li>
                            </Link>
                            <li
                                className={` flex items-center justify-start text-black gap-x-4 p-1 rounded-md cursor-pointer transition-all duration-500 hover:bg-primary hover:text-white`}
                                onClick={() => handleLogout()}
                            >
                                <BiLogOutCircle size={26} />
                                Logout
                            </li>
                        </ul>
                    </div>
                ) : null}
                {notif && (
                    <div ref={notifRef} className="z-[100] absolute shadow-lg rounded-[10px] drop-shadow-md bg-white right-[200px] flex flex-col gap-2 top-24 w-[480px] h-[300px] overflow-y-scroll p-3">
                        {notifications.map((notification) => (
                            <div key={notification._id} className={`flex flex-row p-4 border-b-2 border-[#FF9002] border-dotted ${notification.important === "1" ? "bg-[#ff91023a]" : "bg-white"}`}>
                                <div className="w-[80%]">
                                    <div className="text-[1.2rem] font-bold text-[#FF9002]">
                                        {notification.title}
                                    </div>
                                    <div className="w-[90%] flex pl-5">
                                        {notification.content}
                                    </div>
                                </div>
                                <div data-tooltip-id="star-tooltip" data-tooltip-content="Mark as Important: Unimportant notifications will be cleared after 7 days" onClick={() => toggleImportant(notification._id, notification.important)}>
                                    {notification.important === "1" ? <FaStar className="size-8 text-[#FF9002]" /> : <CiStar className="size-8 text-[#FF9002]"/>}
                                </div>
                                <Tooltip id="star-tooltip" style={{ 
                                        backgroundColor: "white", 
                                        color: "black", 
                                        width: "50%", 
                                        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "5px",
                                        padding: "10px"
                                    }}/>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div >
    );
}
