import React, { useEffect, useState, useCallback, createContext, useContext } from "react";
import { BiHelpCircle, BiLogOutCircle } from "react-icons/bi";
import { BsArrowLeftCircleFill, BsDisplay, BsDot } from "react-icons/bs";
import { RiFolderUploadLine } from "react-icons/ri";
import {
    MdBarChart,
    MdOutlineAccountBalance,
    MdOutlineKeyboardArrowLeft,
    MdOutlineManageAccounts,
    MdSpaceDashboard,
} from "react-icons/md";
import { RxCalendar } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Edlogo from "../Images/everidoorlogosvg.png";
import { VscAccount } from "react-icons/vsc";
import { AiOutlineTeam } from "react-icons/ai";
import { List } from '@mui/material'
import { ListItemButton } from "@mui/material";
import { ListItemText } from "@mui/material";
import { ListItemIcon } from '@mui/material'
import { Collapse } from "@mui/material";
import { ExpandLess } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import nodeContext from "../../Context/nodeContext";


const DpMenu = ({ props }) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const [selectedMenuItem, setSelectedMenuItem] = useState(() => {
        return parseInt(sessionStorage.getItem("selectedMenuItem")) || 0;
    });


    const menu = [
        {
            title: "Dashboard",
            icons: <MdSpaceDashboard size={20} />,
            to: "/dashboard",
        },
        {
            title: "My Displays",
            icons: <BsDisplay size={20} />,
            to: "/MyDisplays", // Route for My Displays
        },

        {
            title: "My Content",
            icons: <RiFolderUploadLine size={20} />,
            to: "/content",
        },

        {
            title: "Schedules",
            icons: <RxCalendar size={20} />,
            to: "/schedule",
        },
        {
            title: "Report",
            icons: <MdBarChart size={20} />,
            to: "/report",
        }
    ];
    const handleMenuItemClick = useCallback((index) => {
        console.log(index);
        setSelectedMenuItem(index);
        sessionStorage.setItem("selectedMenuItem", index);
    }, []);

    const [open1, setOpen1] = useState(false);
    const handleClick = () => {
        if (!open) {
            setOpen(!open);
        }
        setOpen1(!open1);
    };
    const [selectedItem, setSelectedItem] = useState(null);

    // Function to handle the click on a ListItemButton
    const handleItemClick = (index) => {
        setSelectedItem(index);
    };

    const a = useContext(nodeContext);
    console.log("asdas", a.state.count);
    const handler = () => {
        a.updateFunction();
    }

    return (
        <div className="h-full">
            <div
                className={`${open ? "w-[290px]" : "w-24"
                    } p-4 pt-3 bg-white ease-in-out duration-500 flex-col gap-5 items-start relative h-screen shadow-lg shadow-[#FFF1EB] shadow-opacity-[30%] `}
            >
                <div className={` ease-in-out duration-500 cursor-default tracking-wide ${open ? "w-[90%] h-[42px]" : "w-[90%]"} flex items-center justify-center   mb-8`}>
                    <img className="h-[42px] w-[85%]  " src={Edlogo} alt="everidoor" />
                </div>

                <div
                    className={`origin-left text-xl text-black  items-center gap-1  ease-in-out duration-300 ${!open && "scale-24"
                        }`}
                >
                    <div
                        className={` origin-left w-[260px]  text-xl  font-bold flex   items-center gap-1  ease-in-out duration-700 `}
                    >
                        <div className={` ${open ? "ml-1" : "ml-0"
                            } p-3 border-b-[0.5px]  border-[#DEDEDE] ease-in-out duration-700 flex items-center  ${open ? "w-[250px]" : "w-[70px]"} ${open ? "h-12" : "h-12"}`}
                        >
                            <span className={`origin-left ease-in-out ${!open && "hidden"} ${open ? "ml-2" : "ml-0"} font-thin text-[#555555] duration-500 cursor-default tracking-wide  `} >
                                Display Network
                            </span>
                            <MdOutlineKeyboardArrowLeft
                                size={24}
                                className={`absolute ease-in-out text-white bg-[#FF8E00] rounded-full  duration-500 cursor-pointer top-[160px]  ${!open && "rotate-180"
                                    } ${open ? "left-[15rem]  top-28 " : "left-9 top-30"}`}
                                onClick={() => setOpen(!open)}
                            />
                        </div>
                    </div>
                    <ul className={`${open ? "" : "flex flex-col items-center justify-center"}`}>
                        {menu.map((item, index) => (
                            <li
                                key={index}
                                className={` ${item.gap ? "mt-4" : "mt-4"} flex items-center gap-x-4 rounded-[4px] cursor-pointer transition-all duration-500`}
                            >
                                <button
                                    type="button"
                                    onClick={() => handleMenuItemClick(index)}
                                    className={`w-full hover:bg-[#F6F6F6] hover:text-[#555555] h-full
                                    ${index === selectedMenuItem ? 'bg-[#FFF5E7] font-semibold text-[#FF8E00]'
                                            : 'text-[#555555]'
                                        }`}

                                >
                                    <Link to={item.to} className="flex items-center gap-2">
                                        <span>{item.icons}</span>
                                        <p className={`${open ? "" : "hidden scale-0 "} origin-center text-[18px]`}>
                                            {item.title}
                                        </p>
                                    </Link>
                                </button>
                            </li>
                        ))}

                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"

                        >


                            <ListItemButton onClick={handleClick} sx={{ paddingLeft: 0, justifyContent: 'flex-start' }}>
                                <ListItemIcon>
                                    <MdOutlineManageAccounts size={20} className="ml-5" />
                                </ListItemIcon>
                                <ListItemText className={`${open ? 'visible' : 'hidden'}`} primary="Account" sx={{ marginLeft: 0, color: '#555555' }} />
                                {open1 ? <ExpandLess sx={{ color: "#555555" }} /> : <ExpandMore sx={{ color: "#555555" }} />}
                            </ListItemButton>
                            <Collapse in={open1} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to={"/personalInfo"} >
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <BsDot size={36} className="mr-2 ml-2 text-[#FF8E00]" />
                                            </ListItemIcon>
                                            <ListItemText primary="Personal Info" sx={{ color: '#555555' }} />
                                        </ListItemButton>
                                    </Link>
                                    <Link to={"/teamMember"}>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <BsDot size={36} className="mr-2 ml-2 text-[#FF8E00]" />
                                            </ListItemIcon>
                                            <ListItemText primary="Team Members" sx={{ color: '#555555' }} />
                                        </ListItemButton>
                                    </Link>
                                    <Link to={"/Funds"}>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <BsDot size={36} className="mr-2 ml-2  text-[#FF8E00]" />
                                            </ListItemIcon>
                                            <ListItemText primary="Withdraw Funds" sx={{ color: '#555555' }} />
                                        </ListItemButton>
                                    </Link>

                                </List>
                            </Collapse>
                        </List>
                        <li className={"flex items-center w-full p-3 pl-4 my-3 hover:bg-[#F6F6F6] text-[18px] hover:text-[#555555] origin-center text-[#555555]  rounded-[4px] cursor-pointer transition-all duration-500 gap-2"}
                        >
                            <Link
                                to={"/help"}
                                className={"origin-left w-full h-full flex gap-2 items-center"}
                            >
                                <BiHelpCircle size={24} />
                                <p
                                    className={`${open ? "" : "hidden"
                                        } origin-center transition-opacity delay-500`}
                                >
                                    Help & Support
                                </p>
                            </Link>

                        </li>

                    </ul>
                    <div className={`border-[0.5px] border-[#FF8E00] p-2 shadow-md flex flex-col justify-center items-center ${open ? 'visible' : 'hidden'}`}>
                        <p className="text-[#FF8E00] text-center">Getting Started ?</p>
                        <p className="text-[#9D9D9D] leading-6 text-[14px]">Learn how to make the most of our Display Network Web App with this guided walkthrough. Discover key features, tips to get started. Let's get started!"</p>
                        <div className="flex justify-center items-center">
                            <button className={`bg-[#FF8E00] text-[#FFFFFF] text-center text-[13px] px-6 py-1 rounded-[4px] `}
                                onClick={handler}
                            >
                                Start Tour
                            </button>
                        </div>
                    </div>
                </div>
            </div >

        </div >
    );
};

export default DpMenu;