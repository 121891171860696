import React, { useState } from "react";
import { CiTrash } from "react-icons/ci"
import '../NewBox/Box.scss'
import Axios from "../../../Axios/axios";
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';

const MyBox = ({ data, content, setContent, setPopupVisible, setPlay, setCurrContentId }) => {



  // const managePlaylist = async () => {
  //   setCurrContentId(data.contentId);
  //   setPopupVisible(true);
  //   // console.log("Manage Playlist");

  //   await Axios.get("managePlaylist?username=" + sessionStorage.getItem("username") + "&contentId=" + data.contentId).then((res) => {
  //     // console.log("managePlaylist");
  //     console.log(res.data);
  //     setPlay(res.data.playlists);
  //   }
  //   );
  // }

  const handleDelete = async () => {
    // console.log(data);
    // console.log(sessionStorage.getItem("username"));
    //updated username --kaustubh 23/03/24
    await Axios.post('removeContent', {
      username: sessionStorage.getItem("username")||"ahdimainnskkkhugbsyuflabuijkhdaujio@yfyugkbwuihdiohwxcbniojwbnguinbiuFGVI.cuiwgdgbwuiohiugppiohbbkjhhkm",
      contentId: data.contentId
    });
    console.log(data.contentId,"-----------------admin is deleting now -------------------");
    setContent(content.filter((item) => item.contentId !== data.contentId)); //can't use title in the long run
    // console.log("delete");
  }

  const videoUrl = "https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/" + data.contentId + "." + 'mp4';
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const modalStyles = {
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      border: 'none',
      borderRadius: '8px',
      padding: '0',
      width: 'calc(100% - 300px)', // Adjust the value as needed
      maxWidth: '800px', // Adjust the value as needed
      maxHeight: 'calc(100% - 300px)', // Adjust the value as needed
      margin: '200px',
      background: '#000',
    },
  };


  return (
    <>
      <div className="Box">
        <div className="img" onClick={() => openModal()}>
          <img src={data.thumbnailPath} alt="img" />
        </div>
        <div className="content">
          <h4>{data.title}</h4>
          <div className="mid">
            <p>{data.duration}</p>
            <p>{data.advertiser}</p>
          </div>
          <div className="down">
            {/* <button onClick={async () => await managePlaylist()}>Manage Playlist</button> */}
            <div className="icon">
              <CiTrash size={25} onClick={handleDelete} />
            </div>
          </div>
        </div>

        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Video Modal"
          className="modal"
          style={modalStyles}
          ariaHideApp={false}
        >
          <ReactPlayer
            url={videoUrl}
            playing={modalIsOpen}
            controls={true}
            width="100%"
            height="100%"
          />
          <button className="close-button" onClick={closeModal}>
            <span className="close-cross"></span>
          </button>

        </ReactModal>

      </div>
    </>

  )
}

export default MyBox;