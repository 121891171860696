import React, { useEffect, useRef, useState } from "react";
import uploadimg from "../Images/uploadImg.png";
import uploadvdo from "../Images/uploadVideo.png";
import { useNavigate } from "react-router-dom";
import Axios from "../Axios/axios";
import { useContext } from "react";
import { SocketContext } from "../../socketcontext";
import Menu from "../Advertise/Menu";
import { MdAccountBalanceWallet, MdAddToQueue } from "react-icons/md";
import { RxCalendar, RxDashboard } from "react-icons/rx";

import Header from "../Header/AdHeader";
import { IoChevronForwardOutline } from "react-icons/io5";

const Advertise = ({ 
    Ad,
    setAd,
    nextStep,
    prevStep,
    handleChange, 
    New,
    setNew,
    handleVideoUpload,
    duration,
}) => {
    const Websocket = useContext(SocketContext);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        nextStep();
    };

    function handleCancel() {
        navigate("/home");
    }

    const menu = [
        {
            title: "Create Ads",
            icons: <MdAddToQueue size={24} />,
            to: "/advertiser/CreateCampaign",
        },
        {
            title: "View",
            icons: <RxCalendar size={24} />,
            to: "/advertiser/ViewCampaign",
        },
       
        {
            title: "Account",
            icons: <MdAccountBalanceWallet size={24} />,
            to: "/advertiser/Account",
        },
    ];

    const videoRef = useRef(null);
    const [open, setOpen] = useState(true);

    return (
        <>
            <div className="flex h-[100%] w-[100%] bg-gray-100">
                <Menu />

                <div
                    className={`feature flex-1 select-none border h-screen  ${open ? "w-[70%]" : "w-full"
                        }`}
                >
                    <div className="border bg-white ">
                        <Header />
                    </div>
                    <div className="xl:h-[84%] overflow-hidden w-full ">
                        <div className="flex  items-center justify-between p-3 xl:p-5">
                            <div className=" flex items-center ">
                                Home <IoChevronForwardOutline /> Create Ads
                            </div>
                            <div className="">
                                <span>Step 1/3</span>
                            </div>
                        </div>
                        <div className="flex  h-full w-full justify-center xl:mt-10">
                            <div className="border xl:p-3 shadow-lg rounded-[20px] border-gray-100 bg-white p-2 xl:w-[70%]  xl:h-[80%] ">
                                <form className="mt-[1rem]" onSubmit={handleSubmit}>
                                    <div className="">
                                        <div className="text-xl mb-4 mx-3 text-black">
                                            <h2>Create a Campaign</h2>
                                        </div>
                                        <div className="">
                                            <input
                                                className="w-full xl:py-3 py-2 px-3 rounded-full border  outline-none"
                                                required
                                                type="text"
                                                name="CampName"
                                                placeholder="My First Campaign"
                                                onChange={handleChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className="AdHead mt-3">
                                        <div className="AdHead__box">
                                            <select
                                                className="outline-none text-gray-400 px-2 py-2 xl:p-2 w-full border rounded-full"
                                                name="tags"
                                                id="tags"
                                                onChange={handleChange}
                                                required
                                            >
                                                <option
                                                    className="border text-gray-400 outline-none rounded-full"
                                                    value="please select one or more tags"
                                                >
                                                    Please select a tag
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Auto"
                                                >
                                                    Auto
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Retail"
                                                >
                                                    Retail
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Restaurants"
                                                >
                                                    Restaurants
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Consumer Goods
"
                                                >
                                                    Consumer Goods
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Entertainment"
                                                >
                                                    Entertainment
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Travel"
                                                >
                                                    Travel
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Financial"
                                                >
                                                    Financial
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Technology"
                                                >
                                                    Technology
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Telecom"
                                                >
                                                    Telecom
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Healthcare"
                                                >
                                                    Healthcare
                                                </option>
                                                <option
                                                    className="text-gray-800"
                                                    value="Food"
                                                >
                                                    Food
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                            <input
                                                className="w-full xl:py-3 py-2 px-3 rounded-full border  outline-none"
                                                required
                                                type="text"
                                                name="slots"
                                                placeholder="Enter Your slots upto 4"
                                                onChange={handleChange}
                                            />
                                    </div>

                                    <div className="flex flex-col h-[100%] 2xl:gap-10 gap-0  justify-between">

                                    <div className=" xl:flex  items-center justify-evenly mt-10 xl:mt-0 p-6 h-[100%] ">
                                        <div className="xl:flex-row flex flex-col h-[100%]  gap-4">
                                            <input
                                                type="file"
                                                required
                                                id="image"
                                                style={{ display: "none" }}
                                                onChange={(e) => {
                                                    setNew({
                                                        ...Ad,
                                                        imgDetail:
                                                            e.target.files[0],
                                                        imgPath: e.target.value,
                                                    });
                                                }}
                                            />
                                            <input
                                                type="file"
                                                required
                                                id="video"
                                                style={{ display: "none" }}
                                                accept=".mp4"
                                                onChange={(e) => {
                                                    setNew({
                                                        ...New,
                                                        videoDetail:
                                                            e.target.files[0],
                                                        videoPath:
                                                            e.target.value,
                                                    });
                                                        
                                                    handleVideoUpload(
                                                        e.target.files[0],
                                                        videoRef
                                                    );
                                                }} 
                                            />

                                            <div className=" border-4 border-dashed xl:h-40 xl:w-40 h-64 w-64 text-center xl:p-1 p-5">
                                                {New.imgDetail == "" ? (
                                                    <label htmlFor="image">
                                                        <img
                                                            src={uploadimg}
                                                            alt="up"
                                                            className=" xl:w-20 w-42 mx-auto  border-gray-400 rounded-md cursor-pointer"
                                                        />
                                                        <div className="flex mt-3 xl:mt-0 text-gray-400 flex-col justify-center items-center">
                                                            <h3>
                                                                Upload Thumbnail
                                                            </h3>
                                                            <h3>
                                                                JPEG, PNG files
                                                            </h3>
                                                        </div>
                                                    </label>
                                                ) : (
                                                    <label htmlFor="image">
                                                        <img
                                                            src={uploadimg}
                                                            alt="up"
                                                            className="xl:w-20 w-42 mx-auto border-gray-400 rounded-md cursor-pointer"
                                                        />
                                                        <div className="flex mt-3 xl:mt-0 text-gray-400 flex-col justify-center items-center">
                                                            <h3>
                                                                {" "}
                                                                Thumbnail
                                                                Selected
                                                            </h3>
                                                        </div>
                                                    </label>
                                                )}
                                            </div>

                                       
                                            <div className="border-4 border-dashed xl:h-40 xl:w-40 h-64 w-64 text-center xl:p-1  p-5">
                                                {New.videoPath == "" ? (
                                                    <label htmlFor="video">
                                                        <img
                                                            src={uploadvdo}
                                                            alt="up"
                                                            className="xl:w-20 w-42 mx-auto border-gray-400  rounded-md cursor-pointer"
                                                        />
                                                        <div className="flex mt-3 xl:mt-0 text-gray-400 flex-col justify-center items-center">
                                                            <h3>
                                                                Upload Video
                                                                (mp4)
                                                            </h3>
                                                        </div>
                                                    </label>
                                                ) : (
                                                    <label htmlFor="video">
                                                        <img
                                                            src={uploadvdo}
                                                            alt="up"
                                                            className="xl:w-20 w-42 mx-auto border-gray-400  rounded-md cursor-pointer"
                                                        />

                                                        <div className="flex mt-3 xl:mt-0 text-gray-400 flex-col justify-center items-center">
                                                            <h3>
                                                                Video Selected,
                                                                Duration is{" "}
                                                                {duration}
                                                            </h3>
                                                        </div>
                                                    </label>
                                                )}
                                            </div>
                                            <video
                                                ref={videoRef}
                                                className="w-[20%] hidden"
                                            />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex justify-end xl:mr-10 mr-4 gap-4">
                                            <div className="">
                                                <button
                                                    type="reset"
                                                    onClick={handleCancel}
                                                    className="border  border-dashed hover:bg-[#fe4d00]  py-1 text-[#FF4D00] hover:text-white xl:py-1 px-8 rounded-full"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="">
                                                <button
                                                    id="Next"
                                                    className="border bg-[#FF4D00] hover:bg-[#ff8957] text-white xl:py-1 py-1 px-10 rounded-full"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Advertise;
