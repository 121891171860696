import React, {useState } from "react";
import DpInbox from "../../Inbox/Inbox_box/DpInbox"

const DpTabs = ({ pending, approved, rejected }) => {
    const [currentTab, setCurrentTab] = useState("1");
    const tabs = [
        {
            id: 1,
            tabTitle: "Pending",
            title: "",
            content: pending,
        },
        {
            id: 2,
            tabTitle: "Approved",
            title: "",
            content: approved,
        },
        {
            id: 3,
            tabTitle: "Rejected",
            title: "",
            content: rejected,
        },

    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    };

    return (
        <div className="w-full">
            <div className="border-b-4">
                {tabs.map((tab, i) => (
                    <button
                        key={i}
                        id={tab.id}
                        disabled={currentTab === `${tab.id}`}
                        onClick={handleTabClick}
                        className="hover:bg-[#fe7642] hover:text-white disabled:bg-[#fe7642] disabled:text-white transition-all rounded-t-lg mx-2"
                    >
                        {tab.tabTitle}
                    </button>
                ))}
            </div>
            <div className="mt-4 ">
                {tabs.map((tab, i) => (
                    <div key={i}>
                        {currentTab === `${tab.id}` && (
                            <div>

                                {tab.content.length ? (
                                    <div className="pr-12 pl-12 flex  gap-8 flex-wrap w-full">
                                        {tab.content
                                            .slice()
                                            .reverse()
                                            .map((data) => (
                                                <DpInbox
                                                    key={data.accountNumber1}
                                                    status={data.status}
                                                    holderName_1={data.holderName1}
                                                    total_amount={data.total_amount}
                                                    Account_Name={data.accountNumber1}
                                                    username={data.username}
                                                    RequireAmount={data.amount}
                                                    Content={data.contact}
                                                />
                                            ))}
                                    </div>
                                ) : (
                                    <p>No {tab.status} requests to show...</p>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DpTabs;
