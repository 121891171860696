import React from 'react'
import DpMenu from '../../Menu/DpMenu';
import DpHeader from "../../Header/DpHeader"
import { ToastContainer } from 'react-toastify'
import { IoChevronForwardOutline } from "react-icons/io5";
import { GoSearch } from 'react-icons/go'
import { BsSquare, BsThreeDotsVertical } from 'react-icons/bs'
import { CiSearch } from 'react-icons/ci';
import { useEffect } from 'react';
import Axios from '../../Axios/axios';
import { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import { GrView } from "react-icons/gr"
import { MdOutlineFilterList } from 'react-icons/md';
import ReactPlayer from 'react-player';
import ReactModal1 from "react-modal";
import Select from 'react-select'


const Schedules = () => {

    const username = sessionStorage.getItem("username")
    const [loaded, setLoaded] = useState(false)
    const [dpdata, setDpData] = useState([]);

    console.log(dpdata);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get(`Dpsedulehistory?username=${username}`);
                setDpData(response.data);
                console.log("dp response sdaasd", response);
                setLoaded(true);

            } catch (error) {
                console.error('An error occurred:', error);
            }
        }

        fetchData();
    }, [])
    const [searchText, setSearchText] = useState("");
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const [categoryFilter, setCategoryFilter] = useState("All");

    const [selectedDateFilter, setSelectedDateFilter] = useState('Date');



    const filteredData = dpdata.filter((item) => {
        const searchTerm = searchText ? searchText.toLowerCase() : '';
        const category = categoryFilter ? categoryFilter.toLowerCase() : '';
        if (selectedDateFilter !== "Date") {
            const currentDate = new Date();
            console.log(currentDate);
            let filterDate = null;

            switch (selectedDateFilter) {
                case 'Today':
                    filterDate = currentDate;
                    break;
                case 'Yesterday':
                    currentDate.setDate(currentDate.getDate() - 1);
                    filterDate = currentDate;
                    break;
                case 'Last 7 days':
                    currentDate.setDate(currentDate.getDate() - 7);
                    filterDate = currentDate;
                    break;
                case 'Last 30 days':
                    currentDate.setDate(currentDate.getDate() - 30);
                    filterDate = currentDate;
                    break;
                default:
                    break;
            }
            console.log(filterDate);
            const inputDate = new Date(filterDate);
            const day = inputDate.getDate();
            const month = inputDate.getMonth() + 1;
            const year = inputDate.getFullYear() % 100;
            const formattedDate = " ${day}/${month}/${year}";
            console.log(formattedDate);

            // Apply date filtering
            if (item.date === formattedDate) {
                return (
                    (category === "all" || (item.bookingtype &&
                        item.bookingtype.toLowerCase() === category)) &&
                    (item.screenId && item.screenId.toLowerCase().includes(searchTerm) ||
                        item.screenName && item.screenName.toLowerCase().includes(searchTerm) ||
                        item.screen_address && item.screen_address.toLowerCase().includes(searchTerm))
                );
            }
        } else {
            return (
                (category === "all" || (item.bookingtype &&
                    item.bookingtype.toLowerCase() === category)) &&
                (item.screenId && item.screenId.toLowerCase().includes(searchTerm) ||
                    item.screenName && item.screenName.toLowerCase().includes(searchTerm) ||
                    item.screen_address && item.screen_address.toLowerCase().includes(searchTerm))
            );
        }
    });

    dpdata.map((i) => console.log(i.date_of_addition));

    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleCheckboxClick = (rowId) => {

        if (selectedRows.includes(rowId)) {

            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
        selectedRows.map((i) => console.log(i))
    };
    const handleSelectAllClick = () => {
        if (selectedRows.length === dpdata.length) {
            setSelectedRows([]);
            setSelectAll(false);
        } else {
            setSelectedRows(dpdata.map((i) => i.contentId));
            setSelectAll(true);
        }

        selectedRows.map((i) => console.log(i))

    };

    const handleDeleteAll = async () => {
        const username = sessionStorage.getItem("username");
        selectedRows.map((i) => console.log(i))
        const contentIdToDelete = selectedRows.map((i) => i);
        console.log(contentIdToDelete);
        console.log(sessionStorage.getItem("username"));
        await Axios.post('removeDpsedulehistory', {
            username: username,
            contentId: contentIdToDelete
        });

        const updatedContent = dpdata.filter((item) => !selectedRows.includes(item.contentId));

        updatedContent.map((i) => console.log(i.contentId))
        setDpData(updatedContent);
        console.log("delete");
        setSelectAll(false);
    };

    const [page, setPage] = useState(1);
    const itemsPerPage = 12;
    var totalDisplay = dpdata.length;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    console.log(dpdata);
    console.log(totalPages);
    console.log(totalDisplay);
    const currentPageData = filteredData.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const navigate = useNavigate();
    const SchedulesNaviagte = () => {
        navigate("/home")
    }
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const handleOpen = (videoId) => {
        const selectedContent = dpdata.find((item) => item.videoId === videoId);
        if (selectedContent) {
            const videoUrl = `https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/${selectedContent.videoId}.mp4`;
            setSelectedVideoUrl(videoUrl);

        }
        console.log("hello");
    }
    const modalStyles = {
        overlay: {
            display: 'flex',
            justifyContent: 'center',
        },
        content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none',
            borderRadius: '8px',
            padding: '0',
            width: 'calc(100% - 300px)',
            maxWidth: '800px',
            maxHeight: 'calc(100% - 300px)',
            margin: '200px',
            background: 'black',
        },
    };
    const options = [
        { value: 'All', label: 'Screens: All' },
        { value: 'Instant', label: 'Instant' },
        { value: 'Advance', label: 'Advance' },
    ];
    const handleCategoryChange = (selectedOption) => {
        setCategoryFilter(selectedOption);
    };
    const dateFilterOptions = [
        ,
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
        { value: 'Last 7 days', label: 'Last 7 days' },
        { value: 'Last 30 days', label: 'Last 30 days' },
    ];
    const handleDateFilterChange = (selectedOption) => {
        setSelectedDateFilter(selectedOption);
    };

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: "white",
            borderRadius: "4px", // Border radius
            width: "150px",
            borderColor: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                borderColor: state.isFocused ? 0 : 0
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "" : "", // Option background color
            color: state.isFocused ? "" : "", // Option text color
            cursor: 'pointer',
        }),

    };


    return (
        <>
            <div className="h-screen flex w-screen ">
                <ReactModal1
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Video Modal"
                    className="modal"
                    style={modalStyles}
                    ariaHideApp={false}
                >
                    <ReactPlayer
                        playing={modalIsOpen}
                        url={selectedVideoUrl}
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                </ReactModal1>
                <DpMenu />
                <div className="h-[100%] overflow-hidden w-[100%]  bg-[#FFFDF9] " >
                    <ToastContainer />
                    <DpHeader />
                    <div className=" flex items-center bg-[#FFFDF9] p-4">
                        <span onClick={SchedulesNaviagte} className='text-[#8E8E8E] cursor-pointer'>Home</span>   <IoChevronForwardOutline /> Schedules
                    </div>
                    <div className="h-[78%]  w-[98%]  pl-4 pr-2   mx-4  ">
                        <div className='flex flex-col gap-5 '>
                            <p className='text-[#001122] text-2xl'>Schedules</p>
                            <div className="flex  border w-fit bg-white rounded-[4px]">
                                <div className=" flex items-center justify-center h-10 w-10  rounded-[4px] bg-white  ">
                                    <CiSearch size={24} className=" text-gray-400" />
                                </div>
                                <div className="bg-white  flex items-center w-40 rounded-[4px] ">
                                    <input
                                        className=" outline-none h-fit  w-36 text-black"
                                        type="text"
                                        placeholder="Search Display"
                                        value={searchText}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-[98%] flex gap-2 py-6 '>
                            <div className=" flex items-center ">
                                <MdOutlineFilterList size={24} className='text-[#555555]' />
                                <p className='text-[#555555] text-[16px] ' >Filters:</p>
                                <div className="">
                                    <Select
                                        styles={customSelectStyles}
                                        className="rounded-[4px]  bg-[#FFFDF9] text-[#9D9D9D]  px-3 py-2"
                                        value={options.find(option => option.value === categoryFilter)}
                                        onChange={(selectedOption) => handleCategoryChange(selectedOption.value)}
                                        options={options}
                                    />
                                </div>
                                <div className=''>
                                    <Select
                                        styles={customSelectStyles}
                                        className="rounded-[4px]  bg-[#FFFDF9]  flex text-[#9D9D9D] px-3 py-2 cursor-pointer outline-none"
                                        options={dateFilterOptions}
                                        value={selectedDateFilter}
                                        onChange={handleDateFilterChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='w-[100%] h-[60%]  overflow-hidden shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]  bg-[#FFFFFF] mr-1 flex flex-col items-center overflow-y-auto'>
                            <table
                                className="border-separate border-spacing-y-2  w-[98%] text-sm  ">
                                <thead>
                                    <tr className=" text-left ">
                                        <th
                                            scope="col"
                                            className="py-4 border-b  pl-4"
                                        >
                                            <input
                                                type="checkbox"
                                                className="w-6 h-5 mt-2 cursor-pointer rounded-md accent-[#FF8E00]"
                                                checked={selectAll}
                                                onChange={handleSelectAllClick}
                                            />
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-4 pl-6 border-b"
                                        >
                                            Screen Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-4 border-b"
                                        >
                                            Slots
                                        </th>

                                        <th
                                            scope="col"
                                            className=" py-4 
                                                        text-right pl-96 border-b"
                                        >
                                            Screen Code
                                        </th>

                                        <th
                                            scope="col"
                                            className="py-4 
                                                        pl-20    
                                                        border-b "
                                        >
                                            Date & Time
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-4 border-b"
                                        >
                                            Actions
                                        </th>
                                        <th scope='col' className="py-4 pl-10 border-b text-center">
                                            BookingType
                                        </th>
                                    </tr>
                                </thead>
                                {loaded ? (
                                    <tbody className=''>
                                        {currentPageData.map((item, index) => (
                                            <tr key={index} className={selectedRows.includes(item.contentId) ? 'selected' : ''}>
                                                <td className="bg-white rounded-l-full py-4 pl-4">
                                                    <input
                                                        type="checkbox"
                                                        className="w-6 h-5 mt-2 cursor-pointer rounded-md accent-[#FF8E00]"
                                                        checked={selectedRows.includes(item.contentId)}
                                                        onChange={() => handleCheckboxClick(item.contentId)}
                                                    />
                                                </td>
                                                <td className="bg-white pl-6">{item.screenName}</td>
                                                <td className="bg-white text-right pr-6">
                                                    {item.DPslot}
                                                </td>
                                                <td className="bg-white text-right pr-6">
                                                    {item.screenId}
                                                </td>
                                                <td className="bg-white pl-20">
                                                    {item.date_of_addition}
                                                </td>
                                                <td className="bg-white pl-4     ">
                                                    <GrView className=' cursor-pointer' onClick={() => { openModal(); { handleOpen(item.videoId) } }} />
                                                </td>
                                                <td className='rounded-r-lg text-center pl-10'>
                                                    {item.bookingtype}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody className="">
                                        <tr>
                                            <td colSpan={10} >
                                                <div className="flex justify-center items-center"
                                                    style={{ height: '100%' }}
                                                >
                                                    <ThreeDots
                                                        height="80"
                                                        width="80"
                                                        radius="9"
                                                        color="#fe4d00"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={
                                                            true
                                                        }
                                                    />

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                        <div className='w-[100%] h-[10%]  bg-white flex  items-center justify-center '>
                            <div className='w-[99%] border-t flex items-center justify-between pt-2'>
                                <div>
                                    <p className='flex  text-[#555555] select-none'>Showing {page} of {totalDisplay} Results </p>
                                </div>
                                <div>
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="secondary"
                                            defaultPage={page}
                                            shape="rounded"
                                            onChange={(event, value) => {
                                                setPage(value);
                                            }}
                                            count={totalPages} // Use the totalPages calculated from filtered data
                                            variant="outlined"
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >

        </>
    )
}

export default Schedules