import React from "react";
import Box from "../Inbox/Inbox_box";

const Tabs = ({
    pending,
    approved,
    rejected,
    expired,
    emergency,
    currentTab,
    setCurrentTab,
}) => {
    console.log(rejected, "rejected------------->");
    const tabs = [
        {
            id: 1,
            tabTitle: "Pending",
            title: "",
            content: pending,
        },
        {
            id: 2,
            tabTitle: "Approved",
            title: "",
            content: approved,
        },
        {
            id: 3,
            tabTitle: "Rejected",
            title: "",
            content: rejected,
        },
        {
            id: 4,
            tabTitle: "Expired",
            title: "",
            content: expired,
        },
        {
            id: 5,
            tabTitle: "Instant",
            title: "",
            content: emergency,
        },
    ];

    const handleTabClick = (e) => {
        console.log('handleClick called for capmaign button');
        setCurrentTab(e.target.id);
    };

    return (
        <div className="w-full h-[64vh] overflow-y-auto">
            <div className="">
                {tabs.map((tab, i) => (
                    <button
                        key={i}
                        id={tab.id}
                        disabled={currentTab === `${tab.id}`}
                        onClick={handleTabClick}
                        className="hover:bg-[#fe7642] hover:text-white bg-[#DEDEDE] disabled:bg-[#FF4D00] disabled:text-white transition-all rounded-full mx-2"
                    >
                        {tab.tabTitle}
                    </button>
                ))}
            </div>
            <div className="mt-8">
                {tabs.map((tab, i) => (
                    <div key={i}>
                        {currentTab === `${tab.id}` && (
                            <div>
                                {/* <p className="title">{tab.title}</p> */}
                                {tab.content.length ? (
                                    <div className="flex justify-between flex-wrap w-full px-2">
                                        {tab.content
                                            .slice()
                                            .reverse()
                                            .map((data) => (
                                                <Box
                                                    key={data.campaignRequestId}
                                                    campaignRequestId={
                                                        data.campaignRequestId
                                                    }
                                                    campaignName={
                                                        data.campaignName
                                                    }
                                                    fromUser={data.fromUser}
                                                    toUser={data.toUser}
                                                    content_to_be_played={
                                                        data.content_to_be_played
                                                    }
                                                    thumbnail_for_content_to_be_played={
                                                        data.thumbnail_for_content_to_be_played
                                                    }
                                                    fromDate={data.fromDate}
                                                    toDate={data.toDate}
                                                    fromTime={data.fromTime}
                                                    toTime={data.toTime}
                                                    selectedScreens={
                                                        data.selectedScreens
                                                    }
                                                    status={tab.tabTitle}
                                                    newEdit={data.newEdit}
                                                    additional_comments={
                                                        data.additional_comments
                                                    }
                                                />
                                            ))}
                                    </div>
                                ) : (
                                    <p>No {tab.tabTitle} requests to Show...</p>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
