import React, { useState } from "react";
import './AuthForm.scss'
import { Box, Modal } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import ViewPinLogin from "../pages/ViewPinLogin";
import Otp from "../Component/Otp";
import { ToastContainer, toast } from "react-toastify";


const AuthForm = ({ handleSubmit, setAdminId, setPass, pass, otp, setOtp, adminId }) => {

  const notify = (text) =>
    toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = async (e) => {
    setIsSubmitting(true);
    try {
      await handleSubmit(e);
    } catch (error) {
      notify("Invalid Credentials");
    } finally {
      setIsSubmitting(false);
    }
    
  };
    return (
        <div className='signInController'>
        <ToastContainer/>
        <Modal
        open={otp}
        onClose={()=>setOtp(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <div class="relative w-[40vw] h-[90vh] py-12">
            <div class="relative bg-white rounded-lg shadow w-full">
              <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <button
                  type="button"
                  class="text-black rounded-lg hover:bg-slate-300 hover:rounded-xl text-2xl w-8 h-8 ms-auto inline-flex justify-center items-center"
                  onClick={()=>setOtp(false)}
                >
                  x
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div class="px-4 w-full h-full">
                <Otp adminId={adminId} pass={pass}/>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
            <div className='left-Admin'></div>
            <div className='rigth-Admin'>
                <form onSubmit={e => handleFormSubmit(e)}>
                    <div className='heading'>
                        <h1 className='admin-logo'>Admin Login</h1>
                    </div>
                    <div className='admin-label1'>
                        <label >Admin Id</label>
                    </div>
                    <input className="signInController_input " type='name' placeholder='Enter Your Admin Id' onChange={e => setAdminId(e.target.value)} />
                    <div className='admin-label1'>
                        <label >Password</label>
                    </div>
                    <input className="signInController_input " type='password' placeholder='Enter Your Admin Password' onChange={e => setPass(e.target.value)} />
                    <div className="btn_contain">
                      {isSubmitting ? (
                        <ThreeDots
                          height={50}
                          width={50}
                          color="#340C42"
                          className="loader"
                        />
                      ) : (
                        <>
                          <button className="signInController_button submit" type="submit">
                            Submit
                          </button>
                          <button className="signInController_button reset" type="reset">
                            Cancel
                          </button>
                        </>
                      )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AuthForm