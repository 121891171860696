import React, { useEffect, useState } from 'react'
import ManagerMenu from '../Menus/ManagerMenu'
import DpHeader from "../../Components/Header/DpHeader"
import { IoChevronForwardOutline } from 'react-icons/io5'
import { MdCheckCircle} from 'react-icons/md';
import Axios from '../../Components/Axios/axios'
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function PersonalInfo() {
    const notify = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyUser = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyAdd = (text, type) => {
        if (type === "success") {
            toast.success(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }
    const notifyDelete = (text, type) => {
        if (type === "success") {
            toast.success(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }
    const notifyPrimary = (text, type) => {
        if (type === "success") {
            toast.success(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }

    const [username, setUsername] = useState("");
    const [nav, setNav] = useState(false);

    useEffect(() => {
        setUsername(sessionStorage.getItem("username"));

    }, []);

    const [isParentModalOpen, setIsParentModalOpen] = useState(false);
    const [isParentModalOpenView, setIsParentModalOpenView] = useState(false);
    const [isParentModalOpenBank, setIsParentModalOpenBank] = useState(false);
    const [account, setAccount] = useState(false)
    const [primary, setPrimary] = useState(false)

    console.log(account);

    const openParentModal = () => {
        setIsParentModalOpen(true);
    };

    const closeParentModal = () => {
        setIsParentModalOpen(false);
    };

    const openParentModalViewBank = () => {
        setIsParentModalOpenView(true);
        setMenuOpenBank(false);
        setMenuOpenBankDown(false)
    };

    const closeParentModalViewBank = () => {
        setIsParentModalOpenView(false);
    };

    const initState = {
        phoneNumber: "",
        firstName: "",
        lastName: ""
    };
    const [formData, setFormData] = useState(initState);
    const resetData = () => {
        setFormData(initState);
    }



    const [ifscCode, setIfscCode] = useState('');
    const [bankName, setBankName] = useState();

    const [branchName, setBranchName] = useState('');
    useEffect(() => {
        if (ifscCode) {
            axios
                .get(`https://ifsc.razorpay.com/${ifscCode}`)
                .then(response => {
                    const data = response.data;
                    setBankName(data.BANK);
                    setBranchName(data.BRANCH);

                })
                .catch(error => {
                    console.error('Error fetching bank details', error);
                    setBankName('');
                    setBranchName('');
                });
        }
    }, [ifscCode]);




    const [accData, setAccData] = useState({
        accountNumber: "",
        holderName: "",
        ifsc: "",

    });



    const [error, setError] = useState({
        holderName: '',
        accountNumber: '',
        conBankaccountNumber: '',
        ifsc: ''
    })


    useEffect(() => {
        console.log(bankName);
    }, [bankName]);

    const handleChangeAccount = (event) => {
        const { name, value } = event.target;

       
        if (name === "ifsc") {
            setIfscCode(value);
        }
        else if (name === " ") {
            setIfscCode(null)
        }

      
        setAccData((prevData) => ({
            ...prevData,
            [name]: value,
            username: username,
            accountType: selectedType,

        }));

      
        validateInput(event);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            username: username,
        }));
       

    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log('Form Data:', formData); 

        try {
            const response = await Axios.post('/save-userdetail-payment', formData);
       
            notifyUser("User Details Added!")
        } catch (error) {
            console.error('Error saving data:', error);
        }
        resetData();
    };

    const [accountType, setAccountType] = useState('');
    const [open, setOpen] = useState(true);
    const [accDetails, setAccDeatils] = useState({
        accountNumber1: "",
        accountNumber2: "",
    })



    const [bank, setBank] = useState({});
    console.log(bank)
    console.log(bank.accountNumber2)

   
    const handleSubmitAccountDetails = async (event) => {
        event.preventDefault();
        console.log('Form Data:', accData);
        try {
            const response = await Axios.post('/save-accountdetail-payment', {
                ...accData,
                bank: bankName,
            });
            console.log(response);
            if (response.status === 202) {
                notify("Same Account not Allow!")
            }
            else {
                notifyAdd("Bank added successfully", "success");
            }

        } catch (error) {
            console.error('Error saving data:', error);
        }

        setIsParentModalOpen(false);
    };
    const newusername = sessionStorage.getItem("username")

    useEffect(() => {
        const fetchData = async () => {
            try {
  
                const response = await Axios.get(`getPendingPaymentDetails?username=${newusername}`);
            
                console.log(response.data.data);
                setBank(response.data.data);
                console.log(response.data.data.Boolean);
                setAccount(response.data.data.Boolean);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

      
        fetchData(); 
      
    }, [account]);




    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    function classNamesBank(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    function classNamesBankDown(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    const validateInput = (e) => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "holderName":
                    if (!value) {
                        stateObj[name] = "Please enter Account holderName.";
                    }
                    break;

                case "accountNumber":
                    if (!value) {
                        stateObj[name] = "Please enter Account Number.";
                    } else if (isNaN(accData.accountNumber)) {
                        stateObj[name] = "Enter Numeric Value Only";
                    } else if (accData.conBankaccountNumber && value !== accData.conBankaccountNumber) {
                        stateObj["conBankaccountNumber"] = "accountNumber and Confirm accountNumber does not match.";
                    } else {
                        stateObj["conBankaccountNumber"] = accData.conBankaccountNumber ? "" : error.conBankaccountNumber;
                    }
                    break;

                case "conBankaccountNumber":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Account Number.";
                    } else if (accData.accountNumber && value !== accData.accountNumber) {
                        stateObj[name] = "Account No. and Confirm Account No. does not match.";
                    }
                    break;
                case "ifsc":
                    if (!value) {
                        stateObj[name] = "Please enter Account IFSC Code"
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    }
    const [selectedType, setSelectedType] = useState('Select Account Type');
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpenBank, setMenuOpenBank] = useState(false);
    const [menuOpenBankDown, setMenuOpenBankDown] = useState(false);

    const handleSelect = (type) => {
        setSelectedType(type);
        setMenuOpen(false);
    };
    const [selectedAccountBank1, setSelectedAccountBank1] = useState(true);
    const [selectedAccountBank2, setSelectedAccountBank2] = useState(false);

    const handlePrimaryAccount = async (username, accountNumber, primary) => {
        let Action = primary;
        console.log(Action);
        if (Action === "setPrimary") {
            console.log("sdadas", username, accountNumber, Action);
            try {
                const response = await Axios.post('/updateAccountDetails', {
                    username: username,
                    accountNumber: accountNumber,
                    action: Action
                });
                console.log(response);
                if (response.status === 200) {
                    // Handle success, if needed
                    const timeoutId = setTimeout(() => {
                        window.location.reload();
                    }, 1000);

                    // Clear the timeout when the component unmounts (optional)
                    return () => clearTimeout(timeoutId);
                    console.log('Primary account set successfully.');
                }

                else {
                    // Handle other response statuses, if needed
                    console.error('Failed to set primary account.');
                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
            setPrimary(true);
            setMenuOpenBank(false);
            setMenuOpenBankDown(false)
        }

        if (Action === "Delete") {
            console.log("sdadas", username, accountNumber, Action);
            try {
                const response = await Axios.post('/updateAccountDetails', {
                    username: username,
                    accountNumber: accountNumber,
                    action: Action
                });

                if (response.status === 200) {
                    // Handle success, if needed
                    console.log('Account Delete successfully.');
                } else {
                    // Handle other response statuses, if needed
                    console.error('Failed to set primary account.');
                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
            setMenuOpenBank(false);
            setMenuOpenBankDown(false)
        }

    };

    const navigate = useNavigate();
    const PrNaviagte = () => {
        navigate("/Manager")
    }
    const modalContentClasses = "bg-white h-[80%] w-[50%] rounded-md p-6";
    const modalOverlayClasses = "flex bg-[#000000]  opacity-70 w-screem h-screen justify-center items-center";
    return (

        <div className="h-screen  flex w-screen ">
            <ToastContainer />
            <ManagerMenu />
            < div className="h-screen w-screen  overflow-hidden bg-[#FFFDF9]" >
                <DpHeader
                />
                <div className="p-5 h-[100%]">
                    <div className=" flex items-center ">
                        <span className='text-[#8E8E8E] cursor-pointer' onClick={PrNaviagte}> Home</span> <IoChevronForwardOutline /> <span className='text-[#555555]'> Personal Info</span>
                    </div>
                    <div className="">
                        <h1 className='text-2xl mt-6 mb-4'>  Manage Personal Info </h1>
                    </div>

                    <div className='flex gap-3 h-[100%]'>
                        <div className='w-[50%] h-[45%] bg-white shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] border border-gray-300 rounded-md'>
                            <div className="items-center flex gap-3 p-4 mt-3 ">
                                <div className="flex items-center ">
                                    <div className="">
                                        <img
                                            className="h-16 w-16 rounded-full"
                                            src="https://cdn-icons-png.flaticon.com/512/236/236832.png?w=740&t=st=1690788839~exp=1690789439~hmac=4d4fa9ad6657c9817da41cf0e72d2857df6e1f320bfc8a6e75333d25bc8546b8"
                                            alt="user"
                                        />
                                    </div>
                                </div>
                                <div className='flex-col gap-4'>
                                    <h1 className='text-[16px]'>Verified ID</h1>
                                    <div className='flex items-center gap-2'>
                                        <div type="text" className='border flex items-center select-none  text-[#555555] border-gray-300 outline-none px-2 rounded-full' >{username} </div>
                                        <MdCheckCircle className='text-green-500' />
                                    </div>
                                </div>

                            </div>
                            <div className=' p-3 flex flex-col justify-evenly'>
                                <form onSubmit={handleSubmit}>
                                    <div className=' flex  justify-evenly  gap-3'>
                                        <div class="relative mb-6  select-none w-[50%]">
                                            <div className='border rounded-md border-gray-200 h-10 px-5 flex items-center text-[#8E8E8E]'>{username.slice()?.split("@")[0]}</div>
                                            {/* <input type="gmail" name="username" id="username" placeholder={username.slice(0, 9)} value={formData.username} class="border select-none p-2 bg-white rounded-full w-[100%] outline-none" />*/}
                                            <label for="inputField" htmlFor="username" class="absolute  bottom-8 left-3 bg-white px-1 text-sm">Username</label>
                                        </div>
                                        <div class="relative mb-6  w-[50%]">
                                            <input
                                                id="phoneNumber"
                                                type="text"
                                                pattern="[0-9]*"
                                                maxLength="10"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                className="border p-2 rounded-md w-full outline-none"
                                            />

                                            <label htmlFor="phoneNumber" class="absolute  bottom-8 left-3 bg-white px-1 text-sm">Phone Number</label>
                                        </div>

                                    </div>
                                    <div className='  flex  justify-evenly gap-3'>
                                        <div class="relative mb-6  w-[50%]">
                                            <input type="text" name="firstName" id='firstName' value={formData.firstName} onChange={handleChange} class="border p-2 rounded-md w-[100%] outline-none" />
                                            <label htmlFor="firstName" class="absolute  bottom-8 left-3 bg-white px-1 text-sm">First Name</label>
                                        </div> <div class="relative mb-6  w-[50%]">
                                            <input type="text" name="lastName" id='lastName' value={formData.lastName} onChange={handleChange} class="border p-2 rounded-md w-[100%] outline-none" />
                                            <label htmlFor="lastName" class="absolute  bottom-8 left-3  bg-white px-1 text-sm">Last Name</label>
                                        </div>
                                    </div>
                                    <div className='flex items-end justify-end mt-3'>
                                        <button type='submit' className='border text-[#FFFFFF] border-gray-200 shadow-sm  px-6 py-2 rounded-md mx-6 bg-[#FF8E00]'>Save Changes</button>                                    </div>
                                </form>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
        </div >
    )
}

export default PersonalInfo