import React from "react";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import Axios from "../../Axios/axios";

function Payment({ Ad, setAd, addContent, paymentId, orderId, username }) {
    console.log(username);
    const sendPaymentDetails = async () => {
        try {
            const response = await Axios.post("/storePaymentHistory", {
                advertiser_id: username,
                razorpay_paymentId: orderId,
                razorpay_orderId: paymentId,
                timestamp: Date.now(),
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    
    useEffect(() => {
        console.log(Ad);
        sendPaymentDetails();
        addContent(Ad);
    }, []);

    return (
        <div>
            <h1 className="text-center">Payment Succesful!</h1>
            <h1 className="text-center">Your Payment id is {paymentId}</h1>
            <h1 className="text-center">Your Order id is {orderId}</h1>
            <div className="flex items-center justify-center h-[70vh] w-full">
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#fe4d00"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        </div>
    );
}

export default Payment;
