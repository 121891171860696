import React, { useState } from 'react';
import PublisherMenu from "../Menus/PublisherMenu";
import DpHeader from '../../Components/Header/DpHeader';
import { ToastContainer } from 'react-toastify';
import { IoChevronForwardOutline } from 'react-icons/io5';
import {
    BsFillChatLeftDotsFill,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { MdOutlineHelp } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';
import { Accordion } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { AccordionDetails } from '@mui/material';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LuCalendarDays } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot'; // No need to import 'botAvatar' here

const theme = {
    background: '#fff',
    fontFamily: 'TT Interfaces',
    headerBgColor: '#FF8E00',
    headerFontColor: '#fff',
    headerFontSize: '18px',
    botFontColor: '#555555',
    userBubbleColor: '#FF8E00',
    userFontColor: '#fff',
    borderRadius: '4px',
    border: '1px solid black',
};

const HelpAndSupport = () => {
    const navigate = useNavigate();
    const HelpNaviagte = () => {
        navigate('/home');
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const customAccordionClass = {
        border: 'none', // Remove the border
    };

    const [chatbotOpen, setChatbotOpen] = useState(true); // Initial chatbot state

    const openChatbot = () => {
        setChatbotOpen(true);
    };

    const closeChatbot = () => {
        setChatbotOpen(false);
    };

    const renderSummary = (steps) => {
        const { name, gender, age } = steps;

        return (
            <div className='bg-[#EF6C00]' style={{ width: '100%' }}>
                <h3>Summary</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{name.value}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{gender.value}</td>
                        </tr>
                        <tr>
                            <td>Age</td>
                            <td>{age.value}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const steps = [
        {
            id: '1',
            message: 'Hi, Partner! I can help you with:',
            trigger: 'name',
        },
        {
            id: 'name',
            user: true,
            trigger: 'gender',
        },
        {
            id: 'gender',
            message: 'Hi {previousValue}! What is your gender?',
            trigger: 'age',
        },
        {
            id: 'age',
            user: true,
            trigger: 'review',
            validator: (value) => {
                if (isNaN(value)) {
                    return 'Value must be a number';
                } else if (value < 0) {
                    return 'Value must be positive';
                } else if (value > 120) {
                    return 'Come on, are you really that old?';
                }
                return true;
            },
        },
        {
            id: 'review',
            component: <renderSummary />,
            asMessage: true,
            trigger: 'update-question',
        },
        {
            id: 'update-question',
            message: 'Would you like to update some field?',
            trigger: 'update-options',
        },
        {
            id: 'update-options',
            options: [
                { value: 'yes', label: 'Yes', trigger: 'update-fields' },
                { value: 'no', label: 'No', trigger: 'end-message' },
            ],
        },
        {
            id: 'update-fields',
            message: 'What field would you like to update?',
            trigger: 'update-field-options',
        },
        {
            id: 'update-field-options',
            options: [
                { value: 'name', label: 'Name', trigger: 'update-name' },
                { value: 'gender', label: 'Gender', trigger: 'update-gender' },
                { value: 'age', label: 'Age', trigger: 'update-age' },
            ],
        },
        {
            id: 'update-name',
            update: 'name',
            trigger: 'review',
        },
        {
            id: 'update-gender',
            update: 'gender',
            trigger: 'review',
        },
        {
            id: 'update-age',
            update: 'age',
            trigger: 'review',
        },
        {
            id: 'end-message',
            message: 'Thanks! Your data was submitted successfully!',
            end: true,
        },
    ];


    return (
        <>
            <div className="h-screen flex w-screen">
                <PublisherMenu />
                <div className="h-[100%] overflow-hidden w-[100%]  bg-[#FFFDF9]  ">
                    <ToastContainer />
                    <DpHeader />
                    <div className=" flex items-center p-4">
                        <span onClick={HelpNaviagte} className="text-[#555555] cursor-pointer">
                            Home
                        </span>{' '}
                        <IoChevronForwardOutline /> Help & Support
                    </div>
                    <div className="bg-[#FFF5E7] flex mx-4 items-center h-16">
                        <div className="w-[52%] flex justify-between">
                            <MdOutlineHelp className="mx-8 text-[#FFE8CC]" size={36} />
                            <p className="text-[#555555] text-[28px]"> Help & Support</p>
                        </div>
                    </div>
                    <div className="w-[100%]  h-[70%] mx-4 my-4 flex">
                        <div className="w-[75%] h-[100%]">
                            <div className="w-[100%] h-12 rounded-[4px] bg-white border-[#FFE8CC] flex items-center gap-4 hover:shadow-md">
                                <AiOutlineSearch size={24} className="text-[#FE7E02] mx-2" />
                                <input
                                    type="search"
                                    placeholder="Search..."
                                    className="placeholder-[#FE7E02] w-[100%] outline-none"
                                />
                            </div>
                            <p className="my-12 text-[#555555] text-[20px]">Dashboard Queries</p>
                            <div className="flex flex-col gap-4   w-[100%]">
                                <Accordion style={{ border: 'none', boxShadow: '0px 4px 6px rgba(255, 241, 235, 0.3)' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography style={{ color: '#555555', fontWeight: '500' }}>
                                            How do I add a new display to the platform?
                                        </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails
                                        style={{ border: 'none', boxShadow: 'none', fontWeight: '400' }}
                                    >
                                        <Typography style={{ color: '#555555', borderBottom: 'none' }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="w-[25%] mx-4">
                            <ul className="flex flex-col gap-4">
                                <ul>
                                    <Link
                                      
                                        className="flex items-center mx-4 px-2 py-2 rounded-md  w-[80%]  text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-[#555555] "
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Dashboard Queries
                                    </Link>
                                </ul>
                                <ul>
                                    <Link
                                      
                                        className="flex items-center mx-4 px-2 py-2 rounded-md  w-[84%]  text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-[#555555] "
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Earnings & Withdrawals
                                    </Link>
                                </ul>
                                <ul>
                                    <Link
                                      
                                        className="flex items-center mx-4 px-2 py-2 rounded-md  w-[80%]   text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-[#555555] "
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Support & Assistance
                                    </Link>
                                </ul>
                                <ul>
                                    <Link
                                      
                                        className="flex items-center mx-4 px-2 py-2 rounded-md w-[80%] text-[20px] font-medium text-[#555555] hover:bg-[#F6F6F6] hover:text-red visited:bg-yellow"
                                    >
                                        <LuCalendarDays size={24} className="mr-2 ml-2" /> Guidelines
                                    </Link>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <ThemeProvider theme={theme}>
                {chatbotOpen && (
                    <ChatBot
                        headerTitle={"Support Chat"}
                        hideUserAvatar={true}
                        hideBotAvatar={true}
                        speechSynthesis={true}
                        customStyle={true}
                        steps={steps}
                        floating={true}
                        floatingIcon={<BsFillChatLeftDotsFill size={24} className="text-white" />}
                        onFloatingButtonClick={closeChatbot}
                    />
                )}
            </ThemeProvider>
        </>
    );
};

export default HelpAndSupport;
