import { createContext, useContext, useState } from "react";

export const userContext = createContext({})

export function UserContextProvider({children}){
    const [advertiser, setAdvertiser] = useState({})
    return(
        <userContext.Provider value={{advertiser, setAdvertiser}}>
            {children}
        </userContext.Provider>
    )
}

export const useUserContext = () =>{
    return useContext(userContext)
}