import React, { createContext } from "react";
import { io } from "socket.io-client";
// const prodURL = "https://himanshu-35z9.onrender.com/";
const prodURL ="https://www.everidoorbackend.com/"
// const prodURL = "https://vkvp4qqj-4001.inc1.devtunnels.ms/";
// const prodURL = "https://r80q8w1t-4000.inc1.devtunnels.ms/";
// const prodURL = "https://6zqcjmt9-4000.inc1.devtunnels.ms/";
// const prodURL = "https://p8w9c4kr-4000.inc1.devtunnels.ms/";
const socket = io(prodURL),
    SocketContext = createContext(socket);

socket.on("connect", () => console.log("connected to socket"));

const SocketProvider = ({ children }) => {
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
export { SocketContext, SocketProvider };
