import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import Axios from "../../Axios/axios";
import { useNavigate } from "react-router";
import { ThreeDots } from "react-loader-spinner";

const PaymentStatus = () => {
    const stripe = useStripe();
    const [message, setMessage] = useState(null);
    const [ad, setAd] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const xyz = sessionStorage.getItem("Ad");
        setAd(JSON.parse(xyz));
        console.log(ad);
    }, []);

    const addContent = async (e) => {
        const token=localStorage.getItem("token");
        await Axios.post("advertiser/createCampaignRequest", ad,{headers: { Authorization: `Bearer ${token}` },}).then(
            (response) => {
                console.log("xyz", response.data);
                if (
                    response.data["Message"] === "Admin notified. Request sent"
                ) {
                    console.log("REQUEST SENT!!");
                    const timeout = setTimeout(() => {
                        navigate("/home");
                    }, 5000);

                    return () => clearTimeout(timeout);
                }
            }
        );
    };

    const redirect = () => {
        const timeout = setTimeout(() => {
            navigate("/home");
        }, 5000);

        return () => clearTimeout(timeout);
    };

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage(
                        "Success! Payment received. You will be notified when your campaign is approved."
                    );
                    addContent();
                    break;

                case "processing":
                    setMessage(
                        "Payment processing. We'll update you when payment is received."
                    );
                    redirect();
                    break;

                case "requires_payment_method":
                   
                    setMessage(
                        "Payment failed. Please try another payment method."
                    );
                    redirect();
                    break;

                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    return (
        <div className="p-8 text-center text-lg">
            <p>{message}</p>
            <p className="mt-4">
                You will be redirected automatically to the Home Page or{" "}
                <a href="/home" className="text-[#fe4d00] underline">
                    Click Here
                </a>
            </p>
            <div className="flex items-center justify-center h-[70vh] w-full">
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#fe4d00"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        </div>
    );
};
export default PaymentStatus;
