import React, { useEffect, useState } from "react";
import { TbListDetails } from "react-icons/tb";
import { BsSearch } from "react-icons/bs";

const Tabs = ({
    allRequests,
    pendingRequests,
    approvedRequests,
    rejectedRequests,
    completedRequests,
    handledescription,
}) => {
    const [currentTab, setCurrentTab] = useState("1");
    const tabs = [
        {
            id: 1,
            tabTitle: "All",
            title: "All",
            content: allRequests,
        },
        {
            id: 2,
            tabTitle: "Approved",
            title: "Approved",
            content: approvedRequests,
        },
        {
            id: 3,
            tabTitle: "Pending",
            title: "Pending",
            content: pendingRequests,
        },
        {
            id: 4,
            tabTitle: "Rejected",
            title: "Rejected",
            content: rejectedRequests,
        },
        {
            id: 5,
            tabTitle: "Completed",
            title: "Completed",
            content: completedRequests,
        },
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    };

    const [value, setValue] = useState("");
    const [results, setResults] = useState([]);

    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);

        // Filter the array to only include items that match the search
        const filtered = allRequests.filter((item) =>
            item.campaignName.toLowerCase().includes(value.toLowerCase())
        );

        setResults(filtered);
    };

    useEffect(() => {
        console.log(results);
    }, [value]);

    return (
        <div className="w-full">
            <div className="tabs">
                {tabs.map((tab, i) => (
                    <button
                        key={i}
                        id={tab.id}
                        disabled={currentTab === `${tab.id}`}
                        onClick={handleTabClick}
                        className="hover:bg-[#fe7642] hover:text-white disabled:bg-[#fe7642] disabled:text-white transition-all rounded-full mx-2"
                    >
                        {tab.tabTitle}
                    </button>
                ))}
            </div>
            <div className="content">
                {tabs.map((tab, i) => (
                    <div key={i}>
                        {currentTab === `${tab.id}` && (
                            <div className="">
                                <div className="h-auto w-full m-2 overflow-x-hidden">
                                    <div className="flex justify-between min-w-full items-center">
                                        <h1 className="p-2 mt-2">
                                            {tab.title} Campaigns
                                        </h1>
                                        {/* SEARCH BAR START */}
                                        <div class="max-w-md pr-8">
                                            <div class="relative flex items-center w-full h-12 rounded-full focus-within:shadow-lg bg-white overflow-hidden">
                                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                                    <BsSearch
                                                        className="ml-2"
                                                        size={20}
                                                    />
                                                </div>
                                                <input
                                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                                                    type="text"
                                                    id="search"
                                                    value={value}
                                                    onChange={handleChange}
                                                    placeholder="Search all campaings..."
                                                />
                                            </div>
                                        </div>
                                        {/* SEARCH BAR END */}
                                    </div>
                                    <div className="pr-8 h-[54vh]">
                                        <table className="border-separate border-spacing-y-4 w-full text-sm mt-4 divide-y divide-gray-200 rounded-lg overflow-y-scroll">
                                            <thead className="">
                                                <tr className="text-left">
                                                    <th
                                                        scope="col"
                                                        className="py-4 font-medium rounded-l-3xl bg-[#dedede] pl-4"
                                                    >
                                                        Campaign Name
                                                    </th>
                                                   
                                                    <th
                                                        scope="col"
                                                        className="py-4 font-medium bg-[#dedede]"
                                                    >
                                                        From
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="py-4 font-medium bg-[#dedede]"
                                                    >
                                                        To
                                                    </th>
                                                  
                                                    <th
                                                        scope="col"
                                                        className="py-4 font-medium bg-[#dedede]"
                                                    >
                                                        No. of Screens
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="py-4 font-medium bg-[#dedede] rounded-r-3xl text-center"
                                                    >
                                                        View
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="overflow-x-scroll ">
                                                {results.length ? (
                                                    results.map((users, i) => (
                                                        <tr
                                                            key={i}
                                                            className=""
                                                        >
                                                            <td className="rounded-l-full pl-4 bg-white">
                                                                {" "}
                                                                {
                                                                    users.campaignName
                                                                }
                                                            </td>
                                                            
                                                            <td className="py-4 bg-white">
                                                                {" "}
                                                                {users.fromDate}
                                                            </td>
                                                            <td className="py-4 bg-white">
                                                                {" "}
                                                                {users.toDate}
                                                            </td>
                                                            
                                                            <td className="py-4 bg-white">
                                                                {" "}
                                                                {
                                                                    users.selectedScreens.length
                                                                }
                                                            </td>
                                                            <td className="rounded-r-full bg-white text-center">
                                                                {" "}
                                                                <button
                                                                    type="button"
                                                                    className="hover:text-[#fe7642]"
                                                                    onClick={() =>
                                                                        handledescription(
                                                                            users.campaignRequestId
                                                                        )
                                                                    }
                                                                >
                                                                    <TbListDetails
                                                                        size={
                                                                            28
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : tab.content.length ? (
                                                    tab.content.map(
                                                        (users, i) => (
                                                            <tr
                                                                key={i}
                                                                className=" "
                                                            >
                                                                <td className="rounded-l-full pl-4 bg-white">
                                                                    {" "}
                                                                    {
                                                                        users.campaignName
                                                                    }
                                                                </td>
                                                                
                                                                <td className="py-4 bg-white">
                                                                    {" "}
                                                                    {
                                                                        users.fromDate
                                                                    }
                                                                </td>
                                                                <td className="py-4 bg-white">
                                                                    {" "}
                                                                    {
                                                                        users.toDate
                                                                    }
                                                                </td>
                                                              
                                                                <td className="py-4 bg-white">
                                                                    {" "}
                                                                    {
                                                                        users.selectedScreens.length
                                                                    }
                                                                </td>
                                                                <td className="rounded-r-full bg-white text-center">
                                                                    {" "}
                                                                    <button
                                                                        type="button"
                                                                        className="hover:text-[#fe7642]"
                                                                        onClick={() =>
                                                                            handledescription(
                                                                                users.campaignRequestId
                                                                            )
                                                                        }
                                                                    >
                                                                        <TbListDetails
                                                                            size={
                                                                                28
                                                                            }
                                                                        />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td className="bg-white rounded-l-full py-4 pl-4">
                                                            No {tab.title}{" "}
                                                            campaigns to show...
                                                        </td>
                                                        <td className="bg-white">
                                                            {" "}
                                                        </td>
                                                        <td className="bg-white">
                                                            {" "}
                                                        </td>
                                                        <td className="bg-white">
                                                            {" "}
                                                        </td>
                                                        <td className="bg-white">
                                                            {" "}
                                                        </td>
                                                        <td className="bg-white rounded-r-full">
                                                            {" "}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
