import React from 'react'
import ManagerMenu from '../Menus/ManagerMenu';
import DpHeader from "../../Components/Header/DpHeader"
import { ToastContainer } from 'react-toastify'
import { IoChevronForwardOutline } from "react-icons/io5";
import { useEffect } from 'react';
import Axios from '../../Components/Axios/axios';
import { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import { GrView } from "react-icons/gr"
import { MdOutlineFilterList } from 'react-icons/md';
import ReactPlayer from 'react-player';
import ReactModal1 from "react-modal";



const Schedules = () => {

    const username = sessionStorage.getItem("username")
    const [loaded, setLoaded] = useState(false)
    const [dpdata, setDpData] = useState([]);


    console.log(dpdata);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get(`Dpsedulehistory?username=${username}`);
                setDpData(response.data);
                console.log("dp response sdaasd", response);
                setLoaded(true);

            } catch (error) {
                console.error('An error occurred:', error);
            }
        }

        fetchData();
    }, [])
    const [searchText, setSearchText] = useState("");
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const [categoryFilter, setCategoryFilter] = useState("All");
    const handleCategoryChange = (e) => {
        setCategoryFilter(e.target.value);
        console.log("Category Filter:", e.target.value);
    };
    const [selectedDateFilter, setSelectedDateFilter] = useState('Date');

    const handleDateFilterChange = (event) => {
        setSelectedDateFilter(event.target.value);
    };

    const filteredData = dpdata.filter((item) => {
        const searchTerm = searchText ? searchText.toLowerCase() : '';
        const category = categoryFilter ? categoryFilter.toLowerCase() : '';
        // Handle date filtering only when "Date" is selected
        if (selectedDateFilter !== "Date") {
            const currentDate = new Date();
            console.log(currentDate);
            let filterDate = null;

            switch (selectedDateFilter) {
                case 'Today':
                    filterDate = currentDate;
                    break;
                case 'Yesterday':
                    currentDate.setDate(currentDate.getDate() - 1);
                    filterDate = currentDate;
                    break;
                case 'Last 7 days':
                    currentDate.setDate(currentDate.getDate() - 7);
                    filterDate = currentDate;
                    break;
                case 'Last 30 days':
                    currentDate.setDate(currentDate.getDate() - 30);
                    filterDate = currentDate;
                    break;
                // Add more cases for custom date or any other options you have
                default:
                    // For 'All' and 'Custom date', no additional filtering by date
                    break;
            }
            console.log(filterDate);
            const inputDate = new Date(filterDate);
            const day = inputDate.getDate();
            const month = inputDate.getMonth() + 1; // Months are zero-indexed, so add 1
            const year = inputDate.getFullYear() % 100; // Get the last two digits of the year

            // Create the formatted date string
            const formattedDate = `${day}/${month}/${year}`;
            console.log(formattedDate);

            // Apply date filtering
            if (item.date === formattedDate) {
                return (
                    (category === "all" || (item.bookingtype &&
                        item.bookingtype.toLowerCase() === category)) &&
                    (item.screenId && item.screenId.toLowerCase().includes(searchTerm) ||
                        item.screenName && item.screenName.toLowerCase().includes(searchTerm) ||
                        item.screen_address && item.screen_address.toLowerCase().includes(searchTerm))
                );
            }
        } else {
            // When "Date" is not selected, apply other filters without date filtering
            return (
                (category === "all" || (item.bookingtype &&
                    item.bookingtype.toLowerCase() === category)) &&
                (item.screenId && item.screenId.toLowerCase().includes(searchTerm) ||
                    item.screenName && item.screenName.toLowerCase().includes(searchTerm) ||
                    item.screen_address && item.screen_address.toLowerCase().includes(searchTerm))
            );
        }
    });

    dpdata.map((i) => console.log(i.date_of_addition));
    // const filteredData = dpdata.filter((i) => {
    //     const searchTerm = searchText;
    //     return (
    //         i.screenName.includes(searchTerm)
    //     )
    // });

    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleCheckboxClick = (rowId) => {

        if (selectedRows.includes(rowId)) {

            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            // If it is not selected, add it to the selectedRows
            setSelectedRows([...selectedRows, rowId]);
        }
        selectedRows.map((i) => console.log(i))
    };
    const handleSelectAllClick = () => {
        if (selectedRows.length === dpdata.length) {
            // If all rows are selected, clear the selection
            setSelectedRows([]);
            setSelectAll(false);
        } else {
            // Otherwise, select all rows
            setSelectedRows(dpdata.map((i) => i.contentId));
            setSelectAll(true);
        }

        selectedRows.map((i) => console.log(i))

    };

    const handleDeleteAll = async () => {
        const username = sessionStorage.getItem("username");
        selectedRows.map((i) => console.log(i))
        const contentIdToDelete = selectedRows.map((i) => i);
        console.log(contentIdToDelete);
        console.log(sessionStorage.getItem("username"));
        await Axios.post('removeDpsedulehistory', {
            username: username,
            contentId: contentIdToDelete
        });

        const updatedContent = dpdata.filter((item) => !selectedRows.includes(item.contentId));

        updatedContent.map((i) => console.log(i.contentId))
        setDpData(updatedContent); //can't use title in the long run
        console.log("delete");
        setSelectAll(false);
    };

    const [page, setPage] = useState(1);
    const itemsPerPage = 12;
    var totalDisplay = dpdata.length;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    console.log(dpdata);
    console.log(totalPages);
    console.log(totalDisplay);
    const currentPageData = filteredData.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const navigate = useNavigate();
    const SchedulesNaviagte = () => {
        navigate("/Manager")
    }
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const handleOpen = (videoId) => {
        const selectedContent = dpdata.find((item) => item.videoId === videoId);
        if (selectedContent) {
            const videoUrl = `https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/${selectedContent.videoId}.mp4`;
            setSelectedVideoUrl(videoUrl);

        }
        console.log("hello");
    }
    const modalStyles = {
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            //   alignItems: 'center',
        },
        content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none',
            borderRadius: '8px',
            padding: '0',
            width: 'calc(100% - 300px)', // Adjust the value as needed
            maxWidth: '800px', // Adjust the value as needed
            maxHeight: 'calc(100% - 300px)', // Adjust the value as needed
            margin: '200px',
            background: 'black',
        },
    };


    return (
        <>
            <div className="h-screen flex w-screen ">
                <ReactModal1
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Video Modal"
                    className="modal"
                    style={modalStyles}
                    ariaHideApp={false}
                >
                    <ReactPlayer
                        playing={modalIsOpen}
                        url={selectedVideoUrl}
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                </ReactModal1>
                <ManagerMenu />
                <div className="h-[100%] overflow-hidden w-[100%]  bg-[#FFFDF9]  " >
                    <ToastContainer />
                    <DpHeader />
                    <div className=" flex items-center bg-[#FFFDF9] p-4">
                        <span onClick={SchedulesNaviagte} className='text-[#8E8E8E] cursor-pointer'>Home</span>   <IoChevronForwardOutline /> Schedules
                    </div>
                    <div className="h-[78%]  w-[98%]  pl-4 pr-2   mx-4  ">
                        <div className='flex flex-col gap-5 '>
                            <p className='text-[#001122] text-2xl'>Schedules</p>
                          
                        </div>
                        <div className='w-[98%] flex gap-4 py-6 justify-between'>
                            <div className=" flex items-center gap-2">
                                <MdOutlineFilterList size={24} className='text-[#555555]' />
                                <p className='text-[#555555] text-[16px] ' >Filters:</p>
                                <div className="">
                                    <select
                                        className="rounded-[4px] border-[0.5px] bg-[#FFFDF9] text-[#9D9D9D] border-[#9D9D9D] flex px-3 py-2 cursor-pointer outline-none"
                                        value={categoryFilter}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="All">Screens: All</option>
                                        <option value="Instant">
                                            Instant
                                        </option>
                                        <option value="Advance">
                                            Advance
                                        </option>
                                    </select>
                                </div>
                                <div className="">
                                    <select
                                        className="rounded-[4px] border-[0.5px] bg-[#FFFDF9] text-[#9D9D9D] border-[#9D9D9D] flex px-3 py-2 cursor-pointer outline-none"
                                        // value={categoryFilter}
                                        // onChange={handleCategoryChange}
                                    >
                                        <option value="All">Orientation: All</option>
                                        <option value="Instant">
                                            Landscape
                                        </option>
                                        <option value="Advance">
                                            Portrait
                                        </option>
                                    </select>
                                </div>
                                <div className=''>
                                    <select
                                        className="rounded-[4px] border-[0.5px] bg-[#FFFDF9] border-[#9D9D9D] flex text-[#9D9D9D] px-3 py-2 cursor-pointer outline-none"
                                        value={selectedDateFilter}
                                        onChange={handleDateFilterChange}
                                    >
                                        <option value="Date">Date</option>
                                        <option value="Today">
                                            Today
                                        </option>
                                        <option value="Yesterday">
                                            Yesterday
                                        </option>
                                        <option value="Last 7 days">
                                            Last 7 days
                                        </option>
                                        <option value="Last 30 days">
                                            Last 30 days
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='w-[100%] h-[77%] bg-white shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]'>
                        <div className='w-[100%] h-[85%]   shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]  bg-[#FFFFFF] mr-1 flex flex-col items-center '>
                            <table
                                className="border-separate border-spacing-y-2  w-[98%] text-sm  overflow-y-visible">
                                <thead>
                                    <tr className=" text-left ">
                                       
                                        <th
                                            scope="col"
                                            className="py-4 pl-6 border-b"
                                        >
                                            Screen Name
                                        </th>
                                       

                                        <th
                                            scope="col"
                                            className=" py-4 
                                                        text-right pr-4 pl-96 border-b"
                                        >
                                          Ad Type
                                        </th>

                                        <th
                                            scope="col"
                                            className="py-4 
                                                        pl-20    
                                                        border-b "
                                        >
                                            Date & Time
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-4 border-b"
                                        >
                                            Screen
                                        </th>
                                        <th scope='col' className="py-4 pl-10 border-b text-center">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                {loaded ? (
                                    <tbody className=''>
                                        {currentPageData.map((item, index) => (
                                            <tr key={index} className={selectedRows.includes(item.contentId) ? 'selected' : ''}>
                                              
                                                
                                                <td className="bg-white pl-6">{item.screenName}</td>
                                               
                                                <td className="bg-white text-right pr-6">
                                                {item.bookingtype}
                                                </td>
                                                <td className="bg-white pl-20">
                                                    {item.date_of_addition}
                                                </td>
                                                <td className="bg-white pl-4     ">
                                                {item.screenId}
                                                </td>
                                                <td className='rounded-r-lg text-center pl-10'>
                                                 <GrView className=' cursor-pointer' onClick={() => { openModal(); { handleOpen(item.videoId) } }} />
                                                 
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody className="">
                                        <tr>
                                            <td colSpan={10} >
                                                <div className="flex justify-center items-center"
                                                    style={{ height: '100%' }}
                                                >
                                                    <ThreeDots
                                                        height="80"
                                                        width="80"
                                                        radius="9"
                                                        color="#fe4d00"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={
                                                            true
                                                        }
                                                    />

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                        <div className='w-[100%] h-[10%]  bg-white flex  items-center justify-center '>
                            <div className='w-[99%] border-t flex items-center justify-between pt-2'>
                                <div>
                                    <p className='flex  text-[#555555] select-none'>Showing {page} of {totalDisplay} Results </p>
                                </div>
                                <div>
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="secondary"
                                            defaultPage={page}
                                            shape="rounded"
                                            onChange={(event, value) => {
                                                setPage(value);
                                            }}
                                            count={totalPages} // Use the totalPages calculated from filtered data
                                            variant="outlined"
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div >

        </>
    )
}

export default Schedules