import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../Axios/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "../../Menu/Menu"
import AdminHeader from "../adminHeader"




const Signup = ({ setSigned }) => {
    const [name, setName] = useState(""); 
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [pin, setPin] = useState("");
    const [role, setRole] = useState("");

    const [active1, setActive1] = useState(true);
    const [active2, setActive2] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        if (active1 === true && active2 === false) {
            setRole("advertiser");
        } else if (active1 === false && active2 === true) {
            setRole("display-provider");
        }
        console.log(role);
    }, [active1, active2, role]);

    useEffect(()=>{
        setCustomerId(generateRandomCustomerId(10));
        setPin(generateRandomPin(4))
    },[])
    const notify = (text) =>
        toast.warn(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const notifyCreated = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });





    const register = async (e) => {
        // setSigned(true);
        e.preventDefault();
        sessionStorage.setItem("username", username);
        console.log("New User Registration Initiated");
        //sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("role", role);
        console.log(sessionStorage.getItem("role"));
        const response = await Axios.post("register", {
            name: name,
            username: username,
            password: password,
            role: role,
            customerId: customerId,
            pin: pin,
        }).then((response) => {
            // console.log("Register response");
            console.log(response);
            console.log(response.data, response);
            if (response.status === 200) {
                // console.log("Register success");
                notifyCreated("User Created!");
                navigate("/admindashboard/createUsers")

            } if (response.status === 201) {
                console.log("User already exists");
                notify("User Already Registered, Please Sign In instead");
                navigate("/admindashboard/createUsers");
            }
            if (response.status === 202) {
                console.log("Email already registered");
                notify("Email already registered, Please Sign In instead");
                navigate("/admindashboard/createUsers");
            } else {
                console.log("Signup failed");
                // console.log(response.data.Message);
            }
        });
    };




    const [open, setOpen] = useState(true);

    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/admindashboard");
    };

    const generateRandomPassword = (length, e) => {
        e.preventDefault();
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?";
        let password = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset.charAt(randomIndex);
        }
        return password;
    };
    const generateRandomCustomerId = (length) => {
        const charset = "0123456789";
        let customerId = '';
        for(let i = 0; i < length; i++){
            const randomIndex = Math.floor(Math.random() * charset.length);
            customerId += charset.charAt(randomIndex);
        }
        return customerId;
    }
    const generateRandomPin = (length) => {
        const charset = "0123456789";
        let Pin = '';
        for(let i = 0; i < length; i++){
            const randomIndex = Math.floor(Math.random() * charset.length);
            Pin += charset.charAt(randomIndex);
        }
        return parseInt(Pin)
    }

    const RandomPasswordGenerator = () => {
        const [password, setPassword] = useState('');
    }
    const handleGeneratePassword = (e) => {
        e.preventDefault();
        const newPassword = generateRandomPassword(12, e); // Change the length as needed
        setPassword(newPassword);
    };


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="[#FF4D00]"
            />

            <div className="overflow-y-auto flex h-full min-h-screen w-screen">
                <div className="">
                    <Menu
                     
                    />
                </div>
                <div className="flex flex-col overflow-hidden w-[100%] bg-gray-100 text-black">
                    <AdminHeader />
                    <div className="bg-[#f2f2f2] w-full h-[90%] ">
                        <p className="text-[48px] w-[500px] leading-none font-[TTRamillasTrialMedium]  text-[#424242] text-center mx-auto mt-12">
                            Welcome To The<br></br>
                            <span className="text-[#340c42]"> Digital</span>{" "}
                            <span className="text-[#FE7E02] ">Campaign</span> Era!
                        </p>
                        <div className="w-[50%] h-[65%] mx-auto">
                            <div className="w-full flex items-center justify-center">
                                <div className="w-[50%] ml-8 border border-[#FE7E02]">
                                </div>
                            </div>
                            <div className="w-full mt-4 mb-2  flex items-center justify-center">
                                <p className="text-[#555555]">Are you ?</p>
                            </div>

                            <form

                                className="bg-white rounded-md p-6 h-[100%] shadow-xl text-[18px] pt-10"
                            >
                                <div className="w-[100%] items-center justify-center  bg-[#f7f7f7] flex rounded-md mb-4">
                                    <div className="w-[100%]">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setActive1(true);
                                                setActive2(false);
                                            }}
                                            className={`w-[50%] rounded-md transition-all duration-300 ${active1
                                                ? "bg-[#FE7E02] text-white"
                                                : "bg-[#f7f7f7] text-black"
                                                }`}
                                        >
                                            Advertiser
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setActive1(false);
                                                setActive2(true);
                                            }}
                                            className={`w-[50%] rounded-md transition-all duration-300 ${active2
                                                ? "bg-[#FE7E02] text-white"
                                                : "bg-[#f7f7f7] text-black"
                                                }`}
                                        >
                                            Display Network
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <input
                                        type="text"
                                        id="name"
                                        placeholder="Full Name"
                                        onChange={(e) => setName(e.target.value)}
                                        className="w-full rounded-md border-[1px] outline-none placeholder-sm border-gray-300 px-4 py-2"
                                    />
                                </div>
                                <div className="">
                                    <input
                                        type="email"
                                        id="Email"
                                        placeholder="Enter your email"
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        className="w-full rounded-md border-[1px] outline-none border-gray-300 px-4 py-2 mt-4"
                                    />
                                </div>
                                <div className="w-[100%] flex items-center">
                                    <div className="w-[50%]">
                                        {/* <input
                                            type="password"
                                            id="password"
                                            placeholder={password}
                                            onChange={(e) =>
                                                setPassword(e.target.password)
                                            }
                                            className="w-[100%] rounded-md border-[1px] outline-none border-gray-300 px-4 py-2 mt-4"
                                        /> */}
                                        <input
                                            type="password"
                                            id="password"
                                            value={password}
                                            placeholder={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            className="w-[100%] rounded-md border-[1px] outline-none border-gray-300 px-4 py-2 mt-4"
                                        />
                                    </div>

                                    <div className="w-[50%] flex items-center justify-center mt-3">
                                        <button onClick={(e) => handleGeneratePassword(e)}>Generate Password</button>
                                    </div>
                                </div>
                                {/* <div className="w-[100%] flex items-center">
                                <div className="w-[50%] flex items-center justify-center mt-3">
                                       Customer Id
                                    </div>
                                <div className="w-[50%]">
                                    <input
                                        type="text"
                                        id="customer_id"
                                        placeholder="Customer ID"
                                        value={customerId}
                                        // onChange={(e) =>
                                        //     setCustomerId(e.target.value)
                                        // }
                                        className="w-full rounded-md border-[1px] outline-none border-gray-300 px-4 py-2 mt-4"
                                    />
                                </div>
                                </div>
                                <div className="w-[100%] flex items-center">
                                <div className="w-[50%] flex items-center justify-center mt-3">
                                       Pin
                                    </div>
                                    <div className="w-[50%]">
                                    <input
                                        type="text"
                                        id="pin"
                                        placeholder="Pin"
                                        value={pin}
                                        // onChange={(e) =>
                                        //     setPin(e.target.value)
                                        // }
                                        className="w-full rounded-md border-[1px] outline-none border-gray-300 px-4 py-2 mt-4"
                                    />
                                </div>
                                </div> */}
                                
                                <div className="w-full flex flex-col mt-6 items-center justify-center">
                                    <button
                                        onClick={(e) => register(e)}
                                        id="sign"
                                        type="submit"
                                        className="w-full mt-4 py-2 bg-[#FE7E02] text-white rounded-md"
                                    >
                                        Create User
                                    </button>
                                    {/* <p className=" mt-6 text-[18px] text-[#555555] tracking-wide text-center">
                                    OR
                                </p> */}
                                </div>
                                {/* <button
                                onClick={signInwithGoogle}
                                type="button"
                                id="google"
                                className="w-full py-2 rounded-full flex items-center justify-center shadow-md"
                            >
                                {" "}
                                <img src={google} alt="google" /> &nbsp;&nbsp;
                                Sign Up with google
                            </button> */}

                            </form>
                        </div >
                        <div>
                            <h1>Random Password Generator</h1>

                            {password && <p>Your random password is: {password}</p>}
                        </div>
                    </div >
                </div >
            </div >
        </>
    );
};

export default Signup;