import { useEffect, useState } from "react";
// import './StripeCss.css';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import PaymentForm from "./PaymentForm";
import { AiFillCloseCircle } from "react-icons/ai";

const PUBLIC_KEY =
    "pk_live_51NdW1cSH7579ohVObYgZQykcDSGaisYtZAl8dxmTw33TYpIeYoUmixDEprQeYNSzF7PrGiNMzmW2zKmFXojXYit100w5R1OKnI";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

function MainStripe({ Ad, Total, selectedScreens, Amount, saveLocally }) {
    const [showItem, setShowItem] = useState(false);
    const formattedFromDate = new Date(Ad.fromDate);
    const formattedToDate = new Date(Ad.toDate);

    return (
        <div className="">
            {showItem ? (
                <div className="w-full h-full bg-black/50 inset-0 absolute">
                    <div className="w-3/4 h-3/4 bg-white absolute inset-0 left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] rounded-xl p-4 flex">
                        <button
                            type="button"
                            className="absolute right-2 top-4 text-white text-4xl"
                            onClick={() => setShowItem(false)}
                        >
                            <AiFillCloseCircle />
                        </button>
                        <div className="h-full w-[60%] p-8">
                            <h1 className="font-semibold text-2xl mb-8">
                                Pay & Schedule Ad
                            </h1>
                            <p className="font-semibold text-gray-800 my-4">
                                Select Payment Method
                            </p>
                            <div className="items-center flex gap-4 justify-center p-4 w-fit rounded-[20px] border border-[#FF4D00] shadow-md mb-4">
                                <input
                                    type="radio"
                                    name=""
                                    id=""
                                    checked={true}
                                    className="accent-[#FF4D00]"
                                />
                                <p className="text-black">
                                    Debit / Credit Card
                                </p>
                            </div>
                            <Elements stripe={stripeTestPromise}>
                                <PaymentForm
                                    total={Total}
                                    screens={selectedScreens.length}
                                />
                            </Elements>
                        </div>
                        <div
                            className="h-full w-[40%] rounded-xl overflow-hidden"
                            style={{
                                background:
                                    "linear-gradient(360deg, #7D229D 3.46%, #551D68 52.53%, #4A1C5B 100%)",
                            }}
                        >
                            <div className="h-[40%] p-8">
                                <img
                                    src="/edoorwhite.png"
                                    alt="logo"
                                    className="h-[40px] mx-auto mt-4"
                                />
                             
                                <div className="bg-white w-[360px] relative left-[50%] -translate-x-[50%] h-fit rounded-xl shadow-md p-6 flex flex-col text-sm items-center justify-center mt-20">
                                    <h1 className="w-full">
                                        <span className="font-semibold">
                                            Campaign Name:
                                        </span>{" "}
                                        {Ad.CampName}
                                    </h1>
                                    <h1 className="w-full">
                                        <span className="font-semibold">
                                            Scheduled Date:
                                        </span>{" "}
                                        {formattedFromDate.toDateString("IN")}{" "}
                                        to {formattedToDate.toDateString("IN")}
                                    </h1>
                                </div>
                            </div>
                            <div className="h-[60%] bg-[#E5E5E5] pt-8 pb-4 px-4 overflow-auto">
                                <div className="flex justify-between">
                                    <p>Ads Summary</p>
                                    <p>View All</p>
                                </div>
                                <table className=" border-spacing-y-4  border-separate w-full">
                                    <thead>
                                        <tr className=" bg-[#DEDEDE] h-14 rounded-full">
                                            <th className=" rounded-l-full pl-3 ">
                                                Sr.No.
                                            </th>
                                            <th className="">Screen ID</th>
                                            <th>CPS</th>
                                            <th>Loops</th>
                                            <th className="rounded-r-full pr-6">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {Array.isArray(selectedScreens) &&
                                        selectedScreens.length > 0 ? (
                                            selectedScreens.map(
                                                (screenData, index) => (
                                                    <tr
                                                        className="text-center h-14 border mb-3 mt-3"
                                                        key={index}
                                                    >
                                                        <td className="rounded-l-full bg-white">
                                                            {index + 1}
                                                        </td>
                                                        <td className="bg-white">
                                                            {
                                                                screenData.screen_code
                                                            }
                                                        </td>
                                                        <td className="bg-white">
                                                            {screenData.CPS}
                                                        </td>
                                                        <td className="bg-white">
                                                            {
                                                                screenData.TotalLoopCount
                                                            }
                                                        </td>
                                                        {Amount.map(
                                                            (value, index) => {
                                                                if (
                                                                    value.screen_code ===
                                                                    screenData.screen_code
                                                                ) {
                                                                    return (
                                                                        <td
                                                                            className="  rounded-r-full bg-white"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                value.amount
                                                                            }
                                                                        </td>
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={3}>
                                                    No screens selected
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="w-28 xl:w-full ">
                        <button
                            className="border bg-[#FF4D00] hover:bg-[#ff8957] text-white py-1 xl:py-2 xl:px-10 rounded-full"
                            onClick={() => {
                                setShowItem(true);
                                saveLocally();
                            }}
                        >
                            Pay Now
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default MainStripe;
