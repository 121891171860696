import AdminHeader from "../adminHeader";
import Menu from "../../Menu/Menu";
import React, { useState } from "react";
import Axios from "../../Axios/axios";
import { toast } from "react-toastify";

const PushNotifications = () => {
    const [titleData, setTitleData] = useState("");
    const [contentData, setContentData] = useState("");
    const [sending, setSending] = useState(false);
    const [recipientType, setRecipientType] = useState(1);

    const notifyy = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const notify = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        console.log("titleData:", titleData);
        console.log("contentData:", contentData);
        console.log("recipientType:", recipientType);
        try {
            const response = await Axios.post("AdvertiserNotification", {
                title: titleData,
                content: contentData,
                recipientType: recipientType,
                important: 0
            });
            console.log(response);
            notifyy("Notification pushed!");
        } catch (error) {
            console.log("an error occurred while posting notification data", error);
            notify("An error occurred");
        } finally {
            setSending(false);
            setTitleData("");
            setContentData("");
            setRecipientType(1);
        }
    };

    return (
        <div className="w-full">
            <div className="flex bg-gray-100 text-black">
                <div className="w-fit">
                    <Menu />
                </div>
                <div className="w-full h-[88vh]">
                    <AdminHeader />
                    <div className="w-full h-full flex flex-col items-center justify-center py-4 px-2 drop-shadow-xl">
                        <h1 className="text-[48px] text-[#340c42] font-[TTRamillasTrialMedium]">Send <span className="text-[#FE7E02]">instant</span> notifications to clients!</h1>
                        <div className="bg-white w-[80%] p-10 rounded-xl">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="titleData">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        id="titleData"
                                        value={titleData}
                                        onChange={(e) => setTitleData(e.target.value)}
                                        placeholder="Enter title for your notification"
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contentData">
                                        Content
                                    </label>
                                    <textarea
                                        id="contentData"
                                        value={contentData}
                                        onChange={(e) => setContentData(e.target.value)}
                                        placeholder="Enter the content of the notification"
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="recipientType">
                                        Send To
                                    </label>
                                    <select
                                        id="recipientType"
                                        value={recipientType}
                                        onChange={(e) => setRecipientType(parseInt(e.target.value))}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    >
                                        <option value={1}>Advertiser</option>
                                        <option value={2}>Display Provider</option>
                                    </select>
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        type="submit"
                                        disabled={sending}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        {sending ? "Sending..." : "Submit"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PushNotifications;
