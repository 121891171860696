import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useEffect } from "react";
import ReactModal from "react-modal";
import Axios from "../../Axios/axios";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SocketContext } from "../../../socketcontext";
import { Link } from "react-router-dom";
import ads from "../../Images/Default.png";
import { useNavigate } from "react-router-dom";
import DpHeader from "../../Header/DpHeader";
import DpMenu from "../../Menu/DpMenu";
import { ThreeDots } from "react-loader-spinner";
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { IoChevronForwardOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { BiPlus } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";
import Select from "react-select";
import { useState } from "react";
import Item from "../../Dashboard/Item/index";
import nodeContext from "../../../Context/nodeContext";


const notify = (text) =>
    toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

const notifySuccess = (text) =>
    toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

function MyDisplays({ setSigned }) {

    const socket = useContext(SocketContext);
    const navigate = useNavigate();
    const username = sessionStorage.getItem("username")
    console.log(username);

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("before connectClient emitted", New.screen_address);
        console.log("screeen data---------->>>>>>>",username , New.screenId);
       
        const screendetails = {
            username: username,
            screenId: New.screenId 
        }

        socket.emit('connect-Client', screendetails, () => {
            console.log('connectClient emitted');
        });

        
       
        try {
            const response = await Axios.post("addNewScreen", {
                username: sessionStorage.getItem("username"),
                screenId: New.screenId,
                screenName: New.screenName,
                screen_address: New.screen_address,
                TYPE: New.TYPE,
                TYPE_VAL: New.TYPE_VAL,
            });
          
            console.log("add screen", response.status);

            if (response.status === 200) {
                console.log("add screen response");
                setDatas([
                    ...Datas,
                    {
                        screenId: New.screenId,
                        screenName: New.screenName,
                        screen_address: New.screen_address,
                        date_of_addition: "",
                    },
                ]);

                socket.emit("join-device-room", {
                    username: username,
                    screenId: New.screenId,
                });
                console.log("Device Joined Room from mydisplay**********");

                
                socket.emit("get-current-video", {
                    username: username,
                    screenId: New.screenId,
                });
                
                console.log('get-current-video emiited from mydisplay***********');

                notifySuccess("Screen Added Successfully");
                setIsParentModalOpen(false);


            } else if (response.status === 202) {
                notify("Duplicated");
            }
            setNew({});
            // e.target.reset();
        } catch (error) {
            // Handle any errors that occurred during the Axios request.
            console.error("Error:", error);
        }

    };

    const [New, setNew] = useState({
        screenId: "",
        screenName: "",
        screen_address: "",
        TYPE: "",
        TYPE_VAL: "",
    });

    const [Datas, setDatas] = useState([
        {
            screenId: "",
            screenName: "",
            screen_address: "",
            date_of_addition: "",
            TYPE: "",
            TYPE_VAL: "",
        },
    ]);
//  console.log("myvalueOfDatasArray", Datas)

    const [Values, setValues] = useState([]);
    const [type, setType] = useState(false);
    const [isloaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);

    function handleType() {
        setType(true);
    }
    let details = [
        {

            type: "Hotels",
            type_val: [
                "The Oberoi",
                "The Taj",
                "Ashoka",
                "Hyatt Regency",
                "Eros Hotel",
                "The Lalit",
                "Hotel Novotel",
                "Radisson Blu",
                "The Leela",
                "The Imperial",
                "Others",
            ],
        },
        {
            type: "Restaurants",
            type_val: [
                "Indian Accent - The Manor",
                "Bukhara - Itc Maurya",
                "On The Waterfront - The Lodhi",
                "Sevilla - The Claridges",
                "64/6 - Country Inn Suites",
                "Others",
            ],
        },
        {
            type: "Spa and Fitness Centers",
            type_val: [
                "Chi, The Spa",
                "Quan Spa",
                "Blue Terra Spa",
                "NeoVeda Spa",
                "Others",
            ],
        },
        {
            type: "Clubhouse",
            type_val: ["Lutyens Delhi", "Others"],
        },
        {
            type: "Course",
            type_val: ["Molog", "Others"],
        },
        {
            type: "Pro Shop",
            type_val: ["Molog", "Others"],
        },
        {
            type: "Airport Lounges",
            type_val: ["Air India T3", "Indigo T3", "Others"],
        },
        {
            type: "Others",
            type_val: ["Molog"],
        },
    ];

    const handleTypeChange = (e) => {
        setNew({ ...New, TYPE: e.target.value });
        setValues(
            details.find((elem) => elem.type === e.target.value).type_val
        );
    };

    const [isParentModalOpen, setIsParentModalOpen] = useState(false);

    const openParentModal = () => {
        setIsParentModalOpen(true);
    };

    const closeParentModal = () => {
        setIsParentModalOpen(false);
    };
    const [categoryFilter, setCategoryFilter] = useState("All");

    const [screen, setScreen] = useState("");
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        console.log("Search Text:", e.target.value);
    };

    const handleCategoryChange = (e) => {
        setCategoryFilter(e.target.value);
        console.log("Category Filter:", e.target.value);
    };

    useEffect(() => {
        async function getScreens() {
            console.log(username)
            await Axios.get(`getsubuserlistList?username=${username}`)
                .then((response) => {
                    console.log("getScreenResponse-------->>>>>>>", response);
                    setDatas(response.data);
                    setLoaded(true);
                });
        }
        getScreens();
    }, [])


    const itemsPerPage = 12;
    var totalDisplay = Datas.length;

    const openParentModal1 = () => {
        // setbtn(true);
        setIsParentModalOpen(true);
    };



    const filteredData = Datas.filter((item) => {
        var searchTerm = searchText.toLowerCase();
        const category = categoryFilter.toLowerCase();

        if (category === "inactive") {
            return item.connectivity_status === false;
        } else if (category === "active") {
            return item.connectivity_status === true;
        }

        const screenType = item.screenType ? item.screenType.toLowerCase() : "";
        const screenId = item.screenId ? item.screenId.toLowerCase() : "";
        const screenName = item.screenName ? item.screenName.toLowerCase() : "";
        const screenAddress = item.screen_address ? item.screen_address.toLowerCase() : "";

        return (
            (category === "all" || screenType === category) &&
            (screenId.includes(searchTerm) ||
                screenName.includes(searchTerm) ||
                screenAddress.includes(searchTerm))
        );
    });

    // Calculate the total number of pages based on the filtered data and itemsPerPage
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Get the current page of data based on the selected page
    const currentPageData = filteredData.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    // console.log("Hello", Datas.ScreenType);

    const MyDisplayNaviagte = () => {
        navigate("/home");
    };
    const handleCategoryChange1 = (selectedOption) => {
        setCategoryFilter(selectedOption.value);
    };
    const options = [
        { value: "All", label: " All" },
        { value: "Active", label: " Active" },
        { value: "Inactive", label: " Inactive" },
    ];
    const typeOptions = [
        { value: 'All', label: 'Type' },
        { value: 'Landscape', label: 'Landscape' },
        { value: 'Portrait', label: 'Portrait' },
    ];

    const handleTypeChange1 = (selectedOption) => {

        setCategoryFilter(selectedOption.value); // Set the selected value in your state
    };

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "white",
            borderRadius: "4px", // Border radius
            width: "120px",
            outline: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "white" : "white", // Option background color
            color: state.isFocused ? "black" : "black", // Option text color
        }),
        // ... other style properties for menu, singleValue, etc.
    };

    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: '#MyDisplay',
                popover: {
                    title: 'Manage Screens',
                    description: 'View, add and manage all of your screens from here.', side: "right", align: 'start'
                }
            },
            {
                element: '#addScreen',
                popover: {
                    title: 'Add Screen',
                    description: 'Add and pair your screens from here.', side: "right", align: 'start'
                }
            },

        ]
    });

    const a = useContext(nodeContext);
    console.log("asdas", a.state.count);
    if (a.state.count === 1) {
        driverObj.drive();
        a.state.count = 0;
        console.log(a.count);
    }
    const [color, setColor] = useState(false);
    return (
        <>
            <div className="h-screen flex w-screen text-[#555555]">
                <DpMenu />
                <div className="h-[100%] overflow-hidden w-[100%] bg-[#FFFDF9]">
                    <ToastContainer />
                    <DpHeader />
                    <div className="h-[85%] w-[100%] ">
                        <div className="flex items-center justify-between p-4">
                            <div className="flex items-center ">
                                <span
                                    onClick={MyDisplayNaviagte}
                                    className="text-[#8E8E8E] cursor-pointer"
                                >
                                    {" "}
                                    Home
                                </span>{" "}
                                <IoChevronForwardOutline />
                                <span className="text-[#555555]">
                                    My Display
                                </span>
                            </div>
                        </div>
                        <div className="px-8 w-fit py-3">
                            <p id="MyDisplay" className="text-2xl">My Screens</p>
                        </div>

                        <div className="w-[100%] px-[35px] py-3">
                            <div className=" flex gap-3 items-center ">
                                <div className="flex  border border-[#DEDEDE]  bg-white rounded-[4px]">
                                    <div className=" flex items-center gap-2  px-2 py-2">
                                        <CiSearch
                                            size={24}
                                            className=" text-gray-400"
                                        />
                                        <input
                                            className=" outline-none h-fit w-full"
                                            type="text"
                                            placeholder="Search Screens"
                                            value={searchText}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                                <button

                                    id="addScreen"
                                    className="border-[#FF8E00] flex items-center gap-1 py-[7px] px-3 border-solid rounded-[2px] text-[#FF8E00] hover:bg-[#FF8E00] hover:text-white  text-[16px] border"

                                    onClick={openParentModal1}
                                >
                                    <span>
                                        <BiPlus size={24} className="" />{" "}
                                    </span>
                                    Add Screen
                                </button>
                            </div>
                            <div className="flex w-[100%] items-center justify-between mt-5">
                                <div className=" flex gap-3 items-center">
                                    <p className="flex items-center gap-2">
                                        <BsFilter size={24} />
                                        Filters:
                                    </p>

                                    <Select
                                        styles={customSelectStyles}
                                        className="rounded-[4px] cursor-pointer flex px-3 py-2 outline-none"
                                        value={options.find((option) => option.value === categoryFilter)}
                                        onChange={handleCategoryChange1}
                                        options={options}
                                    />
                                    <Select
                                        styles={customSelectStyles}
                                        value={typeOptions.find(option => option.value === categoryFilter)}
                                        onChange={handleTypeChange1}
                                        options={typeOptions}
                                        placeholder="Type"
                                    />
                                </div>
                                <div className=" text-[#8E8E8E] flex justify-between  items-center mt-3">
                                    <p>
                                        Total Display: {totalDisplay}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ReactModal
                            isOpen={isParentModalOpen}
                            onRequestClose={closeParentModal}
                            contentLabel="Video Modal"
                            className=" flex w-screem h-screen bg-[#000000]/70 justify-center items-center"
                            ariaHideApp={false}
                        >
                            <div className="border-[0.5px] border-#9D9D9D bg-[#FFFFFF] rounded-[6px] p-4 w-[800px] h-[404px] ">
                                <div className="flex justify-between ">
                                    <p className="text-[24px] text-[#555555] ml-3 mt-3 font-semibold">
                                        Add a display
                                    </p>
                                </div>
                                <form
                                    className="h-[70%]"
                                    style={{ marginTop: "20px" }}
                                    onSubmit={submitHandler}

                                >
                                    <div className="flex flex-col gap-2">
                                        <div className="w-[100%] gap-5 flex">
                                            <div className="w-[50%] ">
                                                <input
                                                    className="p-3 w-[100%] mb-3 rounded-[4px] placeholder-[#9D9D9D] border-[0.5px] border-[#9D9D9D] outline-none"
                                                    required
                                                    type="text"
                                                    value={New.screenName}
                                                    placeholder="Enter Screen Name"
                                                    onChange={(e) => {
                                                        setNew({
                                                            ...New,
                                                            screenName:
                                                                e.target.value,
                                                        });
                                                        console.log(New);
                                                    }}
                                                />
                                            </div>
                                            <div className="w-[50%] ">
                                                <input
                                                    className="p-3 w-[100%] mb-3 rounded-[4px] placeholder-[#9D9D9D] border-[0.5px] border-[#9D9D9D] outline-none"
                                                    required
                                                    type="text"
                                                    value={New.screenId}
                                                    placeholder="Enter Activation Code "
                                                    onChange={(e) =>
                                                        setNew({
                                                            ...New,
                                                            screenId:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className=" ">
                                            <div className="w-[100%]  gap-5 flex">
                                                <select
                                                    className="p-3 w-1/2 mb-3 rounded-[4px] placeholder-[#9D9D9D] border-[0.5px] border-[#9D9D9D] outline-none"
                                                    required
                                                    value={New.TYPE}
                                                    onClick={handleType}
                                                    onChange={(e) => {
                                                        handleTypeChange(e);
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                        className="p-3 text-[#9D9D9D]"
                                                    >
                                                        Select Venue Type
                                                    </option>
                                                    {details.map((ele) => (
                                                        <option
                                                            value={ele.type}
                                                        >
                                                            {ele.type}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    className="w-1/2 p-3 mb-3 rounded-[4px] placeholder-[#9D9D9D] border-[0.5px] border-[#9D9D9D] outline-none"
                                                    required
                                                    value={New.TYPE_VAL}
                                                    onChange={(e) => {
                                                        setNew({
                                                            ...New,
                                                            TYPE_VAL:
                                                                e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="" className="text-[#9D9D9D]">
                                                        Select Type Value
                                                    </option>
                                                    {Values.map((elem) => (
                                                        <option value={elem}>
                                                            {elem}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-[100%] ">
                                            <input
                                                className="p-3 w-[100%] mb-3 rounded-[4px] placeholder-[#9D9D9D] border-[0.5px] border-[#9D9D9D] outline-none"
                                                required
                                                type="text"
                                                value={New.screen_address}
                                                placeholder="Enter Screen Address "
                                                onChange={(e) =>
                                                    setNew({
                                                        ...New,
                                                        screen_address:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div
                                            className={` flex gap-5 items-end mt-[35px] justify-end`}
                                        >
                                            <button
                                                className="px-10 py-3 font-[TT Interfaces] font-semibold text-[#555555] rounded-[4px] bg-[#F2F2F2]"
                                                type="reset"
                                                onClick={closeParentModal}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={submitHandler}
                                                className={`${color ? " bg-[#5555]" : "bg-[#FF8E00]"}  px-8 py-3 font-medium  rounded-[4px] text-white  `}
                                                id="Addbtn"

                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ReactModal>

                        <div className="flex w-[100%] flex-wrap bg-[#FFFDF9] h-[63%] mt-4">
                            <div className="w-[100%] h-[100%] ">
                                {isloaded ? (
                                    currentPageData.length > 0 ? (
                                        <div className="w-[100%] overflow-hidden h-[100%]">
                                            <div className="h-[100%] w-[100%] overflow-y-auto flex-wrap flex flex-row px-[20px] gap-8">
                                                {currentPageData.map(
                                                    (Data, i) => (
                                                        <div
                                                            key={i}
                                                            className="w-fit h-fit shadow-md shadow-[#FFF1EB] shadow-opacity-[30%] rounded-[4px] overflow-hidden mb-2 hover:shadow-xl"
                                                        >
                                                            <Item Data={Data} />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col border-[2px] border-[#9D9D9D] rounded-[4px] border-dashed items-center justify-center m-8 py-16">
                                            <div className="items-center gap-2">
                                                <img
                                                    src={ads}
                                                    className="border-2 rounded-[4px] overflow-hidden"
                                                    alt="Everidroor"
                                                />
                                                <h1 className="text-[32px] text-center py-4 text-[#555555]">
                                                    Add your Screen
                                                </h1>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={openParentModal}
                                                    className="bg-primary py-[9px] px-5 hover:bg-[#ff8957] rounded-[4px] text-white border-gray-100 border shadow-lg"
                                                >
                                                    + Add New Screen
                                                </button>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="w-full h-full flex justify-center items-center ">
                                        <ThreeDots
                                            height="80"
                                            width="80"
                                            radius="9"
                                            color="#fe4d00"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-[100%] h-[8%] bg-[#FFFDF9] flex items-center pr-6 justify-end">
                        <div className="">
                            <Stack spacing={2}>
                                <Pagination
                                    color="secondary"
                                    defaultPage={page}
                                    onChange={(event, value) => {
                                        setPage(value);
                                    }}
                                    count={totalPages} // Use the totalPages calculated from filtered data
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Stack>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

export default MyDisplays;