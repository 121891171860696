import React, { useState } from "react";
import { useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaImage } from "react-icons/fa";
import {
    MdKeyboardArrowRight,
    MdOutlineScreenLockLandscape,
    MdScreenLockPortrait,
} from "react-icons/md";
import Axios from "../Axios/axios";

function ContentSelect({
    dpSlots,
    setDpSlots,
    updateDpSlots,
    slot,
    close,
    display,
}) {
    const loc = useLocation();
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const username = sessionStorage.getItem("username");
    const notify = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });

    const notifyErr = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });

    const [rightsideVisible, setRightSideVisible] = useState(false);

    const handleRightSide = () => {
        getScreenSlotsDetails();
        setRightSideVisible(!rightsideVisible);
    };

    const [content, setContent] = useState([
        {
            title: "Food - Tactos",
            duration: "00:00",
            advertiser: "Oceanic Airlines Ad",
            thumbnailPath: "",
            contentId: "ididid",
        },
    ]);

    async function getScreenSlotsDetails() {
        await Axios.post("getScreenSlotsDetails", {
            username: username,
            screenId: display,
        }).then((res) => {
            console.log("daafaf", res.data);
            setContent(res.data.contentDetails);
            setDpSlots(res.data.slots);
        });
    }

    const [selectedContentId, setSelectedContentId] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(0);

    const handleDivClick = (contentId, duration) => {
        setSelectedContentId(contentId);
        setSelectedDuration(duration);
    };

    console.log("contentselect log", selectedContentId, selectedDuration);

    return (
        <div className="p-4 bg-white w-full h-full rounded-xl">
            <div className="w-[100%] ">
                <div className="flex w-[100%] justify-between items-center">
                    <div className="text-2xl font-semibold text-[#001122]">
                        Add Content
                    </div>
                    <div className="text-[#555555] text-sm">
                        {" "}
                        {display === "Portrait" ? (
                            <span className="text-black flex items-center ">
                                <MdScreenLockPortrait
                                    size={22}
                                    className="text-[#9D9D9D] "
                                />
                                {display}
                            </span>
                        ) : (
                            <span className="  text-black flex items-center gap-1">
                                <MdOutlineScreenLockLandscape
                                    size={24}
                                    className="text-[#9D9D9D] "
                                />
                                {display}
                            </span>
                        )}
                    </div>
                </div>
                <div className="w-[100%] mt-3 border-[#9D9D9D] border-b-2 "></div>
            </div>
            <div className=" flex py-4 gap-2  h-[85%] w-[100%]">
                <div className="w-[25%] border-[#9D9D9D] py-5 border-r-2 h-[100%]">
                    <p className="text-[#001122] ">Import From</p>
                    {/* <p className="text-gray-500 cursor-pointer">My Desktop "Upcoming Feature"</p> */}
                    <div className="flex mt-4 items-center mx-5  cursor-pointer hover:text-[#FF4D00] text-[#555555] py-2 w-[70%] hover:bg-[#FEE4D8] rounded-full justify-between px-2 ">
                        <div className="flex items-center hover:text-[#FF4D00] text-[#555555] gap-2">
                            <FaImage
                                size={24}
                                className="hover:text-[#FF4D00] "
                            />
                            <p
                                onClick={handleRightSide}
                                className="hover:text-[#FF4D00] text-[#555555] cursor-pointer"
                            >
                                My Content
                            </p>
                        </div>
                        <MdKeyboardArrowRight
                            size={24}
                            className="hover:text-[#FF4D00] "
                        />
                    </div>
                </div>

                {rightsideVisible && (
                    <div className={`h-[100%]  w-[95%]  overflow-y-scroll `}>
                        <form className="h-[85%] w-[100%]">
                            <div className=" h-[100%]  w-[100%]">
                                <div className="flex  items-center mx-5  cursor-pointer   w-[95%]   justify-between px-2 ">
                                    <div className="flex items-center py-2 px-3 bg-[#FEE4D8] rounded-full text-[#FF4D00]  gap-2">
                                        <p className="text-[#FF4D00]  cursor-pointer">
                                            Videos
                                        </p>
                                    </div>
                                    <div className="flex items-center py-2 px-3 bg-[#FEE4D8] rounded-full text-[#FF4D00] gap-2">
                                        <p className="text-[#FF4D00]  cursor-pointer">
                                            First Slot
                                        </p>
                                    </div>
                                </div>
                                <div className="h-[90%] p-3  overflow-y-visible flex flex-wrap gap-2 w-[100%] rounded-lg">
                                    {content.map((option) => (
                                        <div
                                            onClick={() => {
                                                handleDivClick(
                                                    option.contentId,
                                                    option.duration
                                                );
                                            }}
                                            className={`${selectedContentId ===
                                                option.contentId
                                                ? "border-red-400" // Apply the red background color when clicked
                                                : "" // Apply the green background color when not clicked
                                                } h-60 border w-52 rounded-lg cursor-pointer`}
                                            key={option.contentId}
                                        >
                                            <div className="h-[55%] w-[100%]">
                                                <img
                                                    src={option.thumbnail}
                                                    alt="Thumbnail"
                                                    className="h-full w-full object-cover"
                                                />
                                            </div>
                                            <div className="h-[45%]  w-[100%]">
                                                <p>Title: {option.title}</p>
                                                <p>
                                                    Duration: {option.duration}
                                                </p>
                                                <p>
                                                    ContentId:{" "}
                                                    {option.contentId}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <div className="flex items-center justify-end gap-3 w-[100%]">
                <div
                    onClick={close}
                    className="text-[#555555] py-2 px-5 rounded-xl bg-[#DEDEDE] cursor-pointer float-right"
                >
                    Cancel
                </div>
                <div
                    onClick={() => {
                        updateDpSlots(
                            slot,
                            selectedContentId,
                            selectedDuration
                        );
                        close();
                    }}
                    className=" py-2 px-5 rounded-xl bg-[#FF4D00] text-white cursor-pointer float-right"
                >
                    Select
                </div>
            </div>
        </div>
    );
}

export default ContentSelect;
