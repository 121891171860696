import React, { useEffect, useState } from 'react'
import DpMenu from '../../Menu/DpMenu';
import DpHeader from "../../Header/DpHeader"
import { ToastContainer } from 'react-toastify'
import { IoChevronForwardOutline } from "react-icons/io5";
import { GoFilter, GoSearch } from 'react-icons/go'
import { BsChevronDown, BsSquare, BsThreeDotsVertical } from 'react-icons/bs'
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from 'react-router-dom';
import { PiUploadSimpleLight } from 'react-icons/pi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Axios from "../../Axios/axios";
import Select from 'react-select';
import { CiSearch } from 'react-icons/ci';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';



const Report = () => {
    const [dateFilter, setDateFilter] = useState('daily');
    const generatePDF = (data) => {
        const doc = new jsPDF();
        doc.text(`Your ${dateFilter} Report `, 10, 10);

        let yOffset = 30;
        data.forEach((item, index) => {
            yOffset += 7;
            doc.text(`Screen Name: ${item.screenName}`, 7, yOffset);
            yOffset += 7;
            doc.text(`Earnings: ${item.amount}`, 7, yOffset);
            yOffset += 7;
            doc.text(`Location: ${item.screen_address}`, 7, yOffset);
            yOffset += 7;
        });

        // Save the PDF
        doc.save('report.pdf');
    };
    const navigate = useNavigate();
    const ReportNaviagte = () => {
        navigate("/home");
    }
    const [data, setData] = useState([]);
    const [locationFilter, setLocationFilter] = useState('All');
 
    const [firstScreenId, setFirstScreenId] = useState(0);
    const username = sessionStorage.getItem('username');
    console.log(username);
    
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get(`getRatingDetail?username=${username}&range=${dateFilter}&category=all`);
                console.log(response.data,"-------------------------");
                setData(response.data.sanitizedScreens);
                console.log(response.data.sanitizedScreens[0].screenId,"------------------",firstScreenId);
                setFirstScreenId(response.data.sanitizedScreens[0].screenId)
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }

        fetchData();

    }, [username, dateFilter]);



    console.log(firstScreenId);
    const [screenId, setScreenId] = useState(firstScreenId);
    console.log(screenId);
    const [chartData, setChartData] = useState([]);
    useEffect(() => {
        async function ReportData() {
            console.log(screenId,"--------------------------------scren");
            console.log(dateFilter)
            try {

                const response = await Axios.get(`screen-revenue-graph/${firstScreenId}/${dateFilter}`); //range=${dateFilter}
                // console.log(response, "dfdgdfg-------------------------");
                let combinedData = response.data.data;
                setChartData(combinedData);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }

        ReportData();
        handler();
    }, [firstScreenId,dateFilter]);
    const handleFilterChange = (name, value) => {
        if (name === 'location') {
            setLocationFilter(value);
        } else if (name === 'date') {
            setRowColor(null)
            setDateFilter(value);
        }
    };
    console.log(chartData, "chartData------------------------+++++++++++++++");
    const dateOptions = [
        { value: 'daily', label: 'Today' },
        { value: 'weekly', label: 'This Week' },
        { value: 'monthly', label: 'This Month' },
        // { value: 'year', label: 'This Year' },
    ];


    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        console.log("Search Text:", e.target.value);
    };
    const filteredData = searchText === '' ? data : data.filter(item => item.screenName.toLowerCase().includes(searchText.toLowerCase()));
    console.log('Filtered data:', filteredData);
    const [rowColor, setRowColor] = useState(0)
    const handler = (id) => {
        setFirstScreenId(id);
        console.log(id);

    }
    const handlIndex = (index) => {
        setRowColor(index)
    }
    console.log(rowColor);
    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: "white",
            borderRadius: "4px", // Border radius
            width: "150px",
            borderColor: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                borderColor: state.isFocused ? 0 : 0
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "" : "", // Option background color
            color: state.isFocused ? "" : "", // Option text color
            cursor: 'pointer',
        }),

    };
    return (
        <>
            <div className="h-screen flex w-screen ">

                <DpMenu />
                <div className="h-[100%] overflow-hidden w-[100%]  bg-[#FFFDF9] " >
                    <ToastContainer />
                    <DpHeader />
                    <div className=" flex items-center p-4">
                        <span onClick={ReportNaviagte} className='text-[#8E8E8E] cursor-pointer'>Home</span>   <IoChevronForwardOutline /> Report
                    </div>
                    <div className="h-[82%]  w-[100%] pl-4 pr-2 pt-4 flex flex-col gap-4">

                        <div className='flex justify-between pr-3'>
                            <p className='text-[#555555] text-[24px]'>Report</p>
                            <button
                                onClick={() => generatePDF(data)}
                                className=" flex items-center gap-5 py-[9px] px-3  shadow-md hover:bg-[#FF8E00] hover:text-[#FFFFFF]  shadow-[#FFF1EB] shadow-opacity-[30%]  rounded-[4px] text-[#FF8E00] text-[16px] border-[#FF8E00] border border-solid "
                            >
                                <span>
                                    <PiUploadSimpleLight size={24} className="font-medium " />{" "}
                                </span>
                                <span className='text-[16px] font-medium'>Export Report</span>
                                <span>
                                    <BsChevronDown className=' font-medium ' />
                                </span>
                            </button>
                        </div>

                        <div className='w-[100%] h-[100%] rounded-[4px]'>
                            <div className='p-6 flex bg-[#FFFDF9]'>
                                <div className='flex items-center gap-4'>
                                    <GoFilter size={24} className='text-[#555555]' />
                                    <p className='text-[#555555] text-[16px]'>Filters:</p>
                                    <div >
                                        <Select
                                            className="text-[#9D9D9D] cursor-pointer px-2 py-1  bg-[#FFFDF9] rounded-[4px] outline-none"
                                            name="date"
                                            value={dateOptions.find((option) => option.value === dateFilter)||dateOptions.find((option) => option.value === 'daily')}
                                            options={dateOptions}
                                            onChange={(selectedOption) => {
                                                handleFilterChange('date', selectedOption.value);
                                            }}
                                            styles={customSelectStyles}
                                        />

                                       
                                    </div>

                                    <div className="flex  border border-[#DEDEDE]  bg-white rounded-[4px]">
                                        <div className=" flex items-center gap-2  px-2 py-2">
                                            <CiSearch
                                                size={24}
                                                className=" text-gray-400"
                                            />
                                            <input
                                                className=" outline-none h-fit w-full"
                                                type="text"
                                                placeholder="Search Screens"
                                                value={searchText}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-[100%] flex gap-2 h-[85%] '>
                                <div className='w-[60%] h-[95%] p-6 mx-2 rounded-[4px]  shadow-md bg-white shadow-[#FFF1EB] shadow-opacity-[30%] '>
                                    <p className='text-[#555555] text-[20px]'>Summary   </p>
                                    <div className=' w-[100%] h-[90%]'>
                                        {/* <img src={graph} alt='' />
                                         */}
                                        <ResponsiveContainer width="100%" height="80%">
                                            {dateFilter === 'monthly' ? (
                                                <>
                                                {chartData.length>0 ?(<>
                                                    <AreaChart
                                                    width={900}
                                                    height={400}
                                                    data={chartData}
                                                    margin={{
                                                        top: 0,
                                                        right: 34,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}

                                                >
                                                    <XAxis dataKey="date" axisLine={false} tickSize={0}
                                                        padding={{ left: 20, right: 20 }}

                                                    />
                                                    <YAxis dataKey="amount" axisLine={false} tickSize={0}
                                                        padding={{ top: 20, bottom: 20 }}
                                                    />
                                                    <Tooltip />
                                                    <Area type="monotone" dataKey="amount" stroke="#FF8E00" fill="#FFF5E7" />
                                                   
                                                </AreaChart>
                                                
                                                
                                                </>):(
                                                    <div   className="w-full pt-24 h-full  items-center flex  justify-center">
                                                    {/* <p className="text-[#555555]">No Data Available</p> */}
                                                    
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM-hK0RedfGRZP4SQXm_D6n-0Zy94VdmGZcg&s" className="w-20 h-20" alt="" />
                                                    </div>
                                                )}
                                                </>
                                            ) : dateFilter === 'weekly' ? (
                                                <>
                                                {chartData.length>0 ?(<>
                                                    <AreaChart
                                                    width={900}
                                                    height={400}
                                                    data={chartData}
                                                    margin={{
                                                        top: 0,
                                                        right: 34,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}

                                                >
                                                    <XAxis dataKey="date" axisLine={false} tickSize={0}
                                                        padding={{ left: 20, right: 20 }}

                                                    />
                                                    <YAxis dataKey="amount" axisLine={false} tickSize={0}
                                                        padding={{ top: 20, bottom: 20 }}
                                                    />
                                                    <Tooltip />
                                                    <Area type="monotone" dataKey="amount" stroke="#FF8E00" fill="#FFF5E7" />
                                                   
                                                </AreaChart>
                                                
                                                
                                                </>):(
                                                    <div   className="w-full pt-24 h-full  items-center flex  justify-center">
                                                    {/* <p className="text-[#555555]">No Data Available</p> */}
                                                    
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM-hK0RedfGRZP4SQXm_D6n-0Zy94VdmGZcg&s" className="w-20 h-20" alt="" />
                                                    </div>
                                                )}
                                                </>
                                            ) : dateFilter === 'daily' ? (
                                                <>
                                                {chartData.length>0 ?(<>
                                                    <AreaChart
                                                    width={900}
                                                    height={400}
                                                    data={chartData}
                                                    margin={{
                                                        top: 0,
                                                        right: 34,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}

                                                >
                                                    <XAxis dataKey="date" axisLine={false} tickSize={0}
                                                        padding={{ left: 20, right: 20 }}

                                                    />
                                                    <YAxis dataKey="amount" axisLine={false} tickSize={0}
                                                        padding={{ top: 20, bottom: 20 }}
                                                    />
                                                    <Tooltip />
                                                    <Area type="monotone" dataKey="amount" stroke="#FF8E00" fill="#FFF5E7" />
                                                   
                                                </AreaChart>
                                                
                                                
                                                </>):(
                                                    <div   className="w-full pt-24 h-full  items-center flex  justify-center">
                                                    {/* <p className="text-[#555555]">No Data Available</p> */}
                                                    
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM-hK0RedfGRZP4SQXm_D6n-0Zy94VdmGZcg&s" className="w-20 h-20" alt="" />
                                                    </div>
                                                )}
                                                </>
                                            ) : (
                                                <AreaChart
                                                    width={500}
                                                    height={400}
                                                    data={chartData}
                                                    margin={{
                                                        top: 0,
                                                        right: 34,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}
                                                >
                                                    <XAxis dataKey="date" axisLine={false} tickSize={0}
                                                        padding={{ left: 20, right: 20 }}
                                                    />
                                                    <YAxis  dataKey="amount"  axisLine={false} tickSize={0}
                                                        padding={{ top: 20, bottom: 20 }} />
                                                    <Tooltip />
                                                    <Area type="monotone" dataKey="date" stroke="#FF8E00" fill="#FFF5E7" />
                                                </AreaChart>
                                            )}
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                                <div className='w-[50%] h-[95%] rounded-[4px]  shadow-md bg-white shadow-[#FFF1EB] shadow-opacity-[30%]  mx-2'>
                                    <div className="w-[100%] h-[88%] ml-2 items-center overflow-y-scroll ">
                                        <p className='pl-4 pt-4 text-[#555555] text-[20px]'>Details</p>
                                        <table className={`w-[98%] ${rowColor + 1 ? "border-collapse " : " border-separate"}
                                             text-[#555555] text-left`}>
                                            <thead className="">
                                                <tr className=" text-left w-[98%]">
                                                    <th scope="col" className=" pl-6 border-b " colSpan={4}>
                                                        Screen Name
                                                    </th>
                                                    <th scope="col" className="py-4 
                                                        text-center  border-b" >

                                                    </th>
                                                    <th scope="col" className=" border-b text-center pl-4" >
                                                        Earnings
                                                    </th>
                                                    <th scope="col" className=" border-b text-center pl-4" >
                                                        Screenid
                                                    </th>
                                                    <th scope="col" className=" text-center  border-b">
                                                        Location
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody className="">
                                                {filteredData.map((item, index) => (
                                                    <tr key={index} className={`text-left w-[98%] cursor-pointer ${index === rowColor ? "bg-[#FF8E00] text-white" : ""}`}
                                                        onClick={() => { handler(item.screenId); handlIndex(index) }}
                                                    >
                                                        <td scope='col' className='pl-6' colSpan={4}>{item.screenName}</td>
                                                        <td scope='col' className='py-4 pl-6 text-center'></td>
                                                        <td scope='col' className='text-center pl-4'>{item.amount}</td>
                                                        <td scope='col' className='text-center pl-4'>{item.screenId}</td>
                                                        <td scope='col' className='text-center'>{item.screen_address}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className='w-[100%] h-[10%] p-2 bg-white flex  items-center justify-center '>
                                        <div className='w-[99%] border-t flex items-center justify-between pt-2 pb-1'>
                                            <div className='pl-3'>
                                                <p className='flex  text-[14px] text-[#555555] select-none'>
                                                    {/* {totalDisplay}  */}
                                                    Results</p>
                                            </div>
                                            <div>
                                                <Stack spacing={2}>
                                                    <Pagination
                                                        // color="secondary"
                                                        defaultPage={1}
                                                        shape="rounded"
                                                        onChange={(event, value) => {
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >

        </>
    )
}

export default Report