import React, { useEffect } from 'react'
import AdminHeader from '../adminHeader'
import Menu from '../../Menu/Menu'
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import DpTabs from './DpTabs';
import Axios from "../../Axios/axios";


function Module5() {

    const [pending, setPending] = useState({});
    const [approved, setApproved] = useState([]);
    const [rejected, setRejected] = useState([]);
  
    console.log(pending);
    useEffect(() => {
        // Fetch JSON data during page loading
        const fetchData = async () => {
            try {
                const response = await Axios.get("getPendingPayment");
                console.log(response);
                setPending(response.data);
              
                console.log("data set successfully", response);
            } catch (error) {
                console.error("Error fetching JSON data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Fetch JSON data during page loading
        const fetchData = async () => {
            try {
                const response = await Axios.get("getPaymentHistory");
                setApproved(response.data.approvedPayments);
                setRejected(response.data.rejectedPayments);
                console.log("data set successfully", response);
            } catch (error) {
                console.error("Error fetching JSON data:", error);
            }
        };
        fetchData();
    }, []);

    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/");
    };




    return (
        <>
            <div className=" flex h-screen overflow-hidden w-screen">
                <div className="">
                    <Menu
                       
                    />
                </div>
                <div className="flex flex-col overflow-hidden w-[100%] bg-gray-100 text-black">
                    <AdminHeader />
                    <div className="mt-20 h-[80%] overflow-y-scroll">
                        <DpTabs
                            pending={pending}
                            approved={approved}
                            rejected={rejected}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Module5