import React, { useEffect, useState } from 'react'
import Menu from "../Menu";
import Header from '../../Header/AdHeader';
import { MdOutlineEdit } from "react-icons/md";
import axios from '../../Axios/axios';
import { useUserContext } from '../Context/Usercontext';
import SimpleWebAuthnBrowser from '@simplewebauthn/browser';
import { startRegistration } from '@simplewebauthn/browser';
import { IoMdFingerPrint } from "react-icons/io";
import DpMenu from '../../Menu/DpMenu';
import { ToastContainer, toast } from "react-toastify";

function Profile() {
    const {advertiser, setAdvertiser} = useUserContext()
    const user = sessionStorage.getItem('username');
    const [token, setToken] = useState("");
    const [open, setOpen] = useState(true);
    const [edit, setEdit] = useState({
        pin: false
    });
    const [npin, setNpin] = useState('');
    const [opin, setOpin] = useState('');
    const [data, setData] = useState({
        email: 'email',
        customerId: 'customerId',
        pin: '3453324'
    })

    useEffect(() => {
        if (sessionStorage.getItem("role") === "advertiser") {
            setToken(localStorage.getItem('token'));
        } else {
            setToken(localStorage.getItem('Dp_token'));
        }
    },[]);

    useEffect(() => {
        setOpin(advertiser.pin);
      }, [advertiser.pin]);
      
    console.log(advertiser)
    const handleUpdate = async() => {
        const call = await axios.put('/updatePin', {username: user, pin: opin},
    {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    if(call.status === 200) {
        alert('Pin Updated')
    }
    }
    const handleChange = (content, e) =>{
        setData({...data, [content]: e.target.value})
    }
    console.log(edit)

    const handleAddFingerprint = async () => {
        console.log("add fingerprint button clicked");
        console.log("sending username: " +advertiser.username);
        const res = await axios.post('/advertiser/generate-registration-options', {username: advertiser.username}, { headers: { Authorization: `Bearer ${token}` } });
        const options = await res.data.options;
        const registrationRes = await startRegistration(options);
        console.log(registrationRes);
        const verifyres = await axios.post('/advertiser/verify-passkey-registration', { username: advertiser.username, cred: registrationRes }, { headers: { Authorization: `Bearer ${token}` } });
        console.log("response from server on verification of passkey registration: ", verifyres);
        if (verifyres.data.verified) {
            notifyy("Passkey added!")
        } else {
            notify("Error registering passkey");
        }
    }

    const notifyy = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const notify = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

  return (
    <>
            <div className="flex overflow-y-hidden h-[100%] w-[100%] bg-gray-100">
                {
                    sessionStorage.getItem("role") === 'advertiser' ?
                    <Menu /> : <DpMenu/>
                }
                <ToastContainer/>
                <div
                    className={`feature flex-1 select-none border h-screen  ${open ? "w-70" : "w-full"
                        }`}
                >
                    <div className="border bg-white ">
                        <Header />
                    </div>
                    <div className="xl:h-[84%] overflow-hidden w-full flex flex-col gap-10 items-center justify-center">
                        
                        <div className='flex bg-white rounded-xl drop-shadow-md p-4 flex-col gap-10 items-center text-2xl justify-center w-[50%]'>
                        <h1 className='border-b-2 w-full pb-2 border-[#FF9004] font-bold'>Update Details</h1>
                        <div className='w-full flex justify-between items-center'>
                        <div className='w-[30%] flex flex-col justify-between items-center gap-10 border-r-2 border-[#FF9004]'>
                            <IoMdFingerPrint onClick={() => {handleAddFingerprint()}} className='size-24 text-[#FF9004] cursor-pointer hover:drop-shadow-2xl' />
                            <h1 className='text-[1.4rem]'>Add Passkey</h1>
                        </div>
                        <div className='w-[60%] flex flex-col gap-10 ml-[10%]'>
                            <div className='flex gap-4 w-[100%] items-center'>
                            <h1 className='w-[50%] text-[1.2rem] font-semibold'>Name </h1>
                            <div className='relative w-[50%] text-2xl '>
                            <input
                            className='relative w-full p-2 border-2 bg-transparent border-[#FF9004] rounded-xl text-[1rem] bg-gray-300'
                            type="text"
                            disabled={true}
                            value={advertiser.name}
                        />

                            {edit.email === false &&<MdOutlineEdit onClick={()=>setEdit({...edit, 'email': !edit.email})} className='absolute right-4 top-3  rounded-full hover:bg-slate-200'/>}
                            </div>
                            </div>
                            <div className='flex gap-4 w-[100%]'>
                            <h1 className='w-[50%] text-[1.2rem] font-semibold'>Customer Id</h1>
                            <div className='relative w-[50%] text-2xl '>
                            <input disabled={true} className='relative w-full bg-gray-300 p-2 border-2 bg-transparent border-[#FF9004] rounded-xl text-[1rem]' type="text"  value={advertiser.customerId} />
                            </div>
                            </div>
                            <div className='flex gap-4 w-[100%]'>
                            <h1 className='w-[50%] text-[1.2rem] font-semibold'>Pin</h1>
                            <div className='relative w-[50%] text-2xl '>
                            <input 
                            className='relative w-full p-2 border-2 bg-transparent border-[#FF9004] rounded-xl text-[1rem]' 
                            type='text' 
                            value={opin}
                            placeholder='Old Pin'
                            onChange={edit.pin ? (e)=>setOpin(e.target.value) : undefined}
                            onKeyPress={(e) => {
                                if (isNaN(e.key) || e.target.value.length >= 4) {
                                    e.preventDefault();
                                    alert("Only numbers with maximum 4 digits are allowed!");
                                }
                            }} 
                            required 
                        />

                            {edit.pin === false &&<MdOutlineEdit  onClick={()=>setEdit({...edit, 'pin': !edit.pin})} className='absolute right-4 top-3 rounded-full hover:bg-slate-200'/>}
                            </div>
                            </div>
                        </div>
                        </div>
                            <div className='flex justify-center w-full self-center transition-all duration-500 rounded-md hover:border-none'>
                            <button onClick={handleUpdate} className='flex w-[90%] text-center justify-center items-center text-white bg-[#FF9004] hover:bg-[#ff8957] px-4 py-2 rounded-full duration-300' >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default Profile
