import React, { useState } from "react";
import Header from "../../Header/AdHeader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Axios from "../../Axios/axios";
import Menu from "../Menu";
import Tabs from "./Tabs";
import Edlogo from "../../Images/everydoor.png";


export default function ViewCampaign() {
    const [mydata, setMydata] = useState([]);
    const [arr, setArr] = useState([]);

    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/");
    };

    const handledescription = (campId) => {
        navigate("/advDescription?campId=" + campId);
    };

    useEffect(() => {
        const fetchData = async () => {
            const token=localStorage.getItem("token");
            console.log("token at viewCampaign: " + localStorage.getItem("token"));
            try {
                const response = await Axios.get(
                    `advertiser/getAdCampaignRequests?username=${sessionStorage.getItem(
                        "username"
                    )}`,{headers: { Authorization: `Bearer ${token}` },}
                );
                setMydata(response.data.result);
                console.log(response);
                setArr(response.data.result);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const pendingRequests = arr.filter((data) => data.status === -1);
    const approvedRequests = arr.filter((data) => data.status === 1);
    const rejectedRequests = arr.filter((data) => data.status === 0);
    const completedRequests = arr.filter((data) => data.status === 2);
    
    return (
        <div className="w-full overflow-hidden">
            <div className="flex justify-between w-full">
                <Menu />

                <div className="h-screen overflow-y-hidden overflow-x-scroll xl:overflow-x-hidden w-full">
                    <Header />
                    <div className=" w-screen xl:w-full h-[86vh] justify-evenly bg-[#f2f2f2] p-8 shadow-inner">
                        <h1 className="text-[24px] mb-8 ml-[8px]">
                            Scheduled Campaigns
                        </h1>
                        <Tabs
                            allRequests={arr}
                            pendingRequests={pendingRequests}
                            approvedRequests={approvedRequests}
                            rejectedRequests={rejectedRequests}
                            completedRequests={completedRequests}
                            handledescription={handledescription}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
