import {
    CardElement,
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import Axios from "../../Axios/axios";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#FF4D00",
            color: "#000",
            fontWeight: 400,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "18px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#0000FF" },
            "::placeholder": { color: "#000" },
        },
        invalid: {
            color: "#FF0000",
            iconColor: "#FF0000",
        },
    },
};

export default function PaymentForm({ total, screens }) {
    const [success, setSuccess] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const [username, setUsername] = useState("");
    const [paymentdata, setPaymentdata] = useState();
    console.log(username);
    console.log(total);

    useEffect(() => {
        setUsername(sessionStorage.getItem("username"));
    }, []);
    const navigate = useNavigate();

    const redirectToLink = (response) => {
        console.log("Successful", response);
        if (response.status === 200) {
            const paymentGatewayURL = response.data.clientSecret; // Replace with the correct key in your response
            if (paymentGatewayURL) {
                window.location.href = paymentGatewayURL; // Redirect to the payment gateway URL
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (!error) {
            try {
                const { id } = paymentMethod;
                console.log(id);
                const response = await Axios.post("/payment", {
                    amount: total * 100,
                    username,
                    id,
                });
                console.log("Successful payment", response);
                if (response.status === 200) {
                    redirectToLink(response);
                    setSuccess(true);
                    setPaymentdata(response.data);
                    console.log(response.data);
                }
            } catch (error) {
                console.log("Error", error);
            }
        } else {
            console.log(error.message);
        }
    };

    return (
        <>
            {!success ? (
                <form
                    onSubmit={handleSubmit}
                    className="py-4 flex flex-col justify-between h-[70%]"
                >
                    <fieldset className="FormGroup">
                        <div className="FormRow rounded-full px-8 py-4 border-2">
                            <CardElement options={CARD_OPTIONS} />
                        </div>
                    </fieldset>
                    <div className="border-t">
                        <div className="flex justify-between py-4">
                            <p>Total Advertised Displays</p>
                            <p className="font-bold">{screens}</p>
                        </div>
                        <div className="flex justify-between py-4">
                            <p>Total Amount</p>
                            <p className="font-bold">₹ {total}</p>
                        </div>
                        <button
                            type="submit"
                            className="bg-[#FF4D00] text-white rounded-full w-full mt-4"
                        >
                            Pay
                        </button>
                    </div>
                </form>
            ) : (
                <div>
                    <h2 className="text-white">Thank You!</h2>
                </div>
            )}
        </>
    );
}
