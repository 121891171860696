import { useNavigate } from 'react-router-dom';
import axios from '../../Axios/axios'
import React, { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';

function Otp({adminId, pass}) {
  const [otp, setOtp] = useState('')
  const [users, setUsers] = useState([]);
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate()
  const handleSubmit = async() =>{
    setUpload(true);
    const response = await axios.post('verifyadmin', {adminId , otp})
    if(response.status === 200) {
        var allUsers = [];
        allUsers = response.data.Users;
        setUsers(allUsers);
        sessionStorage.setItem("admin", true);
        sessionStorage.setItem("adminId", adminId);
        sessionStorage.setItem("adminPass", pass);
        // setAuth(true);
        window.location.reload()
        console.log('Logged In!')
    }
    else if(response.status === 202){
        alert('Invalid Otp!')
    }
    setUpload(false);
    }
  const handleResend = async(e) =>{
    e.preventDefault()
    setResend(true);
    const res = await axios.post("sendadminOTP", {
        id: adminId,
        password: pass,
    });
    if(res.status === 200){
      alert('OTP has been resent!');
      // can i remove the bottom line?
        setOtp(true)
    }
    setResend(false);
  }

  const [upload, setUpload] = useState(false);
  const [resend, setResend] = useState(false);
  return (
    <div className='w-full h-full flex flex-col gap-5 items-center justify-center py-[15%]'>
        <h1 className='font-bold text-4xl'>Enter Your Otp</h1> 
        <input type="text" className='p-2 border-2 ' value={otp} onChange={(e)=>setOtp(e.target.value)}/>
        <div className='flex gap-4' >
        <button disabled={upload} onClick={handleSubmit} class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
        {!upload ? "Submit" : ""}
        {upload && (
          <ThreeDots
              height="20"
              width="50"
              radius="9"
              color="#ffffff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
          />
        )}
        </button>
        <button disabled={resend} onClick={handleResend} class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
        {!resend ? "Resend OTP" : ""}
        {resend && (
          <ThreeDots
              height="20"
              width="50"
              radius="9"
              color="#ffffff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
          />
        )}
        </button>
        </div>
    </div>
  )
}
export default Otp
