import axios from "axios";

const instance = axios.create({
    // baseURL: "https://j3hg2gqz-4000.inc1.devtunnels.ms/", //The API {Cloud Funtion}sa
    // baseURL: 'https://vkvp4qqj-4000.inc1.devtunnels.ms/'
    // baseURL: "http://ec2-43-204-23-143.ap-south-1.compute.amazonaws.com:4000/"
       baseURL : "https://www.everidoorbackend.com/" 
    // baseURL: "https://r80q8w1t-4000.inc1.devtunnels.ms/"
    // baseURL : 'https://j3hg2gqz-4000.inc1.devtunnels.ms/'
    //below is the server that nobil hosted
    // baseURL: 'https://everidoorbackend2-0.onrender.com/'
    //below is the always running server
    // baseURL: 'https://vkvp4qqj-4001.inc1.devtunnels.ms/'
    // baseURL: 'https://6zqcjmt9-4000.inc1.devtunnels.ms/'
    // baseURL: 'https://p8w9c4kr-4000.inc1.devtunnels.ms/' 
});
 
export default instance;
