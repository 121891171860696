import React from 'react'
import verfiy from '../SignIn/images/verify.png';
import logo from "../Images/everydoor.png"
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import { Link } from 'react-router-dom';
import Axios from '../Axios/axios'
import { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function Verify() {
    const notifyError = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyOtp = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const navigate = useNavigate();

    const NavigateVerify = () => {
        navigate('/newpassword');
    };

    const [err, SetErr] = useState(false);
    const username = localStorage.getItem("username")
    const [formData, setFormData] = useState({
        username: username,
        otp: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    console.log(formData);
    const handleVerify = async (event) => {
        event.preventDefault();
        try {
            const response = await Axios.post('/verifyOtp', formData); // Use Axios to POST data
            console.log('Response:', response.data);
            if (response.status === 200) {
                NavigateVerify();
            }
            if (response.status === 202) {
                notifyError("Wrong OTP");
                SetErr(true)
                console.log('Response:', response.status);
            }
            else if (response.status === 203) {
                notifyOtp("Otp Already Used");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='w-screen h-screen flex items-center justify-center bg-[#F2F2F2]'>
                <div className='h-[85%] border-gray-300   w-[70%] bg-white  flex justify-end '>
                    <div className='flex  flex-col justify-start h-full w-[50%]'>
                        <div className="mx-12" >
                            <img src={logo} alt="evridoor" width="200px" height="100px" />
                        </div>

                        <div className=" ml-6 h-80 text-[#727272] flex flex-col justify-center ">
                            <h1 className='mx-6 my-6'>Important Information:</h1>
                            <div className='flex gap-4 flex-col justify-center h-40 items-center'>
                                <p className='mx-6'>
                                    Please read the below instructions and then kindly share the requested information.
                                </p>
                                <ul className='list-disc mr-10'>
                                    <li> Use Alphanumeric characters in passwords</li>
                                    <li> Your Email ID/ Username  is required</li>
                                    <li> Do not reuse passwords</li>
                                    <li> Do not reveal your password/ OTP to anyone.</li>

                                </ul>
                            </div>
                        </div>
                        <div className="flex text-[#727272] w-full justify-center items-center  mt-56 pr-12">
                            <p>Contant Us &nbsp;&nbsp;|</p>
                            <p>&nbsp;&nbsp;Term & Conditions&nbsp;&nbsp;|</p>
                            <p>&nbsp;&nbsp;FAQ's</p>
                        </div>
                    </div>
                    <div className='  flex justify-center items-center   h-[100%]' style={{
                        backgroundImage: `url(${verfiy})`, // Set the background image
                        backgroundSize: 'cover',
                        height: '100%',
                        width: '55%',
                        
                    }}>
                        <div className=' h-[50%] w-[60%] border flex flex-col  items-center rounded  bg-white'>
                           <div className="w-[90%] mt-4 flex flex-col text-3xl text-[#555555] cursor-pointer gap-[8px]">
                           <Link
                                to="/forgot">
                                <BiArrowBack />
                            </Link>
                            
                            <div className="w-[100%] flex flex-col justify-center items-center gap-[8px]">
                                <h1 className='text-[#555555] text-2xl font-bold'>Verify Your Account</h1>
                                <p className=' text-sm font-medium text-[#555555]'>Enter the OTP number shared on your <br />
                                    <p className='text-center'>Email ID/Number. </p></p>
                          </div>
                            </div>
                            <form className="w-[90%] mt-6">
                                <div className="w-[100%] justify-center flex flex-col">
                                    <input
                                        type="text"
                                        name="otp"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="border  rounded p-3  outline-none " placeholder="Enter OTP" />
                                        <p className='flex justify-end font-semibold mt-1 text-[#FF8E00]'>Resend OTP</p>
                                </div>
                                {err ? (<span className='text-red-400 '>Wrong Otp</span>) : null}
                                <div className="w-[100%]  mt-6 border  bg-gradient-to-r from-[#FB6600] via-[#FF9002] to-[#FB6600] ... flex items-center justify-center rounded">
                                    <button onClick={handleVerify} className='px-4 py-2  text-white' type='submit'>
                                        Continue
                                    </button>
                                </div>

                            </form>
                           
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Verify