import React, { useContext, useState } from "react";
import { CiTrash } from "react-icons/ci";
import "./Inbox_box.scss";
import Axios from "../../Axios/axios";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import { SocketContext } from "../../../socketcontext";
import { useNavigate } from "react-router-dom";

const buttonStyle =
  "w-full rounded-full border-solid border border-[#FF4D00] text-[#FF4D00] transition-all hover:bg-[#FF4D00] hover:text-white";

const Box = ({
  campaignRequestId,
  campaignName,
  fromUser,
  toUser,
  content_to_be_played,
  thumbnail_for_content_to_be_played,
  fromDate,
  toDate,
  fromTime,
  toTime,
  selectedScreens,
  status,
  newEdit,
  additional_comments,
}) => {
  const navigate = useNavigate();
  console.log("thumbnail_for_content_to_be_played---------->" , thumbnail_for_content_to_be_played);
  console.log(newEdit);
  
  const handleView = () => {
    navigate(`/view?campId=${campaignRequestId}&newEdit=${newEdit}`);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const WebSocket = useContext(SocketContext);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleReject = async () => {
    setApproveLoading(true);
    try {
      if (newEdit) {
        const Reject = await Axios.post("instantEditReject", {
          campaignRequestId: campaignRequestId
        });
        if (Reject.status === 200) {
          alert("Request to edit campaign rejected");
        } else {
          alert("Something went wrong, can't edit reject request");
        }
      } else {
        const Reject = await Axios.post("instantReject", {
          campaignRequestId: campaignRequestId,
        });
        if (Reject.data.Message === "Status updated to rejected") {
          alert("Request Rejected");
        } else {
          alert("Something went wrong");
        }
      }
      console.log("rejected");
    } catch (err) {
      console.log(err);
    } finally {
      setApproveLoading(false);
    }
  };
  async function advertiserInstantBooking() {
    setApproveLoading(true);
    if (newEdit) {
      WebSocket.emit("instant-approval-edit", {
        campaignRequestId: campaignRequestId,
      });
      console.log('instant-approval-edit called ////////////////////');
    } else {
      WebSocket.emit("instant-approval", {
        campaignRequestId: campaignRequestId, 
      });
    }
    
    WebSocket.on("success-instant-approval", async () => {
      alert("Instant Campaign scheduled");
      WebSocket.off("instant-approval");
    });
    WebSocket.on("failed-instant-approval", async () => {
      alert("Campaign failed");
      WebSocket.off("instant-approval");
    });
    WebSocket.on("campaignNotFound-instant-approval", async () => {
      alert("Not Found");
      WebSocket.off("instant-approval");
    });
    setApproveLoading(false);
  }

  const handleRejectInstant = async () => {
    setRejectLoading(true);
    try {
      if (newEdit) {
        const Reject = await Axios.post("instantEditReject", {
          campaignRequestId: campaignRequestId,
        });
        if (Reject.status === 200) {
          alert("Request to edit campaign rejected");
        } else {
          alert("Something went wrong, can't edit reject request");
        }
      } else {
        const Reject = await Axios.post("instantReject", {
          campaignRequestId: campaignRequestId,
        });
        if (Reject.status == 200) {
          alert("Request Rejected");
        } else {
          alert("Something went wrong");
        }
        console.log("rejected");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRejectLoading(false);
    }
  };
  const approveClick = async () => {
    setApproveLoading(true);
    try {
      let approveRes;
      if (newEdit) {
        // approveRes = await Axios.post("editrequestApproved", {
        //   campaignRequestId: campaignRequestId,
        // });
        WebSocket.emit("instant-approval-edit", {
          campaignRequestId: campaignRequestId,
        });

        WebSocket.on("success-instant-approval", async () => {
          alert("Campaign Edit Approved");
          WebSocket.off("instant-approval");
        });
        WebSocket.on("failed-instant-approval", async () => {
          alert("Campaign edit failed");
          WebSocket.off("instant-approval");
        });
        WebSocket.on("campaignNotFound-instant-approval", async () => {
          alert("Not Found");
          WebSocket.off("instant-approval");
        });
      } else {
        approveRes = await Axios.post("requestApproved", {
          campaignRequestId: campaignRequestId,
        });
        console.log(approveRes);
        if (approveRes?.status === 200) {
          alert("Approved");
          window.location.reload();
        } else {
          alert("Something went wrong.");
        }
      }
    } catch (e) {
      alert("Server Error. Try again later");
    } finally {
      setApproveLoading(false);
    }
  };

  const modalStyles = {
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      position: "relative",
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      border: "none",
      borderRadius: "8px",
      padding: "0",
      width: "calc(100% - 300px)", // Adjust the value as needed
      maxWidth: "800px", // Adjust the value as needed
      maxHeight: "calc(100% - 300px)", // Adjust the value as needed
      margin: "200px",
      background: "#000",
    },
  };

  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  return (
    <>
      <div className="p-4 h-[460px] w-[312px] rounded-xl border-2 shadow-lg bg-white mb-8 flex flex-col justify-between mx-4">
        <div>
          <div className="w-[280px] h-[180px] overflow-hidden rounded-lg border-2">
            <img
              src={thumbnail_for_content_to_be_played}
              alt="img"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="mt-4">
            <h4 className="text-xl font-bold">{campaignName}</h4>
            <p></p>
            <h5 className="text-md text-gray-500 mt-2">
              <span className="text-black font-medium">Campaign ID: </span>
              {campaignRequestId}
            </h5>
            <div className="mt-2 text-md text-gray-500">
              {/* <p>Req To: {selectedDisplayProviderId}, </p> */}
              <p>
                <span className="text-black font-medium">Advertisier ID:</span>{" "}
                {fromUser}
              </p>
            </div>
          </div>
        </div>
        <div>
          {status === "Pending" && (
            <div className="flex gap-2">
              <button className={buttonStyle} onClick={handleView}>
                View
              </button>
              <button disabled={approveLoading} className={buttonStyle} onClick={approveClick}>
                Approve
              </button>
              <button disabled={rejectLoading} className={buttonStyle} onClick={handleReject}>
                Reject
              </button>
            </div>
          )}
          {status === "Approved" && (
            <div className="down">
              <button className={buttonStyle} onClick={handleView}>
                View
              </button>
            </div>
          )}
          {status === "Rejected" && (
            <div className="down">
              <button className={buttonStyle} onClick={handleView}>
                View
              </button>
            </div>
          )}
          {status === "Expired" && (
            <div className="down">
              <button className={buttonStyle} onClick={handleView}>
                View
              </button>
            </div>
          )}
          {status === "Instant" && (
            <div className="flex gap-2">
              <button className={buttonStyle} onClick={handleView}>
                View
              </button>
              <button
                disabled={approveLoading}
                className={buttonStyle}
                onClick={advertiserInstantBooking}
              >
                Approve
              </button>
              <button disabled={rejectLoading} className={buttonStyle} onClick={handleRejectInstant}>
                Reject
              </button>
            </div>
          )}
        </div>

        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Video Modal"
          className="modal"
          style={modalStyles}
          ariaHideApp={false}
        >
          <ReactPlayer
            url={content_to_be_played}
            playing={modalIsOpen}
            controls={true}
            width="100%"
            height="100%"
          />
          <button className="close-button" onClick={closeModal}>
            <span className="close-cross"></span>
          </button>
        </ReactModal>
      </div>
    </>
  );
};

export default Box;
