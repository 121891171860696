import React, { useEffect, useState } from "react";
import Axios from "../Axios/axios";

import { useLocation, Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

function ViewDeltails() {
    const loc = useLocation();
    const params = new URLSearchParams(loc.search);
    const newEdit = params.get("newEdit");
    const campaignRequestId = params.get("campId");

    const [adData, setAdData] = useState({
        steps: ["Pending", "Pending", "Pending"],
    });

    const baseURL = "https://s3.ap-south-1.amazonaws.com/everidoor2.0/Videos/";
    const thumnailURL =
        "https://s3.ap-south-1.amazonaws.com/everidoor2.0/arshad01%40gmail.com/Thumbnails/";
    const [videoUrl, setvideoUrl] = useState(baseURL);
    const [imgUrl, setimgUrl] = useState(thumnailURL);
    const [newdata, setNewData] = useState([]);
    const [screens, setScreens] = useState([]);

    useEffect(() => {
        async function fetchDetails() {
            const token=localStorage.getItem("token");
            await Axios.get(
                `advertiser/getParticularAdvertiser?campaignRequestId=${campaignRequestId}&newEdit=${newEdit}`,{headers: { Authorization: `AdminEveridoorLogin` },}
            ).then((res) => {
                console.log(res.data.result);
                setAdData(res.data.result);
                setvideoUrl(res.data.result.content_to_be_played);
                setimgUrl(res.data.result.thumbnail_for_content_to_be_played);
                setNewData(res.data.result);
                setScreens(res.data.result.selectedScreens);
            });
        }
        fetchDetails();
    }, []);

    const updateStatus = async (status, step) => {
        console.log(status, step);

        await Axios.post("updateAStep", {
            username: sessionStorage.getItem("username"),
            step: step,
            campaignRequestId: campaignRequestId,
            status: status,
        })
            .then((res) => {
                console.log(res);
                if (res.status == 200) {
                    const newSteps = [...adData.steps];
                    newSteps[step - 1] = status ? "Approved" : "Rejected";
                    setAdData({
                        ...adData,
                        steps: newSteps,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
        
            <div className="h-auto  w-full p-10 gap-3 bg-gray-100  flex flex-col">
               
                <div className="p-3 h-auto w-[100%] shadow-md bg-white">
                    <div className="w-[100%] gap-2 flex">
                        <div className="border w-2/3 h-[500px]">
                            <ReactPlayer
                                url={videoUrl}
                                controls={true}
                                width="100%"
                                height="100%"
                            />
                        </div>
                        <div className="border w-[35%] h-auto ">
                            <img
                                src={imgUrl}
                                alt=""
                                width="100%"
                                height="auto"
                                className=" overflow-hidden"
                            />
                        </div>
                    </div>
                    <div className="w-full h-full ">
                        <div className="border p-3 uppercase text-black w-full h-full ">
                            <h1 className="font-bold mb-3">USER DETAILS</h1>
                            <p className="font-bold">
                                username:{" "}
                                <span className="font-medium">
                                    {newdata.username}
                                </span>
                            </p>
                            <p className="font-bold">
                                campaignName:{" "}
                                <span className="font-medium">
                                    {newdata.campaignName}
                                </span>
                            </p>
                            <p className="font-bold">
                                campaignRequestId:{" "}
                                <span className="font-medium">
                                    {newdata.campaignRequestId}
                                </span>{" "}
                            </p>
                            <p className="font-bold">
                                fromDate:{" "}
                                <span className="font-medium">
                                    {newdata.fromDate}
                                </span>{" "}
                            </p>
                            <p className="font-bold">
                                toDate:{" "}
                                <span className="font-medium">
                                    {newdata.toDate}
                                </span>{" "}
                            </p>
                            <p className="font-bold">
                                fromTime:{" "}
                                <span className="font-medium">
                                    {" "}
                                    {newdata.fromTime}
                                </span>
                            </p>
                            <p className="font-bold">
                                toTime:{" "}
                                <span className="font-medium">
                                    {newdata.toTime}
                                </span>{" "}
                            </p>
                            <p className="font-bold">
                                fromUser:{" "}
                                <span className="font-medium">
                                    {newdata.fromUser}
                                </span>{" "}
                            </p>
                            <p className="font-bold">
                                toUser:{" "}
                                <span className="font-medium">
                                    {" "}
                                    {newdata.toUser}
                                </span>
                            </p>
                            <p className="font-bold">
                                id:{" "}
                                <span className="font-medium">
                                    {newdata._id}
                                </span>{" "}
                            </p>
                            <p className="font-bold">
                                status:{" "}
                                <span className="font-medium">
                                    {newdata.status}
                                </span>{" "}
                            </p>
                            <p className="font-bold">
                                duration:{" "}
                                <span className="font-medium">
                                    {newdata.duration}
                                </span>{" "}
                            </p>

                            <h1 className="mt-3 mb-3 font-bold">
                                Screens Details
                            </h1>
                            <div className="border">
                                <table className="w-[1451px] border-collapse bg-white text-sm justify-evenly text-gray-500 overflow-scroll">
                                    <thead className="bg-gray-50 table-header capitalize">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900"
                                            >
                                                S/No.
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900"
                                            >
                                                screenId
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900"
                                            >
                                                screenName
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900"
                                            >
                                                type
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900"
                                            >
                                                type_val
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900"
                                            >
                                                username
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900"
                                            >
                                                Address
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center bg-white">
                                        {screens.map((users, i) => (
                                            <tr key={i} className="">
                                                <td className="py-2">
                                                    {i + 1}{" "}
                                                </td>
                                                <td className="py-2">
                                                    {users.screenId}{" "}
                                                </td>
                                                <td className="py-2">
                                                    {users.screenName}{" "}
                                                </td>
                                                <td className="py-2">
                                                    {users.type}{" "}
                                                </td>
                                                <td className="py-2">
                                                    {users.type_val}{" "}
                                                </td>
                                                <td className="py-2">
                                                    {users.username}{" "}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewDeltails;
