import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import Axios from "../../Components/Axios/axios";
import { ToastContainer } from "react-toastify";
import DpHeader from "../../Components/Header/DpHeader";
import ViewerMenu from "../Menus/ViewerMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";

export default function Dashboard() {
    const [dpdata, setDpData] = useState([]);
    console.log(dpdata);

    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [dpdataPayment, setDpDataPayment] = useState({});

    useEffect(() => {
        setUsername(sessionStorage.getItem("username")?.split("@")[0]);
    }, []);
    const newusername = localStorage.getItem("Admin")
    console.log(username);
    useEffect(() => {
        async function fetchData() {
            try {
                console.log(username);
                const response = await Axios.get(`getAllDashboardDataOfDN?username=${newusername}`);
                setDpData(response.data.saduleData);
                setDpDataPayment(response.data);
                console.log("dp response", response);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }

        fetchData();
    }, []);
    return (
        <div className="h-screen  flex w-screen ">
            <ViewerMenu />
            <div className="h-screen w-screen  overflow-hidden bg-[#FFFDF9]">
                <ToastContainer />
                <DpHeader />
                <div className="w-[98%] h-[12%]  mx-3 my-6 shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]  flex gap-4">
                    <div className="w-[100%] h-[100%] bg-white  rounded-sm flex   gap-4 ">
                        <div className="w-[100%]  flex m-4 items-start divide-x-2 divide-dashed ">
                            <div className=" flex flex-col gap-2 w-[80%] ">
                                <p>Active Screens</p>
                                <p className="text-[#FF8E00] text-[24px] font-medium">{dpdataPayment.ActiveScreen}</p>
                            </div>
                            <div className="flex flex-col gap-2 w-[80%]">
                                <p className="mx-4">InActive Screens</p>
                                <p className="text-[#9D9D9D] text-[24px] font-medium mx-4">{dpdataPayment.UnActiveScreen}</p>
                            </div>
                            <div className="flex flex-col gap-2 w-[80%]">
                                <p className="mx-4">Total Screens</p>
                                <p className="text-[#555555] text-[24px] font-medium mx-4">{dpdataPayment.totalScreen}</p>
                            </div>
                            <div className="flex flex-col items-center justify-center  gap-2 w-[50%] h-[100%]">
                                <span className="bg-[#FFE8CC] cursor-pointer flex items-center justify-center h-[60%] w-[80%]  mx-4 rounded-sm text-[#FF8E00]">
                                    View All ({dpdataPayment.totalScreen})
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' m-3 shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%] bg-white  h-[60%] w-[98%] '>
                    <div className=' m-3  bg-white   w-[98%] flex justify-between'>
                        <h1 className='p-4 text-[20px] text-[#555555]'>Recent Schedules</h1>
                        <h1 className='p-4 pr-6 text-xl'> <BiDotsVerticalRounded /></h1>
                    </div>
                    <table className="w-[100%]   ">
                        <tr className='flex justify' >
                            <th className='w-[60%] text-left text-[#555555] ml-11'>Screen ID</th>
                            <th className='w-[10%] text-left text-[#555555] ml-20'>Ad Type </th>
                            <th className='w-[10%] text-left text-[#555555]'>Screen Name</th>
                            <th className='w-[20%] text-left text-[#555555] ml-11'>Date&Time</th>
                        </tr>
                        <hr className=' w-[95%]  mx-5   my-4' />
                        {dpdata.map((item, index) => (
                                        <tbody key={index}> 
                                            <tr className='flex mt-4'>
                                                <td className='w-[60%] text-left ml-11'>
                                                    {item.screenId}
                                                </td>
                                                <td className='w-[10%] text-left ml-20'>
                                                    {item.bookingtype}
                                                </td>
                                                <td  className='w-[10%] text-left '>
                                                    {item.screenName}
                                                </td>
                                                <td className='w-[20%] text-left  ml-11'>
                                                    {item.date}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                        
                    </table>


                </div>

            </div>
        </div>
    );
}
