import React, { useEffect, useRef, useState } from "react";
import Header from "../../Header/AdHeader";
import { useNavigate } from "react-router-dom";
import Axios from "../../Axios/axios";
import { ThreeDots } from "react-loader-spinner";
import { MdAccountBalanceWallet, MdAddToQueue } from "react-icons/md";
import { RxCalendar } from "react-icons/rx";
import Menu from "../Menu";
import { IoChevronForwardOutline } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import admap from "../../Images/Admap.png";
import MainStripe from "../Pages/MainStripe";
import MapComponent from "../Ad_first/MapComponent";

function loadScript(src) {
  
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const menu = [
  {
    title: "Create Ads",
    icons: <MdAddToQueue size={24} />,
    to: "/advertiser/CreateCampaign",
  },
  {
    title: "View",
    icons: <RxCalendar size={24} />,
    to: "/advertiser/ViewCampaign",
  },

  {
    title: "Account",
    icons: <MdAccountBalanceWallet size={24} />,
    to: "/advertiser/Account",
  },
];

export const Ad_th = ({
  Ad,
  setAd,
  prevStep,
  nextStep,
  addContent,
  setPaymentId,
  setOrderId,
  setScreenSelected,
  screenSelected,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  load,
  setLoad,
  duration,
  noOfDays,
  instant,
  addInstantContent,
  slots,
  setSigned,
  saveLocally,
  setIndex,
}) => {

  const [locations, setLocations] = useState([]);
  console.log('location data need to check screen type', locations);
  const data = {
    Delhi: [28.7041, 77.1025],
    "Andhra Pradesh": [15.9129, 79.74],
    "Arunachal Pradesh": [28.218, 94.7278],
    Assam: [26.2006, 92.9376],
    Bihar: [25.0961, 85.3131],
    Chhattisgarh: [21.2787, 81.8661],
    Goa: [15.2993, 74.124],
    Gujarat: [22.2587, 71.1924],
    Haryana: [29.0588, 76.0856],
    "Himachal Pradesh": [31.1048, 77.1734],
    Jharkhand: [23.6102, 85.2799],
    Karnataka: [15.3173, 75.7139],
    Kerala: [10.8505, 76.2711],
    "Madhya Pradesh": [22.9734, 78.6569],
    Maharashtra: [19.7515, 75.7139],
    Manipur: [24.6637, 93.9063],
    Meghalaya: [25.467, 91.3662],
    Mizoram: [23.1645, 92.9376],
    Nagaland: [26.1584, 94.5624],
    Odisha: [20.31836, 85.8192],
    Punjab: [31.1471, 75.3412],
    Rajasthan: [27.0238, 74.2179],
    Sikkim: [27.533, 88.5122],
    "Tamil Nadu": [11.1271, 78.6569],
    Telangana: [18.1124, 79.0193],
    Tripura: [23.9408, 91.9882],
    "Uttar Pradesh": [26.8467, 80.9462],
    Uttarakhand: [30.0668, 79.0193],
    "West Bengal": [22.9868, 87.855],
  };

  let types = [
    "Hotels",
    "Restaurants",
    "Spa and Fitness Centers",
    "Clubhouse",
    "Course",
    "Pro Shop",
    "Airport Lounges",
    "Others",
  ];
  const [totalScreens, setTotalscreens] = useState(0);
  console.log(totalScreens);
  
  const [center, setCenter] = useState([]);
  const [city, setCity] = useState("");
  const [radius, setRadius] = useState(300);
  const [type, setType] = useState("");

  const handleFromDateChange = async (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = async (e) => {
    setToDate(e.target.value);
  };
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };
  const [message, setMessage] = useState("");
  const keys = Object.keys(data);
  const placeSelect = useRef();
  const [loading, setLoading] = useState(false);
  const [screensLoading, setScreensLoading] = useState(false);

  function CircleAnimation() {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="circleAnimation" style={{ animationDelay: "0s" }}></div>
        <div
          className="circleAnimation"
          style={{ animationDelay: "0.7s" }}
        ></div>
        <div
          className="circleAnimation"
          style={{ animationDelay: "1.4s" }}
        ></div>
        <div
          className="circleAnimation"
          style={{ animationDelay: "2.1s" }}
        ></div>
        <div
          className="circleAnimation"
          style={{ animationDelay: "2.8s" }}
        ></div>
        <div
          className="circleAnimation"
          style={{ animationDelay: "3.5s" }}
        ></div>
      </div>
    );
  }

  const [Amount, setAmount] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedScreens, setSelectedScreens] = useState([]);

  const calculateTotalAmount = () => {
    const total = selectedScreens.reduce((sum, item) => sum + item.price, 0);
    setTotalAmount(total);
  };
  useEffect(() => {
    calculateTotalAmount();
  }, [Amount]);

  useEffect(() => {
    const increment = () => {
      setTotalscreens(Amount.length);
    };
    increment();
  }, [totalScreens, Amount]);

  
  console.log('selected screens from brands--------->',selectedScreens,"-------------------------");
  function selectScreens(screen) {
    const removeItem = () => {
      const updatedData = selectedScreens.filter(
        (item) => item.screen_code !== screen.screen_code
      );
      setSelectedScreens(updatedData);
    };
    const removeAmount = () => {
      const updatedData = Amount.filter(
        (item) => item.screen_code !== screen.screen_code
      );
      setAmount(updatedData);
    };
     console.log(screen,"sdasasfsdfsdgsdgdfgdfhfh");
     console.log("screen code :","screen.CPS",screen.CPS,"slots",slots,"noOfDays",noOfDays,"screen.TotalLoopCount",screen.TotalLoopCount);

    if (screen.checked === true) {
      removeItem();
      removeAmount();
    } else {
      const loc = locations.find(item => item.screen_code === screen.screen_code);
      const index = loc ? loc.ind_val : null;
      console.log("this is your fetched index-------->", index);
      const screenobj = { ...screen, index: index };
      setSelectedScreens((prevselectedScreens) => [
        ...prevselectedScreens,
        screenobj,
      ]);
      console.log("this is selected screen data -------->", selectedScreens);
      setAmount((prevAmount) => [
        ...prevAmount,
        {
          screen_code: screen.screen_code,
          amount: screen.CPS * slots * screen.TotalLoopCount,
        },
      ]);
    }
  }

  useEffect(() => {
  }, [selectedScreens, Amount]);

  const [checkboxes, setCheckboxes] = useState(
    
    locations.map((item) => ({
      screen_code: item.screen_code,
      name: item.name,
      username: item.username,
      type: item.type,
      type_val: item.type_val,
      CPS: item.CPS,
      // duration: item.duration,
      price: item.price,
      TotalLoopCount: item.loops,
      checked: false,
      // screenType:item.screenType,
    }))
  );


  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    console.log("selectall clicked", checkboxes);
    if (selectAll === false) {
      setSelectAll(true);
      setSelectedScreens([]);
      setAmount([]);
      for (let i = 0; i < checkboxes.length; i++) {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[i].checked = true;
        setCheckboxes(updatedCheckboxes);
      }
      setSelectedScreens(checkboxes);
      for (let i = 0; i < checkboxes.length; i++) {
        setAmount((prevAmount) => [
          ...prevAmount,
          {
            screen_code: checkboxes[i].screen_code,
            amount:
              checkboxes[i].CPS *
              slots *
              checkboxes[i].TotalLoopCount,
          },
        ]);
      }
    } else {
      setSelectAll(false);
      for (let i = 0; i < checkboxes.length; i++) {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[i].checked = false;
        setCheckboxes(updatedCheckboxes);
      }
      setSelectedScreens([]);
      setAmount([]);
    }
  };
  useEffect(() => {
    console.log("location details for schedddules", locations.map((item) => ({
      CPS: item.CPS,
      loops: item.loops,
        })));
        console.log(slots,noOfDays,"slots and no of days ------------------- ++++++++++++ ");
    setCheckboxes(
      locations.map((item) => ({
        screen_code: item.screen_code,
        name: item.name,
        username: item.username,
        type: item.type,
        price: item.loops*item.CPS*slots,
        duration: item.duration,
        type_val: item.type_val,
        CPS: item.CPS,
        address: item.address,
        coordinates: item.coordinates,
        TotalLoopCount: item.loops,
        checked: false,
        screenType:item.screenType,
      }))
    );
  }, [locations]);

  useEffect(() => {}, [checkboxes, selectAll]);
  useEffect(() => {
    setScreenSelected(selectedScreens);
    console.log(selectedScreens);
  }, [selectedScreens]);

  const toggleChecked = (i) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[i].checked = !updatedCheckboxes[i].checked;

    setCheckboxes(updatedCheckboxes);
  };
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const handleSubmit = (e) => {
    setButtonDisabled(true);
    e.preventDefault();

    console.log("selectedScreens", selectedScreens);
    setScreenSelected(selectedScreens);
    console.log("screenSelected", screenSelected);
    setLoading(true);
    // if (paymentDone) {
    if (instant === true) {
      console.log("Instant campaign created successfully");
      console.log(e);
      addInstantContent(e);
    } else {
      console.log("Standard campaign created successfully");
      addContent(e);
    }
    console.log(e);
  };
  const centerChange = (e) => {
    console.log(e.target.value, typeof e.target.value);
    setCenter(data[e.target.value]);
    setCity(e.target.value);
  };
  const [open, setOpen] = useState(true);
  const [location, setLocation] = useState([28.6041, 77.25]);
  const handleSearch = () => {
    console.log({
      location: city, 
      radius: radius, 
      type: type,
      fromDate: Ad.fromDate,
      toDate: Ad.toDate,
      duration: duration,
    });
    if (city === "") {
      alert("Please select a city or Search for nearby screens");
    } else {
      if (instant === true) {
        fetchInstantData();
      } else {
        fetchData();
      }
    }
  };

  const handleReset = (e) => {
    setMessage("");
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  console.log(
    token,
    "-------------------------------------+++++++++++++++++++++++++++++"
  );
  async function fetchData() {
    setScreensLoading(true);
    await Axios.post(
      "advertiser/getScreensForLocation",
      {
        location: city, //replace with location from dropdown
        radius: radius, //replace with radius from dropdown
        type: type,
        fromDate: Ad.fromDate,
        toDate: Ad.toDate,
        user_slots: slots,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    ).then((res) => {
      setLocations(res.data);
      console.log(res.data,"shudule data-------------------------");
      setScreensLoading(false);
    });
 
  }
  const currentTimeWithoutSeconds = new Date().toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' });
  console.log("time------------",currentTimeWithoutSeconds);
  async function fetchInstantData() {
    const token = localStorage.getItem("token");
    setScreensLoading(true);
    try {
      const response = await Axios.post(
        "advertiser/getInstantlyAvailableScreens",
        {
          location: city,
          radius: radius,
          type: type,
          time: currentTimeWithoutSeconds,
          user_slots:slots,
          fromDate: fromDate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("instant data", response.data, "----------------------");
      setLocations(response.data);

      if (response.status === 401) {
        console.log("Unauthorized: ", response?.data.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setScreensLoading(false);
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }

    const data = await fetch("https://j3hg2gqz-4000.inc1.devtunnels.ms/makePayment",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: totalAmount * 100 }),
      }
    ).then((t) => t.json());

    console.log("data log", data);
    console.log(data.orderId);
    setOrderId(data.orderId);
    // console.log(orderId);

    const options = {
      key: "rzp_test_1D4IBcX4yO8hNy", 
      amount: totalAmount * 100,
      currency: "INR",
      name: "Everidoor",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: data.order_id,
      handler: function(response) {
        console.log(response);
        setPaymentId(response.razorpay_payment_id);
        nextStep();
      },
   
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#fe6b31",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handlePay = () => {
    navigate("/pay");
  };

  const [checked, setChecked] = useState(false);
  const handleChecked = (e) => {
    const checked = e.target.checked;
    setChecked(checked);
  };

  const [checkout, setCheckout] = useState(true);
  function handleCheckout() {
    setCheckout((prevcheckout) => !prevcheckout);
  }

  const [currentLocation, setCurrentLocation] = useState([]);
  const getLocation = async () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude,
        ]);
        
        setCity([
          position.coords.latitude.toString(),
          position.coords.longitude.toString(),
        ]);
      });
    } else {
      console.log("geolocation not available");
    }
  };

  const [screensForMap, setScreensForMap] = useState([[{id: 1, coordinates: [28.6041, 77.25] }, {id:2, coordinates: [28.6002, 77.2270]}, {id: 3, coordinates: [28.29, 77.4]}]]);
  async function fetchScreenMapData() {
    console.log("fetching data for maps");
    try {
      const response = await Axios.get("screenDataApiforMap");
      setScreensForMap(response.data);
      console.log("your data for maps is: ", screensForMap);
    } catch (error) {
      console.log("unable to fetch screen data for maps");
    }
  }

  useEffect(() => {
    getLocation();
    fetchScreenMapData();
  }, []);

  const [isParentModalOpen, setIsParentModalOpen] = useState(false);
  const openParentModal = () => {
    setIsParentModalOpen(true);
  };

  const closeParentModal = () => {
    setIsParentModalOpen(false);
  };

  return load ? (
    <div className="flex items-center justify-center h-[80vh] w-full">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#fe4d00"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  ) : (
    <>
      <div className="flex h-screen w-full  overflow-hidden overflow-x-scroll bg-gray-100">
        <Menu menu={menu} open={open} />

        <div
          className={`feature flex-1   select-none  h-screen   ${open ? "w-[70%]" : "w-full"}`}
        >
          <div className="border bg-[white]">
            <Header />
          </div>
          <div
            className={`h-[76%] w-full pb-10 ${
              checkout
                ? "overflow-hidden"
                : "overflow-y-scroll overflow-x-hidden"
            }`}
          >
            <div className="flex items-center justify-between p-5">
              <div className="flex items-center ">
                Home <IoChevronForwardOutline /> Create Ads{" "}
                <IoChevronForwardOutline /> Select Display
              </div>
              <div className="">
                <span>Step 3/3</span>
              </div>
            </div>
            <div className="">
              <div className="">
                <form onSubmit={handleSubmit}>
                  {checkout ? (
                    <div className="w-full">
                      <h1 className="mb-3 px-6 text-[24px] font-normal">
                        Select Display Screens {instant ? "Instant" : ""}{" "}
                      </h1>
                      <div className="">
                        <div className="">
                          <form>
                            <div className="flex justify-between items-center px-6">
                              <div className="flex gap-2">
                                <select
                                  className="border pl-[10px] pr-[50px] py-[10px] rounded-full outline-none"
                                  ref={placeSelect}
                                  name="place"
                                  id="place"
                                  value={Ad.map.place}
                                  onChange={(e) => {
                                    centerChange(e);
                                    setAd({
                                      ...Ad,
                                      map: {
                                        ...Ad.map,
                                        place: e.target.value,
                                      },
                                    });
                                  }}
                                  required
                                >
                                  <option className="text-[#8E8E8E]" value="">
                                    Select city
                                  </option>
                                  {keys.map((elem) => {
                                    return (
                                      <option
                                        className="text-[#8E8E8E]"
                                        value={elem}
                                      >
                                        {elem}
                                      </option>
                                    );
                                  })}
                                </select>

                                <select
                                  className="border text-left pl-[10px] pr-[100px] py-[10px] rounded-full outline-none"
                                  name="type"
                                  id="type"
                                  value={type}
                                  onChange={(e) => setType(e.target.value)}
                                  required
                                >
                                  <option className="text-[#8E8E8E]" value="">
                                    Select Type
                                  </option>
                                  {types.map((elem) => (
                                    <option
                                      className="text-[#8E8E8E]"
                                      value={elem}
                                    >
                                      {elem}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  className="border px-[10px] py-[8px] rounded-full outline-none"
                                  type="number"
                                  name="distance"
                                  id="distance"
                                  placeholder="Radius (km)"
                                  value={Ad.map.distance}
                                  onChange={(e) => {
                                    setRadius(e.target.value);
                                    setAd({
                                      ...Ad,
                                      map: {
                                        ...Ad.map,
                                        distance: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <div className="flex  w-80 gap-2">
                                <button
                                  type="button"
                                  className="bg-[#FF4D00] hover:bg-[#ff8957] text-white xl:px-[24px]  xl:py-[12px] rounded-full font-bold"
                                  onClick={(e) => {
                                    handleSearch(e);
                                  }}
                                >
                                  Search
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    getLocation();
                                    handleSearch();
                                  }}
                                  className="border-[#FF4D00] border-2 text-sm border-solid font-bold hover:bg-[#fe4d00]  text-[#FF4D00] hover:text-white py-2 xl:px-8  rounded-full"
                                >
                                  Show Nearby screens
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="flex w-full flex-col">
                          <div className=" w-full flex justify-between items-center px-16 py-2">
                            <div className="text-[#555555]">
                              (Available Screens In {city.toString()}
                              :&nbsp;
                              <span className="font-bold">
                                {locations.length})
                              </span>
                            </div>
                            <div>Select All</div>
                          </div>
                          <div className="w-full h-[53vh] overflow-y-scroll">
                            <table className="border-spacing-y-4 text-left border-separate w-full px-6">
                              <thead className="">
                                <tr className="border bg-[#DEDEDE] h-14 rounded-full">
                                  
                                  <th className="rounded-l-full pl-8 w-[10%]">Screen Name</th>
                                
                                  <th className="w-[10%]">Type</th>
                                  <th className="w-[20%]">Location</th>
                                  <th className="w-[30%]">Address</th>
                                  <th className="">CPS</th>
                                  <th className="">Loops</th>
                                  <th className="pl-2">Price</th>
                                  <th className=" rounded-r-full pr-4 pt-2">
                                    {" "}
                                    <input
                                      type="checkbox"
                                      checked={selectAll}
                                      onChange={handleSelectAll}
                                      className="w-6 h-6 accent-[#FF4D00]"
                                    />
                                  </th>
                                </tr>
                              </thead>
                              {screensLoading ? (
                                <div className="relative left-[480%]">
                                  <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#fe4d00"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : checkboxes.length ? (
                                <tbody className="w-full">
                                  {checkboxes.map((elem, i) => (
                                    <tr
                                      className="text-left ml-4 h-14 border mb-3 mt-3"
                                      key={i}
                                    >
                                     
                                      <td className="rounded-l-full  pl-8 bg-white">{elem.name}</td>
                                     
                                      <td className=" bg-white">{elem.type}</td>
                                      <td className=" bg-white">
                                        {elem.type_val}
                                      </td>
                                      <td className=" bg-white">
                                        {elem.address}
                                      </td>
                                      <td className="pl-4 bg-white">
                                        {elem.CPS}
                                      </td>
                                      <td className="pl-2 bg-white">
                                      {elem.TotalLoopCount}                   
                                      </td>
                                      <td className="pl-2 bg-white">
                                      ₹ {elem.price}
                                      </td>
                                      <td className="rounded-r-full bg-white pr-4 pt-2">
                                        <label className="relative cursor-pointer">
                                          <input
                                            checked={elem.checked}
                                            type="checkbox"
                                            key={i}
                                            value={elem}
                                            className="w-6 h-6 accent-[#FF4D00]"
                                            onClick={(e) => {
                                              selectScreens(elem);
                                            }}
                                            onChange={(e) => {
                                              handleChecked(e);
                                              toggleChecked(i);
                                            }}
                                          />
                                        </label>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={8}
                                      className="bg-white rounded-full py-4 px-4"
                                    >
                                      Please select a city or search for nearby
                                      screens...
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-[100%] flex flex-col justify-center">
                      <div className="flex items-center justify-between h-20 px-6">
                        <div className="">
                          <h1 className="text-[#001122] xl:text-[24px]">
                            Preview Screens & Checkout
                          </h1>
                        </div>
                        <div className="flex gap-4">
                          <div className="border shadow-md text-[#001122] border-gray-100 rounded-md h-[73px] w-[200px] flex items-center text-[20px] justify-center font-bold bg-white ">
                            <p className="text-[#FF4D00] mx-2 font-normal">
                              {selectedScreens.reduce((sum, item) => sum + item.TotalLoopCount, 0)}
                            </p>{" "}
                            Loops
                          </div>
                          <div className="border shadow-md text-[#001122] border-gray-100 rounded-md h-[73px] w-[200px] flex items-center text-[20px] justify-center font-bold bg-white ">
                            <p className="text-[#FF4D00] mx-2 font-normal">
                              {slots}
                            </p>{" "}
                            Slots
                          </div>
                        </div>
                      </div>
                      <div className="px-6">
                        <h1 className=" text-[#555555] ">
                          (Selected Displays: &nbsp;
                          {selectedScreens.length})
                        </h1>
                      </div>

                      <div className="px-6">
                        <table className=" border-spacing-y-4  border-separate w-full">
                          <thead>
                            <tr className=" bg-[#DEDEDE] h-14 rounded-full">
                             
                              <th className=" rounded-l-full pl-3 ">Screen Name</th>
                              <th>Address</th>
                              <th>CPS</th>
                              <th>Loops</th>
                              <th className="rounded-r-full pr-6">Amount</th>
                            </tr>
                          </thead>
                          <tbody className="full">
                            {Array.isArray(selectedScreens) &&
                            selectedScreens.length > 0 ? (
                              selectedScreens.map((screenData, index) => (
                                <tr
                                  className="text-center h-14 border mb-3 mt-3"
                                  key={index}
                                >
                                 
                                  <td className="rounded-l-full bg-white">
                                    {screenData.name}
                                  </td>
                                  <td className="bg-white text-sm py-1">
                                    {screenData.address}
                                  </td>
                                  <td className="bg-white">{screenData.CPS}</td>
                                  <td className="bg-white">
                                    {screenData.TotalLoopCount}
                                  </td>
                                  <td
                                          className="  rounded-r-full bg-white"
                                          key={index}
                                        >
                                          {screenData.price}
                                        </td>
                                  
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={3}>No screens selected</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      {/*  <div
                          style={{
                            height: "70%",
                            width: "100%",
                          }}
                          className=" m-2 bg-white rounded-lg shadow-md border-gray-100 relative"
                        >
                          <div className="h-[10%] p-2 ">
                            <div className="p-3">
                              <img src={admap} alt="Everidoor" />
                            </div>
                            <div className="flex items-center gap-2">
                              <h1 className="text-lg font-bold">
                                Selected Display Locations
                              </h1>{" "}
                              <FaArrowRight />
                            </div>
                          </div>
                          <div className="text-gray-400 ml-2 ">
                            <h1>
                              Explore Maps to finds our selected display to be
                              advertiser.
                            </h1>
                          </div>
                          <div
                            style={{
                              height: "74vh",
                              width: "100%",
                            }}
                            className="p-3 rounded-md"
                          >
                            <MapComponent screens={screensForMap} />
                            <div
                              className={`${
                                loading
                                  ? "bg-black/40 h-[97%] w-[98%] absolute z-20 myOverlay"
                                  : ""
                              }`}
                            />
                           
                          </div>
                        </div>
*/}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          {checkout ? (
            <div className="flex bg-white px-8 pt-4 pb-8  border items-center justify-between">
              <div>
                <button
                  className="border bg-[#FF4D00] hover:bg-[#ff8957] text-white py-2 px-10 rounded-full"
                  onClick={() => {
                    prevStep();
                  }}
                >
                  Back
                </button>
              </div>
              <div className="flex items-center">
                <div className="flex mr-10 w-auto gap-4">
                  <div>
                    <span className="text-[16px]">
                      No. of Displays:{" "}
                      <span className="text-[#000000] text-[16px] font-bold">
                        {totalScreens}
                      </span>{" "}
                    </span>
                  </div>
                  <div>
                    <span className="text-[16px]">
                      Total Campaign Cost:{" "}
                      <span className="font-extrabold">{totalAmount}</span>
                    </span>
                  </div>
                </div>
                
                <div className="flex gap-4 ml-10">
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectAll(false);
                        for (let i = 0; i < checkboxes.length; i++) {
                          const updatedCheckboxes = [...checkboxes];
                          updatedCheckboxes[i].checked = false;
                          setCheckboxes(updatedCheckboxes);
                        }
                        setSelectedScreens([]);
                        setAmount([]);
                      }}
                    >
                      Clear All
                    </button>
                    <button
                      onClick={() => handleCheckout()}
                      className="border bg-[#FF4D00] hover:bg-[#ff8957] text-white py-2 px-10 rounded-full"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
             
            </div>
          ) : (
            <div className="flex px-2 bg-white  xl:px-8 pt-4 pb-8  border items-center justify-between">
              <div>
                <button
                  className="border bg-[#FF4D00] text-sm md:text-lg hover:bg-[#ff8957] text-white py-2 xl:px-10 rounded-full"
                  onClick={() => handleCheckout()}
                >
                  Back
                </button>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex flex-col xl:flex-row items-start xl:items-center xl:gap-3">
                  <span className="xl:text-[16px] text-sm">
                    No of Display:{" "}
                    <span className="text-[#000000] text-[16px] font-bold">
                      {totalScreens}
                    </span>{" "}
                  </span>
                <div>
                  <span>
                    Total Amount:{" "}
                    <span className="font-bold"> {totalAmount}</span>
                  </span>
                </div>
                <div>

                </div>
                </div>
                  <button
                    type="button"
                    className="border border-dashed border-[#FF4D00] hover:bg-[#FF4D00] hover:text-white rounded-full p-[6px] px-2 text-sm xl:text-lg xl:px-8"
                    onClick={() => navigate("/home")}
                  >
                    Cancel
                  </button>




                <div className="flex xl:gap-2 items-center">
                  <MainStripe
                    saveLocally={saveLocally}
                    Ad={Ad}
                    Total={totalAmount}
                    selectedScreens={selectedScreens}
                    Amount={Amount}
                  />
                  <button
                    type="button"
                    className="border xl:ml-2 bg-[#FF4D00] hover:bg-[#ff8957] text-white py-1 xl:py-2 xl:px-10 rounded-full"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Ad_th;
