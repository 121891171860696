import React, { useEffect, useRef, useState, useCallback } from 'react';
import { AdvancedMarker, InfoWindow, useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const ClusteredMarkers = ({ screens }) => {
  const map = useMap();
  const [markers, setMarkers] = useState({});
  const [activeMarker, setActiveMarker] = useState(null);
  const clusterer = useRef(null);

  useEffect(() => {
    console.log("this is from clustered markers", screens);
  }, [screens]);

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker, id) => {
    if (marker && markers[id]) return;
    if (!marker && !markers[id]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [id]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[id];
        return newMarkers;
      }
    });
  };

  const handleMarkerClick = useCallback((id, position, address) => {
    console.log(position);
    setActiveMarker({ id, position, address });
  }, []);

  return (
    <>
      {screens.map((screen) => (
        screen.last_known_location.length === 2 &&
        <AdvancedMarker
          key={screen.screenId}
          position={{ lat: screen.last_known_location[0], lng: screen.last_known_location[1] }}
          ref={(marker) => setMarkerRef(marker, screen.screenId)}
          onClick={() => handleMarkerClick(screen.screenId, { lat: screen.last_known_location[0], lng: screen.last_known_location[1] }, screen.SCREEN_ADDRESS_FROM_PHONE)}
        >
          <span className='text-[2rem]'>📱</span>
        </AdvancedMarker>
      ))}
      {activeMarker && (
        <InfoWindow
          position={activeMarker.position}
          onCloseClick={() => setActiveMarker(null)}
        >
          <div className='flex flex-col gap-1'>
            <h4>Address of Screen:</h4>
            <p>{activeMarker.address}</p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default ClusteredMarkers;
