import React from 'react'
import verfiy from '../SignIn/images/verify.png';
import logo from "../Images/everydoor.png"
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Axios from '../Axios/axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Forget() {


    const notifyError = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifySuccess = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 20000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const navigate = useNavigate();

    const NavigateVerify = () => {
        navigate('/verify');
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    const [formData, setFormData] = useState({
        username: "",
    });
    const [err, SetErr] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const handleSubmitForgot = async (event) => {
        event.preventDefault();
        setOtpSent(true);
        setIsButtonDisabled(true)
        localStorage.setItem("username", formData.username);
        try {
            const response = await Axios.post("/sendOTP", formData); // Use Axios to POST data
            console.log('Response:', response.status);
            if (response.status === 200) {
                notifySuccess("OTP Send");
                setTimeout(() => {
                    // Navigate to another page after a specified time (e.g., 3 seconds)
                    NavigateVerify();
                }, 3000); // 3000 milliseconds (3 seconds)
            } else if (response.status === 202) {
                SetErr(true);
                notifyError("This Account does not Exist");
            }
            // if (response.status === 202) {
            //     SetErr(true);
            //     notifyError("This Account is not Exits");
            // }


        } catch (error) {
            console.error('Error:', error);
        }
    };



    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='w-screen h-screen flex items-center justify-center bg-[#F2F2F2]'>
                <div className='h-[85%] border-gray-300   w-[70%] bg-white  flex justify-end '>
                    <div className='flex  flex-col justify-start h-full w-[50%]'>
                        <div className="mx-12" >
                            <img src={logo} alt="evridoor" width="200px" height="100px" />
                        </div>

                        <div className=" ml-6 h-80 text-[#727272] flex flex-col justify-center ">
                            <h1 className='mx-6 my-6'>Important Information:</h1>
                            <div className='flex gap-4 flex-col justify-center h-40 items-center'>
                                <p className='mx-6'>
                                    Please read the below instructions and then kindly share the requested information.
                                </p>
                                <ul className='list-disc mr-10'>
                                    <li> Use Alphanumeric characters in passwords</li>
                                    <li> Your Email ID/ Username  is required</li>
                                    <li> Do not reuse passwords</li>
                                    <li> Do not reveal your password/ OTP to anyone.</li>

                                </ul>
                            </div>
                        </div>
                        <div className="flex text-[#727272] w-full justify-center items-center mt-56 pr-12">
                            <p>Contant Us &nbsp;&nbsp;|</p>
                            <p>&nbsp;&nbsp;Term & Conditions&nbsp;&nbsp;|</p>
                            <p>&nbsp;&nbsp;FAQ's</p>
                        </div>
                    </div>
                    <div className='  flex justify-center items-center  h-[100%]' style={{
                        backgroundImage: `url(${verfiy})`, // Set the background image
                        backgroundSize: 'cover',
                        height: '100%',
                        width: '55%',
                        
                    }}>
                        <div className=' h-[50%] w-[60%] border flex flex-col justify-evenly items-center rounded  bg-white'>
                               
                           
                            
                             <div className="w-[100%] flex flex-col justify-center items-center gap-[8px]">
                                    
                                <h1 className='text-[#555555] text-2xl font-bold'>Forget Password ?</h1>
                                <p className=' text-sm font-medium text-[#555555]'>
                                    No Worries we will send reset instructions
                                </p>
                             
                            </div>
                            <form className="w-[90%]" >
                                <div className="w-[100%] justify-center   flex flex-col">
                                    <input
                                        type="text"
                                        name="username"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="border rounded p-2  outline-none " placeholder="Enter Your Email ID" />
                                </div>
                                <div className='flex items-center'>
                                    {err ? (
                                        <span className='text-red-400 text-sm'>This Account is not Exists</span>
                                    ) : (otpSent && <span className='text-red-400 text-sm'>
                                        Check your email for the OTP</span>
                                    )}
                                </div>

                                <div className="w-[100%]  mt-7 border bg-gradient-to-r from-[#FB6600] via-[#FF9002] to-[#FB6600] ... flex items-center justify-center rounded">
                                    <button onClick={handleSubmitForgot}
                                        disabled={isButtonDisabled}
                                        className={`px-4 py-2  text-white
                                     ${isButtonDisabled ?
                                                'opacity-50 cursor-wait' : ''
                                            }`} type='submit'>

                                        Send Password
                                    </button>
                                </div>

                            </form>
                            <Link
                                to="/Signin">
                                <div className="w-[100%] gap-2 mr-3 text-[#555555] cursor-pointer flex justify-center items-center">
                                    <BiArrowBack /> Back to login
                                </div>
                            </Link>

                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Forget