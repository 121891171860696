import React, { useState, useEffect, useContext, useRef } from "react";
import logo from "../Images/everydoor.png";
import { Link, useNavigate } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";
import { LuIndianRupee } from "react-icons/lu";
import { FiChevronDown } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { useUserContext } from "../Advertise/Context/Usercontext";
import axios from "../Axios/axios";
import { IoMdNotifications } from "react-icons/io";
import { SocketContext } from "../../socketcontext";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

export default function Header() {
    const [nav, setNav] = useState(false);
    const [username, setUsername] = useState("");
    const [view, setView] = useState(false);
    const { advertiser, setAdvertiser } = useUserContext();
    const navigate = useNavigate();
    const WebSocket = useContext(SocketContext);
    const [numberOfNotifications, setNumberOfNotifications] = useState(0);

    const notifRef = useRef(null);

    useEffect(() => {
        setUsername(sessionStorage.getItem("username")?.split("@")[0]);
    }, []);

    useEffect(() => {
        const profile = async () => {
            const data = await axios.get(`/getuserdetail?username=${sessionStorage.getItem("username")}`);
            if (data.status === 200) {
                setAdvertiser(data.data.User);
            }
        };
        profile();
    }, [setAdvertiser]);

    const handleLogout = () => {
        localStorage.removeItem("token");
        sessionStorage.clear();
        navigate("/Signin");
    };

    const [notifications, setNotifications] = useState([{
        title: "No notifications",
        content: "Try exploring something new and come back here for updates!"
    }]);
    const [notif, setNotif] = useState(false);

    const handleNotificationClick = () => {
        if (!notif) {
            WebSocket.emit("sendNotificationAdvertiser");
            WebSocket.on("recieverNotificationAdvertiser", (data) => {
                const now = new Date();
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(now.getDate() - 7);

                const filteredNotifications = data.filter(notification => {
                    const notificationDate = new Date(notification.createdAt);
                    return notification.recipientType === "1" && (notificationDate >= sevenDaysAgo || notification.important === 1);
                });

                setNotifications(filteredNotifications.length > 0 ? filteredNotifications : [{
                    title: "No notifications",
                    content: "Try exploring something new and come back here for updates!"
                }]);
                setNumberOfNotifications(filteredNotifications.length);
            });
        }
        setNotif(!notif);
    };

    useEffect(() => {
        console.log("fetching from useEffect for number of notifications");
        WebSocket.emit("sendNotificationAdvertiser");
        WebSocket.on("recieverNotificationAdvertiser", (data) => {
            const now = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(now.getDate() - 7);
            const filteredNotifications = data.filter(notification => {
                const notificationDate = new Date(notification.createdAt);
                return notification.recipientType === "1" && (notificationDate >= sevenDaysAgo || notification.important === 1);
            });
            setNotifications(filteredNotifications.length > 0 ? filteredNotifications : [{
                title: "No notifications",
                content: "Try exploring something new and come back here for updates!"
            }]);
            setNumberOfNotifications(filteredNotifications.length);
        });
    }, []);

    const toggleImportant = async (id, currentImportant) => {
        const updatedImportant = currentImportant === "1" ? "0" : "1";

        setNotifications(prevNotifications => 
            prevNotifications.map(notification => 
                notification._id === id ? { ...notification, important: updatedImportant } : notification
            )
        );

        WebSocket.emit("starNotificationupdate", {
            important: updatedImportant,
            id: id
        });

        WebSocket.on("Updated-Notification-status", (response) => {
            if (response.message !== "status updated of notification") {
                setNotifications(prevNotifications => 
                    prevNotifications.map(notification => 
                        notification._id === id ? { ...notification, important: currentImportant } : notification
                    )
                );
            }
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notifRef.current && !notifRef.current.contains(event.target)) {
                setNotif(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notifRef]);

    return (
        <div>
            <div className="h-[14vh] w-full flex items-center justify-end mr-8 shadow-sm border-b border-gray-300">
                <div className="flex gap-5">
                    <div className="xl:mr-10">
                        <div className="flex xl:px-4 py-2 gap-2 rounded-[10px] cursor-pointer bg-white items-center w-auto">
                            <div className="relative">
                                <h1 className="text-[#000000] bg-red-500 rounded-full w-fit px-[0.4rem] absolute right-3 text-[0.75rem]">{numberOfNotifications}</h1>
                                <IoMdNotifications size={27} className="text-[#555555] mr-4 size-10" onClick={handleNotificationClick} />
                            </div>
                            <div className="items-center flex gap-2 justify-between w-full" onClick={() => setNav(!nav)}>
                                <div className="flex items-center gap-2">
                                    <div>
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src="https://cdn-icons-png.flaticon.com/512/236/236832.png?w=740&t=st=1690788839~exp=1690789439~hmac=4d4fa9ad6657c9817da41cf0e72d2857df6e1f320bfc8a6e75333d25bc8546b8"
                                            alt="user"
                                        />
                                    </div>
                                    <h1>{username}</h1>
                                </div>
                                <div>
                                    <button type="button">
                                        <FiChevronDown size={24} className={`${nav ? "rotate-180" : ""}`} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {nav && (
                    <div className="z-[100] absolute shadow-lg rounded-[10px] border-b border-x border-gray-300 bg-white xl:right-[2.5rem] top-24 xl:top-28 xl:w-[210px] xl:h-30 p-3 xl:p-4">
                        <ul className="text-lg text-left">
                            <li className="flex flex-col justify-start text-black cursor-pointer border-b-2 border-slate-300 transition-all duration-500">
                                <div className='text-sm'>CustomerId : {advertiser.customerId} </div>
                                <div className="flex items-center text-sm">
                                    Pin : {view ? advertiser.pin : '****'}
                                    <button type="button" className="" onClick={() => setView(!view)}>
                                        {view ? "Hide" : "View"}
                                    </button>
                                </div>
                            </li>
                            <Link to={'/profile'}>
                                <li className="flex items-center justify-start text-black gap-x-4 p-2 mt-2 rounded-md cursor-pointer transition-all duration-500 hover:bg-[#fe4d00] hover:text-white">
                                    <CgProfile size={26} />
                                    Profile
                                </li>
                            </Link>
                            <li className="flex items-center justify-start text-black gap-x-4 p-2 rounded-md cursor-pointer transition-all duration-500 hover:bg-[#fe4d00] hover:text-white">
                                <BiSupport size={26} />
                                <Link to="/Adhelp">Support</Link>
                            </li>
                            <li className="flex items-center justify-start text-black gap-x-4 p-2 rounded-md cursor-pointer transition-all duration-500 hover:bg-[#fe4d00] hover:text-white" onClick={handleLogout}>
                                <BiLogOutCircle size={26} />
                                Logout
                            </li>
                        </ul>
                    </div>
                )}
                {notif && (
                    <div ref={notifRef} className="z-[100] absolute shadow-lg rounded-[10px] drop-shadow-md bg-white right-[200px] flex flex-col gap-2 top-24 w-[480px] h-[300px] overflow-y-scroll p-3">
                        {notifications.map((notification) => (
                            <div key={notification._id} className={`flex flex-row p-4 border-b-2 border-[#FF9002] border-dotted ${notification.important === "1" ? "bg-[#ff91023a]" : "bg-white"}`}>
                                <div className="w-[80%]">
                                    <div className="text-[1.2rem] font-bold text-[#FF9002]">
                                        {notification.title}
                                    </div>
                                    <div className="w-[90%] flex pl-5">
                                        {notification.content}
                                    </div>
                                </div>
                                <div data-tooltip-id="star-tooltip" data-tooltip-content="Mark as Important: Unimportant notifications will be cleared after 7 days" onClick={() => toggleImportant(notification._id, notification.important)}>
                                    {notification.important === "1" ? <FaStar className="size-8 text-[#FF9002]" /> : <CiStar className="size-8 text-[#FF9002]"/>}
                                </div>
                                <Tooltip id="star-tooltip" style={{ 
                                        backgroundColor: "white", 
                                        color: "black", 
                                        width: "50%", 
                                        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "5px",
                                        padding: "10px"
                                    }}/>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
