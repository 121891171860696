import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import Content from "./Components/Content/Content";
import Dashboard from "./Components/Dashboard/Dashboard";
import Description from "./Components/Description";
import Signup from "./Components/Signup";
import SignIn from "./Components/SignIn";
import Inbox from "./Components/Inbox/Inbox";
import Admin from "./Components/Admin/admin";
import { SocketContext, SocketProvider } from "./socketcontext";
import Module2 from "./Components/Admin/pages/Module2";
import Module3 from "./Components/Admin/pages/Module3";
import Module4 from "./Components/Admin/pages/Module4";
import Module5 from "./Components/Admin/pages/Module5";
import CreateCampaign from "./Components/Advertise/Pages/CreateCampaign";
import ViewCampaign from "./Components/Advertise/Pages/ViewCampaign";
import Account from "./Components/Advertise/Pages/Account";
import Selector from "./Components/Selector";
import ViewDeltails from "./Components/View/ViewDeltails";
import UserDescritpion from "./Components/Advertise/Pages/userDescritpion";
import Payment from "./Components/Advertise/Pages/Payment";
import ScreensDetails from "./Components/Admin/pages/Module1";
import MyBox from "./Components/Admin/pages/NewBox";
import MyDisplays from "./Components/Dashboard/Pages/MyDisplays";
import PersonalInfo from "./Components/Dashboard/Pages/PersonalInfo";
import Finds from "./Components/Dashboard/Pages/Funds";
import Verify from "./Components/SignIn/Verfiy";
import Forgot from "./Components/SignIn/Forgot";
import NewPassword from "./Components/SignIn/NewPassword";
import Schedules from "./Components/Dashboard/Pages/Schedules";
import StatusStripe from "./Components/Advertise/Pages/StatusStripe";
import TeamMember from "./Components/Dashboard/Pages/TeamMember";
import Module6 from "./Components/Admin/pages/Module6";
import Module7 from "./Components/Admin/pages/Module7";
import Report from "./Components/Dashboard/Pages/Report";
import ManagerDashboard from "./DpMembers/Manager/ManagerDashboard";
import PublisherDashboard from "./DpMembers/Publisher/PublisherDashboard";
import ManagerScreens from "./DpMembers/Manager/ManagerScreens";
import ManagerContent from "./DpMembers/Manager/ManagerContent";
import ManagerSchedule from "./DpMembers/Manager/ManagerSchedule";
import ManagerAccount from "./DpMembers/Manager/ManagerAccount";
import ManagerTeammember from "./DpMembers/Manager/ManagerTeammember";
import ManagerDescription from "./DpMembers/Manager/ManagerDescription";
import PublisherScreens from "./DpMembers/Publisher/PublisherScreens";
import PublisherSchedule from "./DpMembers/Publisher/PublisherSchedule";
import PublisherContent from "./DpMembers/Publisher/PublisherContent";
import PublisherAccount from "./DpMembers/Publisher/PublisherAccount";
import PublisherDescription from "./DpMembers/Publisher/PublisherDescription";
import ViewerDashboard from "./DpMembers/Viewer/ViewerDashboard";
import ViewerScreens from "./DpMembers/Viewer/ViewerScreens";
import ViewerSchedule from "./DpMembers/Viewer/ViewerSchedule";
import ViewerContent from "./DpMembers/Viewer/ViewerContent";
import ViewerAccount from "./DpMembers/Viewer/ViewerAccount";
import ViewerDescription from "./DpMembers/Viewer/ViewerDescription";
import HelpAndSupport from "./Components/Dashboard/Pages/HelpAndSupport";
import ManagerHelpAndSupport from "./DpMembers/Manager/ManagerHelpandSupport";
import PublisherHelpAndSupport from "./DpMembers/Publisher/PublisherHelpandSupport";
import ViewerHelpAndSupport from "./DpMembers/Viewer/ViewerHelpandSupport";
import AdHelpAndSupport from "./Components/Advertise/Pages/HelpAndSpport";
import StateContext from "./Context/StateContext";
import Terms from "./Components/Pages/Terms";
import Profile from "./Components/Advertise/Pages/Profile";
import { UserContextProvider } from "./Components/Advertise/Context/Usercontext";
import ShareCompaignUrl from "./Components/Advertise/ShareCompaignUrl";
import ErrorBoundary from "./ErrorBoundary";
import { useSignedContext } from "./Components/Advertise/Context/SignedContext";

function App() {
  const onlyOnce = 1;
  // check the admin is authenticated
  const adminAuthenticated = sessionStorage.getItem("admin") === "true";
  
  const [Ad, setAd] = useState({
    CampName: "",
    imgDetail: "",
    imgPath: "",
    mediaDetail: "",
    mediaPath: "",
    videoDetail: "",
    videoPath: "",
    tags: "",
    budget: "",
    duration: "",
    contacts: "",
    fromDate: "",
    toDate: "",
    fromTime: "",
    toTime: "",
    map: {
      place: "",
      distance: "",
      door: "",
      res: "",
      point: "",
      range: "",
      play: "",
    },
  });

  const [playlist, setPlaylist] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { signed, setSigned } = useSignedContext();

  const LogRoutes = () => {
    const location = useLocation();
  
    useEffect(() => {
      console.log(`Navigated to ${location.pathname}`);
      console.log("signed state: " + signed);
    }, [location]);
  
    return null;
  };

  return (
    <ErrorBoundary>
    <StateContext>
      <UserContextProvider>
      <SocketProvider>
        <LogRoutes/>
        <Routes>
          <Route path="/Signin" element={<SignIn />} />
          <Route path="/compaignURL/:screenId/:username" element={<ShareCompaignUrl/>} />
          <Route
            path="/"
            element={
              signed ? <Navigate to="/home" /> : <Navigate to="/Signin" />
            }
          />
          {
            signed ? (
              <>
                <Route path="/home" element={ <Selector Ad={Ad} setAd={setAd} /> } />
                <Route path="/content" element={<Content />} />
                <Route path="/Description" element={<Description />} />
                <Route path="/adminDashboard" element={<Admin />} />
                <Route path="/advertiser/CreateCampaign" element={<CreateCampaign Ad={Ad} setAd={setAd} />} />
                <Route path="/advertiser/ViewCampaign" element={<ViewCampaign />} />
                <Route path="/advertiser/MyContent" element={<Content />} />
                <Route path="/advertiser/Account" element={<Account />} />
                <Route path="/inbox" element={<Inbox />} />
                <Route path="/myBox" element={<MyBox />} />
                <Route path="/view" element={<ViewDeltails />} />
                <Route path="/advDescription" element={<UserDescritpion />} />
                <Route path="/pay" element={<Payment />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/MyDisplays" element={<MyDisplays />} />
                <Route path="/personalInfo" element={<PersonalInfo />} />
                <Route path="/teamMember" element={<TeamMember />} />
                <Route path="/Funds" element={<Finds />} />
                <Route path="/help" element={<HelpAndSupport />} />
                <Route path="/Adhelp" element={<AdHelpAndSupport />} />
                <Route path="/Profile" element={<Profile />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/newpassword" element={<NewPassword />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/schedule" element={<Schedules />} />
                <Route path="/report" element={<Report />} />
                <Route path="/paymentStatus" element={<StatusStripe />} />
                <Route path="/Manager" element={<ManagerDashboard />} />
                <Route path="/ManagerScreens" element={<ManagerScreens />} />
                <Route path="/ManagerContent" element={<ManagerContent />} />
                <Route path="/ManagerSchedule" element={<ManagerSchedule />} />
                <Route path="/ManagerAccount" element={<ManagerAccount />} />
                <Route path="/ManagerDescription" element={<ManagerDescription />} />
                <Route path="/ManagerTeammember" element={<ManagerTeammember />} />
                <Route path="/Managerhelp" element={<ManagerHelpAndSupport />} />
                <Route path="/Publisher" element={<PublisherDashboard />} />
                <Route path="/PublisherScreens" element={<PublisherScreens />} />
                <Route path="/PublisherDescription" element={<PublisherDescription />} />
                <Route path="/PublisherContent" element={<PublisherContent />} />
                <Route path="/PublisherSchedule" element={<PublisherSchedule />} />
                <Route path="/PublisherAccount" element={<PublisherAccount />} />
                <Route path="/Publisherhelp" element={<PublisherHelpAndSupport />} />
                <Route path="/Viewer" element={<ViewerDashboard />} />
                <Route path="/ViewerScreens" element={<ViewerScreens />} />
                <Route path="/ViewerDescription" element={<ViewerDescription />} />
                <Route path="/ViewerContent" element={<ViewerContent />} />
                <Route path="/ViewerSchedule" element={<ViewerSchedule />} />
                <Route path="/ViewerAccount" element={<ViewerAccount />} />
                <Route path="/Viewerhelp" element={<ViewerHelpAndSupport />} />
              </>
            ) : (<Route path="*" element={<SignIn />} />)
          }

          <Route path="/adminDashboard" element={<Admin />} />


          {/* <Route
                path="/adminDashboard/screensdetails"
                element={<ScreensDetails />}
              />
              <Route path="/admindashboard/usersinbox" element={<Module2 />} />
              <Route path="/admindashboard/mycampaign" element={<Module3 />} />
              <Route path="/admindashboard/module4" element={<Module4 />} />
              <Route path="/admindashboard/module5" element={<Module5 />} />
              <Route path="/admindashboard/createUsers" element={<Module6 />} />
              <Route path="/view" element={<ViewDeltails />} /> */}

          {/* Use AdminRoute for routes/components that should be restricted to admins */}
          {adminAuthenticated ? (
            <>
              <Route path="/adminDashboard/screensdetails" element={<ScreensDetails />} />
              <Route path="/admindashboard/usersinbox" element={<Module2 />} />
              <Route path="/admindashboard/mycampaign" element={<Module3 />} />
              <Route path="/admindashboard/module4" element={<Module4 />} />
              <Route path="/admindashboard/module5" element={<Module5 />} />
              <Route path="/admindashboard/createUsers" element={<Module6 />} />
              <Route path="/view" element={<ViewDeltails />} />
              <Route path="/admindashboard/pushnotifications" element={<Module7 />} />
            </>
          ) : (
            <Route path="/adminDashboard" element={<Admin />} />
          )}
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
          
        </Routes>
      </SocketProvider>
      </UserContextProvider>
    </StateContext>
    </ErrorBoundary>
  );
}

export default App;
