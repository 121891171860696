import React, { createContext, useContext, useState } from "react";

export const signedContext = createContext(null);

export const SignedContextProvider = ({children}) => {
    const [signed, setSigned] = useState(false);
    return (
        <signedContext.Provider value={ {signed, setSigned} }>
            {children}
        </signedContext.Provider>
    )
}

export const useSignedContext = () => {
    return useContext(signedContext);
}