import React, { useEffect, useState } from "react";
import DpMenu from "../../Menu/DpMenu";
import DpHeader from "../../Header/DpHeader";
import { IoChevronForwardOutline } from "react-icons/io5";
import { FiChevronDown } from "react-icons/fi";
import {
  MdCheckCircle,
  MdOutlineAccountBalance,
  MdOutlineManageAccounts,
} from "react-icons/md";
import { LuSettings } from "react-icons/lu";
import ReactModal from "react-modal";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Axios from "../../Axios/axios";
import axios from "axios";
import { VscAccount } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineBank } from "react-icons/ai";

function PersonalInfo() {
  const notify = (text) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyUser = (text) =>
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyAdd = (text, type) => {
    if (type === "success") {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const notifyDelete = (text, type) => {
    if (type === "success") {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const notifyPrimary = (text, type) => {
    if (type === "success") {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const [username, setUsername] = useState("");
  const [nav, setNav] = useState(false);

  useEffect(() => {
    setUsername(sessionStorage.getItem("username"));
  }, []);

  const [isParentModalOpen, setIsParentModalOpen] = useState(false);
  const [isParentModalOpenView, setIsParentModalOpenView] = useState(false);
  const [isParentModalOpenBank, setIsParentModalOpenBank] = useState(false);
  const [account, setAccount] = useState(false);
  const [primary, setPrimary] = useState(false);

  console.log(account);

  const openParentModal = () => {
    setIsParentModalOpen(true);
  };

  const closeParentModal = () => {
    setIsParentModalOpen(false);
  };

  const openParentModalViewBank = () => {
    setIsParentModalOpenView(true);
    setMenuOpenBank(false);
    setMenuOpenBankDown(false);
  };

  const closeParentModalViewBank = () => {
    setIsParentModalOpenView(false);
  };

  const initState = {
    phoneNumber: "",
    firstName: "",
    lastName: "",
    CompanyName: "",
    IndustryName: "",
  };
  const [formData, setFormData] = useState(initState);
  const resetData = () => {
    setFormData(initState);
  };

  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState();

  const [branchName, setBranchName] = useState("");
  useEffect(() => {
    if (ifscCode) {
      axios
        .get(`https://ifsc.razorpay.com/${ifscCode}`)
        .then((response) => {
          const data = response.data;
          setBankName(data.BANK);
          setBranchName(data.BRANCH);

          // Now you can access bankName here, as it's set within the callback
          // console.log(bankName);
        })
        .catch((error) => {
          console.error("Error fetching bank details", error);
          setBankName("");
          setBranchName("");
        });
    }
  }, [ifscCode]);

  // const [accData, setAccData] = useState(initialState);

  const [accData, setAccData] = useState({
    accountNumber: "",
    holderName: "",
    ifsc: "",
  });

  const [error, setError] = useState({
    holderName: "",
    accountNumber: "",
    conBankaccountNumber: "",
    ifsc: "",
  });

  useEffect(() => {
    console.log(bankName); // This will log the updated bankName
  }, [bankName]);

  const handleChangeAccount = (event) => {
    const { name, value } = event.target;

    // If the input field has a name of "ifsc", update the IFSC code state
    if (name === "ifsc") {
      setIfscCode(value);
    } else if (name === " ") {
      setIfscCode(null);
    }

    // Update the account data state, preserving the previous data
    setAccData((prevData) => ({
      ...prevData,
      [name]: value,
      username: username,
      accountType: selectedType,
    }));

    // Assuming "validateInput" is a function that validates the input
    validateInput(event);
  };

  const handleChange = (e) => {
    setColor(true);
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      username: username,
    }));
    // console.log("cg", setFormData);
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  console.log("Form Data:", formData);
  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Form Data:", formData); // Log the form data before sending the request

    try {
      const response = await Axios.post("/save-userdetail-payment", formData);
      // console.log('Data saved successfully:', response.data);
      // sessionStorage.setItem("bankName", bankName)
      notifyUser("User Details Added!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
    resetData();
  };

  const [accountType, setAccountType] = useState("");
  const [open, setOpen] = useState(true);
  const [accDetails, setAccDeatils] = useState({
    accountNumber1: "",
    accountNumber2: "",
  });

  const [bank, setBank] = useState({});
  console.log(bank);
  console.log(bank.accountNumber2);

  // Define the function outside of the useEffect scope
  const handleSubmitAccountDetails = async (event) => {
    event.preventDefault();
    console.log("Form Data:", accData);
    try {
      const response = await Axios.post("/save-accountdetail-payment", {
        ...accData,
        bank: bankName, // Include the bankName in the request data
      });
      console.log(response);
      if (response.status === 202) {
        notify("Same Account not Allow!");
      } else {
        notifyAdd("Bank added successfully", "success");
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }

    setIsParentModalOpen(false);
  };
  const newusername = sessionStorage.getItem("username");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const username = sessionStorage.getItem("username");
        const response = await Axios.get(`/getPendingPaymentDetails?username=${newusername}`);
        // Handle the response data here
        // console.log(response.data.data);
        setBank(response.data.data);
        console.log(response.data.data.Boolean);
        setAccount(response.data.data.Boolean);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Set a timeout of 2 seconds (2000 milliseconds) before fetching data
    fetchData(); // Call the async function after the delay
    // const delay = 1000; // Adjust the delay as needed

    // const timeoutId = setTimeout(() => {
    // }, delay);

    // // Clear the timeout when the component unmounts (optional)
    // return () => clearTimeout(timeoutId);
  }, [account]);

  // An empty dependency array means it runs once after the initial render
  console.log(bank, "----------------------------------------------------");
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function classNamesBank(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function classNamesBankDown(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "holderName":
          if (!value) {
            stateObj[name] = "Please enter Account holderName.";
          }
          break;

        case "accountNumber":
          if (!value) {
            stateObj[name] = "Please enter Account Number.";
          } else if (isNaN(accData.accountNumber)) {
            stateObj[name] = "Enter Numeric Value Only";
          } else if (
            accData.conBankaccountNumber &&
            value !== accData.conBankaccountNumber
          ) {
            stateObj["conBankaccountNumber"] =
              "accountNumber and Confirm accountNumber does not match.";
          } else {
            stateObj["conBankaccountNumber"] = accData.conBankaccountNumber
              ? ""
              : error.conBankaccountNumber;
          }
          break;

        case "conBankaccountNumber":
          if (!value) {
            stateObj[name] = "Please enter Confirm Account Number.";
          } else if (accData.accountNumber && value !== accData.accountNumber) {
            stateObj[name] =
              "Account No. and Confirm Account No. does not match.";
          }
          break;
        case "ifsc":
          if (!value) {
            stateObj[name] = "Please enter Account IFSC Code";
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };
  const [selectedType, setSelectedType] = useState("Select Account Type");
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBank, setMenuOpenBank] = useState(false);
  const [menuOpenBankDown, setMenuOpenBankDown] = useState(false);

  const handleSelect = (type) => {
    setSelectedType(type);
    setMenuOpen(false);
  };
  const [selectedAccountBank1, setSelectedAccountBank1] = useState(true);
  const [selectedAccountBank2, setSelectedAccountBank2] = useState(false);
  const [color, setColor] = useState(false);

  const handlePrimaryAccount = async (username, accountNumber, primary) => {
    let Action = primary;
    console.log(Action);
    if (Action === "setPrimary") {
      console.log("sdadas", username, accountNumber, Action);
      try {
        const response = await Axios.post("/updateAccountDetails", {
          username: username,
          accountNumber: accountNumber,
          action: Action,
        });
        console.log(response);
        if (response.status === 200) {
          // Handle success, if needed
          const timeoutId = setTimeout(() => {
            window.location.reload();
          }, 1000);

          // Clear the timeout when the component unmounts (optional)
          return () => clearTimeout(timeoutId);
          console.log("Primary account set successfully.");
        } else {
          // Handle other response statuses, if needed
          console.error("Failed to set primary account.");
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
      setPrimary(true);
      setMenuOpenBank(false);
      setMenuOpenBankDown(false);
    }

    if (Action === "Delete") {
      console.log("sdadas", username, accountNumber, Action);
      try {
        const response = await Axios.post("/updateAccountDetails", {
          username: username,
          accountNumber: accountNumber,
          action: Action,
        });

        if (response.status === 200) {
          // Handle success, if needed
          console.log("Account Delete successfully.");
        } else {
          // Handle other response statuses, if needed
          console.error("Failed to set primary account.");
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
      setMenuOpenBank(false);
      setMenuOpenBankDown(false);
    }
  };

  const navigate = useNavigate();
  const PrNaviagte = () => {
    navigate("/home");
  };

  const handleColour = () => {
    setColor(true);
  };

  return (
    <div className="h-screen  flex w-screen ">
      <ToastContainer />
      <DpMenu />
      <div className="h-screen w-screen  overflow-hidden bg-[#FFFDF9]">
        <DpHeader />
        <div className="p-5 h-[100%]">
          <div className=" flex items-center ">
            <span
              className="text-[#8E8E8E] cursor-pointer"
              onClick={PrNaviagte}
            >
              {" "}
              Home
            </span>{" "}
            <IoChevronForwardOutline />{" "}
            <span className="text-[#555555]">My Setting</span>
          </div>
          <div className="">
            <p className="text-[24px] mt-6 mb-4 text-[#555555]">My Settings</p>
          </div>

          <div className="flex gap-3 h-[100%]">
            <div className="w-[50%] h-[72%] shadow-md bg-white shadow-[#FFF1EB] shadow-opacity-[30%]  rounded-[4px]">
              <p className="text-[#555555] mt-4 ml-4 text-[20px] font-semibold">
                Personal Information
              </p>
              <div className="items-center flex gap-3 p-4 mt-1 ">
                <div className="flex items-center ">
                  <div className="">
                    <img
                      className="h-16 w-16 rounded-full"
                      src="https://cdn-icons-png.flaticon.com/512/236/236832.png?w=740&t=st=1690788839~exp=1690789439~hmac=4d4fa9ad6657c9817da41cf0e72d2857df6e1f320bfc8a6e75333d25bc8546b8"
                      alt="user"
                    />
                  </div>
                </div>
                <div className="flex-col gap-4">
                  <h1 className="text-[16px]">Verified ID</h1>
                  <div className="flex items-center gap-2">
                    <div
                      type="text"
                      className="border flex items-center select-none  text-[#555555] border-gray-300 outline-none px-2 rounded-full"
                    >
                      {username}{" "}
                    </div>
                    <MdCheckCircle className="text-green-500" />
                  </div>
                </div>
              </div>
              <div className=" p-3 flex flex-col justify-evenly">
                <form onSubmit={handleSubmit}>
                  <div className=" flex  justify-evenly  gap-3">
                    <div class="relative mb-6  select-none w-[50%]">
                      <div className="border rounded-md border-gray-200 h-10 px-5 flex items-center text-[#8E8E8E]">
                        {username?.slice()?.split("@")[0]}
                      </div>
                      {/* <input type="gmail" name="username" id="username" placeholder={username.slice(0, 9)} value={formData.username} class="border select-none p-2 bg-white rounded-full w-[100%] outline-none" />*/}
                      <label
                        for="inputField"
                        htmlFor="username"
                        class="absolute  bottom-8 left-3 bg-white px-1 text-sm"
                      >
                        Username
                      </label>
                    </div>
                    <div class="relative mb-6  w-[50%]">
                      <input
                        id="phoneNumber"
                        type="text"
                        pattern="[0-9]*"
                        maxLength="10"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder={!bank?"Your Contact":`${bank.contact}`}
                        className="border p-2 rounded-md w-full outline-none"
                      />

                      <label
                        htmlFor="phoneNumber"
                        class="absolute  bottom-8 left-3 bg-white px-1 text-sm"
                      >
                        Phone Number
                      </label>
                    </div>
                  </div>
                  <div className="  flex  justify-evenly gap-3">
                    <div class="relative mb-6  w-[50%]">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder={!bank?"First Name":`${bank.name?.slice()?.split(" ")[0]}`}
                        class="border p-2 rounded-md w-[100%] outline-none"
                      />
                      <label
                        htmlFor="firstName"
                        class="absolute  bottom-8 left-3 bg-white px-1 text-sm"
                      >
                        First Name
                      </label>
                    </div>{" "}
                    <div class="relative mb-6  w-[50%]">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder={!bank?"First Name":`${bank.name?.slice()?.split(" ")[1]}`}
                        class="border p-2 rounded-md w-[100%] outline-none"
                      />
                      <label
                        htmlFor="lastName"
                        class="absolute  bottom-8 left-3  bg-white px-1 text-sm"
                      >
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6">
                    <p className="text-[#555555] text-[20px] font-semibold">
                      Company Details
                    </p>
                    <div class="relative mt-1  w-[100%]">
                      <input
                        type="text"
                        name="CompanyName"
                        id="CompanyName"
                        value={formData.CompanyName}
                        placeholder={!bank?"Company Name":`${bank.CompanyName}`}
                        onChange={handleChange}
                        class="border p-2 rounded-md w-[100%] outline-none"
                      />
                      <label
                        htmlFor="firstName"
                        class="absolute  bottom-8 left-3 bg-white px-1 text-sm"
                      >
                        Company Name
                      </label>
                    </div>{" "}
                    <div class="relative   w-[100%]">
                      <input
                        type="text"
                        name="IndustryName"
                        id="IndustryName"
                        value={formData.IndustryName}
                        placeholder={!bank?"Industry":`${bank.IndustryName}`}
                        onChange={handleChange}
                        class="border p-2 rounded-md w-[100%] outline-none"
                      />
                      <label
                        htmlFor="lastName"
                        class="absolute  bottom-8 left-3  bg-white px-1 text-sm"
                      >
                        Industry
                      </label>
                    </div>
                  </div>
                  <div className="flex items-end justify-end mt-6">
                    <button
                      onClick={handleColour}
                      type="submit"
                      className={`border text-[#FFFFFF]  shadow-sm  px-6 py-2 rounded-[4px] mx-6 ${
                        color ? "bg-[#FF8E00]" : "bg-[#5555]"
                      } `}
                    >
                      Save Changes
                    </button>{" "}
                  </div>
                </form>
              </div>
            </div>
            {account ? (
              <div className="w-[50%] h-[45%] p-5 shadow-md bg-white shadow-[#FFF1EB] shadow-opacity-[30%] rouneded-[4px]">
                <p className=" text-[20px] flex mb-4 text-[#555555] font-semibold w-fit ">
                  Bank Account Details
                </p>
                <p className="text-[#555555] select-none ml-1 mb-4 text-[16px] ">
                  Attached Accounts:
                </p>
                <div className="flex gap-5 items-center ml-8 w-[100%] ">
                  <div className="flex w-[80%] items-center gap-2 ">
                    <div className="w-[100%]">
                      <p className="flex items-center justify-between  mb-1">
                        <p className="flex items-center gap-2 text-[#FF8E00] ">
                          {" "}
                          <AiOutlineBank size={24} /> {bank.bank1}
                          <span className="text-[#20A756] border px-2 ml-2 bg-[#20A75633] rounded-[4px] capitalize ">
                            {bank.type1}
                          </span>
                        </p>
                        <Menu
                          as="div"
                          className="relative inline-block focus:border-[#555555] text-center"
                        >
                          <div className="ml-6">
                            <Menu.Button
                              id="accountTypeButton"
                              className="inline-flex text-md font-thin w-full border-[#9D9D9D] focus:border-[#555555] gap-x-1.5  bg-white px-2 py-2 text-gray-700"
                              onClick={() => setMenuOpenBank(!menuOpenBank)}
                            >
                              <BiDotsVerticalRounded
                                className=" cursor-pointer"
                                size={24}
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            show={menuOpenBank}
                            as={React.Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute z-10  w-[140px] text-left rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNamesBank(
                                        active
                                          ? "bg-gray-100  text-gray-700 w-80"
                                          : "text-gray-700",
                                        "block px-2 w-full text-sm font-thin"
                                      )}
                                      onClick={() => {
                                        handlePrimaryAccount(
                                          newusername,
                                          bank.accountNumber1,
                                          "setPrimary"
                                        );
                                      }}
                                    >
                                      Primary
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100  text-gray-700 w-80"
                                          : "text-gray-700",
                                        "block px-2 text-sm w-full font-thin"
                                      )}
                                      onClick={() => {
                                        handlePrimaryAccount(
                                          newusername,
                                          bank.accountNumber1,
                                          "Delete"
                                        );
                                        notifyDelete(
                                          "Account Deleted!",
                                          "success"
                                        );
                                      }}
                                    >
                                      Delete Account
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100  text-gray-700 w-80"
                                          : "text-gray-700",
                                        "block px-2 text-sm w-full font-thin"
                                      )}
                                      onClick={() => {
                                        openParentModalViewBank();
                                        setSelectedAccountBank1(true);
                                      }}
                                    >
                                      View A/c
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </p>
                      <p className=" flex items-center justify-between  ">
                        {bank.bank2 && (
                          <p className="flex items-center gap-2 text-[#FF8E00]">
                            <AiOutlineBank size={24} /> {bank.bank2}{" "}
                            <span className="text-[#20A756] border px-2 ml-2 bg-[#20A75633] rounded-[4px] capitalize">
                              {bank.type2}{" "}
                            </span>
                          </p>
                        )}

                        <Menu
                          as="div"
                          className="relative inline-block focus:border-[#555555] text-center"
                        >
                          <div>
                            <Menu.Button
                              id="accountTypeButton"
                              className="inline-flex text-md font-thin w-full border-[#9D9D9D] focus:border-[#555555] gap-x-1.5 rounded-full bg-white px-2 py-2 text-gray-700"
                              onClick={() =>
                                setMenuOpenBankDown(!menuOpenBankDown)
                              }
                            >
                              {bank.bank2 && (
                                <BiDotsVerticalRounded
                                  className=" cursor-pointer"
                                  size={24}
                                />
                              )}
                            </Menu.Button>
                          </div>

                          <Transition
                            show={menuOpenBankDown}
                            as={React.Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute z-10  w-[140px] text-left rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNamesBankDown(
                                        active
                                          ? "bg-gray-100  text-gray-700 w-80"
                                          : "text-gray-700",
                                        "block px-2 w-full text-sm font-thin"
                                      )}
                                      onClick={() => {
                                        handlePrimaryAccount(
                                          newusername,
                                          bank.accountNumber2,
                                          "setPrimary"
                                        );
                                      }}
                                    >
                                      Primary
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100  text-gray-700 w-80"
                                          : "text-gray-700",
                                        "block px-2 text-sm w-full font-thin"
                                      )}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        handlePrimaryAccount(
                                          newusername,
                                          bank.accountNumber1,
                                          "Delete"
                                        );
                                        notifyDelete(
                                          "Account Deleted!",
                                          "success"
                                        );
                                      }}
                                    >
                                      Delete Account
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100  text-gray-700 w-80"
                                          : "text-gray-700",
                                        "block px-2 text-sm w-full font-thin"
                                      )}
                                      onClick={() => {
                                        openParentModalViewBank();
                                        setSelectedAccountBank1(false);
                                      }}
                                    >
                                      View A/c
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </p>
                    </div>
                    {/* <div className=' bg-green-500 text-white w-fit px-2 text-xs rounded-full'>
                                        Primary A/c
                                    </div> */}
                  </div>
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <div className="flex flex-col gap-2 items-center justify-start w-[100%]">
                    <p className="text-[#001122] select-non mb-4 w-full ml-2 text-md">
                      Add New Bank
                    </p>
                    <div className="flex items-center justify-around w-full">
                      <span className="flex items-center mr-36 gap-2">
                        {" "}
                        <AiOutlineBank size={26} />
                        Link Another Bank A/c
                      </span>
                      <div
                        onClick={openParentModal}
                        className="border  w-fil border-[#FF8E00] rounded-[4px] hover:bg-[#FF8E00] text-[#FF8E00] hover:text-[#FFFFFF]"
                      >
                        <button className="  border-1    px-2 py-2 rounded-[4px] mx-6 ">
                          Connect
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-[50%] h-[28%] p-5 bg-white shadow-md border border-gray-300 rounded-md">
                <div className=" float-right flex justify-end ">
                  <span className=" bg-[#EF4E38] rounded-full text-sm flex items-end text-white w-fit px-2">
                    Attention Required
                  </span>
                </div>
                <span className="text-[#001122] select-none items-start text-xl ">
                  Add A Primary Bank Account
                </span>
                <p className="text-[#001122] select-none items-start text-md ml-6 mt-10 ">
                  Primary Account is required to withdraw funds.
                </p>
                <div className="flex mt-8 justify-between items-center">
                  <div className="flex gap-5 items-center justify-center w-[50%]">
                    <AiOutlineBank size={26} />
                    <span>Link Your Bank A/c</span>
                  </div>
                  <div
                    onClick={openParentModal}
                    className="border w-fil border-[#FF8E00] rounded-md"
                  >
                    <button className="  border-1  text-[#FF8E00]  px-2 py-2 rounded-full mx-6 ">
                      Connect
                    </button>
                  </div>
                </div>
              </div>
            )}
            <ReactModal
              isOpen={isParentModalOpenView}
              onRequestClose={closeParentModalViewBank}
              contentLabel="Video Modal"
              className=" flex bg-[#000000]/70 w-screem h-screen justify-center items-center"
              ariaHideApp={false}
            >
              <div className=" w-[50%] h-[35%] rounded-lg bg-white">
                <div className="flex justify-between  w-[100%]">
                  <p className="text-[#555555] text-2xl p-3 pt-4 pl-6">
                    Bank Account Details
                  </p>
                  <p
                    onClick={closeParentModalViewBank}
                    className="text-[#555555] p-3 pt-4 pr-4 cursor-pointer "
                  >
                    <RxCross2 size={26} />
                  </p>
                </div>
                <div className=" w-[100%] flex  flex-col items-center">
                  {selectedAccountBank1 ? (
                    <div className="border-t-2 pt-6 flex flex-col gap-4 w-[94%] ">
                      <p className="flex gap-2">
                        Bank Name:&nbsp;{bank.bank1}{" "}
                        <span className="text-[#20A756] border px-2 ml-2 bg-[#20A75633] rounded-[20px] capitalize">
                          {bank.type1}{" "}
                        </span>{" "}
                      </p>
                      <p className=" capitalize">
                        A/c Holder Name:&nbsp;{bank.holderName1}
                      </p>
                      <p>A/c Type:&nbsp;{bank.accountType1}</p>
                      <p>Bank Account No:&nbsp;{bank.accountNumber1} </p>
                      <p>IFSC Code:&nbsp;{bank.ifsc1}</p>
                    </div>
                  ) : (
                    <div className="border-t-2 pt-6 flex flex-col gap-4 w-[94%] ">
                      <p className="flex gap-2">
                        Bank Name:&nbsp;{bank.bank2}{" "}
                        <span className="text-[#20A756] border px-2 ml-2 bg-[#20A75633] rounded-[20px] capitalize">
                          {bank.type2}{" "}
                        </span>
                      </p>
                      <p className=" capitalize">
                        A/c Holder Name:&nbsp;{bank.holderName2}
                      </p>
                      <p>A/c Type:&nbsp;{bank.accountType2}</p>
                      <p>Bank Account No:&nbsp;{bank.accountNumber2} </p>
                      <p>IFSC Code:&nbsp;{bank.ifsc2}</p>
                    </div>
                  )}
                </div>
              </div>
            </ReactModal>

            <ReactModal
              isOpen={isParentModalOpen}
              onRequestClose={closeParentModal}
              contentLabel="Video Modal"
              className=" flex bg-[#000000]/70 w-screem h-screen justify-center items-center"
              ariaHideApp={false}
            >
              <div className="w-[50%] bg-white rounded-md py-5 px-5 h-[65%] border">
                <h3 className="text-[#001122] font-bold text-xl">
                  Add Bank Account Details
                </h3>
                <form onSubmit={handleSubmitAccountDetails}>
                  <p className="mt-6 mb-3 text-[#555555] font-bold">
                    Enter bank account details to withdraw generated revenue.
                  </p>
                  <div className="bg-white flex flex-col gap-3 relative">
                    <input
                      type="text"
                      id="inputField"
                      placeholder="Enter Account Holder Name"
                      name="holderName"
                      value={accData.holderName}
                      onBlur={validateInput}
                      onChange={handleChangeAccount}
                      required
                      className="border border-[#9D9D9D] focus:border-[#555555]  outline-none p-2 placeholder-gray-700 rounded-full w-full bg-white "
                    />
                    {/* {error.holderName && <span className='err'>{error.holderName}</span>} */}
                    <Menu
                      as="div"
                      className="relative inline-block focus:border-[#555555] text-center"
                    >
                      <div>
                        <Menu.Button
                          id="accountTypeButton"
                          className="inline-flex text-md font-thin w-full border-[#9D9D9D] focus:border-[#555555] gap-x-1.5 rounded-full bg-white px-3 py-2 text-gray-700 s ring-1 ring-inset ring-gray-400"
                          onClick={() => setMenuOpen(!menuOpen)}
                        >
                          {selectedType}
                        </Menu.Button>
                      </div>

                      <Transition
                        show={menuOpen}
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 ml-3 mt-2 outline-none w-[732px] text-left rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100  text-gray-700 w-full"
                                      : "text-gray-700",
                                    "block px-4 py-1 w-full text-md font-thin"
                                  )}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleSelect("Saving Account");
                                  }}
                                >
                                  Saving Account
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100  text-gray-700 w-full"
                                      : "text-gray-700",
                                    "block px-4 py-1 text-md w-full font-thin"
                                  )}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleSelect("Current Account");
                                  }}
                                >
                                  Current Account
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <input
                      type="text"
                      id="inputField"
                      placeholder="Enter Bank Account No."
                      name="accountNumber"
                      value={accData.accountNumber}
                      onBlur={validateInput}
                      onChange={handleChangeAccount}
                      required
                      className="border border-[#9D9D9D] z-0 p-2 placeholder-gray-700 rounded-full w-full bg-white  outline-none "
                    />
                    {error.accountNumber && (
                      <span className="err text-red-600">
                        {error.accountNumber}
                      </span>
                    )}
                    <input
                      type="text"
                      id="inputField"
                      placeholder="Confirm Bank Account No."
                      name="conBankaccountNumber"
                      value={accData.conBankaccountNumber}
                      onBlur={validateInput}
                      onChange={handleChangeAccount}
                      required
                      className="border  p-2 placeholder-gray-700 border-[#555555] rounded-full w-full bg-white   outline-none "
                    />
                    {error.conBankaccountNumber && (
                      <span className="err text-red-600">
                        {error.conBankaccountNumber}
                      </span>
                    )}
                    <input
                      type="text"
                      id="ifscField"
                      placeholder="Enter IFSC Code"
                      name="ifsc"
                      onChange={handleChangeAccount}
                      value={accData.ifsc}
                      onBlur={validateInput}
                      required
                      className="border border-[#9D9D9D] p-2 placeholder-gray-700 rounded-full w-full bg-white  outline-none"
                    />
                    {/* {error.ifsc && <span className='err'>{error.ifsc}</span>} */}
                  </div>
                  <p>Bank Name: {bankName}</p>
                  <p>Branch Name: {branchName}</p>

                  <div className="w-fit flex gap-4 float-right mt-8">
                    <div
                      onClick={closeParentModal}
                      className="border w-fil border-[#FF8E00] rounded-md"
                    >
                      <button className="  border-1  text-[#FF8E00]  px-2 py-2 rounded-md mx-6 ">
                        Cancel
                      </button>
                    </div>
                    <div
                      onClick={() => {
                        openParentModal();
                      }}
                      className="border w-fil bg-[#FF8E00] border-[#FF8E00] rounded-md"
                    >
                      <button
                        type="submit"
                        className="border-1  text-white  px-2 py-2 rounded-md mx-6 "
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </ReactModal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
