import React, { useContext, useEffect, useRef, useState } from "react";
import AdminHeader from "../adminHeader";
import ReactModal from "react-modal";
import Axios from "../../Axios/axios";
import food from "../../Images/food.png";
import MyBox from "./NewBox/index";
import { SocketContext } from "../../../socketcontext";
import ReactPlayer from "react-player";
import {  toast } from "react-toastify";
import Menu from "../../Menu/Menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Module3() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [modalContentIsOpen, setModalContentIsOpen] = useState(false);
  const [isloaded, setLoaded] = useState(false);
  const [showContentDiv, setShowContentDiv] = useState(true);
  const [showContentDiv2, setShowContentDiv2] = useState(false);
  const [showScreens, setShowScreens] = useState([]);
  const [saved, setSaved] = useState(true);
  const videoRef = useRef(null);
  const [dur, setdurr] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [managePlay, setPlay] = useState([]);
  const [currContentId, setCurrContentId] = useState("");
  const [btn, setbtn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);


  const Websocket = useContext(SocketContext);
  const notify = (text, type) => {
    if (type === "success") {
      toast.success(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handlecontent = () => {
    setbtn(!btn);
  };
  const handledisplay = () => {
    setbtn(!btn);
  };

  const [New, setNew] = useState({
    CampName: "",
    duration: "",
    user_slot:"",
    company: "",
    videoDetail: "",
    imgDetail: "",
    imgPath: "",
    videoPath: "",
    description: "",
  });

  const [content, setContent] = useState([
    {
      title: "Dummy",
      duration: "00:00",
      advertiser: "Oceanic Airlines Ad",
      thumbnailPath: food,
      contentId: "ididid",
    },
  ]);

  const handleCancel = () => {
    // Reset the thumbnail and video fields
    setNew({
      ...New,
      CampName: "",
      duration: "",
      user_slot:"",
      company: "",
      videoDetail: "",
      imgDetail: "",
      imgPath: "",
      videoPath: "",
      description: "",
    });
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const formatTime = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const formattedHours =
      hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";
    const formattedMinutes = `${minutes.toString().padStart(2, "0")}:`;
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return formattedHours + formattedMinutes + formattedSeconds;
  };
  //get all screens details
  const [selectedScreens, setSelectedScreens] = useState([]);
  async function fetchData() {
    try {
      const response = await Axios.get("getAllScreens");
      if (response.data && response.data.Screens) {
        setShowScreens(response.data.Screens);
        console.log("da", response.data.Screens);
        console.log(response.data.Screens.videoURL);
        setVideoUrl(response.data.Screens.videoURL);
        const screenIds = response.data.Screens.map(
          (screen) => screen.screenId
        );
      } else {
        console.error("Invalid API response:", response.data);
      }
      console.log("my response", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    console.log("Updated selectedScreens:", selectedScreens);
  }, [selectedScreens]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleVideoUpload = (videoFile) => {
    const file = videoFile;
    const videoObjectURL = URL.createObjectURL(file);
    videoRef.current.src = videoObjectURL;
    videoRef.current.addEventListener("loadedmetadata", () => {
      const durationInSeconds = Math.ceil(videoRef.current.duration);
      const formattedDuration = formatTime(durationInSeconds);
      console.log("Video duration:", formattedDuration);
      setdurr(formattedDuration);
      console.log("Set Duration");
      // Use the video duration as needed
    });
  };

  var data = "";
  const username =
    "ahdimainnskkkhugbsyuflabuijkhdaujio@yfyugkbwuihdiohwxcbniojwbnguinbiuFGVI.cuiwgdgbwuiohiugppiohbbkjhhkm";
  const addContent = async (e) => {
    e.preventDefault();
    console.log(username);
    setSaved(false);
    // data = new FormData();
    // data.set("postedvideos", New.video);
    // data.set("thumbnail", New.thumbnail);
    // data.set("username", username);
    // data.set("title", New.title);
    // data.set("size", New.video.size);
    // data.set("duration", dur);
    // data.set("advertiser", New.company);
    // data.set("description", New.description);
    // data.set("mimetype", New.video.type.split("/")[1]);
    // var newContentId = "newIdId";
    const generatedContentId = Math.floor(Math.random() * 100000000).toString();
    // const headers = {
    //   "content-type": "multipart/form-data",
    // };

    const contentToUpload = {
      username: username,
      title: New?.CampName,
      size: New?.videoDetail?.size,
      duration: dur,
      advertiser: New?.company,
      description: New?.description,
      thumbnailMimetype: New?.imgDetail?.type?.split("/")[1],
      videoMimetype: New?.videoDetail?.type?.split("/")[1],
      contentId: generatedContentId,
    }

    try {
      console.log("generating your presigned url ********");
      const thumbnailResponse = await Axios.post(
        `/presigned-url?${new URLSearchParams({
          type: "thumbnail",
          contentFormat: New?.imgDetail?.type?.split("/")[1],
          contentId: generatedContentId,
        })}`,
        {username: username},
      );
      console.log("this is response for thumbnail response --------->", thumbnailResponse);

      const { thumbnailUrl } = thumbnailResponse.data;
      console.log("presignedURL is:::::::::::", thumbnailUrl);
      if (thumbnailResponse.status !== 200) {
        throw new Error(
          "Error uploading file: thumbnail response status is not 200"
        );
      }

      const videoResponse = await Axios.post(
        `/presigned-url?${new URLSearchParams({
          type: "video",
          contentFormat: New?.videoDetail?.type?.split("/")[1],
          contentId: generatedContentId,
        })}`,
        {username: username},
      );

      console.log("this is response for video response-------------", videoResponse);
            
      const { videoUrl } = videoResponse.data;
      console.log("Presigned URL ****:", videoUrl);
      //upload thumbnail using presenged URL
      if (thumbnailResponse.status !== 200 && videoResponse.status !== 200) {
        throw new Error(
          "Error uploading file: Thumbnail or Video response status is not 200"
        );
      }

      await axios.put(thumbnailUrl, New?.imgDetail, {
        headers: {
          "Content-Type": New?.imgDetail.type,
        },
      });
      console.log("thumbnail uploded ..........");
  
      // Upload the file using the pre-signed URL
  
      await axios.put(videoUrl, New?.videoDetail, {
        headers: {
          "Content-Type": New?.videoDetail.type,
        },
      });
  
      console.log("PUT request successful");
      console.log("VIdeoFile uploaded successfully!..............");
    } catch (error) {
      console.log("some error occured");
    }

    //Do not remove this Kavin, I have commented so that you can see changes for now in Frontend
    await Axios.post("addNewContent", contentToUpload)
      .then(function (response) {
        console.log(response.data);
        const newContentId = response.data.contentId;
        if (response.status === 200) {
          console.log(response.data);
          setSaved(true);
          setbtn(!btn);
          setContent([
            ...content,
            {
              title: New.CampName,
              duration: dur,
              advertiser: New.company,
              thumbnailPath:
                "https://s3.ap-south-1.amazonaws.com/everidoor2.0/" +
                sessionStorage.getItem("username") +
                "/Thumbnails/" +
                response.data.contentId +
                "." +
                New.imgDetail.type.split("/")[1],
              contentId: newContentId,
            },
          ]);

          setNew({
            CampName: "",
            duration: "",
            user_slot: "",
            company: "",
            videoDetail:"",
            imgDetail:"",
            videoPath:"",
            description:"",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (showContentDiv2) {
      setLoaded(false);
    }
    if (showContentDiv) {
      setShowContentDiv(false);
      setLoaded(true);
    }
  }, [showContentDiv2]);

  useEffect(() => {
    async function getContent() {
      const response = await Axios.get("getAllContent?username=ahdimainnskkkhugbsyuflabuijkhdaujio@yfyugkbwuihdiohwxcbniojwbnguinbiuFGVI.cuiwgdgbwuiohiugppiohbbkjhhkm");
      console.log("dasda", response.data.Content);
      setContent(response.data.Content);
      setLoaded(true);
      setShowContentDiv(false);
    }
    getContent();
  }, []);
  const [user, setUser] = useState("");
  const [selectedContentId, setSelectedContentId] = useState(0);
  const [durationID, setDuration] = useState("");
  const [screenId, setScreenId] = useState(0);
  const [screenData, setScreenData] = useState({
    username: username,
    screenId: screenId,
    duration: 0,
    content_to_be_played: "000",
  });
  const handleSetContent = (contentId, duration) => {
    setSelectedContentId(contentId);
    setDuration(duration);
    console.log(durationID);
    console.log(selectedContentId);
  };
  const handleCheckboxChange = (e, screenId, username) => {
    const { checked } = e.target;
    setScreenId(screenId);
    setUser(username);
    if (checked) {
      setSelectedScreens((prevSelectedScreens) => [
        ...prevSelectedScreens,
        {
          screenId: screenId,
          username: username,
        },
      ]);
      console.log("Screen ID:", screenId);
    } else {
      setSelectedScreens((prevSelectedScreens) =>
        prevSelectedScreens.filter((id) => id !== screenId)
      );
    }
    setScreenData({
      username: user,
      screenId: screenId,
      duration: durMin,
      content_to_be_played: selectedContentId,
    });
  };
  async function schedule() {
    try {
      if (!username || !selectedContentId || selectedScreens.length === 0) {
        console.log("Please fill in all required fields.");
        return;
      }
      const selectedScreenId = selectedScreens.filter(
        (screenId) => screenId !== null
      );
      console.log(selectedScreenId);
      console.log(selectedContentId);
      // Send the POST request to the server
      for (let i = 0; i < selectedScreenId.length; i++) {
        const response = await Axios.post("adminSlotBooking", {
          username: selectedScreenId[i]["username"],
          screenId: selectedScreenId[i]["screenId"],
          contentId: selectedContentId,
        }).then((response) => {
          console.log(
            "Response for ",
            selectedScreenId[i]["screenId"] + response
          );
        });
      }
      console.log("Booking successful!");
    } catch (error) {
      console.log("Error in booking:", error);
    }
  }
  async function instantSchedule() {
    try {
      if (!username || !selectedContentId || selectedScreens.length === 0) {
        console.log("Please fill in all required fields.");
        return;
      }
      const selectedScreenId = selectedScreens.filter(
        (screenId) => screenId !== null
      );
      console.log(selectedScreenId);
      console.log(selectedContentId);
      // Send the POST request to the server
      for (let i = 0; i < selectedScreenId.length; i++) {
        Websocket.emit("admin-slot-booking", {
          username: selectedScreenId[i]["username"],
          screenId: selectedScreenId[i]["screenId"],
          contentId: selectedContentId,
        });
      }
    } catch (error) {
      console.log("Error in booking:", error);
    }
  }
  const modalStyles = {
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      position: "relative",
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      border: "none",
      borderRadius: "8px",
      padding: "0",
      width: "calc(100% - 300px)", // Adjust the value as needed
      maxWidth: "800px", // Adjust the value as needed
      maxHeight: "calc(100% - 300px)", // Adjust the value as needed
      margin: "200px",
      background: "#000",
    },
  };

  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [isParentModalOpen, setIsParentModalOpen] = useState(false);

  const openParentModal = () => {
    setIsParentModalOpen(true);
  };

  const closeParentModal = () => {
    setIsParentModalOpen(false);
  };
  const openChildModal = (url) => {
    setIsChildModalOpen(true);
    console.log("checkhere", url);
    setVideoUrl(url);
    setModalContentIsOpen(true);
  };

  const closeChildModal = () => {
    setIsChildModalOpen(false);
  };

  const isLoaded = true; // Set this based on your loading logic
  const hasContent = content && content.length > 0;
  function extractMinutesFromTime(timeString) {
    const timeParts = timeString.split(":");
    if (timeParts.length === 2) {
      const minutes = parseInt(timeParts[1], 10);
      console.log(minutes);
      return minutes;
    }
    return null;
  }
  const [durMin, setDurMin] = useState(0);
  useEffect(() => {
    setDurMin(extractMinutesFromTime(durationID));
  }, [durationID]);

  const handleButton = (a, b) => {
    setScreenId(a);
  };
  async function dpAdSlotBooking(screenId, username) {
    
    setScreenData({
      username: username,
      screenId: screenId,
      duration: durMin,
      content_to_be_played: selectedContentId,
    })
    console.log("screenData after clicking setData is: ", screenData);
    Websocket.emit("admin-dp-advertiser-instant-booking", screenData);
    console.log("Instant Booking for Ad Slots done");

    Websocket.on("success-Admin-DP-Advertiser-SlotBooking", async () => {
      notify("Content Assigned Successfully!", "success");
      console.log("Event handler executed");
      Websocket.off("success-Admin-DP-Advertiser-SlotBooking");
    });
  }

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/admindashboard");
  };
  return (
    <div className="flex h-screen overflow-hidden w-[100%]">
      <Menu open={open} setOpen={setOpen} handleLogout={handleLogout} />

      <div className="w-full border  h-full ">
        <AdminHeader />

        <div className="border">
          <div className="flex px-10 p-3 items-center">
            <div className="w-full flex items-center pl-5 gap-2 ">
              <button
                onClick={() => {
                  setShowContentDiv2(false);
                  setShowContentDiv(true);
                  openModal();
                }}
                className=" bg-violet-950 text-white py-2 text-md rounded-sm"
              >
                + Add Content
              </button>
              <button
                onClick={() => {
                  setShowContentDiv2(true);
                  setShowContentDiv(false);
                }}
                className="bg-violet-950 text-white text-md py-2 rounded-sm"
              >
                Select Display
              </button>
            </div>
            <div className="">
              <button
                className=" bg-violet-950 text-white py-2 text-md rounded-sm w-[150px]"
                onClick={openParentModal}
              >
                Set Content
              </button>

              <ReactModal
                isOpen={isParentModalOpen}
                onRequestClose={closeParentModal}
                contentLabel="Video Modal"
                className=" flex  justify-center items-center"
                ariaHideApp={false}
              >
                <div className="flex  bg-black/70  h-screen w-full flex-wrap  items-center justify-center gap-1">
                  {content?.map((item, i) => (
                    <div className="w-96 bg-white  h-26 p-3 border" key={i}>
                      <div className="w-96">
                        <div onClick={() => openModal()}>{item.title}</div>
                        <div>{item.contentId}</div>
                        <div>{item.duration}</div>
                        <button
                          onClick={() =>
                            handleSetContent(item.contentId, item.duration)
                          }
                        >
                          Set Content
                        </button>
                        <button onClick={() => openChildModal(item.contentUrl)}>
                          View Content
                        </button>
                      </div>
                    </div>
                  ))}
                  <button
                    className="hover:bg-red-500 bg-red-300 hover:text-white px-12 py-2"
                    onClick={closeParentModal}
                  >
                    Close
                  </button>
                </div>
                <ReactModal
                  isOpen={isChildModalOpen}
                  onRequestClose={closeChildModal}
                  contentLabel="Video Modal"
                  className="modal border"
                  style={modalStyles}
                  ariaHideApp={false}
                >
                  <div className="w-96 h-96 border border-black">
                    {modalContentIsOpen && (
                      <ReactPlayer
                        url=""
                        playing={modalContentIsOpen}
                        controls={true}
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                  <button
                    className="hover:bg-red-500 hover:text-white px-12 py-2"
                    onClick={closeChildModal}
                  >
                    Close
                  </button>
                </ReactModal>
              </ReactModal>
            </div>
          </div>
        </div>
        {showContentDiv && (
          <div className="p-10 ">
            {/* <div className="w-full border bg-white h-[800px] flex flex-col justify-center items-center">
              <h1 className="text-2xl">No Content to Show here</h1>
              <button
                onClick={openModal}
                className="text-xl ml-10 mt-2 bg-orange-500 text-white"
              >
                Add New Content
              </button>
            
            </div> */}
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                className=" h-full bg-black/70 flex justify-center items-center"
                ariaHideApp={false}
              >
                <video ref={videoRef} controls style={{ display: "none" }} />
                <div className="border w-[50%] shadow-md">
                  <div className="bg-violet-950  p-5 h-20 text-white">
                    <h1 className="text-3xl ">Add Content</h1>
                  </div>
                  <div className="w-full bg-white p-3 h-fit">
                    <form onSubmit={addContent}>
                      <br />
                      <input
                        required
                        type="text"
                        value={New.title}
                        placeholder="Name"
                        onChange={(e) =>
                          setNew({
                            ...New,
                            CampName: e.target.value,
                          })
                        }
                        className="w-full border outline-none mb-3 p-2 rounded-sm"
                      />
                      <br />
                      <input
                        required
                        type="text"
                        value={New.company}
                        placeholder="Company"
                        onChange={(e) =>
                          setNew({
                            ...New,
                            company: e.target.value,
                          })
                        }
                        className="w-full border outline-none mb-3 p-2 rounded-sm"
                      />
                      <br />
                      <input
                        required
                        type="text"
                        value={New.description}
                        placeholder="Describe the Advertisement"
                        onChange={(e) =>
                          setNew({
                            ...New,
                            description: e.target.value,
                          })
                        }
                        className="w-full border outline-none mb-3 p-2 rounded-sm"
                      />
                      <br />
                      <div className="border-2 border-dashed h-20 items-center flex gap-20  justify-center p-3 mb-3">
                        <div className="border-2  hover:border-dashed hover:border-orange-500 cursor-pointer px-5 py-3">
                          {New.videoPath == "" ? (
                            <label
                              className=" cursor-pointer"
                              htmlFor="postedvideos"
                            >
                              Select Advertisement
                            </label>
                          ) : (
                            <label
                              className=" cursor-pointer"
                              htmlFor="postedvideos"
                            >
                              Advertisement Selected
                            </label>
                          )}
                          <input
                            required
                            type="file"
                            id="postedvideos"
                            style={{ display: "none" }}
                            placeholder="Select the Advertisement"
                            accept="video/*"
                            onChange={(e) => {
                              const selectedFile = e.target.files[0];
                              // Check if a file is selected
                              if (selectedFile) {
                                // Get the duration of the selected video
                                const videoElement = document.createElement(
                                  "video"
                                );
                                videoElement.src = URL.createObjectURL(
                                  selectedFile
                                );

                                videoElement.onloadedmetadata = () => {
                                  // Get the duration in seconds
                                  const duration = videoElement.duration;
                                  console.log('video duration is------------------', duration);
                                  // Limit the video duration to 10 seconds
                                  const maxDuration = 10;

                                  if (duration <= maxDuration) {
                                    setNew({
                                      ...New,
                                      videoDetail: selectedFile,
                                      videoPath: e.target.value,
                                    });
                                    handleVideoUpload(selectedFile);
                                  } else {
                                    // Show alert popup
                                    alert(
                                      "You can not upload a video longer than 10 seconds."
                                    );
                                    // Reset the file input
                                    e.target.value = null;
                                  }

                                  // Clean up the videoElement
                                  videoElement.remove();
                                };
                              }
                            }}
                          />
                        </div>
                        <div className="border-2  hover:border-dashed hover:border-orange-500 cursor-pointer px-8 py-3">
                          {New.thumbnailPath == "" ? (
                            <label
                              className=" cursor-pointer"
                              htmlFor="selectThumbnail"
                            >
                              Select Thumbnail
                            </label>
                          ) : (
                            <label
                              className=" cursor-pointer"
                              htmlFor="selectThumbnail"
                            >
                              Thumbnail Selected
                            </label>
                          )}

                          <input
                            required
                            type="file"
                            id="selectThumbnail"
                            style={{ display: "none" }}
                            placeholder="Select the Thumbnail "
                            onChange={(e) => {
                              setNew({
                                ...New,
                                imgDetail: e.target.files[0],
                                imgPath: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex  justify-between mx-2">
                        <div className="flex gap-4">
                          <button
                            id="Addbtn"
                            type="submit"
                            className="bg-orange-400 hover:bg-orange-500 rounded-md text-white px-12 py-2"
                          >
                            Add
                          </button>
                          <button
                            className="hover:bg-violet-950  rounded-md hover:text-white px-12 py-2"
                            type="reset"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                        <button
                          className="hover:bg-red-500 hover:text-white px-12 py-2"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </ReactModal>
          </div>
        )}
        {showContentDiv2 && (
          <div className=" overflow-y-scroll h-[80%]">

          <table className="w-full  border bg-white text-sm text-gray-500  overflow-x-hidden">
            <thead className="bg-gray-50 text-md ">
              <tr className="uppercase text-center text-[12px] text-black">
                <th scope="col" className="py-2 font-medium text-gray-900">
                  S/No.
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  screen_Id
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  screen_Name
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  screen_address
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  TYPE
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  TYPE_VAL
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  CPS
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  Available Slots
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  Select Content
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  Select Screen
                </th>
                <th scope="col" className="py-2 font-medium text-gray-900">
                  Schedule Campaign
                </th>
              </tr>
            </thead>

            <tbody>
              {showScreens.length > 0 ? (
                showScreens.map((elem, i) => (
                  <tr className="text-center" key={i}>
                    <td>{i + 1}</td>
                    <td>{elem?.screenId}</td>
                    <td>{elem?.screenName}</td>
                    <td>{elem?.SCREEN_ADDRESS_FROM_PHONE}</td>
                    <td>{elem?.TYPE}</td>
                    <td>{elem?.TYPE_VAL}</td>
                    <td>{elem?.CPS}</td>
                    <td>{elem?.unbookedSlots}</td>
                    <td></td>
                    <td>
                      {showButton && (
                        <button
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setShowButton(!showButton);
                          }}
                        >
                          Schedule
                        </button>
                      )}
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleCheckboxChange(
                              e,
                              elem.screenId,
                              elem.username
                            )
                          }
                        />
                      </td>
                      {isOpen && (
                        <div>
                          <button onClick={instantSchedule}>
                            Instance Booking
                          </button>
                          <button onClick={schedule}>Advance Booking</button>
                        </div>
                      )}
                    </td>
                    <td>
                      <button onClick={() => dpAdSlotBooking(elem.screenId, elem.username)}>setData</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">Loading data...</td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
        )}

        <div className="flex gap-5 flex-wrap px-3 py-3 justify-evenly">
          {isloaded ? (
            hasContent ? (
              content.map((ind, i) => (
                <MyBox
                  data={ind}
                  key={i}
                  content={content}
                  setPopupVisible={setPopupVisible}
                  setContent={setContent}
                  setPlay={setPlay}
                  setCurrContentId={setCurrContentId}
                />
              ))
            ) : (
              <div className="no_data">
                {/* <h1>No Content to show here</h1>
                            <div className="add" onClick={() => setbtn(true)}>
                                <p>Add New Content</p>
                            </div> */}
              </div>
            )
          ) : (
            <div className="loadContain">
              <div className="load"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Module3;
