import React, { useState } from 'react';
import NodeContext from './nodeContext';

const StateContext = (props) => {
  const tour = {
    count:0 
  }
   // Provide an initial value (e.g., null)
 const [state,setState]= useState(tour);
 const updateFunction = ()=>{
    setState({
        "count":1
    })
 }
  return (
    <NodeContext.Provider value={{state,updateFunction}}>
      {props.children}
    </NodeContext.Provider>
  );
};

export default StateContext;
