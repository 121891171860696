import React, { useState, useEffect, useContext } from "react";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "../Axios/axios";
import { SocketContext } from "../../socketcontext";
import { ToastContainer, toast } from "react-toastify";
import DpHeader from "../Header/DpHeader";
import DpMenu from "../Menu/DpMenu";
import { BsArrowDownShort, BsArrowUpShort, } from "react-icons/bs";
import { LiaRupeeSignSolid } from 'react-icons/lia'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Select from "react-select";
import NodeContext from '../../Context/nodeContext';
import { Line } from "react-chartjs-2";
import {Chart as Chartjs} from 'chart.js/auto'


export default function Dashboard() {
    const WebSocket = useContext(SocketContext)
    const [dpdata, setDpData] = useState([]);
    const [dpRevenu, setDpRevenu] = useState([]);
    const [dpdataPayment, setDpDataPayment] = useState({});
    const [dn, setDn] = useState({});
    const [dnWithdwral, setDnWithdwral] = useState([]);

    console.log("dasdasdas", dn);
    console.log("withwral------------------------------", dpRevenu);
    const navigate = useNavigate();

    function handleDisplay() {
        navigate("/MyDisplays");
    }

    // const [data, setData] = useState([]); // To store the fetched data
    const [username, setUsername] = useState("");
    useEffect(() => {
        setUsername(sessionStorage.getItem("username")?.split("@")[0]);
    }, []);
    const [location, setLocation] = useState([28.6041, 77.25]);
    const newusername = sessionStorage.getItem("username")
    useEffect(() => {
        WebSocket.on('graf-data', (response) => {
            // Handle the received data, e.g., update state
            console.log('Received message:', response);
        });
      
    }, []);

    useEffect(() => {
        async function fetchDataPayment() {
            try {

                const response = await Axios.get(`getAllDashboardDataOfDN?username=${newusername}`);
                console.log("resposne", response.data.ActiveScreen);
                console.log("resposne", response.data);
                setDpDataPayment(response.data);
                setDnWithdwral(response.data.WithdrawalData)
                setDpData(response.data.saduleData)
                setDn(response.data.Totalearnig)

                console.log("dp response", response);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }

        fetchDataPayment();
    }, []);
    const [chartData, setChartData] = useState([]);
    const [range, setRange] = useState('weekly');
    const [currRevenue, setCurrRevenue] = useState(5000);
    console.log(range,"------------------------");
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long' };
        return date.toLocaleDateString('en-GB', options);
    }

    const [userGraph, setUserGraph] = useState(null)
    const [dataError, setDataError] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`user-revenue-graph/${newusername}/${range}`);
                console.log('pen--------------', response?.data?.data);
    
                if (response?.data?.data?.length > 0) {
                    setUserGraph({
                        labels: response?.data?.data?.map((individualData) => formatDate(individualData.date)),
                        datasets: [{
                            label: 'Amount',
                            fill: true,
                            backgroundColor: 'rgba(255, 245, 231, 0.4)',
                            borderColor: 'rgb(255, 142, 0)',
                            borderWidth: 1,
                            data: response?.data.data.map((individualAmount) => individualAmount.amount)
                        }],
                        options: {
                            scales: {
                                yAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }],
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }]
                            }
                        }
                    });
                } else {
                    console.log('No Data Available');
                }
            } catch (error) {
                if(error.response.status==404) {
                    setDataError(true)
                }
                console.error('Error fetching data:', error);
                // Handle error here
            }
        };
    
        fetchData();
    }, [range]);
    
    const options = {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            grid: {
                display: false, 
            },
            beginAtZero: false,
          },
          x: {
            grid: {
              display: false, 
            },
          },
        },
        cubicInterpolationMode: 'monotone', // Set the interpolation mode to 'monotone' for curved lines
      };

    const initialDateFilter = { value: 'daily', label: 'Today' };
    const [selectedDateFilter, setSelectedDateFilter] = useState(initialDateFilter);
    const dateOptions = [
        { value: 'daily', label: 'Today' },
        { value: 'weekly', label: 'Last 7 days' },
        { value: 'monthly', label: 'Last 30 days' }
    ];

    const initialCategoryFilter = "all";
    const [categoryFilter, setCategoryFilter] = useState(initialCategoryFilter);
    const categoryOptions = [
        { value: 'all', label: 'Venue Type' },
        { value: 'Hotels', label: 'Hotels' },
        { value: 'Restaurants', label: 'Restaurants' },
        { value: 'Spa and Fitness Centers', label: 'Spa and Fitness Centers' },
        { value: 'Clubhouse', label: 'Clubhouse' },
        { value: 'Pro Shop', label: 'Pro Shop' },
        { value: 'Airport Lounges', label: 'Airport Lounges' },
        { value: 'Others', label: 'Others' },
    ];
    const handleRange = (e) => {
        setRange(e.target.value);
        console.log('graph select value', range);
    }

   

    const handleCategoryChange = (selectedOption) => {
        setCategoryFilter(selectedOption.value);
    };

    const handleDateFilterChange = (selectedOption) => {
        setSelectedDateFilter(selectedOption);
    };

    useEffect(() => {
        async function fetchData() {
            setDpRevenu([]);
            try {
                const selectedValue = selectedDateFilter.value;
                const category = categoryFilter;
                console.log("category",category,selectedValue,newusername);
                const response = await Axios.get(`getRatingDetail?username=${newusername}&range=${selectedValue}&category=${category}`);
                console.log("-----------------------response", response);
                if(response.data.sanitizedScreens.length === 0){
                    setDpRevenu([]);
                    return;
                }
                setDpRevenu(response.data.sanitizedScreens);
            } catch (error) {
                console.error('An error occurred:', error);
            }
        }

        fetchData();
    }, [selectedDateFilter, categoryFilter]);

    const styles = {
        fontSize: 14,
        color: 'blue',
    }
    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: "white",
            borderRadius: "4px", 
            width: "150px",
            borderColor: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                borderColor: state.isFocused ? 0 : 0
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "" : "", 
            color: state.isFocused ? "" : "", 
            cursor: 'pointer',
        }),

    };


    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: '#Welcome',
                popover: {
                    title: 'Welcome!', description: 'Hi Partner Welcome to Display Network ! Lets take a tour to help you get started.', side: "over", align: 'center'
                }
            },
            {
                element: '#dpMenu',
                popover: {
                    title: 'Side Bar Navigation',
                    description: 'You can navigate through this web app using the sidebar on the left.', side: "left", align: 'start'
                }
            },
            {
                element: '#view',
                popover: {
                    title: 'View Screens',
                    description: 'You can view and manage all of your screens from here.', side: "bottom", align: 'start'
                }
            },
            {
                element: '#funds',
                popover: {
                    title: 'Withdraw Funds',
                    description: 'You can view, track and withdraw all of your available funds from here.', side: "bottom", align: 'start'
                }
            },
            {
                element: '#revenue',
                popover: {
                    title: 'View Revenue',
                    description: 'Quick access to revenue generated and view detailed report.', side: "left", align: 'start'
                }
            },
            {
                element: '#recent',
                popover: {
                    title: 'View Your Recent withdrawals',
                    description: 'Quick glimpse of your recent withdrawal requests.', side: "top", align: 'start'
                }
            },
            {
                element: '#revenueScreen',
                popover: {
                    title: 'View Your Revenue by Screens',
                    description: 'Quick glimpse of your recent withdrawal requests.', side: "bottom", align: 'start'
                }
            },
            {
                element: '#filter',
                popover: {
                    title: 'Use Filters',
                    description: 'Use these filters to narrow down your list of items.', side: "top", align: 'start'
                }
            },
            {
                element: '#recentSchedule',
                popover: {
                    title: 'View recent schedules',
                    description: 'View your recent scheduled contents on the screens', side: "left", align: 'start'
                }
            },
        ]
    });

    
    const a = useContext(NodeContext);
    console.log("asdas", a.state.count);
    if (a.state.count === 1) {
        driverObj.drive();
        a.state.count = 0;
        console.log(a.state.count);
    }

    return (
        <div id="Welcome" className="h-screen  flex w-screen ">
            <div id="dpMenu" >
                <DpMenu />
            </div>
            <div className="h-screen w-screen  overflow-hidden bg-[#FFFDF9]">
                <ToastContainer />
                <DpHeader />

                <div className="flex flex-col p-[15px] gap-4 w-[100%] h-[100%]">
                    <div className="w-[100%] h-[14%] flex gap-4">
                        <div className="w-[50%] h-[104px] shadow-md bg-white shadow-[#FFF1EB] shadow-opacity-[30%] rounded-[4px] flex justify-evenly gap-4 divide-x-2 divide-dashed "
                            style={{ boxShadow: '0px 0px 11px 0px rgba(255, 209, 189, 0.3)' }}>
                            <div className="w-[60%] flex md:m-4   divide-x-2 divide-dashed ">
                                <div className=" flex flex-col gap-2 w-[50%] ">
                                    <p className="text-[#555555] font-medium"
                                    >Active Screens</p>
                                    <p className="text-[#FF8E00] text-[24px] font-medium">{dpdataPayment.ActiveScreen}
                                    </p>
                                </div>
                                <div className="flex flex-col gap-2 w-[50%]">
                                    <p className="mx-4 text-[#555555]">InActive Screens</p>
                                    <p className="text-[#9D9D9D] text-[24px] font-medium mx-4">{dpdataPayment.UnActiveScreen}</p>
                                </div>
                            </div>
                            <div className="w-[40%] h-[70%] m-4 ">
                                <Link
                                    to={"/MyDisplays"}
                                >
                                    <button id="view" className="border border-solid border-[#FF8E00] hover:bg-[#FF8E00] hover:text-white  w-[94%] h-[65%] mx-4 my-2 rounded-sm text-[#FF8E00] font-semibold"

                                    >
                                        View All ({dpdataPayment.totalScreen})
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="w-1/2 h-[104px] bg-white shadow-[#FFF1EB] shadow-md shadow-opacity-[30%] rounded-[4px] flex justify-evenly gap-4 divide-x-2 divide-dashed" style={{ boxShadow: '0px 0px 11px 0px rgba(255, 209, 189, 0.3)' }}>
                            <div className="w-[60%] flex md:m-4   divide-x-2 divide-dashed ">
                                <div className=" flex flex-col gap-2 w-[50%] ">
                                    <p className="text-[#555555]">Total Earnings</p>
                                    <p className="text-[#555555] text-[24px] font-medium">
                                        {parseFloat(dn.TotalRevenue).toFixed(2)}
                                    </p>
                                </div>
                                <div className="flex flex-col gap-2 w-[50%]">
                                    <p className="mx-4 text-[#555555]">Available Funds</p>
                                    <p className="text-[#555555] text-[24px] font-medium mx-4">
                                        {dn.AvailableFunds}
                                    </p>
                                </div>
                            </div>
                            <div className="w-[40%] h-[70%] m-4">
                                <Link
                                    to={"/Funds"}
                                >
                                    <button id="funds" className="border border-solid border-[#FF8E00] hover:bg-[#FF8E00] hover:text-white w-[94%] h-[70%] mx-4 my-2 rounded-sm text-[#FF8E00] font-semibold">
                                        Withdraw Funds
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-[100%] h-[40%]  gap-4  flex">
                        <div className="w-[60%]  bg-white shadow-[#FFF1EB] shadow-md shadow-opacity-[30%] rounded-[4px]  flex ">
                            <div className="w-[100%] shadow-sm   shadow-[#FFD1BD4D] bg-white flex "
                                style={{ boxShadow: '0px 0px 11px 0px rgba(255, 209, 189, 0.3)' }}>
                                <div className="w-full">
                                    <div className="flex justify-between mx-12 my-4 w-[92%]">
                                        <h1 id="revenue" className="text-[#555555] text-[20px] font-normal">Revenue Report </h1>
                                        <select className="outline-none cursor-pointer mr-6"
                                            value={range}
                                            onChange={handleRange}
                                        >
                                            <option className="text-[#555555]" value="weekly">
                                                This Week
                                            </option>
                                            <option className="text-[#555555]" value="monthly">
                                                This Month
                                            </option>
                                        </select>
                                                                        </div>
                                    {dataError?(
                                        <div   className="w-full pt-24   flex  justify-center">
                                        {/* <p className="text-[#555555]">No Data Available</p> */}
                                        
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM-hK0RedfGRZP4SQXm_D6n-0Zy94VdmGZcg&s" className="w-20 h-20" alt="" />
                                        </div>
                                    ):(
                                     <ResponsiveContainer width="100%" height="80%">
                                        {range === 'weekly' ? (userGraph ? (<Line className="p-4" options={options} data={userGraph}/>):( <div></div>) ) :
                                         ( <Line className="p-4" options={options} data={userGraph}/>)}                                            
                                    </ResponsiveContainer>
                                    )
                                                                }

                                </div>




                            </div>
                        </div>
                        <div className="w-[40%] shadow-[#FFF1EB] shadow-md shadow-opacity-[30%] rounded-[4px]   bg-white"
                            style={{ boxShadow: '0px 0px 11px 0px rgba(255, 209, 189, 0.3)' }}>
                            <div className="flex justify-between m-4 items-center">
                                <p id="recent" className="text-[#555555] font-normal text-[20px]">Recent Withdrawal Requests</p>
                                <Link to={"/Funds"} className="text-[#FF9002]">View All History</Link >
                            </div>
                            <div className='w-[100%] h-[60%] mr-1 flex flex-col items-center '>
                                <table className="border-separate border-spacing-y-2 text-[#555555]  w-[98%] text-sm">
                                    <thead >
                                        <tr className="text-left">
                                            <th scope="col"
                                                className="py-4 border-b  pl-4">Date & Time</th>
                                            <th scope="col"
                                                className="border-b pr-20">Amount</th>
                                            <th scope="col"
                                                className="py-4 border-b  pl-4">Status</th>
                                        </tr>
                                    </thead>
                                    {dnWithdwral.map((items, index) => (
                                        <tbody>
                                            <tr key={index}>
                                                <td className="bg-white text-[#555555] rounded-l-full py-[0.5px] pl-4">
                                                    {items.createdAt}
                                                </td>
                                                <td className="bg-white text-[#555555] pr-20">   {items.amount}</td>
                                                <td className={` rounded-[4px] w-[90px]   text-center ${items.status === "Approved" ? "bg-[#E9F6EE]  text-[#20A756]" :
                                                    items.status === "Pending" ? "bg-[#FDEDCF] text-[#F4A50D]" :
                                                        items.status === "Rejected" ? "bg-[#FCEDED] text-[#EF4E38]" : ""
                                                    }`}>
                                                    {items.status}
                                                </td>
                                            </tr>
                                        </tbody>))}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="w-[100%] h-[33%]  rounded-[4px]   flex gap-4">
                        <div className="w-[60%] h-[100%] shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]  bg-white"
                            style={{ boxShadow: '0px 0px 11px 0px rgba(255, 209, 189, 0.3)' }}>
                            <div className="flex items-center justify-between border-b pb-2 m-4">
                                <p id="revenueScreen" className="text-[#555555] text-[20px] font-normal">
                                    Revenue by Screens
                                </p>
                                <div id="filter" className="flex items-center gap-2 justify-end">
                                    <Select

                                        styles={customSelectStyles}
                                        className="w-fit flex text-[#9D9D9D] cursor-pointer outline-none focus:outline-none !important"
                                        value={categoryOptions.find(option => option.value === categoryFilter)}
                                        onChange={handleCategoryChange}
                                        options={categoryOptions}
                                        clearable={false}
                                        style={styles.select}
                                    />

                                    <Select
                                        styles={customSelectStyles}
                                        className="w-fit flex text-[#9D9D9D] cursor-pointer outline-none focus:outline-none !important"
                                        value={selectedDateFilter}
                                        onChange={handleDateFilterChange}
                                        options={dateOptions}
                                    />

                                </div>

                            </div>
                            <div className="w-[96%] h-[60%]  mx-4 flex justify-between overflow-y-scroll">
                                <table className="border-separate border-spacing-y-2  w-[98%] text-sm">
                                    {dpRevenu.length === 0 && (<>No Screen Found </>)}

                                    {dpRevenu?.map((item, index) => (
                                        <tbody key={index}> {/* Don't forget to add a key */}
                                            < tr className="h-fit flex items-center justify-between" >
                                                <td className="bg-white h-10 flex flex-col  w-fit  pl-4">
                                                    <p className="text-[16px] w-fit text-[#555555] origin-top font-normal capitalize "> {item.screenName}</p>
                                                    <p className="text-[12px] text-[#555555]">{item.TYPE}</p>
                                                </td>
                                                <td className="bg-white flex items-center justify-end gap-3 h-10 text-right">
                                                    <span className="text-[16px] flex gap-0 items-center font-normal text-[#555555]">
                                                        <LiaRupeeSignSolid /> {item.amount?.toFixed(2)}
                                                    </span>
                                                    <span>      {item.date_of_addition}</span>

                                                    {item.status === "No Change" ? (
                                                        <span className={`hidden text-[12px] items-center rounded-md px-1 justify-center bg-[#FCEDED] text-[#F4A50D] cursor-pointer`}>
                                                          {item.percentage?.toFixed(2)} %
                                                        </span>
                                                    ) : item.status == true? (
                                                        <span className={`flex text-[12px] items-center rounded-md px-1 justify-center bg-[#E9F6EE] text-[#20A756] cursor-pointer`}>
                                                            <BsArrowUpShort className="text-[14px]" /> {item.percentage?.toFixed(2)} %
                                                        </span>
                                                    ) : item.status == false ? (
                                                        <span className={`flex text-[12px] items-center rounded-md px-1 justify-center bg-[#FDEDCF] text-[#EF4E38] cursor-pointer`}>
                                                            <BsArrowDownShort className="text-[14px]" /> {item.percentage?.toFixed(2)} %
                                                        </span>
                                                    ) : item.status=="N/A" &&(<>
                                                        <span className={`text-[12px] items-center rounded-md px-1 justify-center bg-[#FDEDCF] text-[#EF4E38] cursor-pointer hidden`}>
                                                             --
                                                        </span>
                                                    </>)
                                                    } 
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                        <div className="w-[40%] h-auto shadow-md  shadow-[#FFF1EB] shadow-opacity-[30%]  rounded-[4px]  bg-white   "
                            style={{ boxShadow: '0px 0px 11px 0px rgba(255, 209, 189, 0.3)' }}>
                            <div className="flex justify-between m-4 items-center">
                                <p id="recentSchedule" className="text-[#555555]  text-[20px] font-normal">Recent Schedules</p>
                                <Link to={"/schedule"} className="text-[#FF9002]">View All Schedules</Link >
                            </div>
                            <div className='w-[100%] h-[60%] mr-1 flex flex-col items-center'>
                                <table className="border-separate text-[#555555] border-spacing-y-2  w-[98%] text-sm">
                                    <thead >
                                        <tr className="text-left text-[16px]">
                                            <th scope="col"
                                                className="py-2 border-b  pl-4">
                                                Screen Id
                                            </th>
                                            <th scope="col"
                                                className="py-2 border-b  pl-24">
                                                Ad Type
                                            </th>
                                            <th scope="col"
                                                className="border-b pl-2 ">
                                                Screen
                                            </th>
                                            <th scope="col"
                                                className="py-2 border-b  text-center">Date & Time
                                            </th>
                                        </tr>
                                    </thead>
                                    {dpdata.map((item, index) => (
                                        <tbody key={index}> {/* Don't forget to add a key */}
                                            <tr className="text-[#555555] text-[16px]">
                                                <td className="bg-white py-1 pl-6">
                                                    {item.screenId}
                                                </td>
                                                <td className="bg-white  pl-24">{/* You can add content here */}
                                                    {item.bookingtype}
                                                </td>
                                                <td className="bg-white pl-2">
                                                    {item.screenName}
                                                </td>
                                                <td className="bg-white text-center">
                                                    {item.date}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}