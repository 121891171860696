import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import Axios from "../Axios/axios";
import ReactModal from "react-modal";
import AuthForm from "./AuthForm";
import { ThreeDots } from "react-loader-spinner";
import { IoCloseOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "../Menu/Menu";



function Admin() {

    const [loading, setLoading] = useState(true);
    const [auth, setAuth] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [adminId, setAdminId] = useState("");
    const [pass, setPass] = useState("");
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [selectedUserView, setSelectedUserView] = useState(null);
    const [showModalView, setShowModalView] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const usersPerPage = 10;
    const [order, setOrder] = useState("ASC");
  

    const sorting = (col) => {
        if (order === "ASC") {
            const sorted = [...users].sort((a, b) =>
                a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
            );
            setUsers(sorted);
            setOrder("DSC");
        }
        if (order === "DSC") {
            const sorted = [...users].sort((a, b) =>
                a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
            );
            setUsers(sorted);
            setOrder("ASC");
        }
    };
    const [otp, setOtp] = useState(false);
    const rotate = () => { };

    const [role, setRole] = useState("Advertiser");

    useEffect(() => {
        async function fetchData() {
            const admin = sessionStorage.getItem("admin");
            console.log(admin);
            if (admin !== null) {
                //loader begin
                const adminId = sessionStorage.getItem("adminId");
                const pass = sessionStorage.getItem("adminPass");
                var allUsers = [];

                const res = await Axios.post("adminAccess", {
                    id: adminId,
                    password: pass,
                }).then((res) => {
                    // console.log(res.data);
                    // allUsers = res.data.Users;
                    // setUsers(allUsers);
                    if (res.status === 200) {
                        setAuth(true);
                        setLoading(true);
                        role == "Advertiser"
                            ? Axios.get("getAllAdvertisers")
                                .then((res) => {
                                    console.log(res.data.Users);
                                    setUsers(res.data.Users);
                                })
                                .catch((err) => {
                                    console.log(err);
                                })
                            : Axios.get("getAllUsers").then((res) => {
                                console.log(res.data.Users);
                                setUsers(res.data.Users);
                            });
                    }
                });
            } else {
                setLoading(true);
            }
        }
        fetchData();
    }, [role]);

    const offset = currentPage * usersPerPage;
    // const paginatedUsers = users.slice(offset, offset + usersPerPage);

    function handlePageChange(data) {
        const selectedPage = data.selected;
        setCurrentPage(selectedPage);
    }

    const removeUserView = (user) => {
        setSelectedUserView(user);
        openModalView();
    };

    const openModalView = () => {
        setShowModalView(true);
    };

    const closeModalView = () => {
        setShowModalView(false);
    };

    const handleSearch = () => {
        // Perform the search logic based on the searchValue
        const filteredUsers = users.filter((user) =>
            user.username.toLowerCase().includes(searchValue.toLowerCase())
        );
        console.log("Filtered Users:", filteredUsers);
    };

    const register = async (e) => {
        e.preventDefault();
        const response = await Axios.post("register", {
            name: name,
            username: username,
            password: password,
        });
        if (response.status === 200) {
            notifyy("User Added Successfull");
            setUsers([
                ...users,
                {
                    name: name,
                    username: username,
                    no_of_screens: 0,
                    total_videos_added: 0,
                    playlists_created: 0,
                },
            ]);
            setAddUser(false);
        } else {
            notify("User Not Created");
        }
    };
    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(adminId);
        console.log(pass);
        var allUsers = [];
        const res = await Axios.post("sendadminOTP", {
            id: adminId,
            password: pass,
        });
        if(res?.status === 200){
            setOtp(true)

            notifyy("OTP Sent Successfully");
        } else if (res?.status === 401) {
            notify("Invalid Credentials");
        }
        else{
            notify("Invalid Credentials");
        }
        // allUsers = res.data.Users;
        // setUsers(allUsers);
        // sessionStorage.setItem("admin", true);
        // sessionStorage.setItem("adminId", adminId);
        // sessionStorage.setItem("adminPass", pass);
        // setAuth(true);
    };

    const removeUser = async (aUser) => {
        let ans = prompt("Please enter your name", "");
        if (ans == aUser.username) {
            try {
                await Axios.post("/removeUserFromAdmin", {
                    username: aUser.username,
                });
                setUsers(
                    users.filter((user) => user.username !== aUser.username)
                );
            } catch (error) {
                console.error(error);
                alert("An error occurred while removing the user.");
            }
        } else if (ans == aUser.name) {
            alert("Please Enter User Id");
        } else {
            alert("User input was canceled.");
        }
    };

    const loginFromAdmin = (user) => {
        sessionStorage.setItem("username", user.username);
        // navigate("/Home");
    };

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };
        
    const closeModal = () => {
        setShowModal(false);
    };

    const notify = (text) =>
        toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyy = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    return !loading ? (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#340c42"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    ) : auth ? (
        <>
            <div className="userControlle h-fit">
                <div className="w-full h-fit ">
                    <div className="flex bg-gray-100 text-black ">
                        <Menu />
                    </div>
                </div>

               

                <ReactModal
                    isOpen={showModal}
                    className="modal"
                    overlayClassName="overlay"
                >
                    <div className="flex justify-between rounded-md bg-fuchsia-950 mb-0 p-2 pl-2 pr-2">
                        <div className="pl-2 font-medium text-white">
                            Add User Here
                        </div>
                        <div className="">
                            <IoCloseOutline
                                size={25}
                                className="text-white rounded-2xl cursor-pointer border"
                                onClick={closeModal}
                                id="cross"
                            />
                        </div>
                    </div>
                    <form
                        onSubmit={(e) => {
                            register(e);
                            closeModal();
                        }}
                        className="form"
                    >
                        <div className="box">
                            <label htmlFor="name">Full Name</label>
                            <input
                                type="text"
                                id="name"
                                placeholder="Enter your name"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="box">
                            <label htmlFor="Email">Email</label>
                            <input
                                type="email"
                                id="Email"
                                placeholder="Enter your email"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="box">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Enter your password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button id="sign" type="submit">
                            Sign Up
                        </button>
                    </form>
                </ReactModal>
                <ToastContainer />
            </div>
        </>
    ) : (
        <AuthForm
            handleSubmit={handleSubmit}
            setAdminId={setAdminId}
            setPass={setPass}
            pass = {pass}
            otp={otp}
            setOtp={setOtp}
            adminId={adminId}
        />
    );
}

export default Admin;
