import React from 'react';
import {
  APIProvider,
  Map,
  Marker,
} from '@vis.gl/react-google-maps';
import ClusteredMarkers from '../MapUtil/ClusteredMarkers';

const API_KEY = 'AIzaSyAM3haKObpp45tDYEhjN1oasaDvf9PHJH4';

const MapComponent = ({screens}) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <APIProvider apiKey={API_KEY} libraries={['marker']}>
        <Map
          mapId={'b5387d230c6cf22f'}
          defaultZoom={3}
          defaultCenter={{ lat: 28.6041, lng: 77.25 }}
          gestureHandling={'greedy'}
          style={{ width: '100%', height: '100%' }}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={true}
        >
          <ClusteredMarkers screens={screens} />
        </Map>
      </APIProvider>
    </div>
  );
};

export default MapComponent;
