import React, { useState, useEffect } from 'react';
import Box from './Inbox_box';
import Axios from '../Axios/axios';


function Inbox() {
  const [arr, setArr] = useState([])
  useEffect(() => {
    // Fetch JSON data during page loading
    const fetchData = async () => {
      try {
        const response = await Axios.get('getInbox');
        console.log(response.data.Inbox);
        const data = response.data.Inbox;
        setArr(data);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    fetchData();
  }, []);
  const pendingRequests = arr.filter((data) => data.status == -1);
  const approvedRequests = arr.filter((data) => data.status == 1);
  const rejectedRequests = arr.filter((data) => data.status == 0);
  return (
    <div className='main'>
      <div className="Inbox_header">
        <h1>Inbox - Request</h1>
      </div>
      <div className="BoxContain">
        {pendingRequests.map((data) => (
          <Box
            key={data.campaignRequestId}
            campaignRequestId={data.campaignRequestId}
            campaignName={data.campaignName}
            fromUser={data.fromUser}
            toUser={data.toUser}
            content_to_be_played={data.content_to_be_played}
            thumbnail_for_content_to_be_played={data.thumbnail_for_content_to_be_played}
            fromDate={data.fromDate}
            toDate={data.toDate}
            fromTime={data.fromTime}
            toTime={data.toTime}
            selectedScreens={data.selectedScreens}
            status={data.status}
            newEdit={data.newEdit}
            additional_comments={data.additional_comments} />
        ))}
      </div>
    </div>
  )
}

export default Inbox