import React, { useEffect, useRef, useState } from "react";
import Header from "../../Header/AdHeader";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDownCircle } from "react-icons/ai";
import { MdAccountBalanceWallet, MdAddToQueue } from "react-icons/md";
import { RxCalendar, RxDashboard } from "react-icons/rx";
import { BiBookContent, BiSolidBookContent } from "react-icons/bi";
import { BsArrowLeftCircleFill, BsCheck2, BsPlus } from "react-icons/bs";
import Edlogo from "../../Images/everydoor.png";
import Menu from "../Menu";
import { IoChevronForwardOutline } from "react-icons/io5";
 
const Ad_sec = ({
    Ad,
    setAd,
    nextStep,
    prevStep,
    setNoOfDays,
    instant,
    setInstant,
    setFormattedCurrentDate,
}) => {
    const [amt, setAmt] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    const [toDateError, setToDateError] = useState("");
    const [correctDate, setCorrectDate] = useState(false);

    const [endBookingDate, setEndBookingDate] = useState(null);

    const [showTime, setShowTime] = useState(true);

    const [fromDateSelected, setFromDateSelected] = useState(false);
    const [toDateSelected, setToDateSelected] = useState(false);
    const [open, setOpen] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().substring(0, 10);
    setFormattedCurrentDate(formattedCurrentDate);
    const sevenDaysLater = new Date(currentDate);
    sevenDaysLater.setDate(currentDate.getDate() + 7);
    const formattedSevenDaysLater = sevenDaysLater
        .toISOString()
        .substring(0, 10);

    const [fromDateObject, setFromDateObject] = useState(new Date());
    const [toDateObject, setToDateObject] = useState(new Date());

    const handleFromDateChange = async (e) => {
        const { name, value } = e.target;
        setFromDate(e.target.value);
        setFromDateObject(new Date(e.target.value));
        setAd((prevAdData) => ({
            ...prevAdData,
            [name]: value,
        }));
    };
    const handleToDateChange = async (e) => {
        setToDate(e.target.value);
        setToDateObject(new Date(e.target.value));
        const { name, value } = e.target;
        setAd((prevAdData) => ({
            ...prevAdData,
            [name]: value,
        }));
    };

    const handleChangeTime = async (e) => {
        setInstant(true);
        const { name, value } = e.target;
        setAd((prevAdData) => ({
            ...prevAdData,
            [name]: value,
        }));
    };
    console.log(toDateObject);
    console.log(fromDateObject);

    const diffInMs = Math.abs(toDateObject - fromDateObject);
    const numberOfDays = Math.ceil(diffInMs / (1000 * 3600 * 24));
    setNoOfDays(numberOfDays + 1);

    useEffect(() => {
        if (instant === false) {
            if (fromDate <=  formattedCurrentDate) {
                setFromDateSelected(false);
                setFromDateError("Booking is only available from tomorrow.");
            } else {
                setFromDateSelected(true);
                setFromDateError("");
            }
            setToDateSelected(true);
            setToDateError("");
            if (toDate < fromDate) {
                setToDateSelected(false);
                setToDateError("Invalid To Date");
            }
        } else {
            setToDateSelected(true);
            setFromDateSelected(true);
        }

        console.log(fromDate);
        console.log(toDate);
        console.log(Ad);
    }, [fromDate, toDate, instant]);

    useEffect(() => {
        if (fromDateSelected === true && toDateSelected === true) {
            setCorrectDate(true);
        } else {
            setCorrectDate(false);
        }
    }, [fromDateSelected, toDateSelected, fromDate, toDate]);

    const navigate = useNavigate();
    function handleCancel() {
        navigate("/home");
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    const [btn, setBtn] = useState(false);
    function handleBtn() {
        setBtn((prevBtn) => !prevBtn);
        setShowTime((prevShowTime) => !prevShowTime);
    }
    return (
        <div className="flex h-screen w-[100%] overflow-hidden bg-gray-100">
            <Menu />

            <div
                className={`feature flex-1   select-none  h-screen   ${open ? "w-70" : "w-full"
                    }`}
            >
                <div className="border bg-[white] ">
                    <Header />
                </div>
                <div className="w-full pb-10">
                    <div className="flex  flex-wrap  gap-3 p-2 items-center xl:justify-between xl:p-5">
                        <div className=" flex items-center ">
                            Home <IoChevronForwardOutline /> Create Ads{" "}
                            <IoChevronForwardOutline />
                            Set Schedule
                        </div>
                        <div className="">
                            <span>Step 2/3</span>
                        </div>
                    </div>
                    <div className="flex  h-full w-full justify-center mt-8">
                        <div className="w-[95%] flex items-center justify-center  ">
                            <form onSubmit={handleSubmit}>
                                {showTime ? (
                                    <div className="xl:w-[1065px] flex flex-col h-[452px] bg-white border border-gray-200 shadow-lg rounded-md">
                                        <div className="flex    p-3 xl:justify-between items-center">
                                            <div className="">
                                                <h1 className="xl:text-[24px] text-sm">
                                                    Set Regular Campaign Date
                                                </h1>
                                            </div>
                                            <div className="mt-2">
                                                <div>
                                                    {isHovered && (
                                                        <div className="bg-white border hidden md:block border-gray-200 rounded-xl h-48   w-72 absolute mt-12 p-8">
                                                            <p>
                                                                Instant
                                                                Advertisements
                                                                are for the
                                                                current day
                                                                only.
                                                                Advertisements
                                                                will run after
                                                                the given start
                                                                time until the
                                                                end of day.
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                                <label
                                                    class="relative inline-flex  items-center cursor-pointer"
                                                    onMouseEnter={() =>
                                                        setIsHovered(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        setIsHovered(false)
                                                    }
                                                    onClick={() => {
                                                        handleBtn();
                                                        setShowTime(!showTime);
                                                        setInstant(!instant);
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value=""
                                                        class="sr-only pt-6  peer outline-none"
                                                    />
                                                    <div className="w-16 h-8  xl:mb-0 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-2 xl:after:top-1  after:left-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-[#FF4D00]"></div>
                                                    {btn ? (
                                                        <span class="ml-3 text-sm font-bold">
                                                            REGULAR ADS
                                                        </span>
                                                    ) : (
                                                        <span class="ml-3 text-sm font-light opacity-80">
                                                            INSTANT ADS
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="p-3 pl-5">
                                                <h1>From</h1>
                                            </div>
                                            <div className="">
                                                <div className="flex-col  items-center xl:justify-center text-center mt-2">
                                                    <label htmlFor="fromDate">
                                                        <input
                                                            className="outline-none cursor-pointer  w-[254px] xl:w-[1024px] p-3  border rounded-full "
                                                            type="Date" 
                                                            value={fromDate}
                                                            id="fromDate"
                                                            name="fromDate"
                                                            required
                                                            onChange={
                                                                handleFromDateChange
                                                            }
                                                            placeholder="From Date"
                                                        />
                                                    </label>
                                                    <p className="mt-6 text-sm xl:text-[16px]">
                                                        {fromDateError}
                                                    </p>
                                                </div>
                                                <div className="p-3 pl-5">
                                                    <h1>To</h1>
                                                </div>
                                                <div className="flex-col  items-center justify-center text-center mt-2">
                                                    <label htmlFor="toDate">
                                                        <input
                                                            className="outline-none p-3  w-[254px] xl:w-[1024px]  border rounded-full "
                                                            type="Date"
                                                            value={toDate}
                                                            id="toDate"
                                                            name="toDate"
                                                            required
                                                            onChange={
                                                                handleToDateChange
                                                            }
                                                            placeholder="To Date"
                                                        />
                                                    </label>
                                                    <div className="h-4">
                                                        <p className="mt-4">
                                                            {toDateError}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex xl:mt-4 justify-end gap-4 mr-10">
                                            <button
                                                className="border   border-dashed hover:bg-[#fe4d00]  text-[#FF4D00] hover:text-white py-1 xl:py-3xl: px-8 rounded-full"
                                                onClick={() => {
                                                    prevStep();
                                                }}
                                                id="Cancel"
                                            >
                                                Back
                                            </button>
                                            <button
                                                className="border bg-[#FF4D00] hover:bg-[#ff8957] text-white py-3 px-10 rounded-full"
                                                id="Next"
                                                onClick={() => {
                                                    if (correctDate === true) {
                                                        nextStep();
                                                    } else {
                                                        alert(
                                                            "Invalid dates selected"
                                                        );
                                                    }
                                                }}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className={`bg-white border border-gray-200 shadow-lg rounded-md xl:w-[1065px] h-[452px] border-b-2  transition-all`}
                                    >
                                        <div className="flex  p-3 justify-between items-center">
                                            <div>
                                                <h1 className="xl:text-[24px] text-sm">
                                                    Schedule Instant Campaign
                                                </h1>
                                            </div>
                                            <div className="mt-2">
                                                <label
                                                    class="relative inline-flex items-center cursor-pointer"
                                                    onMouseEnter={() =>
                                                        setIsHovered(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        setIsHovered(false)
                                                    }
                                                    onClick={() => {
                                                        handleBtn();
                                                        setShowTime(!showTime);
                                                        setInstant(!instant);
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={true}
                                                        value=""
                                                        class="sr-only  peer outline-none"
                                                    />
                                                    <div className="w-16 h-8   bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-2 xl:after:top-1  xl:after:left-[12px] after:left-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-[#FF4D00]"></div>
                                                    {btn ? (
                                                        <span class="xl:ml-3 ml-4 text-sm font-bold text-[#FF4D00]">
                                                            INSTANT ADS
                                                        </span>
                                                    ) : (
                                                        <span class="ml-3 text-md">
                                                            INSTANT AD
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="flex xl:mt-12 mt-5 justify-end gap-4 mr-10">
                                            <button
                                                className="border  border-dashed hover:bg-[#fe4d00]  text-[#FF4D00] hover:text-white py-2 xl:py-3 px-8 rounded-full"
                                                onClick={() => {
                                                    prevStep();
                                                }}
                                                id="Cancel"
                                            >
                                                Back
                                            </button>
                                            <button
                                                className="border bg-[#FF4D00] hover:bg-[#ff8957] text-white xl:py-3 px-10 rounded-full"
                                                id="Next"
                                                onClick={() => {
                                                    if (correctDate === true) {
                                                        nextStep();
                                                    } else {
                                                        alert(
                                                            "Invalid dates selected"
                                                        );
                                                    }
                                                }}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ad_sec;
