import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStatus from "./PaymentStatus";

const PUBLIC_KEY =
    "pk_live_51NdW1cSH7579ohVObYgZQykcDSGaisYtZAl8dxmTw33TYpIeYoUmixDEprQeYNSzF7PrGiNMzmW2zKmFXojXYit100w5R1OKnI";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

function StatusStripe() {
    return (
        <div>
            <Elements stripe={stripeTestPromise}>
                <PaymentStatus />
            </Elements>
        </div>
    );
}

// http://localhost:3000/advertiser/CreateCampaign/?payment_intent=pi_3NqBomSH7579ohVO1weRiimU&payment_intent_client_secret=pi_3NqBomSH7579ohVO1weRiimU_secret_1sY614zkvKXBOiCMaWUbE7F0P&source_redirect_slug=live_YWNjdF8xTmRXMWNTSDc1NzlvaFZPLF9PZFNrbHR0T2VlOGNjR1d4dFhFdmtzYlg4SzB6WDkz0100afvFsNd3&source_type=card

export default StatusStripe;
