import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../Axios/axios";
import ReactPaginate from "react-paginate";
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineSortAscending } from "react-icons/ai";
import Menu from "../../Menu/Menu";
import { Box, Modal } from "@mui/material";
import SignIn from "../../SignIn";
import ViewPinLogin from "./ViewPinLogin";
import { useSignedContext } from "../../Advertise/Context/SignedContext";

function Module4() {
  const [open, setOpen] = useState(true);
  const [addUser, setAddUser] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [selectedUserView, setSelectedUserView] = useState(null);
  const [showModalView, setShowModalView] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10; // Number of users to display per page
  const [order, setOrder] = useState("ASC");
  const [rotated, setRotated] = useState();
  const [loaded, setLoaded] = useState(0);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("ASC");
    }
  };

  const [role, setRole] = useState("display-provider");

  const offset = currentPage * usersPerPage;
  const paginatedUsers =
    users.length > 0 ? users.slice(offset, offset + usersPerPage) : 0;
  function handlePageChange(data) {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  }

  const removeUserView = (user) => {
    setSelectedUserView(user);
    openModalView();
  };

  const openModalView = () => {
    setShowModalView(true);
  };
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const [opening, setOpening] = useState(false);
  const handleOpen = () => setOpening(true);
  const handleClose = () => setOpening(false);

  const removeUser = async (aUser) => {
    let ans = prompt("Please enter your name", "");
    if (ans == aUser.username) {
      try {
        await Axios.post("/removeUserFromAdmin", {
          username: aUser.username,
        });
        setUsers(users.filter((user) => user.username !== aUser.username));
      } catch (error) {
        console.error(error);
        alert("An error occurred while removing the user.");
      }
    } else if (ans == aUser.name) {
      alert("Please Enter User Id");
    } else {
      alert("User input was canceled.");
    }
  };
  const [usering, setUsering] = useState(null)
  const [customerId, setCustomerId] = useState("379ufhwvndf");
  const [pin, setPin] = useState("8484521");

  const {signed, setSigned} = useSignedContext();
  
  const loginFromAdmin = async (user) => {
    try {
      sessionStorage.setItem("username", user.username);
      console.log(user.username);
      const response = await Axios.post("/loginFromAdmin", {
        username: user.username,
        cid: customerId,
        pin: pin,
      });
      console.log(response);
      if (response.status === 200) {
        notifyy("User Logged In Successfully");
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("Admin", response.data.DpUsername);
        sessionStorage.setItem("role", response.data.role);
        setSigned(true);
        navigate("/home");
      } else {
        toast.error("An error occurred while logging in.");
      }
    } catch (error) {
      console.log(error);
      notify("An error occurred while logging in.");
    }
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const notify = (text) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyy = (text) =>
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await Axios.get(
          `AdmingetAlladvertiserUsers?role=${role}`
        );
        console.log(response);
        setLoaded(1);
        setUsers(response.data);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
    fetchData();
  }, [role]);
  console.log('usering', usering)
  return (
    <div className="userControlle h-fit">
        <Modal
        open={opening}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <div class="relative w-[85vw] h-[90vh] py-12">
            <div class="relative bg-white rounded-lg shadow w-full">
              <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <button
                  type="button"
                  class="text-black rounded-lg hover:bg-slate-300 hover:rounded-xl text-2xl w-8 h-8 ms-auto inline-flex justify-center items-center"
                  onClick={handleClose}
                >
                  x
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div class="px-4 w-full h-full">
              <ViewPinLogin username={usering}/>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div className="w-full h-fit">
        <div className="flex bg-gray-100 text-black ">
          <Menu />
          <div className="feature flex-1 mt-8">
            <div className="rounded-lg h-[96%] p-8 border bg-white border-gray-200 overflow-x-hidden shadow-md m-5 mt-0">
              <div className="flex justify-between">
                <div className="flex ml-[-12px]">
                  <button
                    className="select"
                    onClick={() => {
                      setRole("display-provider");
                    }}
                  >
                    Show Display Provider
                  </button>
                  <button
                    className="select"
                    onClick={() => {
                      setRole("advertiser");
                    }}
                  >
                    Show Advertiser
                  </button>
                </div>
                <div className="flex items-center gap-3 mr-6">
                  <div>
                    <button className="remove" onClick={openModal}>
                      + Add User
                    </button>
                  </div>
                  <div className="pl-2">
                    <input
                      className="p-3 rounded-md border-none outline-none"
                      type="text"
                      placeholder="Search User"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <table className="border-separate border-spacing-y-4 w-full bg-white text-sm text-gray-500  overflow-x-hidden mt-4 divide-y divide-gray-200 rounded-lg ">
                <thead class="">
                  <tr className="">
                    <th
                      scope="col"
                      className="flex items-center pl-24 gap-3 py-4 font-medium text-gray-900 rounded-l-3xl bg-gray-100"
                    >
                      <AiOutlineSortAscending
                        size={24}
                        className={`cursor-pointer ${!rotated && "rotate-360"}`}
                        onClick={() => {
                          sorting("name");
                        }}
                      />{" "}
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-4 font-medium text-gray-900 bg-gray-100"
                    >
                      Email
                    </th>
                    {/* <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900 bg-gray-100"
                                            >
                                                Screens
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900 bg-gray-100"
                                            >
                                                Content
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-4 font-medium text-gray-900 bg-gray-100"
                                            >
                                                Playlists
                                            </th> */}
                    <th
                      scope="col"
                      className="py-4 font-medium text-gray-900 bg-gray-100"
                    >
                      View
                    </th>
                    <th
                      scope="col"
                      className="py-4 font-medium text-gray-900 bg-gray-100 rounded-r-3xl"
                    >
                      Remove
                    </th>
                  </tr>
                </thead>
                {loaded ? (
                  <tbody className="pb-10 mt-4 text-center h-full border">
                    {paginatedUsers
                      ? paginatedUsers.map(
                          (user, i) =>
                            user.username
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) && (
                              <tr key={i} className="">
                                <td
                                  className="rounded-l-full border-l-2 border-y-2"
                                  onClick={() => removeUserView(user)}
                                >
                                  {user.name}
                                </td>
                                <td className="px-4 border-y-2">
                                  {user.username}
                                </td>
                                {/* <td className="px-4 border-y-2">
                                                            {" "}
                                                            {user.no_of_screens}
                                                        </td>
                                                        <td className="px-4 border-y-2">
                                                            {" "}
                                                            {
                                                                user.total_videos_added
                                                            }
                                                        </td>
                                                        <td className="px-4 border-y-2">
                                                            {" "}
                                                            {
                                                                user.playlists_created
                                                            }
                                                        </td> */}
                                <td className="px-4 border-y-2">
                                  <button
                                    className="remove"
                                    onClick={()=>{
                                      handleOpen();
                                      setUsering(user.username);
                                    }}
                                  >
                                    {/* () => loginFromAdmin(user) */}
                                    View it
                                  </button>
                                </td>
                                <td className="rounded-r-full border-r-2 border-y-2">
                                  <button
                                    onClick={() => {
                                      removeUser(user);
                                    }}
                                    className="text-white rounded-md px-8 shadow-lg font-bold text-md bg-orange-500"
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            )
                        )
                      : ""}
                  </tbody>
                ) : (
                  <div className="w-full h-full flex justify-center items-center ">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#fe4d00"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={Math.ceil(users.length / usersPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={
                    "pagination flex flex-row gap-2  bg-white justify-center items-center"
                  }
                  subContainerClassName={
                    "pages flex flex-row justify-center  bg-white"
                  }
                  activeClassName={"active"}
                />
              </table>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Module4;