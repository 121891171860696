import React, { useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { SlLocationPin } from 'react-icons/sl';
import { GrView } from 'react-icons/gr';
import { MdOutlineDateRange, MdOutlineMail } from 'react-icons/md';
import logo from '../../Images/Ellipse14.png'
import { Menu, Transition } from '@headlessui/react'
import Axios from '../../Axios/axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Members({ member }) {


    const notifyUser = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyEditAccess = (text) =>
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    console.log("hello", member);
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    function classNamesEdit(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [menuactive, setMenuActive] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpenEdit, setMenuOpenEdit] = useState(false);
    const handleSelect = (type) => {
        setMenuOpen(false);
    };
    const handleSelectEdit = (type) => {
        setMenuOpenEdit(false);
    };
    const handleDelete = async (e) => {
        e.preventDefault();
        setMenuOpen(false);
        notifyUser("User Deleted!")
        try {
            const response = await Axios.delete(`sub/subUserDelete/?id=${member._id}`);
            if (response.status === 200) {
                console.log('Response Data:', response);

            } else {
                // Request failed, handle errors here
                console.error('Request failed:', response.status);
            }

        } catch (error) {
            // Handle any network or other errors that might occur during the request
            console.error('Error:', error);
        }
    }
    const handleEditAccess = async (e, role) => {
        e.preventDefault();
        notifyEditAccess("User Role Updated!");
        setMenuOpenEdit(false);
        console.log(member.username)
        console.log(role)
        try {
            const response = await Axios.post('sub/changeRole', {
                username: member.username,
                role: role
            });
            if (response.status === 200) {
                console.log('Response Data:', response);

            } else {
                // Request failed, handle errors here
                console.error('Request failed:', response.status);
            }

        } catch (error) {
            // Handle any network or other errors that might occur during the request
            console.error('Error:', error);
        }
    }

    return (
        <div className="p-4 bg-white rounded-[4px] w-[23%] h-[314px] gap-2">
            <div className=" flex justify-between items-center text-center">
                <p className='bg-[#E9F6EE] text-[#20A756] px-3 py-0  rounded-[4px]'>{member.state} </p>
                <Menu as="div" className="relative inline-block focus:border-[#555555] text-center">
                    <Menu.Button
                        id="accountTypeButton"
                        className=" cursor-pointer bg-[#9D9D9D33] rounded-full p-1"
                    >
                        <BsThreeDotsVertical size={20} onClick={() => setMenuOpen(!menuOpen)} className='' />
                    </Menu.Button>
                    <Transition
                        show={menuOpen}
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute z-20 ml-3 mt-1 outline-none w-[130px] text-left rounded-sm bg-white shadow-xl ring-1 ring-black ring-opacity-5">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-100  text-gray-700 w-full' : 'text-gray-700',
                                                'block px-4 py-1  w-full text-md font-thin'
                                            )}
                                            onClick={handleDelete}
                                        >
                                            DeActivate
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-100  text-gray-700 w-full' : 'text-gray-700',
                                                'py-1   text-md font-thin'
                                            )}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleSelectEdit('Current Account');
                                            }}
                                        >
                                            <Menu as="div" className="relative inline-block focus:border-[#555555] text-center">
                                                <Menu.Button
                                                    id="accountTypeButton"
                                                    className=" cursor-pointer pl-1 py-2 text-md w-[130px] font-thin "
                                                >
                                                    <span className={classNames(
                                                        active ? 'bg-gray-100  text-gray-700 w-full' : 'text-gray-700',
                                                        'py-1  text-md font-thin'
                                                    )} onClick={() => { setMenuOpenEdit(!menuOpenEdit); setMenuActive(!menuactive) }}>Edit Access</span>
                                                </Menu.Button>
                                                <Transition
                                                    show={menuOpenEdit}
                                                    as={React.Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute z-0 left-[130px] top-[7px]   outline-none w-[130px] text-left rounded-sm bg-white ring-1 ring-black ring-opacity-5">
                                                        <div className="py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        href="#"
                                                                        className={classNamesEdit(
                                                                            active ? 'bg-gray-100  text-gray-700 w-full' : 'text-gray-700',
                                                                            'block px-4  w-full text-md font-thin'
                                                                        )}
                                                                        onClick={(e) => handleEditAccess(e, "Manager")}
                                                                    >
                                                                        Manager
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        href="#"
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100  text-gray-700 w-full' : 'text-gray-700',
                                                                            'block px-4  text-md w-full font-thin'
                                                                        )}
                                                                        onClick={(e) => handleEditAccess(e, "Publisher")}
                                                                    >
                                                                        Publisher
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        href="#"
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100  text-gray-700 w-full' : 'text-gray-700',
                                                                            'block px-4  text-md w-full font-thin'
                                                                        )}
                                                                        onClick={(e) => handleEditAccess(e, "Viewer")}
                                                                    >
                                                                        Viewer
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-100  text-gray-700 w-full' : 'text-gray-700',
                                                'block px-4 py-1  text-md w-full font-thin'
                                            )}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleSelect('Current Account');
                                            }}
                                        >
                                            Edit Profile
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <div className='rounded-full  w-16 h-20 py-4'>
                <img src={logo} alt='logo' className='' />
            </div>
            <div className='w-18'>
                <p className='py-2  capitalize'>{member.name}</p>
            </div>
            <div className='w-12 py-3 flex  items-center gap-1'>
                <p className='bg-[#9D9D9D33] px-2 rounded-[4px]' >Role </p><span>{member.role}</span>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex items-center overflow-hidden gap-2'>
                    <MdOutlineMail />
                    <p className=''>{member.username}</p>
                </div>
                <div className='flex items-center gap-2'>
                    <SlLocationPin />
                    <p>Gurugram</p>
                </div>
                <div className='flex items-center gap-2'>
                    <MdOutlineDateRange />
                    <p>{member.createdAt}</p>
                </div>
            </div>
        </div >
    )
}

export default Members