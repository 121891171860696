import React from "react";

class ErrorBoundary extends React.Component{
    constructor(props){
        super(props);
        this.state = {hasError : false}
    }

    componentDidCatch(error){
        console.error('Error caught by error boundary:', error)
        this.setState({hasError : true});
    }

    render(){
        if(this.state.hasError){
            <h1>Something went Wrong</h1>
        }

        return this.props.children ;
    }
}

export default ErrorBoundary ;