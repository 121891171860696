import React, { useState } from 'react'
import verfiy from '../SignIn/images/verify.png';
import logo from "../Images/everydoor.png"
import { Link, useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import Axios from '../Axios/axios'
import { ToastContainer, toast } from "react-toastify";




const notify = (text) =>
    toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

const notifyError = (text) =>
    toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

function NewPassword() {


    const navigate = useNavigate();

    const NavigateVerify = () => {
        navigate('/Signin');
    };


    const username = localStorage.getItem("username")
    const [input, setInput] = useState({
        username: username,
        confirmPassword: ''
    });


    console.log(input);
    const [error, setError] = useState({
        username: username,
        confirmPassword: ''
    })

    const onInputChange = e => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "password":
                    if (!value) {
                        stateObj[name] = "Please enter Password";
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = "Password and Confirm Password does not match";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;

                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match";
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }
    // const handle = () => {
    //     console.log("error")
    // }

    const handleVerify = async (event) => {
        event.preventDefault();
        console.log('data:', input);
        try {
            const response = await Axios.post('/updatePassword', input);
            console.log('data:', response);

            if (response.status === 200) {
                notify("Your Password Successfully Updated");
                setTimeout(() => {
                    // Navigate to another page after a specified time (e.g., 3 seconds)
                    NavigateVerify();
                }, 3000); // 3000 milliseconds (3 seconds)
            } else if (response.status === 201) {
                notifyError("You Entered Old Password!");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }



    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='w-screen h-screen flex items-center justify-center bg-[#F2F2F2]'>
                <div className='h-[85%]  w-[70%] bg-white  flex justify-end '>
                    <div className='flex  flex-col justify-start h-full w-[50%]'>
                        <div className="mx-12" >
                            <img src={logo} alt="evridoor" width="200px" height="100px" />
                        </div>

                        <div className=" ml-6 h-[80%] text-[#727272] flex flex-col justify-center ">
                            <h1 className='mx-6 mb-6'>Important Information:</h1>
                            <div className='flex gap-4 flex-col justify-center h-40 items-center'>
                                <p className='mx-6'>
                                    Please read the below instructions and then kindly share the requested information.
                                </p>
                                <ul className='list-disc mr-10'>
                                    <li> Use Alphanumeric characters in passwords</li>
                                    <li> Your Email ID/ Username  is required</li>
                                    <li> Do not reuse passwords</li>
                                    <li> Do not reveal your password/ OTP to anyone.</li>

                                </ul>
                            </div>
                        </div>
                        <div className="flex text-[#727272] w-full justify-center items-center  pr-12">
                            <p>Contant Us &nbsp;&nbsp;|</p>
                            <p>&nbsp;&nbsp;Term & Conditions&nbsp;&nbsp;|</p>
                            <p>&nbsp;&nbsp;FAQ's</p>
                        </div>
                    </div>
                    <div className='  flex justify-center items-center  rouneded h-[100%]' style={{
                        backgroundImage: `url(${verfiy})`, // Set the background image
                        backgroundSize: 'cover',
                        height: '100%',
                        width: '55%',
                       
                    }}>
                        <div className=' h-[50%] w-[60%] border flex flex-col   items-center gap-4 rounded bg-white'>
                          <div className="w-[90%] mt-4 flex flex-col text-3xl text-[#555555] cursor-pointer gap-[8px]">
                           <Link
                                to="/verify">
                                <BiArrowBack />
                            </Link>
                            <div className="w-[100%] flex flex-col justify-center items-center ">
                                <h1 className='text-[#555555] text-2xl font-bold'>Create A New Password !</h1>
                                <p className='text-sm font-medium'>Set a new password  for your  Account.</p>
                            </div>
                            </div>
                            <form className="w-[90%]">
                                <div className="w-[100%] gap-3   justify-center flex flex-col">
                                    <input className=" border  p-3  rounded outline-none " placeholder="Enter Your New Password" name="password" type='password' value={input.password}
                                        onChange={onInputChange} onBlur={validateInput}
                                        required
                                    />
                                    {error.password && <span className='err text-xs text-red-500'>{error.password}</span>}
                                    <input className=" border  p-3  rounded outline-none" placeholder="Enter Confirm Password" name='confirmPassword' type='password' value={input.confirmPassword}
                                        onChange={onInputChange} onBlur={validateInput}
                                        required
                                    />
                                    {error.confirmPassword && <span className='err text-xs text-red-500'>{error.confirmPassword}</span>}
                                </div>
                                <div className="w-[100%]  mt-6 border bg-gradient-to-r from-[#FB6600] via-[#FF9002] to-[#FB6600] ... flex items-center justify-center rounded">
                                    <button onClick={handleVerify} className='px-4 py-2  text-white' type='submit'>
                                        Set Password
                                    </button>
                                </div>
                            </form>
                           
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default NewPassword