import React, { useState } from "react";
import img from "../../Components/Images/Default.png";
import cartImg from "../../Components/Images/cartImg.png";
import { Link, useNavigate } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { VscKebabVertical } from "react-icons/vsc";
import {
    MdOutlineScreenLockLandscape,
    MdScreenLockPortrait,
} from "react-icons/md";

const Item = ({ Data }) => {
    const navigate = useNavigate();
    console.log(Data);
    const divStyle = {
        backgroundImage: `url(${cartImg})`,
        backgroundSize: "cover",
    };
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toogleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <div className=" w-[314px] h-[100%]  rounded-md border-gray-300 text-[#555555]">
                <div className="p-4 bg-white rounded-md w-[100%]  ">
                    <div className=" h-[100%] ">
                        {Data.connectivity_status ? (
                            <div className="flex  rounded-md h-[188px] w-[282px]">
                                <div
                                    className="relative justify-center w-full cursor-pointer"
                                    style={divStyle}
                                    onClick={() =>
                                        navigate(
                                            `/ManagerDescription?screenId=${Data.screenId}`
                                        )
                                    }
                                >
                                    <div className="flex justify-between">
                                        <div
                                            className={`h-fit relative top-2 text-lg shadow-sm p-1 rounded-sm ml-2 ${Data.connectivity_status
                                                ? "bg-[#20A75633]/80 text-[#20A756]"
                                                : "bg-[#EEEEEE] text-[#9D9D9D]"
                                                } flex  items-center`}
                                        >
                                            <p className=" select-none text-white text-sm">
                                                Active
                                            </p>
                                        </div>
                                        <div className="h-fit cursor-pointer flex bg-white rounded-full mt-2 mr-2 justify-end p-2 ">
                                            <VscKebabVertical />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="flex  rounded-md h-[188px] w-[282px] bg-cover bg-center cursor-pointer"
                                style={{ backgroundImage: `url(${img})` }}
                                onClick={() =>
                                    navigate(
                                        `/ManagerDescription?screenId=${Data.screenId}`
                                    )
                                }
                            >
                                <div className="flex justify-between w-full">
                                    <div
                                        className={`h-fit relative top-2 text-lg shadow-sm p-1 rounded-sm ml-2 ${Data.connectivity_status
                                            ? "bg-[#20A75633]/20 text-[#20A756]"
                                            : "bg-[#EEEEEE] text-[#9D9D9D]"
                                            } flex  items-center`}
                                    >
                                        <p className="select-none text-sm">
                                            Inactive
                                        </p>
                                    </div>

                                    <div className="h-fit cursor-pointer flex bg-white rounded-full mt-2 mr-2 justify-end p-2 ">
                                        <VscKebabVertical />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="mx-1">
                            <div className="flex justify-between  mt-3 capitalize ">
                                <Link
                                    to={`/ManagerDescription?screenId=${Data.screenId}`}
                                    id="link"
                                    style={{ textDecoration: "none" }}
                                >
                                    <p className="text-[20px] text-[#555555]">
                                        {Data.screenName}
                                    </p>
                                </Link>
                                {Data.screenType === "Portrait" ? (
                                    <span className=" flex items-center ">
                                        <MdScreenLockPortrait
                                            size={22}
                                            className="text-[#9D9D9D]"
                                        />
                                        {Data.screenType}
                                    </span>
                                ) : (
                                    <span className="   flex items-center gap-1">
                                        <MdOutlineScreenLockLandscape
                                            size={24}
                                            className="text-[#9D9D9D] "
                                        />
                                        {Data.screenType}
                                    </span>
                                )}{" "}
                            </div>

                            <div className="">
                                <div className="itemCenter">
                                    <p className="flex  text-[#555555] items-center">
                                        <span className="text-[14px]">
                                            Total Earnings : ₹ {Data.amount}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <span
                                onClick={toogleDropdown}
                                className="flex cursor-pointer w-full justify-between mt-4 text-sm"
                            >
                                <p className="flex w-full justify-center text-[#555555]/70 items-center">
                                    <span className="">View More</span>
                                    <span className="">
                                        <RiArrowDropDownLine
                                            size={30}
                                            className={`text-[#555555]/40 ${isDropdownOpen
                                                ? "rotate-180"
                                                : "rotate-0"
                                                } transition-all ease-in-out duration-700 float-right`}
                                            onClick={toogleDropdown}
                                        />
                                    </span>
                                </p>
                            </span>
                            <span className="mb-1  transition-all ease-in-out duration-700 ">
                                {isDropdownOpen && (
                                    <>
                                        <div className="mt-1">
                                            <p className="flex text-[#555555] items-center">
                                                <span className="text-[#001122]">
                                                    Screen Code :&nbsp;
                                                </span>
                                                {Data.screenId}
                                            </p>
                                        </div>
                                        <p className="flex items-center">
                                            Screen Address:&nbsp;
                                            <span className=" capitalize text-[#555555]">
                                                {" "}
                                                {Data.screen_address}
                                            </span>{" "}
                                            <br />
                                        </p>
                                        <p className="flex  text-[#555555] items-center">
                                            <span className="">
                                                Loop Count:&nbsp;{Data.loop}
                                            </span>
                                        </p>
                                        <p className="flex  text-[#555555] items-center">
                                            <span className="">
                                                Content Assigned:&nbsp;
                                                <span className="bg-[#FF8050] text-md  font-thin text-white rounded-full px-1">
                                                    {Data.DPslot}
                                                </span>
                                            </span>
                                        </p>

                                 
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Item;
