import React, { useContext, useEffect, useState } from "react";
import Header from "../../Header/AdHeader";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { BsPlus } from "react-icons/bs";
import admap from "../../Images/Admap.png";
import { FaArrowRight } from "react-icons/fa";
import { HiOutlineHome } from "react-icons/hi";
import { RiAdvertisementFill, } from "react-icons/ri";
import Axios from "../../Axios/axios";
import nodeContext from "../../../Context/nodeContext";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useSignedContext } from "../Context/SignedContext";
import MapComponent from "./MapComponent";

function Ad_first({ google, nextstep }) {
  const defaultProps = {
    center: {
      lat: 28.679079,
      lng: 77.06971,
    },
    zoom: 11,
  };

  const [open, setOpen] = useState(true);

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const handleMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  };

  const handleInfoWindowClose = () => {
    setShowInfoWindow(false);
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const [username, setUsername] = useState("");

  const [stats, setStats] = useState({
    DigitalScreens: 10,
    publishers: 13,
    countries: "1",
  });
  async function fetchStats() {
    const token = localStorage.getItem("token");
    try {
      const response = await Axios.get("advertiser/getALLScreenData", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStats(response.data);
    } catch (error) {
      // Handle errors here, such as logging or displaying a message to the user
      console.error("Error fetching stats:", error);
    }
  }

  const [screensForMap, setScreensForMap] = useState([{screenId: 1,last_known_location: [28.6041, 77.25], SCREEN_ADDRESS_FROM_PHONE: "MOLOG INDUSTRIES"}, {screenId:2, last_known_location: [28.6002, 77.2270], SCREEN_ADDRESS_FROM_PHONE: "MOLOG INDUSTRIES"}, {screenId: 3,last_known_location: [28.29, 77.4], SCREEN_ADDRESS_FROM_PHONE: "MOLOG INDUSTRIES"}]);

  async function fetchScreenMapData() {
    console.log("fetching data for maps");
    try {
      const response = await Axios.get("screenDataApiforMap");
      setScreensForMap(response.data);
      console.log("your data for maps is: ", screensForMap);
    } catch (error) {
      console.log("unable to fetch screen data for maps");
    }
  }

  const {signed, setSigned} = useSignedContext();
  
  useEffect(() => {
    console.log("this is signed value: ", signed);
    setUsername(localStorage.getItem("username")?.split("@")[0]);
    
    setTimeout(async () => {
      fetchScreenMapData();
      fetchStats();
    }, 3000);

  }, []);
  
  useEffect(() => {
    console.log("your data for maps is: ", screensForMap);
    // console.log("your first data is: ", screensForMap[0].last_known_location[0], screensForMap[0].last_known_location[1]);
  }, [screensForMap]);

  const driverObj = driver({
    showProgress: true,
    steps: [
        {
            element: '#Welcome',
            popover: {
                title: 'Welcome!', description: 'Hi Partner Welcome to Advertiser Network! Lets take a tour to help you get started.', side: "over", align: 'center'
            }
        },
        {
            element: '#AdvertiserMenu',
            popover: {
                title: 'Advertiser Menu',
                description: 'You can navigate through this web app using the sidebar on the left.', side: "left", align: 'start'
            }
        },
        {
            element: '#NewCampaign',
            popover: {
                title: 'New Campaign',
                description: 'You can create a new campaign and publish it from here. ', side: "bottom", align: 'start'
            }
        },
        {
            element: '#OurWorldWideScreen',
            popover: {
                title: 'Our WorldWide Screen',
                description: 'You can view all screens from here', side: "bottom", align: 'start'
            }
        },
       
    ]
});

// const a = useContext(nodeContext);

const a = useContext(nodeContext);
console.log("asdas", a.state.count);
if (a.state.count === 1) {
    driverObj.drive();
    a.state.count = 0;
    console.log(a.state.count);
}
console.log("asdas", a.state.count);
const handler = () => {
    a.updateFunction();
}

  return (
    <>
      <div id="#Welcome" className="w-full flex  overflow-x-scroll md:overflow-x-hidden overflow-y-hidden bg-gray-100">
        {/* <div className={`h-screen border bg-red-100 ${open ? "w-25" : "w-0"} `}> */}
        <div id="AdvertiserMenu" >
                <Menu />
          </div>
        <div className={`feature flex-1 select-none h-full w-full`}>
          <div className="border bg-white  ">
            <Header />
          </div>
          <div class="flex flex-col xl:h-[700px] h-full overflow-x-hidden mt-0 p-4">
            <div className="w-full  xl:p-2 gap-3  xl:flex  xl:h-[40%]  h-[70%]">
              <div className="border  mb-3 md:mb-0 p-3 xl:p-6 xl:w-1/3 rounded-xl shadow-md border-gray-300 bg-white flex flex-col justify-between">
                <div>
                  <div>
                    <div>
                      <HiOutlineHome size={30} className="text-orange-400" />
                    </div>
                    <div className="mt-3 mb-3">
                      <h1 className="xl:text-lg text-sm font-bold">
                        Welcome, {username}!
                      </h1>
                    </div>
                  </div>
                  <div className="mb-3">
                    <span className="md:text-lg text-sm  font-extralight">
                      Get Familiar with the dashboard, here are some ways to get
                      started.
                    </span>
                  </div>
                </div>
                <div className="justify-end flex items-center  ">
                  <div    onClick={handler} className="bg-[#FF4D00] p-3 px-4 xl:px-8 mt-2 hover:bg-[#ff8957] cursor-pointer rounded-full text-white w-fit  flex items-center">
                    <span  className="mr-2 text-xs md:text-lg">Explore </span>
                    <div className="">
                      <FaArrowRight />
                    </div>
                  </div>
                </div>
              </div>
              <div className="border mb-3 md:mb-0   p-3 xl:p-6 xl:w-1/3 rounded-xl shadow-md border-gray-300 bg-white flex flex-col justify-between">
                <div>
                  <div>
                    <RiAdvertisementFill
                      size={30}
                      className="text-orange-400"
                    />
                  </div>
                  <div className="mt-3 mb-3">
                    <h1 className="xl:text-lg text-sm  font-bold">
                      Start Your First Campaign!
                    </h1>
                  </div>
                  <div className="mb-3">
                    <span className="md:text-lg text-sm font-extralight">
                      Create Edit and manage all ad campaigns in schedules
                      section.
                    </span>
                  </div>
                </div>
                <div className="justify-end flex items-center  ">
                  <button 
                  id="NewCampaign" 
                    onClick={() => {
                      navigate("/advertiser/CreateCampaign");
                    }}
                    class="bg-[#fe4d00] flex items-center hover:bg-[#ff8957] text-white py-1 xl:py-2 px-2 xl:px-4 border border-blue-700 rounded-full mt-2"
                  >
                    <BsPlus size={30} className="text-white mr-0" />{" "}
                    <span className="mr-3 xl:text-lg text-sm ">New Campaign</span>
                  </button>
                </div>
              </div>
              <div className=" xl:w-1/3  ">
                <div className="flex h-1/2  w-full  ">
                  <div className="w-1/2 border mr-2 mb-2 bg-white border-gray-300 shadow-lg rounded-xl flex flex-col items-center ">
                    <div className="h-1/2 flex items-center justify-center">
                      <h1 className="text-2xl mt-4 text-[#FF4D00] font-bold">
                        {stats.DigitalScreens}
                      </h1>
                    </div>
                    <div className="h-1/2 font-bold">
                      <h1>Digital Screens</h1>
                    </div>
                  </div>
                  <div className="w-1/2 border mb-2 bg-white border-gray-300 shadow-lg rounded-xl flex flex-col items-center ">
                    <div className="h-1/2 flex items-center justify-center">
                      <h1 className="text-2xl mt-4 text-[#FF4D00] font-bold">
                        {stats.countries}
                      </h1>
                    </div>
                    <div className="h-1/2 font-bold">
                      <h1>Countries</h1>
                    </div>
                  </div>
                </div>
                <div className="flex h-1/2  w-full ">
                  <div className="w-1/2 border mr-2  bg-white border-gray-300 shadow-lg rounded-xl flex flex-col items-center ">
                    <div className="h-1/2 flex items-center justify-center">
                      <h1 className="text-2xl mt-4 text-[#FF4D00] font-bold">
                        {stats.publishers}
                      </h1>
                    </div>
                    <div className="h-1/2 font-bold">
                      <h1>Publishers</h1>
                    </div>
                  </div>
                  <div className="w-1/2 border bg-white border-gray-300 shadow-lg rounded-xl flex flex-col items-center ">
                    <div className="h-1/2 flex items-center justify-center">
                      <h1 className="text-2xl mt-4 text-[#FF4D00] font-bold">
                        33k+
                      </h1>
                    </div>
                    <div className="h-1/2 font-bold">
                      <h1>Impressions</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full gap-3 overflow-hidden p-2 md:flex h-[80vh] md:h-[65%]">
      
              <div id="OurWorldWideScreen" className="border w-full h-[100%] flex flex-col gap-2 rounded-xl shadow-lg border-gray-300 bg-white p-2">
                <div className="h-[20%] p-2">
                  <div className="py-2">
                    <img src={admap} alt="" />
                  </div>
                  <div className="flex items-center gap-2">
                    <h1 className="text-lg font-bold py-2">
                      Our WorldWide Displays
                    </h1>{" "}
                    <FaArrowRight />
                  </div>
                  <div className="text-gray-400">
                    <h1>Explore Maps to finds our display screens globally.</h1>
                  </div>
                </div>
           {/*     <div className="h-[70%] p-3 mt-8">
                    <MapComponent screens={screensForMap} />
                </div>
*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ad_first;

