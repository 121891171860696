import React, { useState, useEffect, useContext } from "react";
import Menu from "../../Menu/Menu";
import axios from "axios";
import Axios from "../../Axios/axios";
import { TiEdit } from "react-icons/ti";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { SocketContext } from "../../../socketcontext";
import AdminHeader from "../adminHeader";

function ScreensDetails() {
    const [open, setOpen] = useState(true);
    const [data, setData] = useState({});
    const [inputValue, setInputValue] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("All");
    const [cpsUpdate, setCpsUpdate] = useState(false);
    const [DpSlotUpdate, setDpSlotUpdate] = useState(false);
    const [DpAdSlotUpdate, setAdDpSlotUpdate] = useState(false);
    const [adminSlotUpdate, setAdminSlotUpdate] = useState(false);
    const [slotsUpdate, setSlotsUpdate] = useState(false);
    const [startTimeUpdate, setStartTimeUpdate] = useState(false);
    const [endTimeUpdate, setEndTimeUpdate] = useState(false);
  

    const Websocket = useContext(SocketContext)
    const handleSubmit = async (screenId, cpsValue) => {

        try {
            const response = await Axios.post(
                "updateCPS",
                {
                    screenId: screenId,
                    cps: cpsValue,
                }
            );
            console.log(response);
            setIsUpdated(true);
            toast.success("CPS Value Updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (err) {
            console.log(err);
            toast.error("CPS Value not Updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    const notify = (text, type) => {
        if (type === "success") {
            toast.success(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });

        } else {
            toast.error(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    };

    // const [slots, setSlots] = useState();
    const updateSlots = async (screenId, slots) => {
        try {
            const response = await Axios.post(
                "updateLoopLength",
                {
                    screenId: screenId,
                    NO_OF_SLOTS: slots,
                }
            );
            console.log(response);
            setIsUpdated(true);
            if (response.status === 200) {
              toast.success("Slots Value Updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else if (response.status == 201) {
              toast.error("Slots value can not be less than existing value!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else if (response.status == 204) {
              toast.error("Slots value should be a multiple of 3!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }); 
            }
        } catch (err) {
            console.log(err);
            toast.error("Slots Value not Updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const updateVenueTime = async (screenId, startingTime, endingTime) => {
        try {
            const response = await Axios.post(
                "UpdateVenueTime",
                {
                    screenId: screenId,
                    startingTime: startingTime,
                    endingTime: endingTime,
                }
            );
            console.log(response);
            setIsUpdated(true);
            toast.success("Venue Time Updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (err) {
            console.log(err);
            toast.error("Venue Time not Updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

  const updateAdDpSlots = async (screenId, AllowDpforAddSlot) => {
    try {
      const response = await Axios.post("/allowDpBookingForAdvertiserSlots", {
        screenId: screenId,
        value: AllowDpforAddSlot,
      });
      console.log(response);
      setIsUpdated(true);
      toast.success("Dp Slots Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
      toast.error("Dp Slots Not Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const updateDpSlots = async (screenId, DpSlotVisibility) => {
    if (DpSlotVisibility === "true") {
      DpSlotVisibility = true;
    } else if (DpSlotVisibility === "false") {
      DpSlotVisibility = false;
    }

    try {
      const response = await Axios.post("/updateDpSlotVisibility", {
        screenId: screenId,
        DpSlotVisibility: DpSlotVisibility,
      });
      console.log(response);
      setIsUpdated(true);
      toast.success("Dp Ad Slots Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
      toast.error("Dp Ad Slots Not Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const updateAdminSlots = async (screenId, AdminSlotVisibility) => {
    if (AdminSlotVisibility === "true") {
      AdminSlotVisibility = true;
    } else if (AdminSlotVisibility === "false") {
      AdminSlotVisibility = false;
    }
    try {
      const response = await Axios.post("/updateAdminSlotVisibility", {
        screenId: screenId,
        AdminSlotVisibility: Boolean(AdminSlotVisibility),
      });
      console.log(response);
      setIsUpdated(true);
      toast.success("Admin Slots Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      console.log(err);
      toast.error("Admin Slots Not Updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get("getAllScreens");
                setData(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

    fetchData();
  }, []);

  const handleCPSChange = (event, screenId) => {
    const { value } = event.target;

    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            CPS: value,
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };
  const handleDpSlots = (event, screenId) => {
    const { value } = event.target;

    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            AllowDpforAddSlot: value,
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };

  const handleAdDpSlots = (event, screenId) => {
    const { value } = event.target;

    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            DpSlotVisibility: value,
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };

  const handleAdminSlots = (event, screenId) => {
    const { value } = event.target;

    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            AdminSlotVisibility: value,
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };
  const handleEndTimeChange = (event, screenId) => {
    const { value } = event.target;

    console.log(value, "end time updatedd ------------------------");
    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            endingTime: value,
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };

  const handleSlotsChange = (event, screenId) => {
    const { value } = event.target;

    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            NO_OF_SLOTS: value,
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };

  const handleEditClick = (screenId) => {
    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            isEditing: !screen.isEditing,
            isSaved: false, // Set isSaved to false when edit icon is clicked
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };

  const handleSaveClick = (screenId) => {
    setData((prevData) => {
      const updatedData = {
        ...prevData,
        Screens: prevData.Screens.map((screen) => {
          if (screen.screenId === screenId) {
            return {
              ...screen,
              isEditing: false,
              isSaved: true,
            };
          }
          return screen;
        }),
      };

      return updatedData;
    });
  };

  const handleStartTimeChange = (event, screenId) => {
    const { value } = event.target;
    console.log(value, "start time updatedd ------------------------");
    setData((prevData) => {
      const updatedData = prevData.Screens.map((screen) => {
        if (screen.screenId === screenId) {
          return {
            ...screen,
            startingTime: value,
          };
        }
        return screen;
      });

      return {
        ...prevData,
        Screens: updatedData,
      };
    });
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  const filteredData = data.Screens?.filter((screen) => {
    const searchTerm = searchText.toLowerCase();
    const category = categoryFilter.toLowerCase();
    const screenId = screen.screenId.toString().toLowerCase();

    return (
      (screen.username.toLowerCase().includes(searchTerm) ||
        screenId.includes(searchTerm)) &&
      (category === "all" || screen.TYPE.toLowerCase() === category)
    );
  });

  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  // sleep mode code
  const [formData, setFormData] = useState({
    startingTime: "2023-10-02T10:00:00",
    endingTime: "2023-10-02T11:00:00",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  async function SleepMode(screenId, username, formData) {
    console.log(screenId, username, formData.startingTime, formData.endingTime);
    Websocket.emit("sleep-mode", {
      username: username,
      screenId: screenId,
      startingTime: formData.startingTime,
      endingTime: formData.endingTime,
    });
    Websocket.on("sleep-mood-response", (data) => {
      console.log("Received sleep-mood-response:", data);
    });
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex h-screen w-screen ">
        <Menu />
        <div className="w-full">
          <AdminHeader />
          <div className=" flex-grow w-[100%] h-[85%] overflow-y-scroll">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
            />

            <select value={categoryFilter} onChange={handleCategoryChange}>
              <option value="All">All Categories</option>
              <option value="Hotels">Hotels</option>
              <option value="Restaurants">Restaurants</option>
              <option value="Spa and Fitness Centers">
                Spa and Fitness Centers
              </option>
              <option value="Clubhouse">Clubhouse</option>
              <option value="Course">Course</option>
              <option value="Pro Shop">Pro Shop</option>
              <option value="Airport Lounges">Airport Lounges</option>
              <option value="Others">Others</option>
              {/* Add more category options as needed */}
            </select>
            <table className="w-[100%] border bg-white text-sm text-gray-500  overflow-x-hidden">
              <thead className="bg-gray-50 text-md ">
                <tr className="uppercase">
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    S/No.
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    username
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    screen_Id
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    screen_Name
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    screen_address
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    TYPE
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    TYPE_VAL
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    Slots/CPS/Venue-Time/DP-Slots
                  </th>
                  <th scope="col" className="py-4 font-medium text-gray-900">
                    Save
                  </th>
                  {/* <th
                                scope="col"
                                className="py-4 font-medium text-gray-900"
                            >
                                Sleep Mode On
                            </th> */}
                </tr>
              </thead>
              <tbody className="pb-10 text-center">
                {filteredData &&
                  filteredData.reverse().map((screen, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="w-fit">{screen.username}</td>
                      <td className="px-4">{screen.screenId}</td>
                      <td className="px-4">{screen.screenName}</td>
                      <td className="px-4">
                        {screen.SCREEN_ADDRESS_FROM_PHONE}
                      </td>
                      <td className="px-4">{screen.TYPE}</td>
                      <td className="px-4">{screen.TYPE_VAL}</td>
                      <td className="flex select-none gap-2 items-end w-full pt-6">
                        {screen.isEditing ? (
                          <>
                            <label htmlFor="" className="w-[80px] text-right">
                              Slots:
                            </label>
                            <input
                              type="text"
                              value={screen.NO_OF_SLOTS}
                              onChange={(event) => {
                                setSlotsUpdate(true);
                                handleSlotsChange(event, screen.screenId);
                              }}
                              placeholder="set new cps"
                              className="border pl-3 pr-3 outline-none"
                            />
                          </>
                        ) : (
                          <span>Slots: {screen.NO_OF_SLOTS}</span>
                        )}
                        <TiEdit
                          className="cursor-pointer"
                          size={20}
                          onClick={() => handleEditClick(screen.screenId)}
                        />
                      </td>
                      <td className="flex select-none gap-2 items-end">
                        {screen.isEditing ? (
                          <>
                            <label htmlFor="" className="w-[80px] text-right">
                              CPS:
                            </label>
                            <input
                              type="text"
                              value={screen.CPS}
                              onChange={(event) => {
                                setCpsUpdate(true);
                                handleCPSChange(event, screen.screenId);
                              }}
                              placeholder="set new cps"
                              className="border pl-3 pr-3 outline-none"
                            />
                          </>
                        ) : (
                          <span>CPS: {screen.CPS}</span>
                        )}
                        <TiEdit
                          className="cursor-pointer"
                          size={20}
                          onClick={() => handleEditClick(screen.screenId)}
                        />
                      </td>
                      <td className="flex select-none gap-2 items-end">
                        {screen.isEditing ? (
                          <>
                            <label htmlFor="" className="w-[80px] text-right">
                              Start Time:
                            </label>
                            <input
                              type="time"
                              value={screen.startingTime}
                              onChange={(event) => {
                                setStartTimeUpdate(true);
                                handleStartTimeChange(event, screen.screenId);
                              }}
                              placeholder="Set Start Time"
                              className="border pl-3 pr-3 outline-none"
                            />
                          </>
                        ) : (
                          <span>Start Time:{screen.startingTime}</span>
                        )}
                        <TiEdit
                          className="cursor-pointer"
                          size={20}
                          onClick={() => handleEditClick(screen.screenId)}
                        />
                      </td>
                      <td className="flex select-none gap-2 items-end pb-6">
                        {screen.isEditing ? (
                          <>
                            <label htmlFor="" className="w-[80px] text-right">
                              End Time:
                            </label>
                            <input
                              type="time"
                              value={screen.endingTime}
                              onChange={(event) => {
                                setEndTimeUpdate(true);
                                handleEndTimeChange(event, screen.screenId);
                              }}
                              placeholder="Set End Time"
                              className="border pl-3 pr-3 outline-none"
                            />
                          </>
                        ) : (
                          <span>End Time:{screen.endingTime}</span>
                        )}
                        <TiEdit
                          className="cursor-pointer"
                          size={20}
                          onClick={() => handleEditClick(screen.screenId)}
                        />
                      </td>
                      <td className="flex select-none gap-2 items-end">
                        {screen.isEditing ? (
                          <>
                            <label htmlFor="" className="w-[80px] text-right">
                              Dp-Advertiser-Solts:
                            </label>
                            <input
                              type="text"
                              value={screen.AllowDpforAddSlot}
                              onChange={(event) => {
                                setDpSlotUpdate(true);
                                handleDpSlots(event, screen.screenId);
                              }}
                              placeholder="Update Dp Slots"
                              className="border pl-3 pr-3 outline-none"
                            />
                          </>
                        ) : (
                          <span>Dp-Ad-Slots:{screen.AllowDpforAddSlot}</span>
                        )}
                        <TiEdit
                          className="cursor-pointer"
                          size={20}
                          onClick={() => handleEditClick(screen.screenId)}
                        />
                      </td>
                      <td className="flex select-none gap-2 items-end">
                        {screen.isEditing ? (
                          <>
                            <label htmlFor="" className="w-[80px] text-right">
                              Dp-Solts:
                            </label>
                            <input
                              type="text"
                              value={screen.DpSlotVisibility}
                              onChange={(event) => {
                                setAdDpSlotUpdate(true);
                                handleAdDpSlots(event, screen.screenId);
                              }}
                              placeholder="Update Dp Slots"
                              className="border pl-3 pr-3 outline-none"
                            />
                          </>
                        ) : (
                          <span>Dp-Slots:{screen.DpSlotVisibility}</span>
                        )}
                        <TiEdit
                          className="cursor-pointer"
                          size={20}
                          onClick={() => handleEditClick(screen.screenId)}
                        />
                      </td>
                      <td className="flex  select-none gap-2 items-end">
                        {screen.isEditing ? (
                          <>
                            <label htmlFor="" className="w-full text-right">
                              admin-Solts:
                            </label>
                            <input
                              type="text"
                              value={screen.AdminSlotVisibility}
                              onChange={(event) => {
                                setAdminSlotUpdate(true);
                                handleAdminSlots(event, screen.screenId);
                              }}
                              placeholder="Update Dp Slots"
                              className="border  pl-3 pr-3 outline-none"
                            />
                          </>
                        ) : (
                          <span className="">
                            admin-Slots:{screen.AdminSlotVisibility}
                          </span>
                        )}
                        <TiEdit
                          className="cursor-pointer"
                          size={20}
                          onClick={() => handleEditClick(screen.screenId)}
                        />
                      </td>
                      <td>
                        {!screen.isSaved ? (
                          <button
                            className="remove"
                            onClick={() => {
                              if (cpsUpdate === true && slotsUpdate === true) {
                                handleSaveClick(screen.screenId);
                                updateSlots(
                                  screen.screenId,
                                  screen.NO_OF_SLOTS
                                );
                                handleSubmit(screen.screenId, screen.CPS);
                              } else if (cpsUpdate === true) {
                                handleSaveClick(screen.screenId);
                                handleSubmit(screen.screenId, screen.CPS);
                              } else if (slotsUpdate === true) {
                                handleSaveClick(screen.screenId);
                                updateSlots(
                                  screen.screenId,
                                  screen.NO_OF_SLOTS
                                );
                              }
                              if (
                                startTimeUpdate === true ||
                                endTimeUpdate === true
                              ) {
                                handleSaveClick(screen.screenId);
                                updateVenueTime(
                                  screen.screenId,
                                  screen.startingTime,
                                  screen.endingTime
                                );
                              }
                              if (DpSlotUpdate === true) {
                                handleSaveClick(screen.screenId);
                                updateAdDpSlots(
                                  screen.screenId,
                                  screen.AllowDpforAddSlot
                                );
                              }
                              if (DpAdSlotUpdate === true) {
                                handleSaveClick(screen.screenId);
                                updateDpSlots(
                                  screen.screenId,
                                  screen.DpSlotVisibility
                                );
                              }
                              if (adminSlotUpdate === true) {
                                handleSaveClick(screen.screenId);
                                updateAdminSlots(
                                  screen.screenId,
                                  screen.AdminSlotVisibility
                                );
                              }
                            }}
                            disabled={!screen.isEditing}
                          >
                            Save
                          </button>
                        ) : (
                          <button className="remove" disabled>
                            Saved
                          </button>
                        )}
                      </td>
                      {/* <td className="w-fit">
                                        <label>Start Time:</label>
                                        <input
                                            type="datetime-local"
                                            name="startingTime"
                                            value={formData.startingTime}
                                            onChange={handleInputChange}
                                        />

                                        <label>End Time:</label>
                                        <input
                                            type="datetime-local"
                                            name="endingTime"
                                            value={formData.endingTime}
                                            onChange={handleInputChange}
                                        />
                                        <button onClick={() => { SleepMode(screen.screenId, screen.username, formData) }} className=" cursor-pointer remove"> Sleep Mode On</button>
                                    </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScreensDetails;
