import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/Dashboard";
import Ad_first from "../Advertise/Ad_first";

const Selector = ({ Ad, setAd }) => {
    const [role, setRole] = useState(sessionStorage.getItem("role"));
    return (
        (role == "advertiser") ?
            <Ad_first Ad={Ad} setAd={setAd} /> : <Dashboard />
    )
}

export default Selector